import { defineStore } from 'pinia'
import { promiseTimeout } from '@vueuse/core'
import type { QueueItem, Genre, Song } from '../types'
import { useQueueStore } from './queueStore'
import { useNuxtApp } from '#imports'

const contentState: any = () => {
	return {
		loading: false,
		content: null,
		error: null
	}
}

export const useMediaFileStore = defineStore('mediaStore', {
	state: () => (
		{
			audio: {
				...contentState(),
				selectedAudioFile: ''
			},
			images: contentState(),
			lyrics: contentState(),
			settings: {
				pitch: 0,
				volume: 70,
				vocals: false,
				variant: null as number | null,
				globalVocals: false
			}
		}
	),
	getters: {
		hasAudio: state => state.audio.content !== null,
		hasImages: state => state.images.content !== null,
		hasLyrics: state => state.lyrics.content !== null,
		pitchValue: state => state.settings.pitch,
		volumeAmount: state => state.settings.volume,
		allContentLoaded(): boolean {
			return this.hasAudio && this.hasImages && this.hasLyrics
		},
		isMediaLoading: state => state.audio.loading || state.images.loading || state.lyrics.loading,
		audioFileByType: (state) => {
			if (!state.audio.content) {
				return null
			}
			const firstInQueue = useQueueStore().firstInQueue
			state.settings.vocals = firstInQueue.vocals || state.settings.globalVocals
			const vocals = state.settings.vocals
			const audioFiles: any = state.audio.content
			if (audioFiles.length === 0) {
				return null
			}
			const fileType = vocals ? 'song-cover' : 'song-normal'
			return audioFiles.find((n: any) => n.type === fileType)?.file || audioFiles[0]?.file || null
		}
	},
	actions: {
		setAllLoading() {
			this.audio.loading = true
			this.images.loading = true
			this.lyrics.loading = true
		},
		nullifyFiles() {
			this.audio.content = null
			this.images.content = null
			this.lyrics.content = null
		},
		setPitch(pitch: number) {
			const queueStore = useQueueStore()
			this.$state.settings.pitch = pitch
			queueStore.updateQueueItemPitch(this.$state.settings.pitch)
		},
		setVocals(value: boolean, fromAccountSettings = false) {
			if (fromAccountSettings) {
				const globalVocals = useCookie<boolean>('globalVocals', { maxAge: 365 * 24 * 60 * 60 })
				globalVocals.value = value
				this.settings.globalVocals = value
				return
			}
			this.settings.vocals = value
			const { $singaApi } = useNuxtApp()
			const firstInQueue = useQueueStore().firstInQueue
			const vocalsValue = value ? 'cover' : 'karaoke'
			useEventEmit('player:userUpdatedVocals', vocalsValue)
			const { isLoggedIn } = useAuth()
			if (isLoggedIn.value) {
				$singaApi.Me.Preferences.put(firstInQueue.variant.id, { track: vocalsValue })
			}
		},
		getInitSettings() {
			const globalVocals = useCookie<boolean>('globalVocals')
			this.settings.vocals = globalVocals.value
		},
		/**
		 * Fetches the audio file for the song, and sets it to the audio player
		 *
		 * @param {number} songId - song id
		 * @param {number} variantId - variant id
		 * @param {number} pitch - pitch value
		 * @returns {Promise<any>} - response from the API
		 * @throws {Error} - error from the API
		 */
		async fetchAudio(songId: number, variantId: number, pitch: number) {
			const userStore = useUserStore()
			const useDemoPlayer = userStore.showDemoPlayer

			this.audio.loading = true
			try {
				const { $singaApi } = useNuxtApp()
				const audioResponse = ref()
				if (useDemoPlayer) {
					const response = await $singaApi.Media.getPreviewAudio(songId, variantId)
					audioResponse.value = response

					if (!audioResponse.value || !audioResponse.value.preview) {
						this.audio.error = new Error('No audio found')
						return Promise.reject(this.audio.error)
					}

					this.audio.content = [{ file: audioResponse.value.preview, type: 'song-normal' }]
					if (audioResponse.value.preview_vocals) {
						this.audio.content.push({
							file: audioResponse.value.preview_vocals,
							type: 'song-cover'
						})
					}
				} else {
					const response = await $singaApi.Media.getAudio(songId, variantId, pitch)
					audioResponse.value = response

					if (!audioResponse.value || !audioResponse.value.results || audioResponse.value.results.length === 0) {
						this.audio.error = new Error('No audio found')
						return Promise.reject(this.audio.error)
					}

					this.audio.content = audioResponse.value.results
				}
				const audioFile = this.audioFileByType
				this.audio.selectedAudioFile = audioFile
				const { $audioPlayer } = useNuxtApp()
				$audioPlayer.load(audioFile)
				this.audio.loading = false
				if (useDemoPlayer) {
					return audioResponse.value.preview
				} else {
					return audioResponse.value
				}
			} catch (error) {
				this.audio.loading = false
				console.log('audio error', error)
				this.audio.error = error
				return Promise.reject(error)
			}
		},
		/**
		 * Fetches the lyric file for the song
		 *
		 * @param {number} songId
		 * @param {number} variantId
		 */
		async fetchLyrics(songId: number, variantId: number) {
			const userStore = useUserStore()

			this.lyrics.loading = true
			try {
				const { $singaApi } = useNuxtApp()
				const lyrics = ref()
				if (userStore.showDemoPlayer) {
					const response = await $singaApi.Media.getPreviewLyrics(songId, variantId)
					if (!response) {
						this.lyrics.error = new Error('No lyrics found')
						return Promise.reject(this.lyrics.error)
					}
					lyrics.value = response
				} else {
					const response = await $singaApi.Media.getLyrics(songId, variantId)
					if (!response) {
						this.lyrics.error = new Error('No lyrics found')
						return Promise.reject(this.lyrics.error)
					}
					lyrics.value = response
				}

				this.lyrics.loading = false

				if (userStore.showDemoPlayer) {
					this.lyrics.content = lyrics.value
				} else {
					this.lyrics.content = lyrics.value.lyrics
				}
			} catch (error) {
				this.lyrics.loading = false
				this.lyrics.error = error
				useEventEmit('playerEvent:error')
				return Promise.reject(error)
			}
		},
		/**
		 * Fetches the images for the song variant
		 *
		 * @returns {Promise<void>} - resolves when images are fetched
		 * @param {number} songId
		 * @param {number} variantId
		 */
		async fetchImages(songId: number, variantId: number) {
			this.images.loading = true
			if (variantId === null || variantId === undefined) {
				this.images.loading = false
				return Promise.reject(new Error('No variant id'))
			}
			try {
				const { $singaApi, $viewport } = useNuxtApp()
				const response = await $singaApi.Media.getVariantImages(songId, variantId)
				this.images.loading = false
				if (response.length === 0) {
					this.images.loading = false
					this.images.error = new Error('No images found')
					return Promise.reject(this.images.error)
				}
				this.images.content = response.map((n: Images) => {
					return $viewport.isLessThan('tablet') ? n.medium?.url || n.large?.url || n.small?.url : n.large?.url || n.medium?.url || n.small?.url
				})
			} catch (error) {
				this.images.loading = false
				this.images.error = error
				return Promise.reject(error)
			}
		},
		/**
		 * Fetches all the media files for a song variant
		 *
		 * @param {QueueItem} songEntry - Song to play
		 */
		async getSongMedia(songEntry: QueueItem, errorTranslation: string) {
			this.setAllLoading()
			if (songEntry && songEntry.entry && songEntry.variant) {
				this.audio.loading = true
				const variant = songEntry.variant
				const songId = songEntry.entry.id
				const variantId = variant.id

				this.settings.pitch = songEntry.pitch
				this.settings.variant = variantId

				try {
					await Promise.all([
						this.fetchAudio(songId, variantId, this.settings.pitch),
						this.fetchLyrics(songId, variantId),
						this.fetchImages(songId, variantId)
					])
				} catch (error) {
					const { nextSong } = useQueueStore()
					const { $oruga } = useNuxtApp()

					$oruga.notification.open({
						message: errorTranslation,
						variant: 'warning'
					})
					await promiseTimeout(3000)
					nextSong()
				}
			}
		},
		/**
		 * Artist images for all the song's artists
		 *
		 * @param artists - array of song's artists
		 * @returns {Promise<any>} - Map of to image urls
		 */
		mapArtistsToResponsesToUrls(artists: any[]) {
			const imageResponsesToUrls = function (promises: any) {
				// take array of responses as parameter
				// and map each to response to array of image urls
				return promises
					.map(function (response: any) {
						return response?.results
							.filter((song: Song) => {
								return song.image !== null && song.image.large && song.image.large.url
							})
							.map((n: any) => n.image.large.url)
					})
					.reduce(function (a: any, b: any) {
						// make the arrays of urls into one array
						return a.concat(b)
					}, [])
			}
			return new Promise((resolve, reject) => {
				// filter artists by is_featuring boolean
				const featuringArtists: any[] = []
				const mainArtists = artists.filter((artist) => {
					if (artist.is_featuring) {
						featuringArtists.push(artist)
					}
					return !artist.is_featuring
				})

				// map main artists to their image responses
				const mainArtistImagePromises = Promise.all(
					mainArtists.map((artist) => {
						const { $singaApi } = useNuxtApp()
						return $singaApi.Artists.getArtistImages(
							{
								id: artist.id,
								params: {
									page_size: 100
								}
							}
						)
					})
				)
				// map featuring artists to their image responses
				const featuringArtistImages = Promise.all(
					featuringArtists.map((artist) => {
						const { $singaApi } = useNuxtApp()
						return $singaApi.Artists.getArtistImages(
							{
								id: artist.id,
								params: {
									page_size: 100
								}
							}
						)
					})
				)
				Promise.all([mainArtistImagePromises, featuringArtistImages])
					.then((response) => {
						const resp = response.map(imageResponsesToUrls)
						const mainImages = resp[0]
						const featImages = resp[1]

						if (mainImages.length > 1) {
							const finalImageUrls: any[] = []
							for (let i = 1; i <= 10; i++) {
								// get and delete random image from artist images and push it to results
								const result = mainImages.splice(Math.floor(Math.random() * mainImages.length), 1)[0]
								finalImageUrls.push(result)
								// for every 2 main artist images, get featuring artist image and delete random image from artist images and push it to results

								if ((i + 1) % 3 === 0 && featImages.length > 0) {
									const featResult = featImages.splice(Math.floor(Math.random() * featImages.length), 1)[0]
									finalImageUrls.push(featResult)
									i++
								}
								// if main artist images are empty, stop pushing featuring images as well, for the sake of image ratio
								if (mainImages.length === 0) {
									break
								}
							}

							resolve(finalImageUrls)
						} else {
							// reject promise with detailed response for caller

							reject({ detail: 'NO_ARTIST_IMAGES' })
						}
					})
					.catch((error) => {
						reject(error)
					})
			})
		},
		/**
		 * Fetches the genre images for a song variant
		 *
		 * @param genres - array of song's genres
		 */
		genresImageUrls(genres: Genre[]) {
			return new Promise(function (resolve, reject) {
				if (!Array.isArray(genres)) {
					reject(new Error('Invalid input: genres must be an array'))
					return
				}

				const imageBankIdArray = genres.map(function (genre) {
					if (!genre) {
						reject(new Error('Falsy genre object'))
						return null
					}
					return genre.imagebank
				})

				Promise.all(
					imageBankIdArray.map((genre) => {
						if (!genre) {
							reject(new Error('Invalid genre object'))
							return null
						}
						const { $singaApi } = useNuxtApp()
						return $singaApi.Genres.getImages(
							{
								id: genre.id,
								params: {
									page_size: 100
								}
							}
						)
					}))
					.then((imageResponses: any[]) => {
						if (imageResponses && imageResponses.length > 0) {
							const imageUrls = imageResponses
								// responses to image result arrays
								.map(n => n.results)
								.reduce((a, b) => a.concat(b), [])
								// filter results with no large images available
								.filter((n: any) => n.image !== null && n.image.large && n.image.large.url)
								// objects to urls
								.map((n: any) => n.image.large.url)
								// shuffle all genre images
								.sort(() => Math.random() - 0.5)
								// get first 10
								.slice(0, 10)
							resolve(imageUrls)
						}
					})
					.catch((err) => {
						reject(err)
					})
			})
		},
		/**
		 * Fetches the player background images for a song variant\n
		 * If the song has artist images, it will return those\n
		 * If the song has no artist images, it will return genre images
		 *
		 * @param {Song} songObject
		 * @returns {Promise<string[]>} - array of image urls
		 */
		getImagesForSong(songObject: Song) {
			return this.mapArtistsToResponsesToUrls(songObject.artists)

				.catch((error: any) => {
					if (error.detail === 'NO_ARTIST_IMAGES') {
						return this.genresImageUrls(songObject.genres)
					} else { return Promise.reject(error) }
				})
		}
	}
})
