<template lang="pug">
.cookie-consent(:class="showMiniPlayer ? 'player-bar-visible' : ''")
	.cookie-consent-content
		span.cookie-consent-text
			|{{ t('cookies.bodyText') }}
			NuxtLink.link(:to="localePath('/privacy/')") {{ t('general.privacyPolicy') }}
		SingaButton.is-full.is-primary.button(@click="acceptCookies") {{ t('general.accept') }}
</template>

<script setup lang="ts">
import { usePlayerStore } from '~/pinia/player/karaokePlayer'

const { t } = useI18n()
const localePath = useLocalePath()
const karaokeStore = usePlayerStore()
const { showMiniPlayer } = storeToRefs(karaokeStore)

const cookieConsent = useCookieConsent()

const acceptCookies = () => {
	cookieConsent.value = true
}
</script>

<style lang="sass" scoped>
.cookie-consent
	position: fixed
	bottom: 20px
	right: 20px
	background-color: $transparent-white-8
	backdrop-filter: blur(40px)
	padding: $spacing-16 $spacing-24 $spacing-16 $spacing-24
	text-align: start
	border-radius: $radius-default
	width: 291px
	min-height: 64px
	z-index: 1000
	display: flex
	flex-direction: column
	justify-content: center
	transition: all 0.5s ease-in-out

	&.player-bar-visible
		bottom: 100px
	.cookie-consent-content
		display: flex
		flex-direction: column
		justify-content: center
		align-items: end
		.cookie-consent-text
			color: $color-grey-10
			@include fontSize(s)
			margin-bottom: $spacing-16

.link
	text-decoration: underline !important
	color: $color-grey-10
	&:hover
		color: $color-grey-20

.button
	margin: 0 !important
</style>
