<template lang="pug">
.wrapper
	.SongVersions(v-if="!isSongSettings")
		header.modal-card-head
			p.version-header {{ t('songVersions.select') }}
			p.version-subheader {{ t('songVersions.select.subheader') }}
			button.delete(type="button" @click="$emit('close')")
		.modal-version.modal-section(
			v-if="highlightedVersion && !versionSelectorOpen"
			:class="[selectedVersionsMap[highlightedVersion.id] ? 'is-selected' : '']"
			@click="closeSongVersions(highlightedVersion); changeVersion(highlightedVersion)")
			.version-left
				span.label-text {{ variantName(highlightedVersion.catalog.name, highlightedVersion.is_original) }}
				.tags(v-if="highlightedVersion.tags")
					SongTags(:tags="highlightedVersion.tags" :notClickable="true" :popular="isMostPopularVersion(highlightedVersion)")
			.version-right
				SingaIcon.version-checkmark(v-if="selectedVersionsMap[highlightedVersion.id]" size="large" icon="checkmark-circle")
				SingaProgressBar.row-preview(v-if="isCurrentlyPlaying(highlightedVersion) && !previewEnded && progress < 99" @click.stop="stopPreview()" :completed="progress" :isSongListItem="true")
					span {{ t('song.preview') }}
				SingaButton.versions-preview-button.is-regular(v-if="!isCurrentlyPlaying(highlightedVersion)|| previewEnded" @click.stop="previewVersion(highlightedVersion)" :disabled="isAudioPlaying || playerLoading")
					span {{ t('song.preview') }}
				SingaButton.is-regular.is-primary(@click="closeSongVersions(highlightedVersion); changeVersion(highlightedVersion)" :class="{ 'no-margin': isCurrentlyPlaying(highlightedVersion) && !previewEnded && progress < 99 }")
					span {{ t('songVersions.selectButton') }}
		div
			.versions-wrap
				.modal-section.version-settings.selector-open(v-if="!versionSelectorOpen" @click="versionSelectorOpen = !versionSelectorOpen")
					span.label-text {{ t('songVersions.seeOtherVersions') }}
					SingaButton.is-full-ghost(id="openedVersionSelect" :icon-right="versionSelectorOpen ? 'chevron-up' : 'chevron-down'" size="medium")
				Transition(name="slide-fade")
					.versions(v-if="versionSelectorOpen")
						.versions-item-wrap(v-if="!loading")
							.version-item.modal-section.modal-version(v-for="(version, index) in sortedVersionsWithPreferences" :key="version.id" @click="closeSongVersions(version); changeVersion(version)" :class="[selectedVersionsMap[version.id] ? 'is-selected' : '']" :style="{ animationDelay: `${index * 50}ms` }")
								.version-left
									span.label-text {{ variantName(version.catalog.name, version.is_original) }}
									.tags(v-if="version.tags")
										SongTags(:tags="version.tags" :notClickable="true" :popular="isMostPopularVersion(version)")
								.version-right
									SingaIcon.version-checkmark(v-if="selectedVersionsMap[version.id]" size="large" icon="checkmark-circle")
									SingaProgressBar.row-preview(v-if="isCurrentlyPlaying(version) && !previewEnded && progress < 99" @click.stop="stopPreview()" :completed="progress" :isSongListItem="true")
										span {{ t('song.preview') }}
									SingaButton.versions-preview-button.is-regular(v-if="!isCurrentlyPlaying(version)|| previewEnded" @click.stop="previewVersion(version)" :disabled="isAudioPlaying || playerLoading")
										span {{ t('song.preview') }}
									SingaButton.is-regular.is-primary(@click="closeSongVersions(version); changeVersion(version)" :class="{ 'no-margin': isCurrentlyPlaying(version) && !previewEnded && progress < 99 }")
										span {{ t('songVersions.selectButton') }}
						.loading-wrap(v-else)
							CommonLoadingAnimation.loading(:timeOut="0" :size="24")
		.modal-footer.modal-section(v-if="!showDemoPlayer  || hasOriginal")
			.version-preference(v-if="!showDemoPlayer")
				.preference-left
					span.preference-title {{ t('songVersions.preferAutoSelect') }}
					span.preference-body {{ t('songVersions.preferAutoSelect.details') }}
				.preference-right
					SingaSwitch(:modelValue="versionAutoselect" @toggle="setAutomaticSelection" :disabled="loading")
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import type { PropType } from 'vue'
import { useAudioPlayerStore } from '~/pinia/player/audioPlayerStore'
import { usePreviewPlayerStore } from '~/pinia/player/previewPlayerStore'
import { useVariantStore } from '~/pinia/variantStore'
import { usePlayerStore } from '~/pinia/player/karaokePlayer'

const { $singaApi, $previewPlayer, $oruga } = useNuxtApp()
const { contentMarket } = useGeoLocationStore()
const { t } = useI18n()
const versionStore = useVariantStore()
const { versionAutoselect } = storeToRefs(versionStore)

const loading = ref(false)
const preferences = ref<VariantPreference[] | null>(null)

const versionSelectorOpen = ref(false)

const { playPreview, stopPreview, resetPreview, currentlyPlayingVersion, progress, previewEnded } = useVariantPreview()
const previewPlayerStore = usePreviewPlayerStore()
const queueStore = useQueueStore()
const { firstInQueue } = storeToRefs(queueStore)

const { playing: isPreviewPlaying } = storeToRefs(previewPlayerStore)
const userStore = useUserStore()
const { showDemoPlayer } = storeToRefs(userStore)
const audioStore = useAudioPlayerStore()

const karaokeStore = usePlayerStore()
const { playerLoading } = storeToRefs(karaokeStore)

const { isAudioPlaying } = storeToRefs(audioStore)

const isCurrentlyPlaying = (version: Variant) => {
	return currentlyPlayingVersion.value === version.id
}

const previewClicked = ref(false)

const props = defineProps({
	versions: {
		required: true,
		type: Array as PropType<Variant[]>
	},
	song: {
		required: true,
		type: Object as PropType<Song>
	},
	queueEntryID: {
		type: String,
		default: null
	},
	selectedVersionID: {
		type: Number,
		default: null
	},
	isSongSettings: {
		type: Boolean,
		default: false
	}
})

const selectedVersion = ref(props.selectedVersionID)

// Compare to the first in queue if this is not the song settings view
const isSelectedVersion = (version: Variant) => {
	if (!props.isSongSettings) {
		return version.id === firstInQueue.value?.variant?.id
	} else {
		return version.id === selectedVersion.value
	}
}

const selectedVersionsMap = computed(() => {
	const map: Record<number, boolean> = {}
	props.versions.forEach((version: Variant) => {
		map[version.id] = isSelectedVersion(version)
	})
	return map
})

const versionsWithTags = computed(() => {
	return props.versions.map((n: any) => {
		return {
			...n,
			tags: {
				has_duet: n.is_duet,
				has_explicit: n.is_explicit,
				has_original: n.is_original,
				has_plus: n.is_plus,
				has_cover: n.has_cover
			}
		}
	}).filter((version: Variant) => !!version && version.available_markets.includes(contentMarket.market || ''))
})

const hasOriginal = computed(() => versionsWithTags.value.find((item: any) => item.tags.has_original) !== undefined)
const mostPopularVersion = ref()
const highlightedVersion = computed(() => hasOriginal.value ? versionsWithTags.value.find((item: any) => item.tags.has_original) : mostPopularVersion.value)

const sortVersions = (versions: Variant[]) => {
	// Determine most popular version overall
	mostPopularVersion.value = versions.reduce((prev, current) => {
		return (!prev || parseFloat(current.popularity) > parseFloat(prev.popularity)) ? current : prev
	}, versions[0])

	// Sort for the selector
	return versions.sort((a, b) => {
		if (a.is_original !== b.is_original) {
			return a.is_original ? -1 : 1
		}
		const popularityA = a.popularity === '0.00' ? -1 : parseFloat(a.popularity)
		const popularityB = b.popularity === '0.00' ? -1 : parseFloat(b.popularity)
		if (popularityB === popularityA) {
			// Sort alphabetically by name if popularity is the same
			return a.catalog.name.localeCompare(b.catalog.name)
		}
		return popularityB - popularityA
	})
}

const variantName = (name: string, original: boolean) => original ? t('songVersions.originalRecordingBy', { name }) : t('songVersions.coverBy', { name })

const changeVersion = async (version: Variant) => {
	const queueStore = useQueueStore()
	const { editQueueItem } = queueStore
	editQueueItem({ entryID: props.queueEntryID, variant: version })
	useEventEmit('songSettings:versionChanged', version)
	selectedVersion.value = version.id
}

const sortedVersions = computed(() => sortVersions(versionsWithTags.value.slice()))
sortVersions(versionsWithTags.value.slice())

const sortedVersionsWithPreferences = computed(() => {
	if (preferences.value === null) {
		return sortedVersions.value
	}
	const variantsWithPreferences = versionsWithTags.value.map(variant => ({
		...variant,
		user_preferences: preferences.value?.find((preference: VariantPreference) => preference.variant === variant.id) || {
			pitch: 0
		}
	}))
	// If there is a selected version, put it on top
	if (selectedVersion.value) {
		const selected = variantsWithPreferences.find(variant => variant.id === selectedVersion.value)
		if (selected) {
			return [selected, ...variantsWithPreferences.filter(variant => variant.id !== selectedVersion.value)]
		}
	}
	return sortVersions(variantsWithPreferences)
})

const isMostPopularVersion = computed(() => (version: Variant) => version.id === mostPopularVersion.value.id)

const previewVersion = (songVersion: Variant) => {
	previewClicked.value = true
	if (!currentlyPlayingVersion.value || songVersion.id !== currentlyPlayingVersion.value) {
		playPreview(songVersion, { id: props.song.resource_id, name: props.song.name }, 'Song version selector')
	} else {
		stopPreview()
	}
}

watch(isPreviewPlaying, (newValue: boolean) => {
	if (!newValue) {
		resetPreview()
	}
})

const emit = defineEmits(['close'])

const setAutomaticSelection = (value: boolean) => {
	versionAutoselect.value = value
	if (value) {
		emit('close', { version: mostPopularVersion })
	} else {
		return
	}
	const toastMessage = value ? t('songVersions.automaticSelected') : t('songVersions.automaticDeselected')
	$oruga.notification.open({
		message: toastMessage,
		icon: 'checkmark-outline',
		iconSize: 'medium'
	})
}

const closeSongVersions = (version: Variant) => {
	if (previewClicked.value) {
		previewClicked.value = false
		return
	}
	emit('close', { version })
}

onMounted(async () => {
	loading.value = true
	if (!props.isSongSettings) {
		useEventEmit('songVersionModal:toggle')
	}
	if (!showDemoPlayer.value) {
		const data = await $singaApi.Me.Preferences.get(props.versions.map(n => n.id))
		const prefs = data.map((n: any) => {
			return { ...n, vocals: n.track !== 'karaoke' }
		})
		preferences.value = prefs
	}
	loading.value = false
})

onUnmounted(() => {
	if ($previewPlayer.isPlaying()) {
		stopPreview()
	}
})
</script>

<style lang="sass" scoped>
.wrapper
	display: flex
	justify-content: center
	align-items: center
.version-preference
	display: flex
	justify-content: space-between
	align-items: center
	.preference-left
		max-width: 500px
		.preference-title
			@include font(basier, semiBold)
			display: block
			margin-bottom: $spacing-4
			color: $color-grey-10
		.preference-body
			max-width: 368px
			display: inline-block
			color: $color-grey-30
			@include fontSize(xs)
.modal-section
	background-color: $transparent-white-8
	border-radius: $radius-default
	margin-bottom: $spacing-8
	margin-top: $spacing-16
	padding: $spacing-16 $spacing-24 $spacing-16 $spacing-24
	&.is-selected
		background-color: $color-green-100
		border: 1px solid $color-green-70
.modal-version
	display: flex
	justify-content: space-between
	flex-wrap: wrap
	.version-left
		display: flex
		flex-direction: column
		justify-content: center
		position: relative
		max-width: 100%
		@media (min-width: $tablet)
			max-width: 55%
			span.label-text
				white-space: nowrap
				overflow: hidden
				text-overflow: ellipsis
				max-width: 100%
		.tags
			margin: $spacing-4 0 $spacing-4 0
	.version-right
		position: relative
		display: none
		@media (min-width: $tablet)
			display: flex
			flex-direction: row
			justify-content: space-between
			align-items: center

		.row-preview
			margin-right: $spacing-8
			height: 48px
			width: 114px
			@container (min-width: 600px)
				display: flex
		button
			min-height: 48px
			min-width: 94px
			height: 48px
			width: 94px
		:is(button:first-of-type)
			margin-right: $spacing-8
		.no-margin
			margin-right: 0!important
.versions-preview-button
	background-color: $color-grey-70
	width: 100%
	height: 40px
	border-color: transparent
	&:hover
		background-color: $color-grey-80
		color: $color-white
.version-wrap:hover
	.version
		background-color: $transparent-white-12
		&.is-selected
			background-color: $color-grey-70

.version
	position: relative
	padding: $spacing-24 $spacing-16
	cursor: pointer
	display: flex
	flex-direction: column
	align-items: center
	max-height: 104px
	border-radius: $radius-default $radius-default 0 0
	background-color: $transparent-white-8
	height: 98px
	&.is-selected
		background-color: $transparent-white-16
.publisher
	color: $color-grey-10
	@include font(basier, medium)
.version-popular, .version-original
	position: absolute
	top: -12px
	&.queue-popular
		top: -$spacing-4
	right: 12px
	border-radius: $radius-round
	@include font(basier, medium)
	@include fontSize(xs)
	padding: $spacing-4 $spacing-16
	height: $spacing-24
	display: flex
	align-items: center
.version-header
	@include fontSize(xxl)
	@include font(basier, bold)
	padding-bottom: $spacing-8
	@media (min-width: $tablet)
		@include fontSize(5xl)
.version-subheader
	@include font(basier, regular)
	color: $color-grey-50
	@media (min-width: $tablet)
		@include fontSize(xl)
.modal-card-head
	display: flex
	margin-bottom: 1px
	padding: 20px 0
	@media (min-width: $mobile-small)
		padding: 20px
		align-items: center
		text-align: center
	@media (min-width: $tablet)
		padding-bottom: $spacing-40
.SongVersions
	background-color: $color-grey-90
	border-radius: $radius-default
	padding: 20px
	z-index: 9999
	flex: 1
	@media (min-width: $tablet)
		max-width: 677px
	.delete
		position: absolute
		top: 0
		right: 0
		max-width: 28px
		max-height: 28px
		width: 28px
		height: 28px
		&:hover
			background-color: $color-grey-30
		.song-settings
			padding: 0
	.modal-card-body
		overflow: hidden
.b-tooltip:not(.queue-tip)
	width: 100% !important

.versions-wrap
	animation: fade 150ms

@keyframes fade
	from
		opacity: 0
	to
		opacity: 1

.version-item
	margin-bottom: $spacing-8
	opacity: 0
	animation: fade 300ms forwards
	&:hover
		.version
			background-color: $transparent-white-8

.version-checkmark
	color: $primary
	position: absolute
	left: -48px
	top: 50%
	transform: translateY(-50%)

.toggle
	background-color: $color-grey-80

.song-settings
	.version
		border-radius: $radius-default !important
		max-height: 90px
	.version-checkmark
		top: 50%
		color: $color-green-70
		transform: translate(0%, -50%)

.settings-version-preview
	position: absolute
	right: $spacing-16
	top: 50%
	transform: translateY(-50%)
	display: flex
	align-items: center
	justify-content: center
	width: 30px
	height: 30px
	:deep(.iconify--ion)
		color: $color-grey-50
		&:hover
			color: $color-grey-60

	:deep(.b-tooltip.is-top .tooltip-content)
		padding-right: 220px !important
		color: $color-grey-30
		background: $color-grey-90

.loading-wrap
	height: 35px
.version-settings
	display: flex
	width: 100%
	align-items: center
	justify-content: space-between

	.field
		margin: 0
		width: 100%
	&:deep(.icon)
		color: $transparent-white-48

.selector-open
	cursor: pointer
	padding: $spacing-16 $spacing-24 $spacing-16 $spacing-24
	:deep(button)
		padding: 0

.label-text
	@include fontSize(m)
	@include font(basier, medium)
	max-width: 100%

.versions
	overflow: auto
	max-height: 35vh
	@media (min-width: 400px)
		max-height: 40vh
	@media (min-width: $tablet)
		max-height: 50vh
	@media (min-height: 1000px)
		max-height: 50vh

.fade-enter-active, .fade-leave-active
	position: absolute !important
	transition: opacity 0.5s
.slide-fade-enter-active
	transition: all 0.1s ease-out

.slide-fade-leave-active
	transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1)

.slide-fade-enter-from,
.slide-fade-leave-to
	transform: translateY(20px)
	opacity: 0
</style>
