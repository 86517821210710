import { parse } from 'cookie-es'
import { destr } from 'destr'
import type { FetchContext } from 'ofetch'

export function useFetchApiClient() {
	const runtimeConfig = useRuntimeConfig()
	const apiVersion = '/v1.4'
	const clientCookieHeaders = useRequestHeaders(['Cookie'])
	const debug = process.env.NODE_ENV === 'development'
	const authTokens = useTokenCookie()
	const auth = useAuth()
	const nuxtApp = useNuxtApp()
	const apiClient = $fetch.create({
		retry: 1,
		keepalive: true,
		retryStatusCodes: [400, 401, 402, 403],
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		},
		baseURL: (import.meta.server ? runtimeConfig.SINGA_API_BASE_URL : runtimeConfig.public.SINGA_API_URL) + apiVersion,
		onRequest({ request, options }: FetchContext) {
			if (debug) {
				console.log('Starting Request', JSON.stringify(request))
			}

			let sng_tokens
			const tokens = options.params?.tokens || options.query?.tokens || ''

			if (options.query) {
				const { tokens, ...restQuery } = options.query
				options.query = restQuery
			}

			if (options.params) {
				const { tokens, ...restParams } = options.params
				options.params = restParams
			}

			if (import.meta.client) {
				sng_tokens = authTokens.value
			} else {
				const tokens = clientCookieHeaders.cookie ? parse(clientCookieHeaders.cookie) : undefined
				if (tokens && typeof tokens.sng_tokens === 'string') {
					const sngTokenValue = tokens.sng_tokens.trim() // Remove any leading/trailing spaces
					sng_tokens = destr(sngTokenValue) // Convert the string to an object
				} else {
					sng_tokens = tokens?.sng_tokens // Assign whatever value is there if it's not a string
				}
			}

			if (tokens !== '') {
				sng_tokens = tokens
			}

			if (sng_tokens && options.headers) {
				options.headers = new Headers({
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'Authorization': `${sng_tokens.token_type} ${sng_tokens.access_token}`
				})
			}

			// Handle responseType in case the response is not JSON
			if (options.responseType) {
				options.headers.set('Accept', options.responseType === 'json' ? 'application/json' : '*/*')
			}
		},
		onResponse(ctx: FetchContext) {
			// console.info('Response:', ctx)
		},
		async onRequestError(ctx: FetchContext) {
			if (ctx.error?.name === 'AbortError') {
				console.info('Request was aborted, likely due to navigation or cancellation:', ctx)
				return // Ignore abort errors, as they are expected in some cases
			}
			console.error('Request Error:', ctx)
		},
		async onResponseError(ctx: FetchContext) {
			await nuxtApp.runWithContext(async () => {
				if (ctx.response?.status === 401 && authTokens.value) {
					try {
						const response: any = await auth.refreshTokens(clientCookieHeaders)

						if (response) {
							authTokens.value = typeof response === 'string' ? JSON.parse(response) : response
						}
					} catch (err) {
						console.error('Failed to refresh token:', err)
					}
				}
			})
		}
	})
	return apiClient
}
