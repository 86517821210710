import { SongNotificationsAddedToQueue } from '#components'
import { useNuxtApp } from '#imports'
import type { Song } from '~~/types'

export const useSongs = () => {
	const { $singaApi } = useNuxtApp()
	const { resolveSongObject } = useResolve()

	const resolveSongData = async (slug: string, songSlug: string, hash: string) => {
		return await resolveSongObject(slug, songSlug, hash)
	}

	const getSongObject = async (slug: string, songSlug: string, hash: string) => {
		const { getStateItem } = useContentCacheStore()
		const id = `${slug}/${songSlug}/${hash}`
		if (getStateItem.slug === id) {
			return getStateItem.item as Song
		}
		return await resolveSongData(slug, songSlug, hash)
	}

	const getParsedSongLyrics = async (songId: number, songVariantId: number) => {
		try {
			const response = await $singaApi.Media.getParsedLyrics(songId, songVariantId) as { lyrics: string[] }
			return response.lyrics
		} catch (e) {
			console.error('Failed to get parsed lyrics', e)
			return null
		}
	}

	const loadArtistSongs = async (artistId: number, params: any = {}) => {
		const { loadSongs } = useSonglists({
			songlistApiResource: $singaApi.Artists.artistSongs,
			params: { page_size: 6, sort: 'top', ...params }
		})
		return await loadSongs(params, artistId)
	}

	const promptAddedToQueue = (songName: string, songLink: string) => {
		const { $oruga } = useNuxtApp()
		$oruga.notification.open({
			component: SongNotificationsAddedToQueue,
			props: {
				songName,
				songLink
			}
		})
	}

	return {
		getSongObject,
		getParsedSongLyrics,
		loadArtistSongs,
		promptAddedToQueue
	}
}
