<template lang="pug">
div
	.song-settings
		.versions-item-wrap(v-if="!loading")
			.version-item(v-for="version in sortedVersionsWithPreferences" :key="version.id" @click="closeSongVersions(version); changeVersion(version)")
				.version(:class="{'original': version.is_original, 'is-selected': selectedVersionsMap[version.id]}")
					template(v-if="version.is_original")
						.version-original {{ t('songVersions.original') }}
					.version-popular.queue-popular(v-if="isMostPopularVersion(version)") {{ t('songVersions.popular') }}
					.version-selected
						SingaIcon.version-checkmark(v-if="selectedVersionsMap[version.id]" size="large" icon="checkmark-circle")
					span.publisher {{ version.catalog.name }}
					.version-tags-and-preview
						.version-tags.settings-version-tags
							span.tag(v-for="tag in version.tags.filter((t:string) => t !== 'Original')" :class="{'is-selected': selectedVersionsMap[version.id]}" :key="tag" v-show="tag") {{tag}}
						.settings-version-preview
							SingaTooltip.queue-tip(:content="t('songVersions.disabled')" v-if="playing")
								SingaButton.is-full-ghost(v-if="!isCurrentlyPlaying(version)|| previewEnded" icon-left="volume-high-outline" @click.stop="previewVersion(version)" :disabled="isAudioPlaying || playerLoading")
								SingaProgressCircle(
									v-if="isCurrentlyPlaying(version)&& !previewEnded && progress < 99"
									@click.stop="stopPreview"
									color-back="rgba(255, 255, 255, 0.12)"
									colorUnfilled="#999999"
									size="30"
									stopIconSize="11"
									stopIconColor="#999999"
									:value="progress"
									strokeWidth="10"
									svgBackground="transparent"
								)
							div(v-else)
								SingaButton.is-full-ghost(v-if="!isCurrentlyPlaying(version)|| previewEnded" icon-left="volume-high-outline" @click.stop="previewVersion(version)" :disabled="isAudioPlaying || playerLoading")
								SingaProgressCircle(
									v-if="isCurrentlyPlaying(version)&& !previewEnded && progress < 99"
									@click.stop="stopPreview"
									color-back="rgba(255, 255, 255, 0.12)"
									colorUnfilled="#999999"
									size="30"
									stopIconSize="11"
									stopIconColor="#999999"
									:value="progress"
									strokeWidth="10"
									svgBackground="transparent"
								)
		.loading-wrap(v-else)
			CommonLoadingAnimation.loading(:timeOut="0" :size="24")
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import type { PropType } from 'vue'
import { useAudioPlayerStore } from '~/pinia/player/audioPlayerStore'
import { usePreviewPlayerStore } from '~/pinia/player/previewPlayerStore'
import { usePlayerStore } from '~/pinia/player/karaokePlayer'

const { $singaApi, $previewPlayer } = useNuxtApp()
const { contentMarket } = useGeoLocationStore()
const { t } = useI18n()

const loading = ref(false)
const preferences = ref(null as any)

const { playPreview, stopPreview, resetPreview, currentlyPlayingVersion, progress, previewEnded } = useVariantPreview()
const audioPlayerStore = useAudioPlayerStore()
const previewPlayerStore = usePreviewPlayerStore()

const { playing } = storeToRefs(audioPlayerStore)
const { playing: isPreviewPlaying } = storeToRefs(previewPlayerStore)
const queueStore = useQueueStore()
const { firstInQueue } = storeToRefs(queueStore)
const userStore = useUserStore()
const { showDemoPlayer } = storeToRefs(userStore)
const audioStore = useAudioPlayerStore()

const karaokeStore = usePlayerStore()
const { playerLoading } = storeToRefs(karaokeStore)

const { isAudioPlaying } = storeToRefs(audioStore)

const isCurrentlyPlaying = (version: Variant) => {
	return currentlyPlayingVersion.value === version.id
}

const previewClicked = ref(false)

const props = defineProps({
	versions: {
		required: true,
		type: Array as PropType<Variant[]>
	},
	song: {
		required: true,
		type: Object as PropType<Song>
	},
	queueEntryID: {
		type: String,
		default: null
	},
	selectedVersionID: {
		type: Number,
		default: null
	},
	isSongSettings: {
		type: Boolean,
		default: false
	}
})

const selectedVersion = ref(props.selectedVersionID)

// Compare to the first in queue if this is not the song settings view
const isSelectedVersion = (version: Variant) => {
	if (!props.isSongSettings) {
		return version.id === firstInQueue.value?.variant?.id
	} else {
		return version.id === selectedVersion.value
	}
}

const selectedVersionsMap = computed(() => {
	const map: Record<number, boolean> = {}
	props.versions.forEach((version: Variant) => {
		map[version.id] = isSelectedVersion(version)
	})
	return map
})

const versionsWithTags = computed(() => {
	return props.versions.map((n: any) => {
		return {
			...n,
			tags: [
				n.is_duet ? t('tags.duet') : undefined,
				n.is_explicit ? t('tags.explicit') : undefined,
				n.is_original ? t('tags.original') : undefined,
				n.is_plus ? t('tags.plus') : undefined,
				n.has_cover ? t('tags.vocals') : undefined
			].filter(Boolean)
		}
	}).filter((version: Variant) => !!version && version.available_markets.includes(contentMarket.market || ''))
})

let mostPopularVersion: any

const sortVersions = (versions: Variant[]) => {
	// Determine most popular version overall
	mostPopularVersion = versions.reduce((prev, current) => {
		return (!prev || parseFloat(current.popularity) > parseFloat(prev.popularity)) ? current : prev
	}, versions[0])

	// Sort for the selector
	return versions.sort((a, b) => {
		if (a.is_original !== b.is_original) {
			return a.is_original ? -1 : 1
		}
		const popularityA = a.popularity === '0.00' ? -1 : parseFloat(a.popularity)
		const popularityB = b.popularity === '0.00' ? -1 : parseFloat(b.popularity)
		if (popularityB === popularityA) {
			// Sort alphabetically by name if popularity is the same
			return a.catalog.name.localeCompare(b.catalog.name)
		}
		return popularityB - popularityA
	})
}

const changeVersion = async (version: Variant) => {
	const queueStore = useQueueStore()
	const { editQueueItem } = queueStore
	editQueueItem({ entryID: props.queueEntryID, variant: version })
	useEventEmit('songSettings:versionChanged', version)
	selectedVersion.value = version.id
}

const sortedVersions = computed(() => sortVersions(versionsWithTags.value.slice()))

const sortedVersionsWithPreferences = computed(() => {
	if (preferences.value === null) {
		return sortedVersions.value
	}
	const variantsWithPreferences = versionsWithTags.value.map(variant => ({
		...variant,
		user_preferences: preferences.value?.find((preference: VariantPreference) => preference.variant === variant.id) || {
			pitch: 0
		}
	}))
	// If there is a selected version, put it on top
	if (selectedVersion.value) {
		const selected = variantsWithPreferences.find(variant => variant.id === selectedVersion.value)
		if (selected) {
			return [selected, ...variantsWithPreferences.filter(variant => variant.id !== selectedVersion.value)]
		}
	}
	return sortVersions(variantsWithPreferences)
})

const isMostPopularVersion = computed(() => (version: Variant) => version.id === mostPopularVersion.id)

const previewVersion = (songVersion: Variant) => {
	previewClicked.value = true
	if (!currentlyPlayingVersion.value || songVersion.id !== currentlyPlayingVersion.value) {
		playPreview(songVersion, { id: props.song.resource_id, name: props.song.name }, 'Song version selector')
	} else {
		stopPreview()
	}
}

watch(isPreviewPlaying, (newValue: boolean) => {
	if (!newValue) {
		resetPreview()
	}
})

const emit = defineEmits(['close'])

const closeSongVersions = (version: Variant) => {
	if (previewClicked.value) {
		previewClicked.value = false
		return
	}
	emit('close', { version })
}

onMounted(async () => {
	loading.value = true
	if (!props.isSongSettings) {
		useEventEmit('songVersionModal:toggle')
	}
	if (!showDemoPlayer.value) {
		const data = await $singaApi.Me.Preferences.get(props.versions.map(n => n.id))
		const prefs = data.map((n: any) => {
			return { ...n, vocals: n.track !== 'karaoke' }
		})
		preferences.value = prefs
	}
	loading.value = false
})

onUnmounted(() => {
	if ($previewPlayer.isPlaying()) {
		stopPreview()
	}
})
</script>

<style lang="sass" scoped>
.version-preference
	background-color: $transparent-white-8
	border-radius: $radius-default
	display: flex
	justify-content: space-between
	align-items: center
	padding: $spacing-16 $spacing-24 $spacing-16 $spacing-24
	margin-top: $spacing-48
	.preference-title
		@include font(basier, semiBold)
		display: block
		margin-bottom: $spacing-4
		color: $color-grey-10
	.preference-body
		color: $color-grey-30
		@include fontSize(xs)
.preview-button
	background-color: $transparent-white-4
	border-color: transparent
	border-radius: 0 0 $radius-default $radius-default !important
	width: 100%
	height: 40px
	border: 0
	span
		color: $color-grey-30
	&:focus
		color: $color-grey-50
		background-color: $transparent-white-8
	&:disabled
		color: $color-grey-70
	&:hover
		background-color:  $transparent-white-8
.version-tags
	max-width: 200px
	text-align: center
	.tag
		text-transform: uppercase
		@include fontSize(xxs)
		@include font(basier, medium)
		color: $color-grey-50
		background: transparent
		letter-spacing: 0.5px
		padding: 0
		&.is-selected
			color: $color-grey-30
		&:after
			content: '•'
			margin: 0 $spacing-4 0 $spacing-4

	.tag:last-child
		&:after
			content: ''
			margin: 0
.version-wrap:hover
	.version
		background-color: $transparent-white-12
		&.is-selected
			background-color: $color-grey-70

.version
	position: relative
	padding: $spacing-24 $spacing-16
	cursor: pointer
	display: flex
	flex-direction: column
	align-items: center
	max-height: 104px
	border-radius: $radius-default $radius-default 0 0
	background-color: $transparent-white-8
	height: 98px
	&.is-selected
		background-color: $transparent-white-16
.publisher
	color: $color-grey-10
	@include font(basier, medium)
.version-popular, .version-original
	position: absolute
	top: -12px
	&.queue-popular
		top: -$spacing-4
	right: 12px
	border-radius: $radius-round
	@include font(basier, medium)
	@include fontSize(xs)
	padding: $spacing-4 $spacing-16
	height: $spacing-24
	display: flex
	align-items: center

.version-popular
	background-color: white
	color: $color-grey-90
.version-original
	background-color: white
	color: $color-grey-90
.original-svg
	position: absolute
	top: 0
	left: 0
	border-radius: $radius-default
.original
	.publisher
		@include font(basier, semiBold)
	.tag
		color: $color-grey-20
.original-inprint
	margin-top: $spacing-24
	text-align: center
	span
		color: $color-grey-50
.version-header
	@include fontSize(3xl)
	@include font(basier, bold)
.modal-card-head
	padding-bottom: $spacing-24
.carousel-footer
	padding: 0 14px
.SongVersions
	border: 1px solid $color-grey-80
	border-radius: $radius-default
	padding: 20px
	background-color: $color-grey-90
	z-index: 9999
	max-width: 100vw
	flex: 1
	.delete
		position: absolute
		top: 0
		right: 0
		max-width: 28px
		max-height: 28px
		width: 28px
		height: 28px
		&:hover
			background-color: $color-grey-30
		.song-settings
			padding: 0
	.modal-card-body
		overflow: hidden
.b-tooltip:not(.queue-tip)
	width: 100% !important

.versions-wrap
	animation: fade 150ms
@keyframes fade
	from
		opacity: 0
	to
		opacity: 1

.version-item
	margin-bottom: $spacing-8
	&:hover
		.version
			background-color: $transparent-white-4

.version-checkmark
	color: $primary
	position: absolute
	left: 12px
	top: 12px

.toggle
	background-color: $color-grey-80

:deep(.carousel-item)
	height: 144px

.song-settings
	.version
		border-radius: $radius-default !important
		max-height: 90px
	.version-checkmark
		top: 50%
		color: $color-green-70
		transform: translate(0%, -50%)

	.version-tags:not(.settings-version-tags)
		max-width: 300px

.version-tags-and-preview-queue
	display: flex
	align-items: center
	justify-content: center
	width: 100%

.settings-version-tags
	display: flex
	gap: $spacing-4
	align-content: center
	justify-content: center
	flex-grow: 1

.settings-version-preview
	position: absolute
	right: $spacing-16
	top: 50%
	transform: translateY(-50%)
	display: flex
	align-items: center
	justify-content: center
	width: 30px
	height: 30px
	:deep(.iconify--ion)
		color: $color-grey-50
		&:hover
			color: $color-grey-60

	:deep(.b-tooltip.is-top .tooltip-content)
		padding-right: 220px !important
		color: $color-grey-30
		background: $color-grey-90

.loading-wrap
	height: 35px
</style>
