import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import geo_45redirect_45global from "/app/middleware/geoRedirect.global.ts";
import legacy_45payments_45global from "/app/middleware/legacyPayments.global.ts";
import page_45view_45global from "/app/middleware/pageView.global.ts";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  geo_45redirect_45global,
  legacy_45payments_45global,
  page_45view_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "auth-guard": () => import("/app/middleware/authGuard.ts"),
  "authed-root-guard": () => import("/app/middleware/authedRootGuard.ts"),
  "dynamic-transitions": () => import("/app/middleware/dynamicTransitions.ts"),
  "login-reroutes": () => import("/app/middleware/loginReroutes.ts"),
  stripe: () => import("/app/middleware/stripe.ts")
}