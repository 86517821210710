<template lang="pug">
div
	.report-section-title
		SingaButton.is-icon.back-button(@click="closeReporting()" :icon-left="'arrow-back'" size="small")
		span.reporting-title {{ t('songError.report.mainTitle') }}
	.song-info
		span.song-and-artist {{ songAndArtist }}
		span.catalog-text {{ props.song.variant.catalog.name }} - {{ props.song.variant.catalog_code }}

	.checkboxes
		SingaCheckbox(:label="t('songError.report.lyricsAhead')" v-model="lyricsAheadError")
		SingaCheckbox(:label="t('songError.report.lyricsBehind')" v-model="lyricsBehindError")
		SingaCheckbox(:label="t('songError.report.wrongLyrics')" v-model="wrongLyricsError")
		SingaCheckbox(:label="t('songError.report.wrongAudio')" v-model="wrongAudioError")
		SingaCheckbox(:label="t('songError.report.start')" v-model="startError")
		SingaCheckbox(:label="t('songError.report.audioQuality')" v-model="audioQualityError")
		SingaCheckbox(:label="t('songError.report.other')" v-model="otherError")
	.comment-field.settings-row
		SingaInput.comment-input(ref="feedbackInputElem" :expanded="false" :placeholder="t('songError.report.comment')" v-model="songComment" type="textarea")
	.submit-button
		SingaButton.is-primary.is-full-width.is-regular(@click="sendReport" type="submit" :disabled="!hasCheckedErrors || loading")
			span(v-if="!loading") {{ t('songError.report.linkTitle') }}
			CommonLoadingAnimation(v-else :timeOut="0" :size="24")
</template>

<script setup lang="ts">
const { t } = useI18n()

const props = defineProps({
	song: {
		required: true,
		type: Object
	}
})

const closeReporting = () => {
	useEventEmit('songSettings:closeErrorReporting')
}

const loading = ref(false)

const lyricsError = ref(false)
const startError = ref(false)
const wrongLyricsError = ref(false)
const wrongAudioError = ref(false)
const lyricsAheadError = ref(false)
const lyricsBehindError = ref(false)
const audioQualityError = ref(false)
const otherError = ref(false)
const songComment = ref('')

const hasCheckedErrors = computed(() => {
	return lyricsError.value
		|| startError.value
		|| wrongLyricsError.value
		|| wrongAudioError.value
		|| lyricsAheadError.value
		|| lyricsBehindError.value
		|| audioQualityError.value
		|| otherError.value
})

const issues = computed(() => {
	const issuesList = []

	if (lyricsError.value) {
		issuesList.push('wrong_lyrics')
	}
	if (startError.value) {
		issuesList.push('song_does_not_start')
	}
	if (wrongAudioError.value) {
		issuesList.push('wrong_song')
	}
	if (wrongLyricsError.value) {
		issuesList.push('wrong_lyrics')
	}
	if (lyricsAheadError.value) {
		issuesList.push('lyrics_ahead')
	}
	if (lyricsBehindError.value) {
		issuesList.push('lyrics_behind')
	}
	if (audioQualityError.value) {
		issuesList.push('bad_audio_quality')
	}
	if (otherError.value) {
		issuesList.push('other')
	}
	return issuesList
})

const sendReport = async () => {
	loading.value = true
	const payload = {
		variant: props.song.variant.id,
		issues: issues.value,
		description: songComment.value || undefined
	}
	const { $singaApi, $oruga } = useNuxtApp()
	const config = {
		position: 'bottom-right',
		variant: 'dark',
		icon: 'alert-circle-outline',
		iconSize: 'medium'
	}
	let message = t('songError.report.submitted')
	try {
		await $singaApi.Songs.postError(payload)
	} catch (err) {
		message = t('songError.report.error')
	} finally {
		$oruga.notification.open({
			message,
			...config
		})
	}
	loading.value = false
	useEventEmit('player:closeSettings')
}

const { getAllArtists } = useArtistNames(props.song.entry.artists)

const songAndArtist = computed(() => {
	return `${props.song.entry.name} - ${getAllArtists()}`
})
</script>

<style lang="sass" scoped>
.song-info
	color: $color-grey-30
	display: flex
	margin-left: 30px
	margin-top: $spacing-16
	flex-direction: column

.song-and-artist
	@include font(basier, medium)
	@include fontSize(s)
	display: block
	text-overflow: ellipsis
	white-space: nowrap
	min-width: 0
	overflow: hidden

.catalog-text
	text-transform: uppercase
	color: $color-grey-50
	@include fontSize(xxs)
	@include font(basier, medium)
	margin-top: $spacing-4

.checkboxes
	background-color: $transparent-white-8
	margin: $spacing-24 0 $spacing-8 0
	padding: $spacing-16 $spacing-32
	border-radius: $radius-default
	display: flex
	flex-direction: column
	gap: $spacing-8

:deep(.textarea)
	background: transparent
	border: none
	box-shadow: none
	padding: 0 $spacing-16 0 $spacing-16
	resize: none

.comment-field
	padding: $spacing-24 $spacing-24 $spacing-24 $spacing-16

:deep(.b-checkbox.checkbox .check)
	border: $color-grey-30 1px solid

.settings-row
	background-color: $transparent-white-8
	margin: $spacing-8 0 $spacing-8 0
	padding: 14px 20px
	border-radius: $radius-default
	&:last-child
		margin-bottom: 0

.report-section-title
	display: flex
	align-items: center
	align-content: flex-start

.reporting-title
	color: $color-grey-30
	@include fontSize(xl)
	@include font(basier, semiBold)
	padding-left: $spacing-8
</style>
