import { FullStory, init } from '@fullstory/browser'

export const useFullstory = () => {
	const { isMobile } = useDevice()
	const initFullstory = () => {
		if (import.meta.server || import.meta.dev || isMobile) { return }
		init({
			orgId: 'B3MX9'
		})
	}

	const identifyFullstoryUser = (user: any) => {
		if (import.meta.server || import.meta.dev || isMobile) { return }
		FullStory('setIdentity', {
			uid: user.resource_id,
			properties: {
				displayName: user.name,
				username_str: user.username,
				email: user.email
			}
		})
	}

	const resetFullStory = () => {
		if (import.meta.server || import.meta.dev || isMobile) { return }
		FullStory('setIdentity', {
			anonymous: true
		})
	}

	return {
		initFullstory,
		identifyFullstoryUser,
		resetFullStory
	}
}
