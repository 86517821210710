
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta } from "/app/pages/index.vue?macro=true";
import { default as stripeDbsdokfrs8Rbt2aQpPjwH6lZ3sdBaSM9d7hSNvBZNFAMeta } from "/app/pages/stripe.vue?macro=true";
import { default as karaokemdkj_45V_45N5CD91tUuGBJEPAzCRAQue1KeemRMZm_ZwHcMeta } from "/app/pages/karaoke.vue?macro=true";
import { default as _91_46_46_46slug_93QkkyEDg6xOGuFkoxzFC_4565xuuMFULoZbO_183vt2UD8Meta } from "/app/pages/[...slug].vue?macro=true";
import { default as my_45libraryqfxHmazJWx_452nSHwHg7KedPkDhgoTM3nz9HjHN8P1DMMeta } from "/app/pages/my-library.vue?macro=true";
import { default as index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta } from "/app/pages/login/index.vue?macro=true";
import { default as indexxwbBRaosE2fxw_xFX3ao0u2yfBRkK0KJ1_45VOcD8AG5EMeta } from "/app/pages/songs/index.vue?macro=true";
import { default as indexkSyUcjOCCWd2EFDsOAa1FNOs1FJlbNiaXXXo43jhiBsMeta } from "/app/pages/device/index.vue?macro=true";
import { default as indexyMaKfbLH_45BfoJMK_45bFE5OpzW_CSlLFfNQy11w_qN9TUMeta } from "/app/pages/genres/index.vue?macro=true";
import { default as presentation9dVOWkoyUeUhymZuY7NrCofrrouNVbv6gLaZrqPoIf4Meta } from "/app/pages/presentation.vue?macro=true";
import { default as billingkONIQ_71Qe_3Am3ZQzm138PjIpPnqkA0oCO4nRPNfqgMeta } from "/app/pages/account/billing.vue?macro=true";
import { default as profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta } from "/app/pages/account/profile.vue?macro=true";
import { default as indexB3JHUIG7r7yETiPS1Eugl6zmONaUXZ_45zcH4p4gqT_zwMeta } from "/app/pages/playlists/index.vue?macro=true";
import { default as settingsNfoJHDlJkT4lkPoJ9YyBN4ADOHrFOuNJGEFtb5mfXZ8Meta } from "/app/pages/account/settings.vue?macro=true";
import { default as index_45kanGfTOhm6KsvJSWsUjh_45_45mHmXxs5QULD_450HNAtDlQMeta } from "/app/pages/login/forgot/index.vue?macro=true";
import { default as resetIrMzyjSfn8ipfp_re5quaMmTFoOq35v_vPDHUPCfkMwMeta } from "/app/pages/login/forgot/reset.vue?macro=true";
import { default as index_qM02nPIkLr_UKu_457hsBdBbUBBiztECLWwdWk5IpI5wMeta } from "/app/pages/account/email/index.vue?macro=true";
import { default as index9dvdwdqehqEuFgKrgBz5ioL1AIF1hsK6mgq6zSb9C0MMeta } from "/app/pages/search/results/index.vue?macro=true";
import { default as _91hash_93saIorKqFVXCc2a35Zg91KDsIGwBEbIFd0vAohyTWdMoMeta } from "/app/pages/artists/[slug]/[hash].vue?macro=true";
import { default as _91search_93dQFwr8BOQTuJDCprb1gzcwUEePhTuj4vnjNAxtUeIqgMeta } from "/app/pages/results/songs/[search].vue?macro=true";
import { default as _91hash_93jFC7mF_45Bkep2BZZ6hJmoPTFwGfQPkG_45pRrc7GKMdEHAMeta } from "/app/pages/playlists/[slug]/[hash].vue?macro=true";
import { default as _91search_93aakpwq8VhZc5ksQMY3MC_mtk7Kr9ygJlW_450ZjmghZUQMeta } from "/app/pages/search/results/[search].vue?macro=true";
import { default as _91search_93v_45iVTkokiHWB_8O30vWjXwoHAgdKnmiKvWKtWqv3dgUMeta } from "/app/pages/results/artists/[search].vue?macro=true";
import { default as _91language_93Svk3JeJxA5PENFgvxSQQZlWFcGAk8A_45D63NpP3lE1mMMeta } from "/app/pages/songs/language/[language].vue?macro=true";
import { default as _91id_93jDJJz0cGs2r5XP74IZJL9_bCC0QpMnaOT6tAzBhgaQkMeta } from "/app/pages/genres/[name]-karaoke/[id].vue?macro=true";
import { default as _91search_93IahwNE8svffCb4vGopfsPJBkXMFanrC5aSKhuV7gdx8Meta } from "/app/pages/results/playlists/[search].vue?macro=true";
import { default as index5cDMnHYtH7pw9NnzKAvKbXDo9Xv_45KhDIvKecBkcmbJcMeta } from "/app/pages/karaoke-near-me/[term]/index.vue?macro=true";
import { default as _91hash_93gDbgB5qXlfrcSO6io0pSmYG2tMHKtsSNgjbpkNr6iXAMeta } from "/app/pages/artists/[slug]/[songSlug]/[hash].vue?macro=true";
import { default as index_45GRzWLeAYrvQTXID0d73S1WmVAVcSD3PudxPvAR5gVgMeta } from "/app/pages/karaoke-near-me/[term]/[city]/index.vue?macro=true";
import { default as _91hash_93lDaaubPCh_452lD18UiRKQ_45SSP0NJ9B6ocVOCStrNzk_sMeta } from "/app/pages/karaoke-near-me/[term]/[city]/[slug]/[hash].vue?macro=true";
import { default as component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIA } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta?.name ?? "index",
    path: indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta?.path ?? "/",
    props: indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta?.props ?? false,
    meta: indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta || {},
    alias: indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta?.alias || [],
    redirect: indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta?.redirect,
    component: () => import("/app/pages/index.vue")
  },
  {
    name: indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta?.name ?? "index___en",
    path: indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta?.path ?? "/en",
    props: indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta?.props ?? false,
    meta: indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta || {},
    alias: indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta?.alias || [],
    redirect: indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta?.redirect,
    component: () => import("/app/pages/index.vue")
  },
  {
    name: indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta?.name ?? "index___us",
    path: indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta?.path ?? "/us",
    props: indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta?.props ?? false,
    meta: indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta || {},
    alias: indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta?.alias || [],
    redirect: indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta?.redirect,
    component: () => import("/app/pages/index.vue")
  },
  {
    name: indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta?.name ?? "index___fi",
    path: indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta?.path ?? "/fi",
    props: indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta?.props ?? false,
    meta: indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta || {},
    alias: indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta?.alias || [],
    redirect: indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta?.redirect,
    component: () => import("/app/pages/index.vue")
  },
  {
    name: indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta?.name ?? "index___sv",
    path: indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta?.path ?? "/sv",
    props: indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta?.props ?? false,
    meta: indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta || {},
    alias: indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta?.alias || [],
    redirect: indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta?.redirect,
    component: () => import("/app/pages/index.vue")
  },
  {
    name: indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta?.name ?? "index___de",
    path: indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta?.path ?? "/de",
    props: indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta?.props ?? false,
    meta: indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta || {},
    alias: indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta?.alias || [],
    redirect: indexndqPXFtP262szLmLJV4PriPTgAg5k_457f05QyTfosBXQMeta?.redirect,
    component: () => import("/app/pages/index.vue")
  },
  {
    name: stripeDbsdokfrs8Rbt2aQpPjwH6lZ3sdBaSM9d7hSNvBZNFAMeta?.name ?? "stripe___en",
    path: stripeDbsdokfrs8Rbt2aQpPjwH6lZ3sdBaSM9d7hSNvBZNFAMeta?.path ?? "/en/stripe",
    props: stripeDbsdokfrs8Rbt2aQpPjwH6lZ3sdBaSM9d7hSNvBZNFAMeta?.props ?? false,
    meta: stripeDbsdokfrs8Rbt2aQpPjwH6lZ3sdBaSM9d7hSNvBZNFAMeta || {},
    alias: stripeDbsdokfrs8Rbt2aQpPjwH6lZ3sdBaSM9d7hSNvBZNFAMeta?.alias || [],
    redirect: stripeDbsdokfrs8Rbt2aQpPjwH6lZ3sdBaSM9d7hSNvBZNFAMeta?.redirect,
    component: () => import("/app/pages/stripe.vue")
  },
  {
    name: stripeDbsdokfrs8Rbt2aQpPjwH6lZ3sdBaSM9d7hSNvBZNFAMeta?.name ?? "stripe___us",
    path: stripeDbsdokfrs8Rbt2aQpPjwH6lZ3sdBaSM9d7hSNvBZNFAMeta?.path ?? "/us/stripe",
    props: stripeDbsdokfrs8Rbt2aQpPjwH6lZ3sdBaSM9d7hSNvBZNFAMeta?.props ?? false,
    meta: stripeDbsdokfrs8Rbt2aQpPjwH6lZ3sdBaSM9d7hSNvBZNFAMeta || {},
    alias: stripeDbsdokfrs8Rbt2aQpPjwH6lZ3sdBaSM9d7hSNvBZNFAMeta?.alias || [],
    redirect: stripeDbsdokfrs8Rbt2aQpPjwH6lZ3sdBaSM9d7hSNvBZNFAMeta?.redirect,
    component: () => import("/app/pages/stripe.vue")
  },
  {
    name: stripeDbsdokfrs8Rbt2aQpPjwH6lZ3sdBaSM9d7hSNvBZNFAMeta?.name ?? "stripe___fi",
    path: stripeDbsdokfrs8Rbt2aQpPjwH6lZ3sdBaSM9d7hSNvBZNFAMeta?.path ?? "/fi/stripe",
    props: stripeDbsdokfrs8Rbt2aQpPjwH6lZ3sdBaSM9d7hSNvBZNFAMeta?.props ?? false,
    meta: stripeDbsdokfrs8Rbt2aQpPjwH6lZ3sdBaSM9d7hSNvBZNFAMeta || {},
    alias: stripeDbsdokfrs8Rbt2aQpPjwH6lZ3sdBaSM9d7hSNvBZNFAMeta?.alias || [],
    redirect: stripeDbsdokfrs8Rbt2aQpPjwH6lZ3sdBaSM9d7hSNvBZNFAMeta?.redirect,
    component: () => import("/app/pages/stripe.vue")
  },
  {
    name: stripeDbsdokfrs8Rbt2aQpPjwH6lZ3sdBaSM9d7hSNvBZNFAMeta?.name ?? "stripe___sv",
    path: stripeDbsdokfrs8Rbt2aQpPjwH6lZ3sdBaSM9d7hSNvBZNFAMeta?.path ?? "/sv/stripe",
    props: stripeDbsdokfrs8Rbt2aQpPjwH6lZ3sdBaSM9d7hSNvBZNFAMeta?.props ?? false,
    meta: stripeDbsdokfrs8Rbt2aQpPjwH6lZ3sdBaSM9d7hSNvBZNFAMeta || {},
    alias: stripeDbsdokfrs8Rbt2aQpPjwH6lZ3sdBaSM9d7hSNvBZNFAMeta?.alias || [],
    redirect: stripeDbsdokfrs8Rbt2aQpPjwH6lZ3sdBaSM9d7hSNvBZNFAMeta?.redirect,
    component: () => import("/app/pages/stripe.vue")
  },
  {
    name: stripeDbsdokfrs8Rbt2aQpPjwH6lZ3sdBaSM9d7hSNvBZNFAMeta?.name ?? "stripe___de",
    path: stripeDbsdokfrs8Rbt2aQpPjwH6lZ3sdBaSM9d7hSNvBZNFAMeta?.path ?? "/de/stripe",
    props: stripeDbsdokfrs8Rbt2aQpPjwH6lZ3sdBaSM9d7hSNvBZNFAMeta?.props ?? false,
    meta: stripeDbsdokfrs8Rbt2aQpPjwH6lZ3sdBaSM9d7hSNvBZNFAMeta || {},
    alias: stripeDbsdokfrs8Rbt2aQpPjwH6lZ3sdBaSM9d7hSNvBZNFAMeta?.alias || [],
    redirect: stripeDbsdokfrs8Rbt2aQpPjwH6lZ3sdBaSM9d7hSNvBZNFAMeta?.redirect,
    component: () => import("/app/pages/stripe.vue")
  },
  {
    name: karaokemdkj_45V_45N5CD91tUuGBJEPAzCRAQue1KeemRMZm_ZwHcMeta?.name ?? "karaoke___en",
    path: karaokemdkj_45V_45N5CD91tUuGBJEPAzCRAQue1KeemRMZm_ZwHcMeta?.path ?? "/en/karaoke",
    props: karaokemdkj_45V_45N5CD91tUuGBJEPAzCRAQue1KeemRMZm_ZwHcMeta?.props ?? false,
    meta: karaokemdkj_45V_45N5CD91tUuGBJEPAzCRAQue1KeemRMZm_ZwHcMeta || {},
    alias: karaokemdkj_45V_45N5CD91tUuGBJEPAzCRAQue1KeemRMZm_ZwHcMeta?.alias || [],
    redirect: karaokemdkj_45V_45N5CD91tUuGBJEPAzCRAQue1KeemRMZm_ZwHcMeta?.redirect,
    component: () => import("/app/pages/karaoke.vue")
  },
  {
    name: karaokemdkj_45V_45N5CD91tUuGBJEPAzCRAQue1KeemRMZm_ZwHcMeta?.name ?? "karaoke___us",
    path: karaokemdkj_45V_45N5CD91tUuGBJEPAzCRAQue1KeemRMZm_ZwHcMeta?.path ?? "/us/karaoke",
    props: karaokemdkj_45V_45N5CD91tUuGBJEPAzCRAQue1KeemRMZm_ZwHcMeta?.props ?? false,
    meta: karaokemdkj_45V_45N5CD91tUuGBJEPAzCRAQue1KeemRMZm_ZwHcMeta || {},
    alias: karaokemdkj_45V_45N5CD91tUuGBJEPAzCRAQue1KeemRMZm_ZwHcMeta?.alias || [],
    redirect: karaokemdkj_45V_45N5CD91tUuGBJEPAzCRAQue1KeemRMZm_ZwHcMeta?.redirect,
    component: () => import("/app/pages/karaoke.vue")
  },
  {
    name: karaokemdkj_45V_45N5CD91tUuGBJEPAzCRAQue1KeemRMZm_ZwHcMeta?.name ?? "karaoke___fi",
    path: karaokemdkj_45V_45N5CD91tUuGBJEPAzCRAQue1KeemRMZm_ZwHcMeta?.path ?? "/fi/karaoke",
    props: karaokemdkj_45V_45N5CD91tUuGBJEPAzCRAQue1KeemRMZm_ZwHcMeta?.props ?? false,
    meta: karaokemdkj_45V_45N5CD91tUuGBJEPAzCRAQue1KeemRMZm_ZwHcMeta || {},
    alias: karaokemdkj_45V_45N5CD91tUuGBJEPAzCRAQue1KeemRMZm_ZwHcMeta?.alias || [],
    redirect: karaokemdkj_45V_45N5CD91tUuGBJEPAzCRAQue1KeemRMZm_ZwHcMeta?.redirect,
    component: () => import("/app/pages/karaoke.vue")
  },
  {
    name: karaokemdkj_45V_45N5CD91tUuGBJEPAzCRAQue1KeemRMZm_ZwHcMeta?.name ?? "karaoke___sv",
    path: karaokemdkj_45V_45N5CD91tUuGBJEPAzCRAQue1KeemRMZm_ZwHcMeta?.path ?? "/sv/karaoke",
    props: karaokemdkj_45V_45N5CD91tUuGBJEPAzCRAQue1KeemRMZm_ZwHcMeta?.props ?? false,
    meta: karaokemdkj_45V_45N5CD91tUuGBJEPAzCRAQue1KeemRMZm_ZwHcMeta || {},
    alias: karaokemdkj_45V_45N5CD91tUuGBJEPAzCRAQue1KeemRMZm_ZwHcMeta?.alias || [],
    redirect: karaokemdkj_45V_45N5CD91tUuGBJEPAzCRAQue1KeemRMZm_ZwHcMeta?.redirect,
    component: () => import("/app/pages/karaoke.vue")
  },
  {
    name: karaokemdkj_45V_45N5CD91tUuGBJEPAzCRAQue1KeemRMZm_ZwHcMeta?.name ?? "karaoke___de",
    path: karaokemdkj_45V_45N5CD91tUuGBJEPAzCRAQue1KeemRMZm_ZwHcMeta?.path ?? "/de/karaoke",
    props: karaokemdkj_45V_45N5CD91tUuGBJEPAzCRAQue1KeemRMZm_ZwHcMeta?.props ?? false,
    meta: karaokemdkj_45V_45N5CD91tUuGBJEPAzCRAQue1KeemRMZm_ZwHcMeta || {},
    alias: karaokemdkj_45V_45N5CD91tUuGBJEPAzCRAQue1KeemRMZm_ZwHcMeta?.alias || [],
    redirect: karaokemdkj_45V_45N5CD91tUuGBJEPAzCRAQue1KeemRMZm_ZwHcMeta?.redirect,
    component: () => import("/app/pages/karaoke.vue")
  },
  {
    name: _91_46_46_46slug_93QkkyEDg6xOGuFkoxzFC_4565xuuMFULoZbO_183vt2UD8Meta?.name ?? "slug___en",
    path: _91_46_46_46slug_93QkkyEDg6xOGuFkoxzFC_4565xuuMFULoZbO_183vt2UD8Meta?.path ?? "/en/:slug(.*)*",
    props: _91_46_46_46slug_93QkkyEDg6xOGuFkoxzFC_4565xuuMFULoZbO_183vt2UD8Meta?.props ?? false,
    meta: _91_46_46_46slug_93QkkyEDg6xOGuFkoxzFC_4565xuuMFULoZbO_183vt2UD8Meta || {},
    alias: _91_46_46_46slug_93QkkyEDg6xOGuFkoxzFC_4565xuuMFULoZbO_183vt2UD8Meta?.alias || [],
    redirect: _91_46_46_46slug_93QkkyEDg6xOGuFkoxzFC_4565xuuMFULoZbO_183vt2UD8Meta?.redirect,
    component: () => import("/app/pages/[...slug].vue")
  },
  {
    name: _91_46_46_46slug_93QkkyEDg6xOGuFkoxzFC_4565xuuMFULoZbO_183vt2UD8Meta?.name ?? "slug___us",
    path: _91_46_46_46slug_93QkkyEDg6xOGuFkoxzFC_4565xuuMFULoZbO_183vt2UD8Meta?.path ?? "/us/:slug(.*)*",
    props: _91_46_46_46slug_93QkkyEDg6xOGuFkoxzFC_4565xuuMFULoZbO_183vt2UD8Meta?.props ?? false,
    meta: _91_46_46_46slug_93QkkyEDg6xOGuFkoxzFC_4565xuuMFULoZbO_183vt2UD8Meta || {},
    alias: _91_46_46_46slug_93QkkyEDg6xOGuFkoxzFC_4565xuuMFULoZbO_183vt2UD8Meta?.alias || [],
    redirect: _91_46_46_46slug_93QkkyEDg6xOGuFkoxzFC_4565xuuMFULoZbO_183vt2UD8Meta?.redirect,
    component: () => import("/app/pages/[...slug].vue")
  },
  {
    name: _91_46_46_46slug_93QkkyEDg6xOGuFkoxzFC_4565xuuMFULoZbO_183vt2UD8Meta?.name ?? "slug___fi",
    path: _91_46_46_46slug_93QkkyEDg6xOGuFkoxzFC_4565xuuMFULoZbO_183vt2UD8Meta?.path ?? "/fi/:slug(.*)*",
    props: _91_46_46_46slug_93QkkyEDg6xOGuFkoxzFC_4565xuuMFULoZbO_183vt2UD8Meta?.props ?? false,
    meta: _91_46_46_46slug_93QkkyEDg6xOGuFkoxzFC_4565xuuMFULoZbO_183vt2UD8Meta || {},
    alias: _91_46_46_46slug_93QkkyEDg6xOGuFkoxzFC_4565xuuMFULoZbO_183vt2UD8Meta?.alias || [],
    redirect: _91_46_46_46slug_93QkkyEDg6xOGuFkoxzFC_4565xuuMFULoZbO_183vt2UD8Meta?.redirect,
    component: () => import("/app/pages/[...slug].vue")
  },
  {
    name: _91_46_46_46slug_93QkkyEDg6xOGuFkoxzFC_4565xuuMFULoZbO_183vt2UD8Meta?.name ?? "slug___sv",
    path: _91_46_46_46slug_93QkkyEDg6xOGuFkoxzFC_4565xuuMFULoZbO_183vt2UD8Meta?.path ?? "/sv/:slug(.*)*",
    props: _91_46_46_46slug_93QkkyEDg6xOGuFkoxzFC_4565xuuMFULoZbO_183vt2UD8Meta?.props ?? false,
    meta: _91_46_46_46slug_93QkkyEDg6xOGuFkoxzFC_4565xuuMFULoZbO_183vt2UD8Meta || {},
    alias: _91_46_46_46slug_93QkkyEDg6xOGuFkoxzFC_4565xuuMFULoZbO_183vt2UD8Meta?.alias || [],
    redirect: _91_46_46_46slug_93QkkyEDg6xOGuFkoxzFC_4565xuuMFULoZbO_183vt2UD8Meta?.redirect,
    component: () => import("/app/pages/[...slug].vue")
  },
  {
    name: _91_46_46_46slug_93QkkyEDg6xOGuFkoxzFC_4565xuuMFULoZbO_183vt2UD8Meta?.name ?? "slug___de",
    path: _91_46_46_46slug_93QkkyEDg6xOGuFkoxzFC_4565xuuMFULoZbO_183vt2UD8Meta?.path ?? "/de/:slug(.*)*",
    props: _91_46_46_46slug_93QkkyEDg6xOGuFkoxzFC_4565xuuMFULoZbO_183vt2UD8Meta?.props ?? false,
    meta: _91_46_46_46slug_93QkkyEDg6xOGuFkoxzFC_4565xuuMFULoZbO_183vt2UD8Meta || {},
    alias: _91_46_46_46slug_93QkkyEDg6xOGuFkoxzFC_4565xuuMFULoZbO_183vt2UD8Meta?.alias || [],
    redirect: _91_46_46_46slug_93QkkyEDg6xOGuFkoxzFC_4565xuuMFULoZbO_183vt2UD8Meta?.redirect,
    component: () => import("/app/pages/[...slug].vue")
  },
  {
    name: my_45libraryqfxHmazJWx_452nSHwHg7KedPkDhgoTM3nz9HjHN8P1DMMeta?.name ?? "my-library___en",
    path: my_45libraryqfxHmazJWx_452nSHwHg7KedPkDhgoTM3nz9HjHN8P1DMMeta?.path ?? "/en/my-library",
    props: my_45libraryqfxHmazJWx_452nSHwHg7KedPkDhgoTM3nz9HjHN8P1DMMeta?.props ?? false,
    meta: my_45libraryqfxHmazJWx_452nSHwHg7KedPkDhgoTM3nz9HjHN8P1DMMeta || {},
    alias: my_45libraryqfxHmazJWx_452nSHwHg7KedPkDhgoTM3nz9HjHN8P1DMMeta?.alias || [],
    redirect: my_45libraryqfxHmazJWx_452nSHwHg7KedPkDhgoTM3nz9HjHN8P1DMMeta?.redirect,
    component: () => import("/app/pages/my-library.vue")
  },
  {
    name: my_45libraryqfxHmazJWx_452nSHwHg7KedPkDhgoTM3nz9HjHN8P1DMMeta?.name ?? "my-library___us",
    path: my_45libraryqfxHmazJWx_452nSHwHg7KedPkDhgoTM3nz9HjHN8P1DMMeta?.path ?? "/us/my-library",
    props: my_45libraryqfxHmazJWx_452nSHwHg7KedPkDhgoTM3nz9HjHN8P1DMMeta?.props ?? false,
    meta: my_45libraryqfxHmazJWx_452nSHwHg7KedPkDhgoTM3nz9HjHN8P1DMMeta || {},
    alias: my_45libraryqfxHmazJWx_452nSHwHg7KedPkDhgoTM3nz9HjHN8P1DMMeta?.alias || [],
    redirect: my_45libraryqfxHmazJWx_452nSHwHg7KedPkDhgoTM3nz9HjHN8P1DMMeta?.redirect,
    component: () => import("/app/pages/my-library.vue")
  },
  {
    name: my_45libraryqfxHmazJWx_452nSHwHg7KedPkDhgoTM3nz9HjHN8P1DMMeta?.name ?? "my-library___fi",
    path: my_45libraryqfxHmazJWx_452nSHwHg7KedPkDhgoTM3nz9HjHN8P1DMMeta?.path ?? "/fi/my-library",
    props: my_45libraryqfxHmazJWx_452nSHwHg7KedPkDhgoTM3nz9HjHN8P1DMMeta?.props ?? false,
    meta: my_45libraryqfxHmazJWx_452nSHwHg7KedPkDhgoTM3nz9HjHN8P1DMMeta || {},
    alias: my_45libraryqfxHmazJWx_452nSHwHg7KedPkDhgoTM3nz9HjHN8P1DMMeta?.alias || [],
    redirect: my_45libraryqfxHmazJWx_452nSHwHg7KedPkDhgoTM3nz9HjHN8P1DMMeta?.redirect,
    component: () => import("/app/pages/my-library.vue")
  },
  {
    name: my_45libraryqfxHmazJWx_452nSHwHg7KedPkDhgoTM3nz9HjHN8P1DMMeta?.name ?? "my-library___sv",
    path: my_45libraryqfxHmazJWx_452nSHwHg7KedPkDhgoTM3nz9HjHN8P1DMMeta?.path ?? "/sv/my-library",
    props: my_45libraryqfxHmazJWx_452nSHwHg7KedPkDhgoTM3nz9HjHN8P1DMMeta?.props ?? false,
    meta: my_45libraryqfxHmazJWx_452nSHwHg7KedPkDhgoTM3nz9HjHN8P1DMMeta || {},
    alias: my_45libraryqfxHmazJWx_452nSHwHg7KedPkDhgoTM3nz9HjHN8P1DMMeta?.alias || [],
    redirect: my_45libraryqfxHmazJWx_452nSHwHg7KedPkDhgoTM3nz9HjHN8P1DMMeta?.redirect,
    component: () => import("/app/pages/my-library.vue")
  },
  {
    name: my_45libraryqfxHmazJWx_452nSHwHg7KedPkDhgoTM3nz9HjHN8P1DMMeta?.name ?? "my-library___de",
    path: my_45libraryqfxHmazJWx_452nSHwHg7KedPkDhgoTM3nz9HjHN8P1DMMeta?.path ?? "/de/my-library",
    props: my_45libraryqfxHmazJWx_452nSHwHg7KedPkDhgoTM3nz9HjHN8P1DMMeta?.props ?? false,
    meta: my_45libraryqfxHmazJWx_452nSHwHg7KedPkDhgoTM3nz9HjHN8P1DMMeta || {},
    alias: my_45libraryqfxHmazJWx_452nSHwHg7KedPkDhgoTM3nz9HjHN8P1DMMeta?.alias || [],
    redirect: my_45libraryqfxHmazJWx_452nSHwHg7KedPkDhgoTM3nz9HjHN8P1DMMeta?.redirect,
    component: () => import("/app/pages/my-library.vue")
  },
  {
    name: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta?.name ?? "login___en",
    path: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta?.path ?? "/en/login",
    props: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta?.props ?? false,
    meta: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta || {},
    alias: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta?.alias || [],
    redirect: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta?.redirect,
    component: () => import("/app/pages/login/index.vue")
  },
  {
    name: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta?.name ?? "login___us",
    path: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta?.path ?? "/us/login",
    props: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta?.props ?? false,
    meta: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta || {},
    alias: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta?.alias || [],
    redirect: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta?.redirect,
    component: () => import("/app/pages/login/index.vue")
  },
  {
    name: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta?.name ?? "login___fi",
    path: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta?.path ?? "/fi/login",
    props: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta?.props ?? false,
    meta: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta || {},
    alias: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta?.alias || [],
    redirect: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta?.redirect,
    component: () => import("/app/pages/login/index.vue")
  },
  {
    name: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta?.name ?? "login___sv",
    path: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta?.path ?? "/sv/login",
    props: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta?.props ?? false,
    meta: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta || {},
    alias: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta?.alias || [],
    redirect: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta?.redirect,
    component: () => import("/app/pages/login/index.vue")
  },
  {
    name: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta?.name ?? "login___de",
    path: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta?.path ?? "/de/login",
    props: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta?.props ?? false,
    meta: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta || {},
    alias: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta?.alias || [],
    redirect: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta?.redirect,
    component: () => import("/app/pages/login/index.vue")
  },
  {
    name: indexxwbBRaosE2fxw_xFX3ao0u2yfBRkK0KJ1_45VOcD8AG5EMeta?.name ?? "songs___en",
    path: indexxwbBRaosE2fxw_xFX3ao0u2yfBRkK0KJ1_45VOcD8AG5EMeta?.path ?? "/en/songs",
    props: indexxwbBRaosE2fxw_xFX3ao0u2yfBRkK0KJ1_45VOcD8AG5EMeta?.props ?? false,
    meta: indexxwbBRaosE2fxw_xFX3ao0u2yfBRkK0KJ1_45VOcD8AG5EMeta || {},
    alias: indexxwbBRaosE2fxw_xFX3ao0u2yfBRkK0KJ1_45VOcD8AG5EMeta?.alias || [],
    redirect: indexxwbBRaosE2fxw_xFX3ao0u2yfBRkK0KJ1_45VOcD8AG5EMeta?.redirect,
    component: () => import("/app/pages/songs/index.vue")
  },
  {
    name: indexxwbBRaosE2fxw_xFX3ao0u2yfBRkK0KJ1_45VOcD8AG5EMeta?.name ?? "songs___us",
    path: indexxwbBRaosE2fxw_xFX3ao0u2yfBRkK0KJ1_45VOcD8AG5EMeta?.path ?? "/us/songs",
    props: indexxwbBRaosE2fxw_xFX3ao0u2yfBRkK0KJ1_45VOcD8AG5EMeta?.props ?? false,
    meta: indexxwbBRaosE2fxw_xFX3ao0u2yfBRkK0KJ1_45VOcD8AG5EMeta || {},
    alias: indexxwbBRaosE2fxw_xFX3ao0u2yfBRkK0KJ1_45VOcD8AG5EMeta?.alias || [],
    redirect: indexxwbBRaosE2fxw_xFX3ao0u2yfBRkK0KJ1_45VOcD8AG5EMeta?.redirect,
    component: () => import("/app/pages/songs/index.vue")
  },
  {
    name: indexxwbBRaosE2fxw_xFX3ao0u2yfBRkK0KJ1_45VOcD8AG5EMeta?.name ?? "songs___fi",
    path: indexxwbBRaosE2fxw_xFX3ao0u2yfBRkK0KJ1_45VOcD8AG5EMeta?.path ?? "/fi/songs",
    props: indexxwbBRaosE2fxw_xFX3ao0u2yfBRkK0KJ1_45VOcD8AG5EMeta?.props ?? false,
    meta: indexxwbBRaosE2fxw_xFX3ao0u2yfBRkK0KJ1_45VOcD8AG5EMeta || {},
    alias: indexxwbBRaosE2fxw_xFX3ao0u2yfBRkK0KJ1_45VOcD8AG5EMeta?.alias || [],
    redirect: indexxwbBRaosE2fxw_xFX3ao0u2yfBRkK0KJ1_45VOcD8AG5EMeta?.redirect,
    component: () => import("/app/pages/songs/index.vue")
  },
  {
    name: indexxwbBRaosE2fxw_xFX3ao0u2yfBRkK0KJ1_45VOcD8AG5EMeta?.name ?? "songs___sv",
    path: indexxwbBRaosE2fxw_xFX3ao0u2yfBRkK0KJ1_45VOcD8AG5EMeta?.path ?? "/sv/songs",
    props: indexxwbBRaosE2fxw_xFX3ao0u2yfBRkK0KJ1_45VOcD8AG5EMeta?.props ?? false,
    meta: indexxwbBRaosE2fxw_xFX3ao0u2yfBRkK0KJ1_45VOcD8AG5EMeta || {},
    alias: indexxwbBRaosE2fxw_xFX3ao0u2yfBRkK0KJ1_45VOcD8AG5EMeta?.alias || [],
    redirect: indexxwbBRaosE2fxw_xFX3ao0u2yfBRkK0KJ1_45VOcD8AG5EMeta?.redirect,
    component: () => import("/app/pages/songs/index.vue")
  },
  {
    name: indexxwbBRaosE2fxw_xFX3ao0u2yfBRkK0KJ1_45VOcD8AG5EMeta?.name ?? "songs___de",
    path: indexxwbBRaosE2fxw_xFX3ao0u2yfBRkK0KJ1_45VOcD8AG5EMeta?.path ?? "/de/songs",
    props: indexxwbBRaosE2fxw_xFX3ao0u2yfBRkK0KJ1_45VOcD8AG5EMeta?.props ?? false,
    meta: indexxwbBRaosE2fxw_xFX3ao0u2yfBRkK0KJ1_45VOcD8AG5EMeta || {},
    alias: indexxwbBRaosE2fxw_xFX3ao0u2yfBRkK0KJ1_45VOcD8AG5EMeta?.alias || [],
    redirect: indexxwbBRaosE2fxw_xFX3ao0u2yfBRkK0KJ1_45VOcD8AG5EMeta?.redirect,
    component: () => import("/app/pages/songs/index.vue")
  },
  {
    name: indexkSyUcjOCCWd2EFDsOAa1FNOs1FJlbNiaXXXo43jhiBsMeta?.name ?? "device___en",
    path: indexkSyUcjOCCWd2EFDsOAa1FNOs1FJlbNiaXXXo43jhiBsMeta?.path ?? "/en/device",
    props: indexkSyUcjOCCWd2EFDsOAa1FNOs1FJlbNiaXXXo43jhiBsMeta?.props ?? false,
    meta: indexkSyUcjOCCWd2EFDsOAa1FNOs1FJlbNiaXXXo43jhiBsMeta || {},
    alias: indexkSyUcjOCCWd2EFDsOAa1FNOs1FJlbNiaXXXo43jhiBsMeta?.alias || [],
    redirect: indexkSyUcjOCCWd2EFDsOAa1FNOs1FJlbNiaXXXo43jhiBsMeta?.redirect,
    component: () => import("/app/pages/device/index.vue")
  },
  {
    name: indexkSyUcjOCCWd2EFDsOAa1FNOs1FJlbNiaXXXo43jhiBsMeta?.name ?? "device___us",
    path: indexkSyUcjOCCWd2EFDsOAa1FNOs1FJlbNiaXXXo43jhiBsMeta?.path ?? "/us/device",
    props: indexkSyUcjOCCWd2EFDsOAa1FNOs1FJlbNiaXXXo43jhiBsMeta?.props ?? false,
    meta: indexkSyUcjOCCWd2EFDsOAa1FNOs1FJlbNiaXXXo43jhiBsMeta || {},
    alias: indexkSyUcjOCCWd2EFDsOAa1FNOs1FJlbNiaXXXo43jhiBsMeta?.alias || [],
    redirect: indexkSyUcjOCCWd2EFDsOAa1FNOs1FJlbNiaXXXo43jhiBsMeta?.redirect,
    component: () => import("/app/pages/device/index.vue")
  },
  {
    name: indexkSyUcjOCCWd2EFDsOAa1FNOs1FJlbNiaXXXo43jhiBsMeta?.name ?? "device___fi",
    path: indexkSyUcjOCCWd2EFDsOAa1FNOs1FJlbNiaXXXo43jhiBsMeta?.path ?? "/fi/device",
    props: indexkSyUcjOCCWd2EFDsOAa1FNOs1FJlbNiaXXXo43jhiBsMeta?.props ?? false,
    meta: indexkSyUcjOCCWd2EFDsOAa1FNOs1FJlbNiaXXXo43jhiBsMeta || {},
    alias: indexkSyUcjOCCWd2EFDsOAa1FNOs1FJlbNiaXXXo43jhiBsMeta?.alias || [],
    redirect: indexkSyUcjOCCWd2EFDsOAa1FNOs1FJlbNiaXXXo43jhiBsMeta?.redirect,
    component: () => import("/app/pages/device/index.vue")
  },
  {
    name: indexkSyUcjOCCWd2EFDsOAa1FNOs1FJlbNiaXXXo43jhiBsMeta?.name ?? "device___sv",
    path: indexkSyUcjOCCWd2EFDsOAa1FNOs1FJlbNiaXXXo43jhiBsMeta?.path ?? "/sv/device",
    props: indexkSyUcjOCCWd2EFDsOAa1FNOs1FJlbNiaXXXo43jhiBsMeta?.props ?? false,
    meta: indexkSyUcjOCCWd2EFDsOAa1FNOs1FJlbNiaXXXo43jhiBsMeta || {},
    alias: indexkSyUcjOCCWd2EFDsOAa1FNOs1FJlbNiaXXXo43jhiBsMeta?.alias || [],
    redirect: indexkSyUcjOCCWd2EFDsOAa1FNOs1FJlbNiaXXXo43jhiBsMeta?.redirect,
    component: () => import("/app/pages/device/index.vue")
  },
  {
    name: indexkSyUcjOCCWd2EFDsOAa1FNOs1FJlbNiaXXXo43jhiBsMeta?.name ?? "device___de",
    path: indexkSyUcjOCCWd2EFDsOAa1FNOs1FJlbNiaXXXo43jhiBsMeta?.path ?? "/de/device",
    props: indexkSyUcjOCCWd2EFDsOAa1FNOs1FJlbNiaXXXo43jhiBsMeta?.props ?? false,
    meta: indexkSyUcjOCCWd2EFDsOAa1FNOs1FJlbNiaXXXo43jhiBsMeta || {},
    alias: indexkSyUcjOCCWd2EFDsOAa1FNOs1FJlbNiaXXXo43jhiBsMeta?.alias || [],
    redirect: indexkSyUcjOCCWd2EFDsOAa1FNOs1FJlbNiaXXXo43jhiBsMeta?.redirect,
    component: () => import("/app/pages/device/index.vue")
  },
  {
    name: indexyMaKfbLH_45BfoJMK_45bFE5OpzW_CSlLFfNQy11w_qN9TUMeta?.name ?? "genres___en",
    path: indexyMaKfbLH_45BfoJMK_45bFE5OpzW_CSlLFfNQy11w_qN9TUMeta?.path ?? "/en/genres",
    props: indexyMaKfbLH_45BfoJMK_45bFE5OpzW_CSlLFfNQy11w_qN9TUMeta?.props ?? false,
    meta: indexyMaKfbLH_45BfoJMK_45bFE5OpzW_CSlLFfNQy11w_qN9TUMeta || {},
    alias: indexyMaKfbLH_45BfoJMK_45bFE5OpzW_CSlLFfNQy11w_qN9TUMeta?.alias || [],
    redirect: indexyMaKfbLH_45BfoJMK_45bFE5OpzW_CSlLFfNQy11w_qN9TUMeta?.redirect,
    component: () => import("/app/pages/genres/index.vue")
  },
  {
    name: indexyMaKfbLH_45BfoJMK_45bFE5OpzW_CSlLFfNQy11w_qN9TUMeta?.name ?? "genres___us",
    path: indexyMaKfbLH_45BfoJMK_45bFE5OpzW_CSlLFfNQy11w_qN9TUMeta?.path ?? "/us/genres",
    props: indexyMaKfbLH_45BfoJMK_45bFE5OpzW_CSlLFfNQy11w_qN9TUMeta?.props ?? false,
    meta: indexyMaKfbLH_45BfoJMK_45bFE5OpzW_CSlLFfNQy11w_qN9TUMeta || {},
    alias: indexyMaKfbLH_45BfoJMK_45bFE5OpzW_CSlLFfNQy11w_qN9TUMeta?.alias || [],
    redirect: indexyMaKfbLH_45BfoJMK_45bFE5OpzW_CSlLFfNQy11w_qN9TUMeta?.redirect,
    component: () => import("/app/pages/genres/index.vue")
  },
  {
    name: indexyMaKfbLH_45BfoJMK_45bFE5OpzW_CSlLFfNQy11w_qN9TUMeta?.name ?? "genres___fi",
    path: indexyMaKfbLH_45BfoJMK_45bFE5OpzW_CSlLFfNQy11w_qN9TUMeta?.path ?? "/fi/genres",
    props: indexyMaKfbLH_45BfoJMK_45bFE5OpzW_CSlLFfNQy11w_qN9TUMeta?.props ?? false,
    meta: indexyMaKfbLH_45BfoJMK_45bFE5OpzW_CSlLFfNQy11w_qN9TUMeta || {},
    alias: indexyMaKfbLH_45BfoJMK_45bFE5OpzW_CSlLFfNQy11w_qN9TUMeta?.alias || [],
    redirect: indexyMaKfbLH_45BfoJMK_45bFE5OpzW_CSlLFfNQy11w_qN9TUMeta?.redirect,
    component: () => import("/app/pages/genres/index.vue")
  },
  {
    name: indexyMaKfbLH_45BfoJMK_45bFE5OpzW_CSlLFfNQy11w_qN9TUMeta?.name ?? "genres___sv",
    path: indexyMaKfbLH_45BfoJMK_45bFE5OpzW_CSlLFfNQy11w_qN9TUMeta?.path ?? "/sv/genres",
    props: indexyMaKfbLH_45BfoJMK_45bFE5OpzW_CSlLFfNQy11w_qN9TUMeta?.props ?? false,
    meta: indexyMaKfbLH_45BfoJMK_45bFE5OpzW_CSlLFfNQy11w_qN9TUMeta || {},
    alias: indexyMaKfbLH_45BfoJMK_45bFE5OpzW_CSlLFfNQy11w_qN9TUMeta?.alias || [],
    redirect: indexyMaKfbLH_45BfoJMK_45bFE5OpzW_CSlLFfNQy11w_qN9TUMeta?.redirect,
    component: () => import("/app/pages/genres/index.vue")
  },
  {
    name: indexyMaKfbLH_45BfoJMK_45bFE5OpzW_CSlLFfNQy11w_qN9TUMeta?.name ?? "genres___de",
    path: indexyMaKfbLH_45BfoJMK_45bFE5OpzW_CSlLFfNQy11w_qN9TUMeta?.path ?? "/de/genres",
    props: indexyMaKfbLH_45BfoJMK_45bFE5OpzW_CSlLFfNQy11w_qN9TUMeta?.props ?? false,
    meta: indexyMaKfbLH_45BfoJMK_45bFE5OpzW_CSlLFfNQy11w_qN9TUMeta || {},
    alias: indexyMaKfbLH_45BfoJMK_45bFE5OpzW_CSlLFfNQy11w_qN9TUMeta?.alias || [],
    redirect: indexyMaKfbLH_45BfoJMK_45bFE5OpzW_CSlLFfNQy11w_qN9TUMeta?.redirect,
    component: () => import("/app/pages/genres/index.vue")
  },
  {
    name: presentation9dVOWkoyUeUhymZuY7NrCofrrouNVbv6gLaZrqPoIf4Meta?.name ?? "presentation___en",
    path: presentation9dVOWkoyUeUhymZuY7NrCofrrouNVbv6gLaZrqPoIf4Meta?.path ?? "/en/presentation",
    props: presentation9dVOWkoyUeUhymZuY7NrCofrrouNVbv6gLaZrqPoIf4Meta?.props ?? false,
    meta: presentation9dVOWkoyUeUhymZuY7NrCofrrouNVbv6gLaZrqPoIf4Meta || {},
    alias: presentation9dVOWkoyUeUhymZuY7NrCofrrouNVbv6gLaZrqPoIf4Meta?.alias || [],
    redirect: presentation9dVOWkoyUeUhymZuY7NrCofrrouNVbv6gLaZrqPoIf4Meta?.redirect,
    component: () => import("/app/pages/presentation.vue")
  },
  {
    name: presentation9dVOWkoyUeUhymZuY7NrCofrrouNVbv6gLaZrqPoIf4Meta?.name ?? "presentation___us",
    path: presentation9dVOWkoyUeUhymZuY7NrCofrrouNVbv6gLaZrqPoIf4Meta?.path ?? "/us/presentation",
    props: presentation9dVOWkoyUeUhymZuY7NrCofrrouNVbv6gLaZrqPoIf4Meta?.props ?? false,
    meta: presentation9dVOWkoyUeUhymZuY7NrCofrrouNVbv6gLaZrqPoIf4Meta || {},
    alias: presentation9dVOWkoyUeUhymZuY7NrCofrrouNVbv6gLaZrqPoIf4Meta?.alias || [],
    redirect: presentation9dVOWkoyUeUhymZuY7NrCofrrouNVbv6gLaZrqPoIf4Meta?.redirect,
    component: () => import("/app/pages/presentation.vue")
  },
  {
    name: presentation9dVOWkoyUeUhymZuY7NrCofrrouNVbv6gLaZrqPoIf4Meta?.name ?? "presentation___fi",
    path: presentation9dVOWkoyUeUhymZuY7NrCofrrouNVbv6gLaZrqPoIf4Meta?.path ?? "/fi/presentation",
    props: presentation9dVOWkoyUeUhymZuY7NrCofrrouNVbv6gLaZrqPoIf4Meta?.props ?? false,
    meta: presentation9dVOWkoyUeUhymZuY7NrCofrrouNVbv6gLaZrqPoIf4Meta || {},
    alias: presentation9dVOWkoyUeUhymZuY7NrCofrrouNVbv6gLaZrqPoIf4Meta?.alias || [],
    redirect: presentation9dVOWkoyUeUhymZuY7NrCofrrouNVbv6gLaZrqPoIf4Meta?.redirect,
    component: () => import("/app/pages/presentation.vue")
  },
  {
    name: presentation9dVOWkoyUeUhymZuY7NrCofrrouNVbv6gLaZrqPoIf4Meta?.name ?? "presentation___sv",
    path: presentation9dVOWkoyUeUhymZuY7NrCofrrouNVbv6gLaZrqPoIf4Meta?.path ?? "/sv/presentation",
    props: presentation9dVOWkoyUeUhymZuY7NrCofrrouNVbv6gLaZrqPoIf4Meta?.props ?? false,
    meta: presentation9dVOWkoyUeUhymZuY7NrCofrrouNVbv6gLaZrqPoIf4Meta || {},
    alias: presentation9dVOWkoyUeUhymZuY7NrCofrrouNVbv6gLaZrqPoIf4Meta?.alias || [],
    redirect: presentation9dVOWkoyUeUhymZuY7NrCofrrouNVbv6gLaZrqPoIf4Meta?.redirect,
    component: () => import("/app/pages/presentation.vue")
  },
  {
    name: presentation9dVOWkoyUeUhymZuY7NrCofrrouNVbv6gLaZrqPoIf4Meta?.name ?? "presentation___de",
    path: presentation9dVOWkoyUeUhymZuY7NrCofrrouNVbv6gLaZrqPoIf4Meta?.path ?? "/de/presentation",
    props: presentation9dVOWkoyUeUhymZuY7NrCofrrouNVbv6gLaZrqPoIf4Meta?.props ?? false,
    meta: presentation9dVOWkoyUeUhymZuY7NrCofrrouNVbv6gLaZrqPoIf4Meta || {},
    alias: presentation9dVOWkoyUeUhymZuY7NrCofrrouNVbv6gLaZrqPoIf4Meta?.alias || [],
    redirect: presentation9dVOWkoyUeUhymZuY7NrCofrrouNVbv6gLaZrqPoIf4Meta?.redirect,
    component: () => import("/app/pages/presentation.vue")
  },
  {
    name: billingkONIQ_71Qe_3Am3ZQzm138PjIpPnqkA0oCO4nRPNfqgMeta?.name ?? "account-billing___en",
    path: billingkONIQ_71Qe_3Am3ZQzm138PjIpPnqkA0oCO4nRPNfqgMeta?.path ?? "/en/account/billing",
    props: billingkONIQ_71Qe_3Am3ZQzm138PjIpPnqkA0oCO4nRPNfqgMeta?.props ?? false,
    meta: billingkONIQ_71Qe_3Am3ZQzm138PjIpPnqkA0oCO4nRPNfqgMeta || {},
    alias: billingkONIQ_71Qe_3Am3ZQzm138PjIpPnqkA0oCO4nRPNfqgMeta?.alias || [],
    redirect: billingkONIQ_71Qe_3Am3ZQzm138PjIpPnqkA0oCO4nRPNfqgMeta?.redirect,
    component: () => import("/app/pages/account/billing.vue")
  },
  {
    name: billingkONIQ_71Qe_3Am3ZQzm138PjIpPnqkA0oCO4nRPNfqgMeta?.name ?? "account-billing___us",
    path: billingkONIQ_71Qe_3Am3ZQzm138PjIpPnqkA0oCO4nRPNfqgMeta?.path ?? "/us/account/billing",
    props: billingkONIQ_71Qe_3Am3ZQzm138PjIpPnqkA0oCO4nRPNfqgMeta?.props ?? false,
    meta: billingkONIQ_71Qe_3Am3ZQzm138PjIpPnqkA0oCO4nRPNfqgMeta || {},
    alias: billingkONIQ_71Qe_3Am3ZQzm138PjIpPnqkA0oCO4nRPNfqgMeta?.alias || [],
    redirect: billingkONIQ_71Qe_3Am3ZQzm138PjIpPnqkA0oCO4nRPNfqgMeta?.redirect,
    component: () => import("/app/pages/account/billing.vue")
  },
  {
    name: billingkONIQ_71Qe_3Am3ZQzm138PjIpPnqkA0oCO4nRPNfqgMeta?.name ?? "account-billing___fi",
    path: billingkONIQ_71Qe_3Am3ZQzm138PjIpPnqkA0oCO4nRPNfqgMeta?.path ?? "/fi/account/billing",
    props: billingkONIQ_71Qe_3Am3ZQzm138PjIpPnqkA0oCO4nRPNfqgMeta?.props ?? false,
    meta: billingkONIQ_71Qe_3Am3ZQzm138PjIpPnqkA0oCO4nRPNfqgMeta || {},
    alias: billingkONIQ_71Qe_3Am3ZQzm138PjIpPnqkA0oCO4nRPNfqgMeta?.alias || [],
    redirect: billingkONIQ_71Qe_3Am3ZQzm138PjIpPnqkA0oCO4nRPNfqgMeta?.redirect,
    component: () => import("/app/pages/account/billing.vue")
  },
  {
    name: billingkONIQ_71Qe_3Am3ZQzm138PjIpPnqkA0oCO4nRPNfqgMeta?.name ?? "account-billing___sv",
    path: billingkONIQ_71Qe_3Am3ZQzm138PjIpPnqkA0oCO4nRPNfqgMeta?.path ?? "/sv/account/billing",
    props: billingkONIQ_71Qe_3Am3ZQzm138PjIpPnqkA0oCO4nRPNfqgMeta?.props ?? false,
    meta: billingkONIQ_71Qe_3Am3ZQzm138PjIpPnqkA0oCO4nRPNfqgMeta || {},
    alias: billingkONIQ_71Qe_3Am3ZQzm138PjIpPnqkA0oCO4nRPNfqgMeta?.alias || [],
    redirect: billingkONIQ_71Qe_3Am3ZQzm138PjIpPnqkA0oCO4nRPNfqgMeta?.redirect,
    component: () => import("/app/pages/account/billing.vue")
  },
  {
    name: billingkONIQ_71Qe_3Am3ZQzm138PjIpPnqkA0oCO4nRPNfqgMeta?.name ?? "account-billing___de",
    path: billingkONIQ_71Qe_3Am3ZQzm138PjIpPnqkA0oCO4nRPNfqgMeta?.path ?? "/de/account/billing",
    props: billingkONIQ_71Qe_3Am3ZQzm138PjIpPnqkA0oCO4nRPNfqgMeta?.props ?? false,
    meta: billingkONIQ_71Qe_3Am3ZQzm138PjIpPnqkA0oCO4nRPNfqgMeta || {},
    alias: billingkONIQ_71Qe_3Am3ZQzm138PjIpPnqkA0oCO4nRPNfqgMeta?.alias || [],
    redirect: billingkONIQ_71Qe_3Am3ZQzm138PjIpPnqkA0oCO4nRPNfqgMeta?.redirect,
    component: () => import("/app/pages/account/billing.vue")
  },
  {
    name: profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta?.name ?? "account-profile___en",
    path: profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta?.path ?? "/en/account/profile",
    props: profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta?.props ?? false,
    meta: profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta || {},
    alias: profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta?.alias || [],
    redirect: profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta?.redirect,
    component: () => import("/app/pages/account/profile.vue")
  },
  {
    name: profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta?.name ?? "account-profile___us",
    path: profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta?.path ?? "/us/account/profile",
    props: profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta?.props ?? false,
    meta: profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta || {},
    alias: profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta?.alias || [],
    redirect: profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta?.redirect,
    component: () => import("/app/pages/account/profile.vue")
  },
  {
    name: profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta?.name ?? "account-profile___fi",
    path: profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta?.path ?? "/fi/account/profile",
    props: profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta?.props ?? false,
    meta: profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta || {},
    alias: profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta?.alias || [],
    redirect: profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta?.redirect,
    component: () => import("/app/pages/account/profile.vue")
  },
  {
    name: profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta?.name ?? "account-profile___sv",
    path: profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta?.path ?? "/sv/account/profile",
    props: profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta?.props ?? false,
    meta: profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta || {},
    alias: profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta?.alias || [],
    redirect: profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta?.redirect,
    component: () => import("/app/pages/account/profile.vue")
  },
  {
    name: profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta?.name ?? "account-profile___de",
    path: profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta?.path ?? "/de/account/profile",
    props: profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta?.props ?? false,
    meta: profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta || {},
    alias: profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta?.alias || [],
    redirect: profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta?.redirect,
    component: () => import("/app/pages/account/profile.vue")
  },
  {
    name: indexB3JHUIG7r7yETiPS1Eugl6zmONaUXZ_45zcH4p4gqT_zwMeta?.name ?? "playlists___en",
    path: indexB3JHUIG7r7yETiPS1Eugl6zmONaUXZ_45zcH4p4gqT_zwMeta?.path ?? "/en/playlists",
    props: indexB3JHUIG7r7yETiPS1Eugl6zmONaUXZ_45zcH4p4gqT_zwMeta?.props ?? false,
    meta: indexB3JHUIG7r7yETiPS1Eugl6zmONaUXZ_45zcH4p4gqT_zwMeta || {},
    alias: indexB3JHUIG7r7yETiPS1Eugl6zmONaUXZ_45zcH4p4gqT_zwMeta?.alias || [],
    redirect: indexB3JHUIG7r7yETiPS1Eugl6zmONaUXZ_45zcH4p4gqT_zwMeta?.redirect,
    component: () => import("/app/pages/playlists/index.vue")
  },
  {
    name: indexB3JHUIG7r7yETiPS1Eugl6zmONaUXZ_45zcH4p4gqT_zwMeta?.name ?? "playlists___us",
    path: indexB3JHUIG7r7yETiPS1Eugl6zmONaUXZ_45zcH4p4gqT_zwMeta?.path ?? "/us/playlists",
    props: indexB3JHUIG7r7yETiPS1Eugl6zmONaUXZ_45zcH4p4gqT_zwMeta?.props ?? false,
    meta: indexB3JHUIG7r7yETiPS1Eugl6zmONaUXZ_45zcH4p4gqT_zwMeta || {},
    alias: indexB3JHUIG7r7yETiPS1Eugl6zmONaUXZ_45zcH4p4gqT_zwMeta?.alias || [],
    redirect: indexB3JHUIG7r7yETiPS1Eugl6zmONaUXZ_45zcH4p4gqT_zwMeta?.redirect,
    component: () => import("/app/pages/playlists/index.vue")
  },
  {
    name: indexB3JHUIG7r7yETiPS1Eugl6zmONaUXZ_45zcH4p4gqT_zwMeta?.name ?? "playlists___fi",
    path: indexB3JHUIG7r7yETiPS1Eugl6zmONaUXZ_45zcH4p4gqT_zwMeta?.path ?? "/fi/playlists",
    props: indexB3JHUIG7r7yETiPS1Eugl6zmONaUXZ_45zcH4p4gqT_zwMeta?.props ?? false,
    meta: indexB3JHUIG7r7yETiPS1Eugl6zmONaUXZ_45zcH4p4gqT_zwMeta || {},
    alias: indexB3JHUIG7r7yETiPS1Eugl6zmONaUXZ_45zcH4p4gqT_zwMeta?.alias || [],
    redirect: indexB3JHUIG7r7yETiPS1Eugl6zmONaUXZ_45zcH4p4gqT_zwMeta?.redirect,
    component: () => import("/app/pages/playlists/index.vue")
  },
  {
    name: indexB3JHUIG7r7yETiPS1Eugl6zmONaUXZ_45zcH4p4gqT_zwMeta?.name ?? "playlists___sv",
    path: indexB3JHUIG7r7yETiPS1Eugl6zmONaUXZ_45zcH4p4gqT_zwMeta?.path ?? "/sv/playlists",
    props: indexB3JHUIG7r7yETiPS1Eugl6zmONaUXZ_45zcH4p4gqT_zwMeta?.props ?? false,
    meta: indexB3JHUIG7r7yETiPS1Eugl6zmONaUXZ_45zcH4p4gqT_zwMeta || {},
    alias: indexB3JHUIG7r7yETiPS1Eugl6zmONaUXZ_45zcH4p4gqT_zwMeta?.alias || [],
    redirect: indexB3JHUIG7r7yETiPS1Eugl6zmONaUXZ_45zcH4p4gqT_zwMeta?.redirect,
    component: () => import("/app/pages/playlists/index.vue")
  },
  {
    name: indexB3JHUIG7r7yETiPS1Eugl6zmONaUXZ_45zcH4p4gqT_zwMeta?.name ?? "playlists___de",
    path: indexB3JHUIG7r7yETiPS1Eugl6zmONaUXZ_45zcH4p4gqT_zwMeta?.path ?? "/de/playlists",
    props: indexB3JHUIG7r7yETiPS1Eugl6zmONaUXZ_45zcH4p4gqT_zwMeta?.props ?? false,
    meta: indexB3JHUIG7r7yETiPS1Eugl6zmONaUXZ_45zcH4p4gqT_zwMeta || {},
    alias: indexB3JHUIG7r7yETiPS1Eugl6zmONaUXZ_45zcH4p4gqT_zwMeta?.alias || [],
    redirect: indexB3JHUIG7r7yETiPS1Eugl6zmONaUXZ_45zcH4p4gqT_zwMeta?.redirect,
    component: () => import("/app/pages/playlists/index.vue")
  },
  {
    name: settingsNfoJHDlJkT4lkPoJ9YyBN4ADOHrFOuNJGEFtb5mfXZ8Meta?.name ?? "account-settings___en",
    path: settingsNfoJHDlJkT4lkPoJ9YyBN4ADOHrFOuNJGEFtb5mfXZ8Meta?.path ?? "/en/account/settings",
    props: settingsNfoJHDlJkT4lkPoJ9YyBN4ADOHrFOuNJGEFtb5mfXZ8Meta?.props ?? false,
    meta: settingsNfoJHDlJkT4lkPoJ9YyBN4ADOHrFOuNJGEFtb5mfXZ8Meta || {},
    alias: settingsNfoJHDlJkT4lkPoJ9YyBN4ADOHrFOuNJGEFtb5mfXZ8Meta?.alias || [],
    redirect: settingsNfoJHDlJkT4lkPoJ9YyBN4ADOHrFOuNJGEFtb5mfXZ8Meta?.redirect,
    component: () => import("/app/pages/account/settings.vue")
  },
  {
    name: settingsNfoJHDlJkT4lkPoJ9YyBN4ADOHrFOuNJGEFtb5mfXZ8Meta?.name ?? "account-settings___us",
    path: settingsNfoJHDlJkT4lkPoJ9YyBN4ADOHrFOuNJGEFtb5mfXZ8Meta?.path ?? "/us/account/settings",
    props: settingsNfoJHDlJkT4lkPoJ9YyBN4ADOHrFOuNJGEFtb5mfXZ8Meta?.props ?? false,
    meta: settingsNfoJHDlJkT4lkPoJ9YyBN4ADOHrFOuNJGEFtb5mfXZ8Meta || {},
    alias: settingsNfoJHDlJkT4lkPoJ9YyBN4ADOHrFOuNJGEFtb5mfXZ8Meta?.alias || [],
    redirect: settingsNfoJHDlJkT4lkPoJ9YyBN4ADOHrFOuNJGEFtb5mfXZ8Meta?.redirect,
    component: () => import("/app/pages/account/settings.vue")
  },
  {
    name: settingsNfoJHDlJkT4lkPoJ9YyBN4ADOHrFOuNJGEFtb5mfXZ8Meta?.name ?? "account-settings___fi",
    path: settingsNfoJHDlJkT4lkPoJ9YyBN4ADOHrFOuNJGEFtb5mfXZ8Meta?.path ?? "/fi/account/settings",
    props: settingsNfoJHDlJkT4lkPoJ9YyBN4ADOHrFOuNJGEFtb5mfXZ8Meta?.props ?? false,
    meta: settingsNfoJHDlJkT4lkPoJ9YyBN4ADOHrFOuNJGEFtb5mfXZ8Meta || {},
    alias: settingsNfoJHDlJkT4lkPoJ9YyBN4ADOHrFOuNJGEFtb5mfXZ8Meta?.alias || [],
    redirect: settingsNfoJHDlJkT4lkPoJ9YyBN4ADOHrFOuNJGEFtb5mfXZ8Meta?.redirect,
    component: () => import("/app/pages/account/settings.vue")
  },
  {
    name: settingsNfoJHDlJkT4lkPoJ9YyBN4ADOHrFOuNJGEFtb5mfXZ8Meta?.name ?? "account-settings___sv",
    path: settingsNfoJHDlJkT4lkPoJ9YyBN4ADOHrFOuNJGEFtb5mfXZ8Meta?.path ?? "/sv/account/settings",
    props: settingsNfoJHDlJkT4lkPoJ9YyBN4ADOHrFOuNJGEFtb5mfXZ8Meta?.props ?? false,
    meta: settingsNfoJHDlJkT4lkPoJ9YyBN4ADOHrFOuNJGEFtb5mfXZ8Meta || {},
    alias: settingsNfoJHDlJkT4lkPoJ9YyBN4ADOHrFOuNJGEFtb5mfXZ8Meta?.alias || [],
    redirect: settingsNfoJHDlJkT4lkPoJ9YyBN4ADOHrFOuNJGEFtb5mfXZ8Meta?.redirect,
    component: () => import("/app/pages/account/settings.vue")
  },
  {
    name: settingsNfoJHDlJkT4lkPoJ9YyBN4ADOHrFOuNJGEFtb5mfXZ8Meta?.name ?? "account-settings___de",
    path: settingsNfoJHDlJkT4lkPoJ9YyBN4ADOHrFOuNJGEFtb5mfXZ8Meta?.path ?? "/de/account/settings",
    props: settingsNfoJHDlJkT4lkPoJ9YyBN4ADOHrFOuNJGEFtb5mfXZ8Meta?.props ?? false,
    meta: settingsNfoJHDlJkT4lkPoJ9YyBN4ADOHrFOuNJGEFtb5mfXZ8Meta || {},
    alias: settingsNfoJHDlJkT4lkPoJ9YyBN4ADOHrFOuNJGEFtb5mfXZ8Meta?.alias || [],
    redirect: settingsNfoJHDlJkT4lkPoJ9YyBN4ADOHrFOuNJGEFtb5mfXZ8Meta?.redirect,
    component: () => import("/app/pages/account/settings.vue")
  },
  {
    name: index_45kanGfTOhm6KsvJSWsUjh_45_45mHmXxs5QULD_450HNAtDlQMeta?.name ?? "login-forgot___en",
    path: index_45kanGfTOhm6KsvJSWsUjh_45_45mHmXxs5QULD_450HNAtDlQMeta?.path ?? "/en/login/forgot",
    props: index_45kanGfTOhm6KsvJSWsUjh_45_45mHmXxs5QULD_450HNAtDlQMeta?.props ?? false,
    meta: index_45kanGfTOhm6KsvJSWsUjh_45_45mHmXxs5QULD_450HNAtDlQMeta || {},
    alias: index_45kanGfTOhm6KsvJSWsUjh_45_45mHmXxs5QULD_450HNAtDlQMeta?.alias || [],
    redirect: index_45kanGfTOhm6KsvJSWsUjh_45_45mHmXxs5QULD_450HNAtDlQMeta?.redirect,
    component: () => import("/app/pages/login/forgot/index.vue")
  },
  {
    name: index_45kanGfTOhm6KsvJSWsUjh_45_45mHmXxs5QULD_450HNAtDlQMeta?.name ?? "login-forgot___us",
    path: index_45kanGfTOhm6KsvJSWsUjh_45_45mHmXxs5QULD_450HNAtDlQMeta?.path ?? "/us/login/forgot",
    props: index_45kanGfTOhm6KsvJSWsUjh_45_45mHmXxs5QULD_450HNAtDlQMeta?.props ?? false,
    meta: index_45kanGfTOhm6KsvJSWsUjh_45_45mHmXxs5QULD_450HNAtDlQMeta || {},
    alias: index_45kanGfTOhm6KsvJSWsUjh_45_45mHmXxs5QULD_450HNAtDlQMeta?.alias || [],
    redirect: index_45kanGfTOhm6KsvJSWsUjh_45_45mHmXxs5QULD_450HNAtDlQMeta?.redirect,
    component: () => import("/app/pages/login/forgot/index.vue")
  },
  {
    name: index_45kanGfTOhm6KsvJSWsUjh_45_45mHmXxs5QULD_450HNAtDlQMeta?.name ?? "login-forgot___fi",
    path: index_45kanGfTOhm6KsvJSWsUjh_45_45mHmXxs5QULD_450HNAtDlQMeta?.path ?? "/fi/login/forgot",
    props: index_45kanGfTOhm6KsvJSWsUjh_45_45mHmXxs5QULD_450HNAtDlQMeta?.props ?? false,
    meta: index_45kanGfTOhm6KsvJSWsUjh_45_45mHmXxs5QULD_450HNAtDlQMeta || {},
    alias: index_45kanGfTOhm6KsvJSWsUjh_45_45mHmXxs5QULD_450HNAtDlQMeta?.alias || [],
    redirect: index_45kanGfTOhm6KsvJSWsUjh_45_45mHmXxs5QULD_450HNAtDlQMeta?.redirect,
    component: () => import("/app/pages/login/forgot/index.vue")
  },
  {
    name: index_45kanGfTOhm6KsvJSWsUjh_45_45mHmXxs5QULD_450HNAtDlQMeta?.name ?? "login-forgot___sv",
    path: index_45kanGfTOhm6KsvJSWsUjh_45_45mHmXxs5QULD_450HNAtDlQMeta?.path ?? "/sv/login/forgot",
    props: index_45kanGfTOhm6KsvJSWsUjh_45_45mHmXxs5QULD_450HNAtDlQMeta?.props ?? false,
    meta: index_45kanGfTOhm6KsvJSWsUjh_45_45mHmXxs5QULD_450HNAtDlQMeta || {},
    alias: index_45kanGfTOhm6KsvJSWsUjh_45_45mHmXxs5QULD_450HNAtDlQMeta?.alias || [],
    redirect: index_45kanGfTOhm6KsvJSWsUjh_45_45mHmXxs5QULD_450HNAtDlQMeta?.redirect,
    component: () => import("/app/pages/login/forgot/index.vue")
  },
  {
    name: index_45kanGfTOhm6KsvJSWsUjh_45_45mHmXxs5QULD_450HNAtDlQMeta?.name ?? "login-forgot___de",
    path: index_45kanGfTOhm6KsvJSWsUjh_45_45mHmXxs5QULD_450HNAtDlQMeta?.path ?? "/de/login/forgot",
    props: index_45kanGfTOhm6KsvJSWsUjh_45_45mHmXxs5QULD_450HNAtDlQMeta?.props ?? false,
    meta: index_45kanGfTOhm6KsvJSWsUjh_45_45mHmXxs5QULD_450HNAtDlQMeta || {},
    alias: index_45kanGfTOhm6KsvJSWsUjh_45_45mHmXxs5QULD_450HNAtDlQMeta?.alias || [],
    redirect: index_45kanGfTOhm6KsvJSWsUjh_45_45mHmXxs5QULD_450HNAtDlQMeta?.redirect,
    component: () => import("/app/pages/login/forgot/index.vue")
  },
  {
    name: resetIrMzyjSfn8ipfp_re5quaMmTFoOq35v_vPDHUPCfkMwMeta?.name ?? "login-forgot-reset___en",
    path: resetIrMzyjSfn8ipfp_re5quaMmTFoOq35v_vPDHUPCfkMwMeta?.path ?? "/en/login/forgot/reset",
    props: resetIrMzyjSfn8ipfp_re5quaMmTFoOq35v_vPDHUPCfkMwMeta?.props ?? false,
    meta: resetIrMzyjSfn8ipfp_re5quaMmTFoOq35v_vPDHUPCfkMwMeta || {},
    alias: resetIrMzyjSfn8ipfp_re5quaMmTFoOq35v_vPDHUPCfkMwMeta?.alias || [],
    redirect: resetIrMzyjSfn8ipfp_re5quaMmTFoOq35v_vPDHUPCfkMwMeta?.redirect,
    component: () => import("/app/pages/login/forgot/reset.vue")
  },
  {
    name: resetIrMzyjSfn8ipfp_re5quaMmTFoOq35v_vPDHUPCfkMwMeta?.name ?? "login-forgot-reset___us",
    path: resetIrMzyjSfn8ipfp_re5quaMmTFoOq35v_vPDHUPCfkMwMeta?.path ?? "/us/login/forgot/reset",
    props: resetIrMzyjSfn8ipfp_re5quaMmTFoOq35v_vPDHUPCfkMwMeta?.props ?? false,
    meta: resetIrMzyjSfn8ipfp_re5quaMmTFoOq35v_vPDHUPCfkMwMeta || {},
    alias: resetIrMzyjSfn8ipfp_re5quaMmTFoOq35v_vPDHUPCfkMwMeta?.alias || [],
    redirect: resetIrMzyjSfn8ipfp_re5quaMmTFoOq35v_vPDHUPCfkMwMeta?.redirect,
    component: () => import("/app/pages/login/forgot/reset.vue")
  },
  {
    name: resetIrMzyjSfn8ipfp_re5quaMmTFoOq35v_vPDHUPCfkMwMeta?.name ?? "login-forgot-reset___fi",
    path: resetIrMzyjSfn8ipfp_re5quaMmTFoOq35v_vPDHUPCfkMwMeta?.path ?? "/fi/login/forgot/reset",
    props: resetIrMzyjSfn8ipfp_re5quaMmTFoOq35v_vPDHUPCfkMwMeta?.props ?? false,
    meta: resetIrMzyjSfn8ipfp_re5quaMmTFoOq35v_vPDHUPCfkMwMeta || {},
    alias: resetIrMzyjSfn8ipfp_re5quaMmTFoOq35v_vPDHUPCfkMwMeta?.alias || [],
    redirect: resetIrMzyjSfn8ipfp_re5quaMmTFoOq35v_vPDHUPCfkMwMeta?.redirect,
    component: () => import("/app/pages/login/forgot/reset.vue")
  },
  {
    name: resetIrMzyjSfn8ipfp_re5quaMmTFoOq35v_vPDHUPCfkMwMeta?.name ?? "login-forgot-reset___sv",
    path: resetIrMzyjSfn8ipfp_re5quaMmTFoOq35v_vPDHUPCfkMwMeta?.path ?? "/sv/login/forgot/reset",
    props: resetIrMzyjSfn8ipfp_re5quaMmTFoOq35v_vPDHUPCfkMwMeta?.props ?? false,
    meta: resetIrMzyjSfn8ipfp_re5quaMmTFoOq35v_vPDHUPCfkMwMeta || {},
    alias: resetIrMzyjSfn8ipfp_re5quaMmTFoOq35v_vPDHUPCfkMwMeta?.alias || [],
    redirect: resetIrMzyjSfn8ipfp_re5quaMmTFoOq35v_vPDHUPCfkMwMeta?.redirect,
    component: () => import("/app/pages/login/forgot/reset.vue")
  },
  {
    name: resetIrMzyjSfn8ipfp_re5quaMmTFoOq35v_vPDHUPCfkMwMeta?.name ?? "login-forgot-reset___de",
    path: resetIrMzyjSfn8ipfp_re5quaMmTFoOq35v_vPDHUPCfkMwMeta?.path ?? "/de/login/forgot/reset",
    props: resetIrMzyjSfn8ipfp_re5quaMmTFoOq35v_vPDHUPCfkMwMeta?.props ?? false,
    meta: resetIrMzyjSfn8ipfp_re5quaMmTFoOq35v_vPDHUPCfkMwMeta || {},
    alias: resetIrMzyjSfn8ipfp_re5quaMmTFoOq35v_vPDHUPCfkMwMeta?.alias || [],
    redirect: resetIrMzyjSfn8ipfp_re5quaMmTFoOq35v_vPDHUPCfkMwMeta?.redirect,
    component: () => import("/app/pages/login/forgot/reset.vue")
  },
  {
    name: index_qM02nPIkLr_UKu_457hsBdBbUBBiztECLWwdWk5IpI5wMeta?.name ?? "account-email___en",
    path: index_qM02nPIkLr_UKu_457hsBdBbUBBiztECLWwdWk5IpI5wMeta?.path ?? "/en/account/email",
    props: index_qM02nPIkLr_UKu_457hsBdBbUBBiztECLWwdWk5IpI5wMeta?.props ?? false,
    meta: index_qM02nPIkLr_UKu_457hsBdBbUBBiztECLWwdWk5IpI5wMeta || {},
    alias: index_qM02nPIkLr_UKu_457hsBdBbUBBiztECLWwdWk5IpI5wMeta?.alias || [],
    redirect: index_qM02nPIkLr_UKu_457hsBdBbUBBiztECLWwdWk5IpI5wMeta?.redirect,
    component: () => import("/app/pages/account/email/index.vue")
  },
  {
    name: index_qM02nPIkLr_UKu_457hsBdBbUBBiztECLWwdWk5IpI5wMeta?.name ?? "account-email___us",
    path: index_qM02nPIkLr_UKu_457hsBdBbUBBiztECLWwdWk5IpI5wMeta?.path ?? "/us/account/email",
    props: index_qM02nPIkLr_UKu_457hsBdBbUBBiztECLWwdWk5IpI5wMeta?.props ?? false,
    meta: index_qM02nPIkLr_UKu_457hsBdBbUBBiztECLWwdWk5IpI5wMeta || {},
    alias: index_qM02nPIkLr_UKu_457hsBdBbUBBiztECLWwdWk5IpI5wMeta?.alias || [],
    redirect: index_qM02nPIkLr_UKu_457hsBdBbUBBiztECLWwdWk5IpI5wMeta?.redirect,
    component: () => import("/app/pages/account/email/index.vue")
  },
  {
    name: index_qM02nPIkLr_UKu_457hsBdBbUBBiztECLWwdWk5IpI5wMeta?.name ?? "account-email___fi",
    path: index_qM02nPIkLr_UKu_457hsBdBbUBBiztECLWwdWk5IpI5wMeta?.path ?? "/fi/account/email",
    props: index_qM02nPIkLr_UKu_457hsBdBbUBBiztECLWwdWk5IpI5wMeta?.props ?? false,
    meta: index_qM02nPIkLr_UKu_457hsBdBbUBBiztECLWwdWk5IpI5wMeta || {},
    alias: index_qM02nPIkLr_UKu_457hsBdBbUBBiztECLWwdWk5IpI5wMeta?.alias || [],
    redirect: index_qM02nPIkLr_UKu_457hsBdBbUBBiztECLWwdWk5IpI5wMeta?.redirect,
    component: () => import("/app/pages/account/email/index.vue")
  },
  {
    name: index_qM02nPIkLr_UKu_457hsBdBbUBBiztECLWwdWk5IpI5wMeta?.name ?? "account-email___sv",
    path: index_qM02nPIkLr_UKu_457hsBdBbUBBiztECLWwdWk5IpI5wMeta?.path ?? "/sv/account/email",
    props: index_qM02nPIkLr_UKu_457hsBdBbUBBiztECLWwdWk5IpI5wMeta?.props ?? false,
    meta: index_qM02nPIkLr_UKu_457hsBdBbUBBiztECLWwdWk5IpI5wMeta || {},
    alias: index_qM02nPIkLr_UKu_457hsBdBbUBBiztECLWwdWk5IpI5wMeta?.alias || [],
    redirect: index_qM02nPIkLr_UKu_457hsBdBbUBBiztECLWwdWk5IpI5wMeta?.redirect,
    component: () => import("/app/pages/account/email/index.vue")
  },
  {
    name: index_qM02nPIkLr_UKu_457hsBdBbUBBiztECLWwdWk5IpI5wMeta?.name ?? "account-email___de",
    path: index_qM02nPIkLr_UKu_457hsBdBbUBBiztECLWwdWk5IpI5wMeta?.path ?? "/de/account/email",
    props: index_qM02nPIkLr_UKu_457hsBdBbUBBiztECLWwdWk5IpI5wMeta?.props ?? false,
    meta: index_qM02nPIkLr_UKu_457hsBdBbUBBiztECLWwdWk5IpI5wMeta || {},
    alias: index_qM02nPIkLr_UKu_457hsBdBbUBBiztECLWwdWk5IpI5wMeta?.alias || [],
    redirect: index_qM02nPIkLr_UKu_457hsBdBbUBBiztECLWwdWk5IpI5wMeta?.redirect,
    component: () => import("/app/pages/account/email/index.vue")
  },
  {
    name: index9dvdwdqehqEuFgKrgBz5ioL1AIF1hsK6mgq6zSb9C0MMeta?.name ?? "search-results___en",
    path: index9dvdwdqehqEuFgKrgBz5ioL1AIF1hsK6mgq6zSb9C0MMeta?.path ?? "/en/search/results",
    props: index9dvdwdqehqEuFgKrgBz5ioL1AIF1hsK6mgq6zSb9C0MMeta?.props ?? false,
    meta: index9dvdwdqehqEuFgKrgBz5ioL1AIF1hsK6mgq6zSb9C0MMeta || {},
    alias: index9dvdwdqehqEuFgKrgBz5ioL1AIF1hsK6mgq6zSb9C0MMeta?.alias || [],
    redirect: index9dvdwdqehqEuFgKrgBz5ioL1AIF1hsK6mgq6zSb9C0MMeta?.redirect,
    component: () => import("/app/pages/search/results/index.vue")
  },
  {
    name: index9dvdwdqehqEuFgKrgBz5ioL1AIF1hsK6mgq6zSb9C0MMeta?.name ?? "search-results___us",
    path: index9dvdwdqehqEuFgKrgBz5ioL1AIF1hsK6mgq6zSb9C0MMeta?.path ?? "/us/search/results",
    props: index9dvdwdqehqEuFgKrgBz5ioL1AIF1hsK6mgq6zSb9C0MMeta?.props ?? false,
    meta: index9dvdwdqehqEuFgKrgBz5ioL1AIF1hsK6mgq6zSb9C0MMeta || {},
    alias: index9dvdwdqehqEuFgKrgBz5ioL1AIF1hsK6mgq6zSb9C0MMeta?.alias || [],
    redirect: index9dvdwdqehqEuFgKrgBz5ioL1AIF1hsK6mgq6zSb9C0MMeta?.redirect,
    component: () => import("/app/pages/search/results/index.vue")
  },
  {
    name: index9dvdwdqehqEuFgKrgBz5ioL1AIF1hsK6mgq6zSb9C0MMeta?.name ?? "search-results___fi",
    path: index9dvdwdqehqEuFgKrgBz5ioL1AIF1hsK6mgq6zSb9C0MMeta?.path ?? "/fi/search/results",
    props: index9dvdwdqehqEuFgKrgBz5ioL1AIF1hsK6mgq6zSb9C0MMeta?.props ?? false,
    meta: index9dvdwdqehqEuFgKrgBz5ioL1AIF1hsK6mgq6zSb9C0MMeta || {},
    alias: index9dvdwdqehqEuFgKrgBz5ioL1AIF1hsK6mgq6zSb9C0MMeta?.alias || [],
    redirect: index9dvdwdqehqEuFgKrgBz5ioL1AIF1hsK6mgq6zSb9C0MMeta?.redirect,
    component: () => import("/app/pages/search/results/index.vue")
  },
  {
    name: index9dvdwdqehqEuFgKrgBz5ioL1AIF1hsK6mgq6zSb9C0MMeta?.name ?? "search-results___sv",
    path: index9dvdwdqehqEuFgKrgBz5ioL1AIF1hsK6mgq6zSb9C0MMeta?.path ?? "/sv/search/results",
    props: index9dvdwdqehqEuFgKrgBz5ioL1AIF1hsK6mgq6zSb9C0MMeta?.props ?? false,
    meta: index9dvdwdqehqEuFgKrgBz5ioL1AIF1hsK6mgq6zSb9C0MMeta || {},
    alias: index9dvdwdqehqEuFgKrgBz5ioL1AIF1hsK6mgq6zSb9C0MMeta?.alias || [],
    redirect: index9dvdwdqehqEuFgKrgBz5ioL1AIF1hsK6mgq6zSb9C0MMeta?.redirect,
    component: () => import("/app/pages/search/results/index.vue")
  },
  {
    name: index9dvdwdqehqEuFgKrgBz5ioL1AIF1hsK6mgq6zSb9C0MMeta?.name ?? "search-results___de",
    path: index9dvdwdqehqEuFgKrgBz5ioL1AIF1hsK6mgq6zSb9C0MMeta?.path ?? "/de/search/results",
    props: index9dvdwdqehqEuFgKrgBz5ioL1AIF1hsK6mgq6zSb9C0MMeta?.props ?? false,
    meta: index9dvdwdqehqEuFgKrgBz5ioL1AIF1hsK6mgq6zSb9C0MMeta || {},
    alias: index9dvdwdqehqEuFgKrgBz5ioL1AIF1hsK6mgq6zSb9C0MMeta?.alias || [],
    redirect: index9dvdwdqehqEuFgKrgBz5ioL1AIF1hsK6mgq6zSb9C0MMeta?.redirect,
    component: () => import("/app/pages/search/results/index.vue")
  },
  {
    name: _91hash_93saIorKqFVXCc2a35Zg91KDsIGwBEbIFd0vAohyTWdMoMeta?.name ?? "artists-slug-hash___en",
    path: _91hash_93saIorKqFVXCc2a35Zg91KDsIGwBEbIFd0vAohyTWdMoMeta?.path ?? "/en/artists/:slug()/:hash()",
    props: _91hash_93saIorKqFVXCc2a35Zg91KDsIGwBEbIFd0vAohyTWdMoMeta?.props ?? false,
    meta: _91hash_93saIorKqFVXCc2a35Zg91KDsIGwBEbIFd0vAohyTWdMoMeta || {},
    alias: _91hash_93saIorKqFVXCc2a35Zg91KDsIGwBEbIFd0vAohyTWdMoMeta?.alias || [],
    redirect: _91hash_93saIorKqFVXCc2a35Zg91KDsIGwBEbIFd0vAohyTWdMoMeta?.redirect,
    component: () => import("/app/pages/artists/[slug]/[hash].vue")
  },
  {
    name: _91hash_93saIorKqFVXCc2a35Zg91KDsIGwBEbIFd0vAohyTWdMoMeta?.name ?? "artists-slug-hash___us",
    path: _91hash_93saIorKqFVXCc2a35Zg91KDsIGwBEbIFd0vAohyTWdMoMeta?.path ?? "/us/artists/:slug()/:hash()",
    props: _91hash_93saIorKqFVXCc2a35Zg91KDsIGwBEbIFd0vAohyTWdMoMeta?.props ?? false,
    meta: _91hash_93saIorKqFVXCc2a35Zg91KDsIGwBEbIFd0vAohyTWdMoMeta || {},
    alias: _91hash_93saIorKqFVXCc2a35Zg91KDsIGwBEbIFd0vAohyTWdMoMeta?.alias || [],
    redirect: _91hash_93saIorKqFVXCc2a35Zg91KDsIGwBEbIFd0vAohyTWdMoMeta?.redirect,
    component: () => import("/app/pages/artists/[slug]/[hash].vue")
  },
  {
    name: _91hash_93saIorKqFVXCc2a35Zg91KDsIGwBEbIFd0vAohyTWdMoMeta?.name ?? "artists-slug-hash___fi",
    path: _91hash_93saIorKqFVXCc2a35Zg91KDsIGwBEbIFd0vAohyTWdMoMeta?.path ?? "/fi/artists/:slug()/:hash()",
    props: _91hash_93saIorKqFVXCc2a35Zg91KDsIGwBEbIFd0vAohyTWdMoMeta?.props ?? false,
    meta: _91hash_93saIorKqFVXCc2a35Zg91KDsIGwBEbIFd0vAohyTWdMoMeta || {},
    alias: _91hash_93saIorKqFVXCc2a35Zg91KDsIGwBEbIFd0vAohyTWdMoMeta?.alias || [],
    redirect: _91hash_93saIorKqFVXCc2a35Zg91KDsIGwBEbIFd0vAohyTWdMoMeta?.redirect,
    component: () => import("/app/pages/artists/[slug]/[hash].vue")
  },
  {
    name: _91hash_93saIorKqFVXCc2a35Zg91KDsIGwBEbIFd0vAohyTWdMoMeta?.name ?? "artists-slug-hash___sv",
    path: _91hash_93saIorKqFVXCc2a35Zg91KDsIGwBEbIFd0vAohyTWdMoMeta?.path ?? "/sv/artists/:slug()/:hash()",
    props: _91hash_93saIorKqFVXCc2a35Zg91KDsIGwBEbIFd0vAohyTWdMoMeta?.props ?? false,
    meta: _91hash_93saIorKqFVXCc2a35Zg91KDsIGwBEbIFd0vAohyTWdMoMeta || {},
    alias: _91hash_93saIorKqFVXCc2a35Zg91KDsIGwBEbIFd0vAohyTWdMoMeta?.alias || [],
    redirect: _91hash_93saIorKqFVXCc2a35Zg91KDsIGwBEbIFd0vAohyTWdMoMeta?.redirect,
    component: () => import("/app/pages/artists/[slug]/[hash].vue")
  },
  {
    name: _91hash_93saIorKqFVXCc2a35Zg91KDsIGwBEbIFd0vAohyTWdMoMeta?.name ?? "artists-slug-hash___de",
    path: _91hash_93saIorKqFVXCc2a35Zg91KDsIGwBEbIFd0vAohyTWdMoMeta?.path ?? "/de/artists/:slug()/:hash()",
    props: _91hash_93saIorKqFVXCc2a35Zg91KDsIGwBEbIFd0vAohyTWdMoMeta?.props ?? false,
    meta: _91hash_93saIorKqFVXCc2a35Zg91KDsIGwBEbIFd0vAohyTWdMoMeta || {},
    alias: _91hash_93saIorKqFVXCc2a35Zg91KDsIGwBEbIFd0vAohyTWdMoMeta?.alias || [],
    redirect: _91hash_93saIorKqFVXCc2a35Zg91KDsIGwBEbIFd0vAohyTWdMoMeta?.redirect,
    component: () => import("/app/pages/artists/[slug]/[hash].vue")
  },
  {
    name: _91search_93dQFwr8BOQTuJDCprb1gzcwUEePhTuj4vnjNAxtUeIqgMeta?.name ?? "results-songs-search___en",
    path: _91search_93dQFwr8BOQTuJDCprb1gzcwUEePhTuj4vnjNAxtUeIqgMeta?.path ?? "/en/results/songs/:search()",
    props: _91search_93dQFwr8BOQTuJDCprb1gzcwUEePhTuj4vnjNAxtUeIqgMeta?.props ?? false,
    meta: _91search_93dQFwr8BOQTuJDCprb1gzcwUEePhTuj4vnjNAxtUeIqgMeta || {},
    alias: _91search_93dQFwr8BOQTuJDCprb1gzcwUEePhTuj4vnjNAxtUeIqgMeta?.alias || [],
    redirect: _91search_93dQFwr8BOQTuJDCprb1gzcwUEePhTuj4vnjNAxtUeIqgMeta?.redirect,
    component: () => import("/app/pages/results/songs/[search].vue")
  },
  {
    name: _91search_93dQFwr8BOQTuJDCprb1gzcwUEePhTuj4vnjNAxtUeIqgMeta?.name ?? "results-songs-search___us",
    path: _91search_93dQFwr8BOQTuJDCprb1gzcwUEePhTuj4vnjNAxtUeIqgMeta?.path ?? "/us/results/songs/:search()",
    props: _91search_93dQFwr8BOQTuJDCprb1gzcwUEePhTuj4vnjNAxtUeIqgMeta?.props ?? false,
    meta: _91search_93dQFwr8BOQTuJDCprb1gzcwUEePhTuj4vnjNAxtUeIqgMeta || {},
    alias: _91search_93dQFwr8BOQTuJDCprb1gzcwUEePhTuj4vnjNAxtUeIqgMeta?.alias || [],
    redirect: _91search_93dQFwr8BOQTuJDCprb1gzcwUEePhTuj4vnjNAxtUeIqgMeta?.redirect,
    component: () => import("/app/pages/results/songs/[search].vue")
  },
  {
    name: _91search_93dQFwr8BOQTuJDCprb1gzcwUEePhTuj4vnjNAxtUeIqgMeta?.name ?? "results-songs-search___fi",
    path: _91search_93dQFwr8BOQTuJDCprb1gzcwUEePhTuj4vnjNAxtUeIqgMeta?.path ?? "/fi/results/songs/:search()",
    props: _91search_93dQFwr8BOQTuJDCprb1gzcwUEePhTuj4vnjNAxtUeIqgMeta?.props ?? false,
    meta: _91search_93dQFwr8BOQTuJDCprb1gzcwUEePhTuj4vnjNAxtUeIqgMeta || {},
    alias: _91search_93dQFwr8BOQTuJDCprb1gzcwUEePhTuj4vnjNAxtUeIqgMeta?.alias || [],
    redirect: _91search_93dQFwr8BOQTuJDCprb1gzcwUEePhTuj4vnjNAxtUeIqgMeta?.redirect,
    component: () => import("/app/pages/results/songs/[search].vue")
  },
  {
    name: _91search_93dQFwr8BOQTuJDCprb1gzcwUEePhTuj4vnjNAxtUeIqgMeta?.name ?? "results-songs-search___sv",
    path: _91search_93dQFwr8BOQTuJDCprb1gzcwUEePhTuj4vnjNAxtUeIqgMeta?.path ?? "/sv/results/songs/:search()",
    props: _91search_93dQFwr8BOQTuJDCprb1gzcwUEePhTuj4vnjNAxtUeIqgMeta?.props ?? false,
    meta: _91search_93dQFwr8BOQTuJDCprb1gzcwUEePhTuj4vnjNAxtUeIqgMeta || {},
    alias: _91search_93dQFwr8BOQTuJDCprb1gzcwUEePhTuj4vnjNAxtUeIqgMeta?.alias || [],
    redirect: _91search_93dQFwr8BOQTuJDCprb1gzcwUEePhTuj4vnjNAxtUeIqgMeta?.redirect,
    component: () => import("/app/pages/results/songs/[search].vue")
  },
  {
    name: _91search_93dQFwr8BOQTuJDCprb1gzcwUEePhTuj4vnjNAxtUeIqgMeta?.name ?? "results-songs-search___de",
    path: _91search_93dQFwr8BOQTuJDCprb1gzcwUEePhTuj4vnjNAxtUeIqgMeta?.path ?? "/de/results/songs/:search()",
    props: _91search_93dQFwr8BOQTuJDCprb1gzcwUEePhTuj4vnjNAxtUeIqgMeta?.props ?? false,
    meta: _91search_93dQFwr8BOQTuJDCprb1gzcwUEePhTuj4vnjNAxtUeIqgMeta || {},
    alias: _91search_93dQFwr8BOQTuJDCprb1gzcwUEePhTuj4vnjNAxtUeIqgMeta?.alias || [],
    redirect: _91search_93dQFwr8BOQTuJDCprb1gzcwUEePhTuj4vnjNAxtUeIqgMeta?.redirect,
    component: () => import("/app/pages/results/songs/[search].vue")
  },
  {
    name: _91hash_93jFC7mF_45Bkep2BZZ6hJmoPTFwGfQPkG_45pRrc7GKMdEHAMeta?.name ?? "playlists-slug-hash___en",
    path: _91hash_93jFC7mF_45Bkep2BZZ6hJmoPTFwGfQPkG_45pRrc7GKMdEHAMeta?.path ?? "/en/playlists/:slug()/:hash()",
    props: _91hash_93jFC7mF_45Bkep2BZZ6hJmoPTFwGfQPkG_45pRrc7GKMdEHAMeta?.props ?? false,
    meta: _91hash_93jFC7mF_45Bkep2BZZ6hJmoPTFwGfQPkG_45pRrc7GKMdEHAMeta || {},
    alias: _91hash_93jFC7mF_45Bkep2BZZ6hJmoPTFwGfQPkG_45pRrc7GKMdEHAMeta?.alias || [],
    redirect: _91hash_93jFC7mF_45Bkep2BZZ6hJmoPTFwGfQPkG_45pRrc7GKMdEHAMeta?.redirect,
    component: () => import("/app/pages/playlists/[slug]/[hash].vue")
  },
  {
    name: _91hash_93jFC7mF_45Bkep2BZZ6hJmoPTFwGfQPkG_45pRrc7GKMdEHAMeta?.name ?? "playlists-slug-hash___us",
    path: _91hash_93jFC7mF_45Bkep2BZZ6hJmoPTFwGfQPkG_45pRrc7GKMdEHAMeta?.path ?? "/us/playlists/:slug()/:hash()",
    props: _91hash_93jFC7mF_45Bkep2BZZ6hJmoPTFwGfQPkG_45pRrc7GKMdEHAMeta?.props ?? false,
    meta: _91hash_93jFC7mF_45Bkep2BZZ6hJmoPTFwGfQPkG_45pRrc7GKMdEHAMeta || {},
    alias: _91hash_93jFC7mF_45Bkep2BZZ6hJmoPTFwGfQPkG_45pRrc7GKMdEHAMeta?.alias || [],
    redirect: _91hash_93jFC7mF_45Bkep2BZZ6hJmoPTFwGfQPkG_45pRrc7GKMdEHAMeta?.redirect,
    component: () => import("/app/pages/playlists/[slug]/[hash].vue")
  },
  {
    name: _91hash_93jFC7mF_45Bkep2BZZ6hJmoPTFwGfQPkG_45pRrc7GKMdEHAMeta?.name ?? "playlists-slug-hash___fi",
    path: _91hash_93jFC7mF_45Bkep2BZZ6hJmoPTFwGfQPkG_45pRrc7GKMdEHAMeta?.path ?? "/fi/playlists/:slug()/:hash()",
    props: _91hash_93jFC7mF_45Bkep2BZZ6hJmoPTFwGfQPkG_45pRrc7GKMdEHAMeta?.props ?? false,
    meta: _91hash_93jFC7mF_45Bkep2BZZ6hJmoPTFwGfQPkG_45pRrc7GKMdEHAMeta || {},
    alias: _91hash_93jFC7mF_45Bkep2BZZ6hJmoPTFwGfQPkG_45pRrc7GKMdEHAMeta?.alias || [],
    redirect: _91hash_93jFC7mF_45Bkep2BZZ6hJmoPTFwGfQPkG_45pRrc7GKMdEHAMeta?.redirect,
    component: () => import("/app/pages/playlists/[slug]/[hash].vue")
  },
  {
    name: _91hash_93jFC7mF_45Bkep2BZZ6hJmoPTFwGfQPkG_45pRrc7GKMdEHAMeta?.name ?? "playlists-slug-hash___sv",
    path: _91hash_93jFC7mF_45Bkep2BZZ6hJmoPTFwGfQPkG_45pRrc7GKMdEHAMeta?.path ?? "/sv/playlists/:slug()/:hash()",
    props: _91hash_93jFC7mF_45Bkep2BZZ6hJmoPTFwGfQPkG_45pRrc7GKMdEHAMeta?.props ?? false,
    meta: _91hash_93jFC7mF_45Bkep2BZZ6hJmoPTFwGfQPkG_45pRrc7GKMdEHAMeta || {},
    alias: _91hash_93jFC7mF_45Bkep2BZZ6hJmoPTFwGfQPkG_45pRrc7GKMdEHAMeta?.alias || [],
    redirect: _91hash_93jFC7mF_45Bkep2BZZ6hJmoPTFwGfQPkG_45pRrc7GKMdEHAMeta?.redirect,
    component: () => import("/app/pages/playlists/[slug]/[hash].vue")
  },
  {
    name: _91hash_93jFC7mF_45Bkep2BZZ6hJmoPTFwGfQPkG_45pRrc7GKMdEHAMeta?.name ?? "playlists-slug-hash___de",
    path: _91hash_93jFC7mF_45Bkep2BZZ6hJmoPTFwGfQPkG_45pRrc7GKMdEHAMeta?.path ?? "/de/playlists/:slug()/:hash()",
    props: _91hash_93jFC7mF_45Bkep2BZZ6hJmoPTFwGfQPkG_45pRrc7GKMdEHAMeta?.props ?? false,
    meta: _91hash_93jFC7mF_45Bkep2BZZ6hJmoPTFwGfQPkG_45pRrc7GKMdEHAMeta || {},
    alias: _91hash_93jFC7mF_45Bkep2BZZ6hJmoPTFwGfQPkG_45pRrc7GKMdEHAMeta?.alias || [],
    redirect: _91hash_93jFC7mF_45Bkep2BZZ6hJmoPTFwGfQPkG_45pRrc7GKMdEHAMeta?.redirect,
    component: () => import("/app/pages/playlists/[slug]/[hash].vue")
  },
  {
    name: _91search_93aakpwq8VhZc5ksQMY3MC_mtk7Kr9ygJlW_450ZjmghZUQMeta?.name ?? "search-results-search___en",
    path: _91search_93aakpwq8VhZc5ksQMY3MC_mtk7Kr9ygJlW_450ZjmghZUQMeta?.path ?? "/en/search/results/:search()",
    props: _91search_93aakpwq8VhZc5ksQMY3MC_mtk7Kr9ygJlW_450ZjmghZUQMeta?.props ?? false,
    meta: _91search_93aakpwq8VhZc5ksQMY3MC_mtk7Kr9ygJlW_450ZjmghZUQMeta || {},
    alias: _91search_93aakpwq8VhZc5ksQMY3MC_mtk7Kr9ygJlW_450ZjmghZUQMeta?.alias || [],
    redirect: _91search_93aakpwq8VhZc5ksQMY3MC_mtk7Kr9ygJlW_450ZjmghZUQMeta?.redirect,
    component: () => import("/app/pages/search/results/[search].vue")
  },
  {
    name: _91search_93aakpwq8VhZc5ksQMY3MC_mtk7Kr9ygJlW_450ZjmghZUQMeta?.name ?? "search-results-search___us",
    path: _91search_93aakpwq8VhZc5ksQMY3MC_mtk7Kr9ygJlW_450ZjmghZUQMeta?.path ?? "/us/search/results/:search()",
    props: _91search_93aakpwq8VhZc5ksQMY3MC_mtk7Kr9ygJlW_450ZjmghZUQMeta?.props ?? false,
    meta: _91search_93aakpwq8VhZc5ksQMY3MC_mtk7Kr9ygJlW_450ZjmghZUQMeta || {},
    alias: _91search_93aakpwq8VhZc5ksQMY3MC_mtk7Kr9ygJlW_450ZjmghZUQMeta?.alias || [],
    redirect: _91search_93aakpwq8VhZc5ksQMY3MC_mtk7Kr9ygJlW_450ZjmghZUQMeta?.redirect,
    component: () => import("/app/pages/search/results/[search].vue")
  },
  {
    name: _91search_93aakpwq8VhZc5ksQMY3MC_mtk7Kr9ygJlW_450ZjmghZUQMeta?.name ?? "search-results-search___fi",
    path: _91search_93aakpwq8VhZc5ksQMY3MC_mtk7Kr9ygJlW_450ZjmghZUQMeta?.path ?? "/fi/search/results/:search()",
    props: _91search_93aakpwq8VhZc5ksQMY3MC_mtk7Kr9ygJlW_450ZjmghZUQMeta?.props ?? false,
    meta: _91search_93aakpwq8VhZc5ksQMY3MC_mtk7Kr9ygJlW_450ZjmghZUQMeta || {},
    alias: _91search_93aakpwq8VhZc5ksQMY3MC_mtk7Kr9ygJlW_450ZjmghZUQMeta?.alias || [],
    redirect: _91search_93aakpwq8VhZc5ksQMY3MC_mtk7Kr9ygJlW_450ZjmghZUQMeta?.redirect,
    component: () => import("/app/pages/search/results/[search].vue")
  },
  {
    name: _91search_93aakpwq8VhZc5ksQMY3MC_mtk7Kr9ygJlW_450ZjmghZUQMeta?.name ?? "search-results-search___sv",
    path: _91search_93aakpwq8VhZc5ksQMY3MC_mtk7Kr9ygJlW_450ZjmghZUQMeta?.path ?? "/sv/search/results/:search()",
    props: _91search_93aakpwq8VhZc5ksQMY3MC_mtk7Kr9ygJlW_450ZjmghZUQMeta?.props ?? false,
    meta: _91search_93aakpwq8VhZc5ksQMY3MC_mtk7Kr9ygJlW_450ZjmghZUQMeta || {},
    alias: _91search_93aakpwq8VhZc5ksQMY3MC_mtk7Kr9ygJlW_450ZjmghZUQMeta?.alias || [],
    redirect: _91search_93aakpwq8VhZc5ksQMY3MC_mtk7Kr9ygJlW_450ZjmghZUQMeta?.redirect,
    component: () => import("/app/pages/search/results/[search].vue")
  },
  {
    name: _91search_93aakpwq8VhZc5ksQMY3MC_mtk7Kr9ygJlW_450ZjmghZUQMeta?.name ?? "search-results-search___de",
    path: _91search_93aakpwq8VhZc5ksQMY3MC_mtk7Kr9ygJlW_450ZjmghZUQMeta?.path ?? "/de/search/results/:search()",
    props: _91search_93aakpwq8VhZc5ksQMY3MC_mtk7Kr9ygJlW_450ZjmghZUQMeta?.props ?? false,
    meta: _91search_93aakpwq8VhZc5ksQMY3MC_mtk7Kr9ygJlW_450ZjmghZUQMeta || {},
    alias: _91search_93aakpwq8VhZc5ksQMY3MC_mtk7Kr9ygJlW_450ZjmghZUQMeta?.alias || [],
    redirect: _91search_93aakpwq8VhZc5ksQMY3MC_mtk7Kr9ygJlW_450ZjmghZUQMeta?.redirect,
    component: () => import("/app/pages/search/results/[search].vue")
  },
  {
    name: _91search_93v_45iVTkokiHWB_8O30vWjXwoHAgdKnmiKvWKtWqv3dgUMeta?.name ?? "results-artists-search___en",
    path: _91search_93v_45iVTkokiHWB_8O30vWjXwoHAgdKnmiKvWKtWqv3dgUMeta?.path ?? "/en/results/artists/:search()",
    props: _91search_93v_45iVTkokiHWB_8O30vWjXwoHAgdKnmiKvWKtWqv3dgUMeta?.props ?? false,
    meta: _91search_93v_45iVTkokiHWB_8O30vWjXwoHAgdKnmiKvWKtWqv3dgUMeta || {},
    alias: _91search_93v_45iVTkokiHWB_8O30vWjXwoHAgdKnmiKvWKtWqv3dgUMeta?.alias || [],
    redirect: _91search_93v_45iVTkokiHWB_8O30vWjXwoHAgdKnmiKvWKtWqv3dgUMeta?.redirect,
    component: () => import("/app/pages/results/artists/[search].vue")
  },
  {
    name: _91search_93v_45iVTkokiHWB_8O30vWjXwoHAgdKnmiKvWKtWqv3dgUMeta?.name ?? "results-artists-search___us",
    path: _91search_93v_45iVTkokiHWB_8O30vWjXwoHAgdKnmiKvWKtWqv3dgUMeta?.path ?? "/us/results/artists/:search()",
    props: _91search_93v_45iVTkokiHWB_8O30vWjXwoHAgdKnmiKvWKtWqv3dgUMeta?.props ?? false,
    meta: _91search_93v_45iVTkokiHWB_8O30vWjXwoHAgdKnmiKvWKtWqv3dgUMeta || {},
    alias: _91search_93v_45iVTkokiHWB_8O30vWjXwoHAgdKnmiKvWKtWqv3dgUMeta?.alias || [],
    redirect: _91search_93v_45iVTkokiHWB_8O30vWjXwoHAgdKnmiKvWKtWqv3dgUMeta?.redirect,
    component: () => import("/app/pages/results/artists/[search].vue")
  },
  {
    name: _91search_93v_45iVTkokiHWB_8O30vWjXwoHAgdKnmiKvWKtWqv3dgUMeta?.name ?? "results-artists-search___fi",
    path: _91search_93v_45iVTkokiHWB_8O30vWjXwoHAgdKnmiKvWKtWqv3dgUMeta?.path ?? "/fi/results/artists/:search()",
    props: _91search_93v_45iVTkokiHWB_8O30vWjXwoHAgdKnmiKvWKtWqv3dgUMeta?.props ?? false,
    meta: _91search_93v_45iVTkokiHWB_8O30vWjXwoHAgdKnmiKvWKtWqv3dgUMeta || {},
    alias: _91search_93v_45iVTkokiHWB_8O30vWjXwoHAgdKnmiKvWKtWqv3dgUMeta?.alias || [],
    redirect: _91search_93v_45iVTkokiHWB_8O30vWjXwoHAgdKnmiKvWKtWqv3dgUMeta?.redirect,
    component: () => import("/app/pages/results/artists/[search].vue")
  },
  {
    name: _91search_93v_45iVTkokiHWB_8O30vWjXwoHAgdKnmiKvWKtWqv3dgUMeta?.name ?? "results-artists-search___sv",
    path: _91search_93v_45iVTkokiHWB_8O30vWjXwoHAgdKnmiKvWKtWqv3dgUMeta?.path ?? "/sv/results/artists/:search()",
    props: _91search_93v_45iVTkokiHWB_8O30vWjXwoHAgdKnmiKvWKtWqv3dgUMeta?.props ?? false,
    meta: _91search_93v_45iVTkokiHWB_8O30vWjXwoHAgdKnmiKvWKtWqv3dgUMeta || {},
    alias: _91search_93v_45iVTkokiHWB_8O30vWjXwoHAgdKnmiKvWKtWqv3dgUMeta?.alias || [],
    redirect: _91search_93v_45iVTkokiHWB_8O30vWjXwoHAgdKnmiKvWKtWqv3dgUMeta?.redirect,
    component: () => import("/app/pages/results/artists/[search].vue")
  },
  {
    name: _91search_93v_45iVTkokiHWB_8O30vWjXwoHAgdKnmiKvWKtWqv3dgUMeta?.name ?? "results-artists-search___de",
    path: _91search_93v_45iVTkokiHWB_8O30vWjXwoHAgdKnmiKvWKtWqv3dgUMeta?.path ?? "/de/results/artists/:search()",
    props: _91search_93v_45iVTkokiHWB_8O30vWjXwoHAgdKnmiKvWKtWqv3dgUMeta?.props ?? false,
    meta: _91search_93v_45iVTkokiHWB_8O30vWjXwoHAgdKnmiKvWKtWqv3dgUMeta || {},
    alias: _91search_93v_45iVTkokiHWB_8O30vWjXwoHAgdKnmiKvWKtWqv3dgUMeta?.alias || [],
    redirect: _91search_93v_45iVTkokiHWB_8O30vWjXwoHAgdKnmiKvWKtWqv3dgUMeta?.redirect,
    component: () => import("/app/pages/results/artists/[search].vue")
  },
  {
    name: _91language_93Svk3JeJxA5PENFgvxSQQZlWFcGAk8A_45D63NpP3lE1mMMeta?.name ?? "songs-language-language___en",
    path: _91language_93Svk3JeJxA5PENFgvxSQQZlWFcGAk8A_45D63NpP3lE1mMMeta?.path ?? "/en/songs/language/:language()",
    props: _91language_93Svk3JeJxA5PENFgvxSQQZlWFcGAk8A_45D63NpP3lE1mMMeta?.props ?? false,
    meta: _91language_93Svk3JeJxA5PENFgvxSQQZlWFcGAk8A_45D63NpP3lE1mMMeta || {},
    alias: _91language_93Svk3JeJxA5PENFgvxSQQZlWFcGAk8A_45D63NpP3lE1mMMeta?.alias || [],
    redirect: _91language_93Svk3JeJxA5PENFgvxSQQZlWFcGAk8A_45D63NpP3lE1mMMeta?.redirect,
    component: () => import("/app/pages/songs/language/[language].vue")
  },
  {
    name: _91language_93Svk3JeJxA5PENFgvxSQQZlWFcGAk8A_45D63NpP3lE1mMMeta?.name ?? "songs-language-language___us",
    path: _91language_93Svk3JeJxA5PENFgvxSQQZlWFcGAk8A_45D63NpP3lE1mMMeta?.path ?? "/us/songs/language/:language()",
    props: _91language_93Svk3JeJxA5PENFgvxSQQZlWFcGAk8A_45D63NpP3lE1mMMeta?.props ?? false,
    meta: _91language_93Svk3JeJxA5PENFgvxSQQZlWFcGAk8A_45D63NpP3lE1mMMeta || {},
    alias: _91language_93Svk3JeJxA5PENFgvxSQQZlWFcGAk8A_45D63NpP3lE1mMMeta?.alias || [],
    redirect: _91language_93Svk3JeJxA5PENFgvxSQQZlWFcGAk8A_45D63NpP3lE1mMMeta?.redirect,
    component: () => import("/app/pages/songs/language/[language].vue")
  },
  {
    name: _91language_93Svk3JeJxA5PENFgvxSQQZlWFcGAk8A_45D63NpP3lE1mMMeta?.name ?? "songs-language-language___fi",
    path: _91language_93Svk3JeJxA5PENFgvxSQQZlWFcGAk8A_45D63NpP3lE1mMMeta?.path ?? "/fi/songs/language/:language()",
    props: _91language_93Svk3JeJxA5PENFgvxSQQZlWFcGAk8A_45D63NpP3lE1mMMeta?.props ?? false,
    meta: _91language_93Svk3JeJxA5PENFgvxSQQZlWFcGAk8A_45D63NpP3lE1mMMeta || {},
    alias: _91language_93Svk3JeJxA5PENFgvxSQQZlWFcGAk8A_45D63NpP3lE1mMMeta?.alias || [],
    redirect: _91language_93Svk3JeJxA5PENFgvxSQQZlWFcGAk8A_45D63NpP3lE1mMMeta?.redirect,
    component: () => import("/app/pages/songs/language/[language].vue")
  },
  {
    name: _91language_93Svk3JeJxA5PENFgvxSQQZlWFcGAk8A_45D63NpP3lE1mMMeta?.name ?? "songs-language-language___sv",
    path: _91language_93Svk3JeJxA5PENFgvxSQQZlWFcGAk8A_45D63NpP3lE1mMMeta?.path ?? "/sv/songs/language/:language()",
    props: _91language_93Svk3JeJxA5PENFgvxSQQZlWFcGAk8A_45D63NpP3lE1mMMeta?.props ?? false,
    meta: _91language_93Svk3JeJxA5PENFgvxSQQZlWFcGAk8A_45D63NpP3lE1mMMeta || {},
    alias: _91language_93Svk3JeJxA5PENFgvxSQQZlWFcGAk8A_45D63NpP3lE1mMMeta?.alias || [],
    redirect: _91language_93Svk3JeJxA5PENFgvxSQQZlWFcGAk8A_45D63NpP3lE1mMMeta?.redirect,
    component: () => import("/app/pages/songs/language/[language].vue")
  },
  {
    name: _91language_93Svk3JeJxA5PENFgvxSQQZlWFcGAk8A_45D63NpP3lE1mMMeta?.name ?? "songs-language-language___de",
    path: _91language_93Svk3JeJxA5PENFgvxSQQZlWFcGAk8A_45D63NpP3lE1mMMeta?.path ?? "/de/songs/language/:language()",
    props: _91language_93Svk3JeJxA5PENFgvxSQQZlWFcGAk8A_45D63NpP3lE1mMMeta?.props ?? false,
    meta: _91language_93Svk3JeJxA5PENFgvxSQQZlWFcGAk8A_45D63NpP3lE1mMMeta || {},
    alias: _91language_93Svk3JeJxA5PENFgvxSQQZlWFcGAk8A_45D63NpP3lE1mMMeta?.alias || [],
    redirect: _91language_93Svk3JeJxA5PENFgvxSQQZlWFcGAk8A_45D63NpP3lE1mMMeta?.redirect,
    component: () => import("/app/pages/songs/language/[language].vue")
  },
  {
    name: _91id_93jDJJz0cGs2r5XP74IZJL9_bCC0QpMnaOT6tAzBhgaQkMeta?.name ?? "genres-name-karaoke-id___en",
    path: _91id_93jDJJz0cGs2r5XP74IZJL9_bCC0QpMnaOT6tAzBhgaQkMeta?.path ?? "/en/genres/:name()-karaoke/:id()",
    props: _91id_93jDJJz0cGs2r5XP74IZJL9_bCC0QpMnaOT6tAzBhgaQkMeta?.props ?? false,
    meta: _91id_93jDJJz0cGs2r5XP74IZJL9_bCC0QpMnaOT6tAzBhgaQkMeta || {},
    alias: _91id_93jDJJz0cGs2r5XP74IZJL9_bCC0QpMnaOT6tAzBhgaQkMeta?.alias || [],
    redirect: _91id_93jDJJz0cGs2r5XP74IZJL9_bCC0QpMnaOT6tAzBhgaQkMeta?.redirect,
    component: () => import("/app/pages/genres/[name]-karaoke/[id].vue")
  },
  {
    name: _91id_93jDJJz0cGs2r5XP74IZJL9_bCC0QpMnaOT6tAzBhgaQkMeta?.name ?? "genres-name-karaoke-id___us",
    path: _91id_93jDJJz0cGs2r5XP74IZJL9_bCC0QpMnaOT6tAzBhgaQkMeta?.path ?? "/us/genres/:name()-karaoke/:id()",
    props: _91id_93jDJJz0cGs2r5XP74IZJL9_bCC0QpMnaOT6tAzBhgaQkMeta?.props ?? false,
    meta: _91id_93jDJJz0cGs2r5XP74IZJL9_bCC0QpMnaOT6tAzBhgaQkMeta || {},
    alias: _91id_93jDJJz0cGs2r5XP74IZJL9_bCC0QpMnaOT6tAzBhgaQkMeta?.alias || [],
    redirect: _91id_93jDJJz0cGs2r5XP74IZJL9_bCC0QpMnaOT6tAzBhgaQkMeta?.redirect,
    component: () => import("/app/pages/genres/[name]-karaoke/[id].vue")
  },
  {
    name: _91id_93jDJJz0cGs2r5XP74IZJL9_bCC0QpMnaOT6tAzBhgaQkMeta?.name ?? "genres-name-karaoke-id___fi",
    path: _91id_93jDJJz0cGs2r5XP74IZJL9_bCC0QpMnaOT6tAzBhgaQkMeta?.path ?? "/fi/genres/:name()-karaoke/:id()",
    props: _91id_93jDJJz0cGs2r5XP74IZJL9_bCC0QpMnaOT6tAzBhgaQkMeta?.props ?? false,
    meta: _91id_93jDJJz0cGs2r5XP74IZJL9_bCC0QpMnaOT6tAzBhgaQkMeta || {},
    alias: _91id_93jDJJz0cGs2r5XP74IZJL9_bCC0QpMnaOT6tAzBhgaQkMeta?.alias || [],
    redirect: _91id_93jDJJz0cGs2r5XP74IZJL9_bCC0QpMnaOT6tAzBhgaQkMeta?.redirect,
    component: () => import("/app/pages/genres/[name]-karaoke/[id].vue")
  },
  {
    name: _91id_93jDJJz0cGs2r5XP74IZJL9_bCC0QpMnaOT6tAzBhgaQkMeta?.name ?? "genres-name-karaoke-id___sv",
    path: _91id_93jDJJz0cGs2r5XP74IZJL9_bCC0QpMnaOT6tAzBhgaQkMeta?.path ?? "/sv/genres/:name()-karaoke/:id()",
    props: _91id_93jDJJz0cGs2r5XP74IZJL9_bCC0QpMnaOT6tAzBhgaQkMeta?.props ?? false,
    meta: _91id_93jDJJz0cGs2r5XP74IZJL9_bCC0QpMnaOT6tAzBhgaQkMeta || {},
    alias: _91id_93jDJJz0cGs2r5XP74IZJL9_bCC0QpMnaOT6tAzBhgaQkMeta?.alias || [],
    redirect: _91id_93jDJJz0cGs2r5XP74IZJL9_bCC0QpMnaOT6tAzBhgaQkMeta?.redirect,
    component: () => import("/app/pages/genres/[name]-karaoke/[id].vue")
  },
  {
    name: _91id_93jDJJz0cGs2r5XP74IZJL9_bCC0QpMnaOT6tAzBhgaQkMeta?.name ?? "genres-name-karaoke-id___de",
    path: _91id_93jDJJz0cGs2r5XP74IZJL9_bCC0QpMnaOT6tAzBhgaQkMeta?.path ?? "/de/genres/:name()-karaoke/:id()",
    props: _91id_93jDJJz0cGs2r5XP74IZJL9_bCC0QpMnaOT6tAzBhgaQkMeta?.props ?? false,
    meta: _91id_93jDJJz0cGs2r5XP74IZJL9_bCC0QpMnaOT6tAzBhgaQkMeta || {},
    alias: _91id_93jDJJz0cGs2r5XP74IZJL9_bCC0QpMnaOT6tAzBhgaQkMeta?.alias || [],
    redirect: _91id_93jDJJz0cGs2r5XP74IZJL9_bCC0QpMnaOT6tAzBhgaQkMeta?.redirect,
    component: () => import("/app/pages/genres/[name]-karaoke/[id].vue")
  },
  {
    name: _91search_93IahwNE8svffCb4vGopfsPJBkXMFanrC5aSKhuV7gdx8Meta?.name ?? "results-playlists-search___en",
    path: _91search_93IahwNE8svffCb4vGopfsPJBkXMFanrC5aSKhuV7gdx8Meta?.path ?? "/en/results/playlists/:search()",
    props: _91search_93IahwNE8svffCb4vGopfsPJBkXMFanrC5aSKhuV7gdx8Meta?.props ?? false,
    meta: _91search_93IahwNE8svffCb4vGopfsPJBkXMFanrC5aSKhuV7gdx8Meta || {},
    alias: _91search_93IahwNE8svffCb4vGopfsPJBkXMFanrC5aSKhuV7gdx8Meta?.alias || [],
    redirect: _91search_93IahwNE8svffCb4vGopfsPJBkXMFanrC5aSKhuV7gdx8Meta?.redirect,
    component: () => import("/app/pages/results/playlists/[search].vue")
  },
  {
    name: _91search_93IahwNE8svffCb4vGopfsPJBkXMFanrC5aSKhuV7gdx8Meta?.name ?? "results-playlists-search___us",
    path: _91search_93IahwNE8svffCb4vGopfsPJBkXMFanrC5aSKhuV7gdx8Meta?.path ?? "/us/results/playlists/:search()",
    props: _91search_93IahwNE8svffCb4vGopfsPJBkXMFanrC5aSKhuV7gdx8Meta?.props ?? false,
    meta: _91search_93IahwNE8svffCb4vGopfsPJBkXMFanrC5aSKhuV7gdx8Meta || {},
    alias: _91search_93IahwNE8svffCb4vGopfsPJBkXMFanrC5aSKhuV7gdx8Meta?.alias || [],
    redirect: _91search_93IahwNE8svffCb4vGopfsPJBkXMFanrC5aSKhuV7gdx8Meta?.redirect,
    component: () => import("/app/pages/results/playlists/[search].vue")
  },
  {
    name: _91search_93IahwNE8svffCb4vGopfsPJBkXMFanrC5aSKhuV7gdx8Meta?.name ?? "results-playlists-search___fi",
    path: _91search_93IahwNE8svffCb4vGopfsPJBkXMFanrC5aSKhuV7gdx8Meta?.path ?? "/fi/results/playlists/:search()",
    props: _91search_93IahwNE8svffCb4vGopfsPJBkXMFanrC5aSKhuV7gdx8Meta?.props ?? false,
    meta: _91search_93IahwNE8svffCb4vGopfsPJBkXMFanrC5aSKhuV7gdx8Meta || {},
    alias: _91search_93IahwNE8svffCb4vGopfsPJBkXMFanrC5aSKhuV7gdx8Meta?.alias || [],
    redirect: _91search_93IahwNE8svffCb4vGopfsPJBkXMFanrC5aSKhuV7gdx8Meta?.redirect,
    component: () => import("/app/pages/results/playlists/[search].vue")
  },
  {
    name: _91search_93IahwNE8svffCb4vGopfsPJBkXMFanrC5aSKhuV7gdx8Meta?.name ?? "results-playlists-search___sv",
    path: _91search_93IahwNE8svffCb4vGopfsPJBkXMFanrC5aSKhuV7gdx8Meta?.path ?? "/sv/results/playlists/:search()",
    props: _91search_93IahwNE8svffCb4vGopfsPJBkXMFanrC5aSKhuV7gdx8Meta?.props ?? false,
    meta: _91search_93IahwNE8svffCb4vGopfsPJBkXMFanrC5aSKhuV7gdx8Meta || {},
    alias: _91search_93IahwNE8svffCb4vGopfsPJBkXMFanrC5aSKhuV7gdx8Meta?.alias || [],
    redirect: _91search_93IahwNE8svffCb4vGopfsPJBkXMFanrC5aSKhuV7gdx8Meta?.redirect,
    component: () => import("/app/pages/results/playlists/[search].vue")
  },
  {
    name: _91search_93IahwNE8svffCb4vGopfsPJBkXMFanrC5aSKhuV7gdx8Meta?.name ?? "results-playlists-search___de",
    path: _91search_93IahwNE8svffCb4vGopfsPJBkXMFanrC5aSKhuV7gdx8Meta?.path ?? "/de/results/playlists/:search()",
    props: _91search_93IahwNE8svffCb4vGopfsPJBkXMFanrC5aSKhuV7gdx8Meta?.props ?? false,
    meta: _91search_93IahwNE8svffCb4vGopfsPJBkXMFanrC5aSKhuV7gdx8Meta || {},
    alias: _91search_93IahwNE8svffCb4vGopfsPJBkXMFanrC5aSKhuV7gdx8Meta?.alias || [],
    redirect: _91search_93IahwNE8svffCb4vGopfsPJBkXMFanrC5aSKhuV7gdx8Meta?.redirect,
    component: () => import("/app/pages/results/playlists/[search].vue")
  },
  {
    name: index5cDMnHYtH7pw9NnzKAvKbXDo9Xv_45KhDIvKecBkcmbJcMeta?.name ?? "karaoke-near-me-term___en",
    path: index5cDMnHYtH7pw9NnzKAvKbXDo9Xv_45KhDIvKecBkcmbJcMeta?.path ?? "/en/karaoke-near-me/:term()",
    props: index5cDMnHYtH7pw9NnzKAvKbXDo9Xv_45KhDIvKecBkcmbJcMeta?.props ?? false,
    meta: index5cDMnHYtH7pw9NnzKAvKbXDo9Xv_45KhDIvKecBkcmbJcMeta || {},
    alias: index5cDMnHYtH7pw9NnzKAvKbXDo9Xv_45KhDIvKecBkcmbJcMeta?.alias || [],
    redirect: index5cDMnHYtH7pw9NnzKAvKbXDo9Xv_45KhDIvKecBkcmbJcMeta?.redirect,
    component: () => import("/app/pages/karaoke-near-me/[term]/index.vue")
  },
  {
    name: index5cDMnHYtH7pw9NnzKAvKbXDo9Xv_45KhDIvKecBkcmbJcMeta?.name ?? "karaoke-near-me-term___us",
    path: index5cDMnHYtH7pw9NnzKAvKbXDo9Xv_45KhDIvKecBkcmbJcMeta?.path ?? "/us/karaoke-near-me/:term()",
    props: index5cDMnHYtH7pw9NnzKAvKbXDo9Xv_45KhDIvKecBkcmbJcMeta?.props ?? false,
    meta: index5cDMnHYtH7pw9NnzKAvKbXDo9Xv_45KhDIvKecBkcmbJcMeta || {},
    alias: index5cDMnHYtH7pw9NnzKAvKbXDo9Xv_45KhDIvKecBkcmbJcMeta?.alias || [],
    redirect: index5cDMnHYtH7pw9NnzKAvKbXDo9Xv_45KhDIvKecBkcmbJcMeta?.redirect,
    component: () => import("/app/pages/karaoke-near-me/[term]/index.vue")
  },
  {
    name: index5cDMnHYtH7pw9NnzKAvKbXDo9Xv_45KhDIvKecBkcmbJcMeta?.name ?? "karaoke-near-me-term___fi",
    path: index5cDMnHYtH7pw9NnzKAvKbXDo9Xv_45KhDIvKecBkcmbJcMeta?.path ?? "/fi/karaoke-near-me/:term()",
    props: index5cDMnHYtH7pw9NnzKAvKbXDo9Xv_45KhDIvKecBkcmbJcMeta?.props ?? false,
    meta: index5cDMnHYtH7pw9NnzKAvKbXDo9Xv_45KhDIvKecBkcmbJcMeta || {},
    alias: index5cDMnHYtH7pw9NnzKAvKbXDo9Xv_45KhDIvKecBkcmbJcMeta?.alias || [],
    redirect: index5cDMnHYtH7pw9NnzKAvKbXDo9Xv_45KhDIvKecBkcmbJcMeta?.redirect,
    component: () => import("/app/pages/karaoke-near-me/[term]/index.vue")
  },
  {
    name: index5cDMnHYtH7pw9NnzKAvKbXDo9Xv_45KhDIvKecBkcmbJcMeta?.name ?? "karaoke-near-me-term___sv",
    path: index5cDMnHYtH7pw9NnzKAvKbXDo9Xv_45KhDIvKecBkcmbJcMeta?.path ?? "/sv/karaoke-near-me/:term()",
    props: index5cDMnHYtH7pw9NnzKAvKbXDo9Xv_45KhDIvKecBkcmbJcMeta?.props ?? false,
    meta: index5cDMnHYtH7pw9NnzKAvKbXDo9Xv_45KhDIvKecBkcmbJcMeta || {},
    alias: index5cDMnHYtH7pw9NnzKAvKbXDo9Xv_45KhDIvKecBkcmbJcMeta?.alias || [],
    redirect: index5cDMnHYtH7pw9NnzKAvKbXDo9Xv_45KhDIvKecBkcmbJcMeta?.redirect,
    component: () => import("/app/pages/karaoke-near-me/[term]/index.vue")
  },
  {
    name: index5cDMnHYtH7pw9NnzKAvKbXDo9Xv_45KhDIvKecBkcmbJcMeta?.name ?? "karaoke-near-me-term___de",
    path: index5cDMnHYtH7pw9NnzKAvKbXDo9Xv_45KhDIvKecBkcmbJcMeta?.path ?? "/de/karaoke-near-me/:term()",
    props: index5cDMnHYtH7pw9NnzKAvKbXDo9Xv_45KhDIvKecBkcmbJcMeta?.props ?? false,
    meta: index5cDMnHYtH7pw9NnzKAvKbXDo9Xv_45KhDIvKecBkcmbJcMeta || {},
    alias: index5cDMnHYtH7pw9NnzKAvKbXDo9Xv_45KhDIvKecBkcmbJcMeta?.alias || [],
    redirect: index5cDMnHYtH7pw9NnzKAvKbXDo9Xv_45KhDIvKecBkcmbJcMeta?.redirect,
    component: () => import("/app/pages/karaoke-near-me/[term]/index.vue")
  },
  {
    name: _91hash_93gDbgB5qXlfrcSO6io0pSmYG2tMHKtsSNgjbpkNr6iXAMeta?.name ?? "artists-slug-songSlug-hash___en",
    path: _91hash_93gDbgB5qXlfrcSO6io0pSmYG2tMHKtsSNgjbpkNr6iXAMeta?.path ?? "/en/artists/:slug()/:songSlug()/:hash()",
    props: _91hash_93gDbgB5qXlfrcSO6io0pSmYG2tMHKtsSNgjbpkNr6iXAMeta?.props ?? false,
    meta: _91hash_93gDbgB5qXlfrcSO6io0pSmYG2tMHKtsSNgjbpkNr6iXAMeta || {},
    alias: _91hash_93gDbgB5qXlfrcSO6io0pSmYG2tMHKtsSNgjbpkNr6iXAMeta?.alias || [],
    redirect: _91hash_93gDbgB5qXlfrcSO6io0pSmYG2tMHKtsSNgjbpkNr6iXAMeta?.redirect,
    component: () => import("/app/pages/artists/[slug]/[songSlug]/[hash].vue")
  },
  {
    name: _91hash_93gDbgB5qXlfrcSO6io0pSmYG2tMHKtsSNgjbpkNr6iXAMeta?.name ?? "artists-slug-songSlug-hash___us",
    path: _91hash_93gDbgB5qXlfrcSO6io0pSmYG2tMHKtsSNgjbpkNr6iXAMeta?.path ?? "/us/artists/:slug()/:songSlug()/:hash()",
    props: _91hash_93gDbgB5qXlfrcSO6io0pSmYG2tMHKtsSNgjbpkNr6iXAMeta?.props ?? false,
    meta: _91hash_93gDbgB5qXlfrcSO6io0pSmYG2tMHKtsSNgjbpkNr6iXAMeta || {},
    alias: _91hash_93gDbgB5qXlfrcSO6io0pSmYG2tMHKtsSNgjbpkNr6iXAMeta?.alias || [],
    redirect: _91hash_93gDbgB5qXlfrcSO6io0pSmYG2tMHKtsSNgjbpkNr6iXAMeta?.redirect,
    component: () => import("/app/pages/artists/[slug]/[songSlug]/[hash].vue")
  },
  {
    name: _91hash_93gDbgB5qXlfrcSO6io0pSmYG2tMHKtsSNgjbpkNr6iXAMeta?.name ?? "artists-slug-songSlug-hash___fi",
    path: _91hash_93gDbgB5qXlfrcSO6io0pSmYG2tMHKtsSNgjbpkNr6iXAMeta?.path ?? "/fi/artists/:slug()/:songSlug()/:hash()",
    props: _91hash_93gDbgB5qXlfrcSO6io0pSmYG2tMHKtsSNgjbpkNr6iXAMeta?.props ?? false,
    meta: _91hash_93gDbgB5qXlfrcSO6io0pSmYG2tMHKtsSNgjbpkNr6iXAMeta || {},
    alias: _91hash_93gDbgB5qXlfrcSO6io0pSmYG2tMHKtsSNgjbpkNr6iXAMeta?.alias || [],
    redirect: _91hash_93gDbgB5qXlfrcSO6io0pSmYG2tMHKtsSNgjbpkNr6iXAMeta?.redirect,
    component: () => import("/app/pages/artists/[slug]/[songSlug]/[hash].vue")
  },
  {
    name: _91hash_93gDbgB5qXlfrcSO6io0pSmYG2tMHKtsSNgjbpkNr6iXAMeta?.name ?? "artists-slug-songSlug-hash___sv",
    path: _91hash_93gDbgB5qXlfrcSO6io0pSmYG2tMHKtsSNgjbpkNr6iXAMeta?.path ?? "/sv/artists/:slug()/:songSlug()/:hash()",
    props: _91hash_93gDbgB5qXlfrcSO6io0pSmYG2tMHKtsSNgjbpkNr6iXAMeta?.props ?? false,
    meta: _91hash_93gDbgB5qXlfrcSO6io0pSmYG2tMHKtsSNgjbpkNr6iXAMeta || {},
    alias: _91hash_93gDbgB5qXlfrcSO6io0pSmYG2tMHKtsSNgjbpkNr6iXAMeta?.alias || [],
    redirect: _91hash_93gDbgB5qXlfrcSO6io0pSmYG2tMHKtsSNgjbpkNr6iXAMeta?.redirect,
    component: () => import("/app/pages/artists/[slug]/[songSlug]/[hash].vue")
  },
  {
    name: _91hash_93gDbgB5qXlfrcSO6io0pSmYG2tMHKtsSNgjbpkNr6iXAMeta?.name ?? "artists-slug-songSlug-hash___de",
    path: _91hash_93gDbgB5qXlfrcSO6io0pSmYG2tMHKtsSNgjbpkNr6iXAMeta?.path ?? "/de/artists/:slug()/:songSlug()/:hash()",
    props: _91hash_93gDbgB5qXlfrcSO6io0pSmYG2tMHKtsSNgjbpkNr6iXAMeta?.props ?? false,
    meta: _91hash_93gDbgB5qXlfrcSO6io0pSmYG2tMHKtsSNgjbpkNr6iXAMeta || {},
    alias: _91hash_93gDbgB5qXlfrcSO6io0pSmYG2tMHKtsSNgjbpkNr6iXAMeta?.alias || [],
    redirect: _91hash_93gDbgB5qXlfrcSO6io0pSmYG2tMHKtsSNgjbpkNr6iXAMeta?.redirect,
    component: () => import("/app/pages/artists/[slug]/[songSlug]/[hash].vue")
  },
  {
    name: index_45GRzWLeAYrvQTXID0d73S1WmVAVcSD3PudxPvAR5gVgMeta?.name ?? "karaoke-near-me-term-city___en",
    path: index_45GRzWLeAYrvQTXID0d73S1WmVAVcSD3PudxPvAR5gVgMeta?.path ?? "/en/karaoke-near-me/:term()/:city()",
    props: index_45GRzWLeAYrvQTXID0d73S1WmVAVcSD3PudxPvAR5gVgMeta?.props ?? false,
    meta: index_45GRzWLeAYrvQTXID0d73S1WmVAVcSD3PudxPvAR5gVgMeta || {},
    alias: index_45GRzWLeAYrvQTXID0d73S1WmVAVcSD3PudxPvAR5gVgMeta?.alias || [],
    redirect: index_45GRzWLeAYrvQTXID0d73S1WmVAVcSD3PudxPvAR5gVgMeta?.redirect,
    component: () => import("/app/pages/karaoke-near-me/[term]/[city]/index.vue")
  },
  {
    name: index_45GRzWLeAYrvQTXID0d73S1WmVAVcSD3PudxPvAR5gVgMeta?.name ?? "karaoke-near-me-term-city___us",
    path: index_45GRzWLeAYrvQTXID0d73S1WmVAVcSD3PudxPvAR5gVgMeta?.path ?? "/us/karaoke-near-me/:term()/:city()",
    props: index_45GRzWLeAYrvQTXID0d73S1WmVAVcSD3PudxPvAR5gVgMeta?.props ?? false,
    meta: index_45GRzWLeAYrvQTXID0d73S1WmVAVcSD3PudxPvAR5gVgMeta || {},
    alias: index_45GRzWLeAYrvQTXID0d73S1WmVAVcSD3PudxPvAR5gVgMeta?.alias || [],
    redirect: index_45GRzWLeAYrvQTXID0d73S1WmVAVcSD3PudxPvAR5gVgMeta?.redirect,
    component: () => import("/app/pages/karaoke-near-me/[term]/[city]/index.vue")
  },
  {
    name: index_45GRzWLeAYrvQTXID0d73S1WmVAVcSD3PudxPvAR5gVgMeta?.name ?? "karaoke-near-me-term-city___fi",
    path: index_45GRzWLeAYrvQTXID0d73S1WmVAVcSD3PudxPvAR5gVgMeta?.path ?? "/fi/karaoke-near-me/:term()/:city()",
    props: index_45GRzWLeAYrvQTXID0d73S1WmVAVcSD3PudxPvAR5gVgMeta?.props ?? false,
    meta: index_45GRzWLeAYrvQTXID0d73S1WmVAVcSD3PudxPvAR5gVgMeta || {},
    alias: index_45GRzWLeAYrvQTXID0d73S1WmVAVcSD3PudxPvAR5gVgMeta?.alias || [],
    redirect: index_45GRzWLeAYrvQTXID0d73S1WmVAVcSD3PudxPvAR5gVgMeta?.redirect,
    component: () => import("/app/pages/karaoke-near-me/[term]/[city]/index.vue")
  },
  {
    name: index_45GRzWLeAYrvQTXID0d73S1WmVAVcSD3PudxPvAR5gVgMeta?.name ?? "karaoke-near-me-term-city___sv",
    path: index_45GRzWLeAYrvQTXID0d73S1WmVAVcSD3PudxPvAR5gVgMeta?.path ?? "/sv/karaoke-near-me/:term()/:city()",
    props: index_45GRzWLeAYrvQTXID0d73S1WmVAVcSD3PudxPvAR5gVgMeta?.props ?? false,
    meta: index_45GRzWLeAYrvQTXID0d73S1WmVAVcSD3PudxPvAR5gVgMeta || {},
    alias: index_45GRzWLeAYrvQTXID0d73S1WmVAVcSD3PudxPvAR5gVgMeta?.alias || [],
    redirect: index_45GRzWLeAYrvQTXID0d73S1WmVAVcSD3PudxPvAR5gVgMeta?.redirect,
    component: () => import("/app/pages/karaoke-near-me/[term]/[city]/index.vue")
  },
  {
    name: index_45GRzWLeAYrvQTXID0d73S1WmVAVcSD3PudxPvAR5gVgMeta?.name ?? "karaoke-near-me-term-city___de",
    path: index_45GRzWLeAYrvQTXID0d73S1WmVAVcSD3PudxPvAR5gVgMeta?.path ?? "/de/karaoke-near-me/:term()/:city()",
    props: index_45GRzWLeAYrvQTXID0d73S1WmVAVcSD3PudxPvAR5gVgMeta?.props ?? false,
    meta: index_45GRzWLeAYrvQTXID0d73S1WmVAVcSD3PudxPvAR5gVgMeta || {},
    alias: index_45GRzWLeAYrvQTXID0d73S1WmVAVcSD3PudxPvAR5gVgMeta?.alias || [],
    redirect: index_45GRzWLeAYrvQTXID0d73S1WmVAVcSD3PudxPvAR5gVgMeta?.redirect,
    component: () => import("/app/pages/karaoke-near-me/[term]/[city]/index.vue")
  },
  {
    name: _91hash_93lDaaubPCh_452lD18UiRKQ_45SSP0NJ9B6ocVOCStrNzk_sMeta?.name ?? "karaoke-near-me-term-city-slug-hash___en",
    path: _91hash_93lDaaubPCh_452lD18UiRKQ_45SSP0NJ9B6ocVOCStrNzk_sMeta?.path ?? "/en/karaoke-near-me/:term()/:city()/:slug()/:hash()",
    props: _91hash_93lDaaubPCh_452lD18UiRKQ_45SSP0NJ9B6ocVOCStrNzk_sMeta?.props ?? false,
    meta: _91hash_93lDaaubPCh_452lD18UiRKQ_45SSP0NJ9B6ocVOCStrNzk_sMeta || {},
    alias: _91hash_93lDaaubPCh_452lD18UiRKQ_45SSP0NJ9B6ocVOCStrNzk_sMeta?.alias || [],
    redirect: _91hash_93lDaaubPCh_452lD18UiRKQ_45SSP0NJ9B6ocVOCStrNzk_sMeta?.redirect,
    component: () => import("/app/pages/karaoke-near-me/[term]/[city]/[slug]/[hash].vue")
  },
  {
    name: _91hash_93lDaaubPCh_452lD18UiRKQ_45SSP0NJ9B6ocVOCStrNzk_sMeta?.name ?? "karaoke-near-me-term-city-slug-hash___us",
    path: _91hash_93lDaaubPCh_452lD18UiRKQ_45SSP0NJ9B6ocVOCStrNzk_sMeta?.path ?? "/us/karaoke-near-me/:term()/:city()/:slug()/:hash()",
    props: _91hash_93lDaaubPCh_452lD18UiRKQ_45SSP0NJ9B6ocVOCStrNzk_sMeta?.props ?? false,
    meta: _91hash_93lDaaubPCh_452lD18UiRKQ_45SSP0NJ9B6ocVOCStrNzk_sMeta || {},
    alias: _91hash_93lDaaubPCh_452lD18UiRKQ_45SSP0NJ9B6ocVOCStrNzk_sMeta?.alias || [],
    redirect: _91hash_93lDaaubPCh_452lD18UiRKQ_45SSP0NJ9B6ocVOCStrNzk_sMeta?.redirect,
    component: () => import("/app/pages/karaoke-near-me/[term]/[city]/[slug]/[hash].vue")
  },
  {
    name: _91hash_93lDaaubPCh_452lD18UiRKQ_45SSP0NJ9B6ocVOCStrNzk_sMeta?.name ?? "karaoke-near-me-term-city-slug-hash___fi",
    path: _91hash_93lDaaubPCh_452lD18UiRKQ_45SSP0NJ9B6ocVOCStrNzk_sMeta?.path ?? "/fi/karaoke-near-me/:term()/:city()/:slug()/:hash()",
    props: _91hash_93lDaaubPCh_452lD18UiRKQ_45SSP0NJ9B6ocVOCStrNzk_sMeta?.props ?? false,
    meta: _91hash_93lDaaubPCh_452lD18UiRKQ_45SSP0NJ9B6ocVOCStrNzk_sMeta || {},
    alias: _91hash_93lDaaubPCh_452lD18UiRKQ_45SSP0NJ9B6ocVOCStrNzk_sMeta?.alias || [],
    redirect: _91hash_93lDaaubPCh_452lD18UiRKQ_45SSP0NJ9B6ocVOCStrNzk_sMeta?.redirect,
    component: () => import("/app/pages/karaoke-near-me/[term]/[city]/[slug]/[hash].vue")
  },
  {
    name: _91hash_93lDaaubPCh_452lD18UiRKQ_45SSP0NJ9B6ocVOCStrNzk_sMeta?.name ?? "karaoke-near-me-term-city-slug-hash___sv",
    path: _91hash_93lDaaubPCh_452lD18UiRKQ_45SSP0NJ9B6ocVOCStrNzk_sMeta?.path ?? "/sv/karaoke-near-me/:term()/:city()/:slug()/:hash()",
    props: _91hash_93lDaaubPCh_452lD18UiRKQ_45SSP0NJ9B6ocVOCStrNzk_sMeta?.props ?? false,
    meta: _91hash_93lDaaubPCh_452lD18UiRKQ_45SSP0NJ9B6ocVOCStrNzk_sMeta || {},
    alias: _91hash_93lDaaubPCh_452lD18UiRKQ_45SSP0NJ9B6ocVOCStrNzk_sMeta?.alias || [],
    redirect: _91hash_93lDaaubPCh_452lD18UiRKQ_45SSP0NJ9B6ocVOCStrNzk_sMeta?.redirect,
    component: () => import("/app/pages/karaoke-near-me/[term]/[city]/[slug]/[hash].vue")
  },
  {
    name: _91hash_93lDaaubPCh_452lD18UiRKQ_45SSP0NJ9B6ocVOCStrNzk_sMeta?.name ?? "karaoke-near-me-term-city-slug-hash___de",
    path: _91hash_93lDaaubPCh_452lD18UiRKQ_45SSP0NJ9B6ocVOCStrNzk_sMeta?.path ?? "/de/karaoke-near-me/:term()/:city()/:slug()/:hash()",
    props: _91hash_93lDaaubPCh_452lD18UiRKQ_45SSP0NJ9B6ocVOCStrNzk_sMeta?.props ?? false,
    meta: _91hash_93lDaaubPCh_452lD18UiRKQ_45SSP0NJ9B6ocVOCStrNzk_sMeta || {},
    alias: _91hash_93lDaaubPCh_452lD18UiRKQ_45SSP0NJ9B6ocVOCStrNzk_sMeta?.alias || [],
    redirect: _91hash_93lDaaubPCh_452lD18UiRKQ_45SSP0NJ9B6ocVOCStrNzk_sMeta?.redirect,
    component: () => import("/app/pages/karaoke-near-me/[term]/[city]/[slug]/[hash].vue")
  },
  {
    name: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta?.name ?? "signup___en",
    path: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta?.path ?? "/en/signup",
    props: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta?.props ?? false,
    meta: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta || {},
    alias: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta?.alias || [],
    redirect: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta?.redirect,
    component: () => import("/app/pages/login/index.vue")
  },
  {
    name: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta?.name ?? "signup___us",
    path: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta?.path ?? "/us/signup",
    props: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta?.props ?? false,
    meta: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta || {},
    alias: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta?.alias || [],
    redirect: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta?.redirect,
    component: () => import("/app/pages/login/index.vue")
  },
  {
    name: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta?.name ?? "signup___fi",
    path: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta?.path ?? "/fi/signup",
    props: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta?.props ?? false,
    meta: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta || {},
    alias: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta?.alias || [],
    redirect: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta?.redirect,
    component: () => import("/app/pages/login/index.vue")
  },
  {
    name: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta?.name ?? "signup___sv",
    path: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta?.path ?? "/sv/signup",
    props: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta?.props ?? false,
    meta: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta || {},
    alias: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta?.alias || [],
    redirect: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta?.redirect,
    component: () => import("/app/pages/login/index.vue")
  },
  {
    name: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta?.name ?? "signup___de",
    path: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta?.path ?? "/de/signup",
    props: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta?.props ?? false,
    meta: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta || {},
    alias: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta?.alias || [],
    redirect: index06RuUPKkpIkYJ1u5ZQ6kHhkE76Z6rwcTsSwg0fLJR9IMeta?.redirect,
    component: () => import("/app/pages/login/index.vue")
  },
  {
    name: profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta?.name ?? "account___en",
    path: profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta?.path ?? "/en/account",
    props: profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta?.props ?? false,
    meta: profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta || {},
    alias: profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta?.alias || [],
    redirect: profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta?.redirect,
    component: () => import("/app/pages/account/profile.vue")
  },
  {
    name: profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta?.name ?? "account___us",
    path: profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta?.path ?? "/us/account",
    props: profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta?.props ?? false,
    meta: profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta || {},
    alias: profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta?.alias || [],
    redirect: profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta?.redirect,
    component: () => import("/app/pages/account/profile.vue")
  },
  {
    name: profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta?.name ?? "account___fi",
    path: profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta?.path ?? "/fi/account",
    props: profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta?.props ?? false,
    meta: profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta || {},
    alias: profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta?.alias || [],
    redirect: profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta?.redirect,
    component: () => import("/app/pages/account/profile.vue")
  },
  {
    name: profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta?.name ?? "account___sv",
    path: profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta?.path ?? "/sv/account",
    props: profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta?.props ?? false,
    meta: profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta || {},
    alias: profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta?.alias || [],
    redirect: profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta?.redirect,
    component: () => import("/app/pages/account/profile.vue")
  },
  {
    name: profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta?.name ?? "account___de",
    path: profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta?.path ?? "/de/account",
    props: profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta?.props ?? false,
    meta: profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta || {},
    alias: profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta?.alias || [],
    redirect: profileaoKsz5e523syACRfzuI3T7AUyrbLBLKSNMAtddwLwyQMeta?.redirect,
    component: () => import("/app/pages/account/profile.vue")
  },
  {
    name: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.name ?? undefined,
    path: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.path ?? "/en/*/discover",
    props: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.props ?? false,
    meta: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta || {},
    alias: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.alias || [],
    redirect: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.redirect,
    component: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIA
  },
  {
    name: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.name ?? undefined,
    path: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.path ?? "/us/*/discover",
    props: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.props ?? false,
    meta: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta || {},
    alias: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.alias || [],
    redirect: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.redirect,
    component: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIA
  },
  {
    name: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.name ?? undefined,
    path: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.path ?? "/fi/*/discover",
    props: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.props ?? false,
    meta: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta || {},
    alias: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.alias || [],
    redirect: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.redirect,
    component: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIA
  },
  {
    name: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.name ?? undefined,
    path: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.path ?? "/sv/*/discover",
    props: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.props ?? false,
    meta: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta || {},
    alias: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.alias || [],
    redirect: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.redirect,
    component: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIA
  },
  {
    name: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.name ?? undefined,
    path: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.path ?? "/de/*/discover",
    props: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.props ?? false,
    meta: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta || {},
    alias: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.alias || [],
    redirect: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.redirect,
    component: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIA
  },
  {
    name: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.name ?? undefined,
    path: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.path ?? "/en/*/terms-of-use",
    props: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.props ?? false,
    meta: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta || {},
    alias: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.alias || [],
    redirect: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.redirect,
    component: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIA
  },
  {
    name: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.name ?? undefined,
    path: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.path ?? "/us/*/terms-of-use",
    props: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.props ?? false,
    meta: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta || {},
    alias: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.alias || [],
    redirect: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.redirect,
    component: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIA
  },
  {
    name: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.name ?? undefined,
    path: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.path ?? "/fi/*/terms-of-use",
    props: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.props ?? false,
    meta: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta || {},
    alias: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.alias || [],
    redirect: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.redirect,
    component: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIA
  },
  {
    name: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.name ?? undefined,
    path: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.path ?? "/sv/*/terms-of-use",
    props: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.props ?? false,
    meta: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta || {},
    alias: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.alias || [],
    redirect: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.redirect,
    component: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIA
  },
  {
    name: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.name ?? undefined,
    path: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.path ?? "/de/*/terms-of-use",
    props: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.props ?? false,
    meta: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta || {},
    alias: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.alias || [],
    redirect: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.redirect,
    component: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIA
  },
  {
    name: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.name ?? undefined,
    path: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.path ?? "/en/*/privacy-policy",
    props: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.props ?? false,
    meta: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta || {},
    alias: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.alias || [],
    redirect: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.redirect,
    component: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIA
  },
  {
    name: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.name ?? undefined,
    path: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.path ?? "/us/*/privacy-policy",
    props: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.props ?? false,
    meta: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta || {},
    alias: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.alias || [],
    redirect: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.redirect,
    component: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIA
  },
  {
    name: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.name ?? undefined,
    path: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.path ?? "/fi/*/privacy-policy",
    props: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.props ?? false,
    meta: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta || {},
    alias: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.alias || [],
    redirect: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.redirect,
    component: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIA
  },
  {
    name: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.name ?? undefined,
    path: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.path ?? "/sv/*/privacy-policy",
    props: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.props ?? false,
    meta: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta || {},
    alias: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.alias || [],
    redirect: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.redirect,
    component: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIA
  },
  {
    name: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.name ?? undefined,
    path: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.path ?? "/de/*/privacy-policy",
    props: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.props ?? false,
    meta: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta || {},
    alias: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.alias || [],
    redirect: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIAMeta?.redirect,
    component: component_45stubbjLkcGu196H3H6U3AGpzju91fOJPpk9xx3zVsuzjgIA
  }
]