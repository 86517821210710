export const useQueryParams = () => {
	const route = useRoute()

	const storeQueryParams = () => {
		if (import.meta.prerender) { return }
		const routeParams = ['utm_campaign', 'utm_content', 'utm_medium', 'utm_source', 'utm_term', 'fbclid']
		try {
			const storedParams: Record<string, string> = {}
			routeParams.forEach((param) => {
				if (route.query[param]) {
					storedParams[param] = route.query[param] as string
				}
			})
			sessionStorage.setItem('storedParams', JSON.stringify(storedParams))
		} catch (error) {
			console.error('Error storing params:', error)
		}
	}

	const getStoredQueryParams = () => {
		if (import.meta.prerender) { return }
		const storedParams = sessionStorage.getItem('storedParams')
		return storedParams ? JSON.parse(storedParams) : {}
	}

	return {
		storeQueryParams,
		getStoredQueryParams
	}
}
