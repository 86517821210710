<template lang="pug">
div
	.MagicLink(ref="el")
		.auth-header
			SingaIcon(icon="checkmark-circle-outline" size="large")
			h1 {{ t('auth.magicLink') }}
	.auth-body
		p.body-sub {{ t('auth.magicLink.body') }}
		SingaSubmitButton.is-full-width.is-primary.is-regular(:buttonCTA="t('auth.magicLink.cta')" :isLoading="isMagicLoginLoading")
</template>

<script setup lang="ts">
const isMagicLoginLoading = ref(false)
const el = ref<HTMLDivElement>()
const { t } = useI18n()

const emit = defineEmits([
	'update-height'
])

const updateHeightOnResize = useDebounceFn(() => {
	emit('update-height', el.value?.offsetHeight)
}, 100)

onMounted(() => {
	window.addEventListener('resize', updateHeightOnResize)
	emit('update-height', el.value?.offsetHeight)
})

onUnmounted(() => {
	window.removeEventListener('resize', updateHeightOnResize)
})
</script>

<style lang="sass" scoped>
.MagicLink
	position: absolute
	left: 0
	right: 0
</style>
