import { defineNuxtRouteMiddleware, navigateTo } from '#imports'

export default defineNuxtRouteMiddleware(async (to, from) => {
	// Skip if in build
	if (import.meta.prerender) return
	const { isCrawler } = useDevice()
	// Prevent redirection for crawlers, XML paths, or image paths
	if (isCrawler || to.path.includes('xml') || from.path.includes('xml') || to.path.includes('/img/') || from.path.includes('/img/') || to.path.includes('.js')) {
		return
	}

	const nuxtApp = useNuxtApp()

	const geoLocationStore = useGeoLocationStore()
	const countries = ['AS', 'AT', 'AU', 'AW', 'AX', 'BE', 'BG', 'BL', 'CA', 'CH', 'CW', 'CY', 'CZ', 'DE', 'DK', 'EE', 'ES', 'FI', 'FO', 'FR', 'GB', 'GF', 'GL', 'GP', 'GR', 'GU', 'HU', 'IE', 'IS', 'IT', 'LI', 'LT', 'LU', 'LV', 'MF', 'MP', 'MQ', 'MT', 'NC', 'NL', 'NO', 'PF', 'PH', 'PL', 'PM', 'PR', 'PT', 'RE', 'RO', 'SE', 'SI', 'SK', 'SX', 'TF', 'UA', 'US', 'VI', 'WF', 'YT']
	const reqFromComingSoon = from.path.includes('coming-soon')

	if (to.path.includes('coming-soon')) return

	const isFromOpenMarket = (geoCountry: string | undefined) => {
		return geoCountry ? countries.includes(geoCountry) : false
	}
	if (process.env.NODE_ENV === 'development') {
		if (geoLocationStore.country === undefined) {
			geoLocationStore.setGeoLocation(process.env.DEV_GEO_LOCATION as string)
			geoLocationStore.setGeoCity(process.env.DEV_GEO_CITY as string)
		}
		if (!reqFromComingSoon && !isFromOpenMarket(process.env.DEV_GEO_LOCATION) && process.env.DEV_GEO_LOCATION) {
			return navigateTo({ path: nuxtApp.$localePath('/coming-soon/') })
		}
		return
	}

	if (nuxtApp.$preview) {
		console.log('preview mode - returning from middleware')
		return
	}

	if (geoLocationStore.country === undefined) {
		const headers = useRequestHeaders(['x-forwarded-for'])
		const response: any = await $fetch('/api/getGeoLocation', { method: 'GET', headers })
		geoLocationStore.setGeoLocation(response?.country?.isoCode)
		geoLocationStore.setGeoCity(response?.city?.names.en)
	}
	if (geoLocationStore.country !== undefined && geoLocationStore.country !== null && !reqFromComingSoon && !isFromOpenMarket(geoLocationStore.country)) {
		return navigateTo({ path: nuxtApp.$localePath('/coming-soon/') })
	}
})
