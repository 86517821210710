<template lang="pug">
SingaButton.is-transparent-dark.back-button.is-regular(v-if="hasHistory" @click="goBack()")
	.btn-wrap
		SingaIcon(icon="arrow-back")
		span {{ t('general.goBack') }}
</template>

<script setup lang="ts">
const { t } = useI18n()
const router = useRouter()
const hasHistory = router.options.history.state.back

const goBackPath = useState('goBackPath')

const goBack = () => {
	if (goBackPath.value) {
		navigateTo({ path: goBackPath.value as string })
		goBackPath.value = null
	} else {
		router.back()
	}
}
</script>

<style lang="sass" scoped>
button
	color: $color-grey-30
	display: none
	.btn-wrap
		display: flex
		align-items: center
	@media (min-width: $tablet)
		display: flex
</style>
