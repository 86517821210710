import { AnalyticsBrowser } from '@segment/analytics-next'
import type { AnalyticsBrowserSettings } from '@segment/analytics-next'
import { useTitle } from '@vueuse/core'

declare global {
	interface Window {
		sgmnt: AnalyticsBrowser
	}
}
export const useSegment = () => {
	const loadSegment = () => {
		if (import.meta.server || import.meta.dev) { return }
		const analytics = new AnalyticsBrowser()
		const config = useRuntimeConfig()
		const key = config.public.segmentPublicKey
		const moduleOptions: AnalyticsBrowserSettings = {
			writeKey: key,
			cdnURL: 'https://cdn.s.singa.com'
		}
		const cdnSettings = {
			integrations: {
				'Segment.io': { apiHost: 's.singa.com/v1' }
			}
		}
		const loadedAnalytics = analytics.load(moduleOptions, cdnSettings)
		window.sgmnt = loadedAnalytics
	}
	const segmentEvent = (event: string, eventParams?: object) => {
		if (import.meta.server || import.meta.dev) { return }
		window.sgmnt?.track(event, { ...eventParams })
	}

	const segmentPageView = (path: string, fullPath: string) => {
		const { isLoggedIn } = useAuth()
		const { userResourceId } = useUserStore()
		const { public: { SINGA_API_URL } } = useRuntimeConfig()
		const title = useTitle()
		if (isLoggedIn) {
			segmentEvent('Page Viewed', {
				path,
				referrer: document?.referrer,
				title: title.value,
				url: SINGA_API_URL + fullPath,
				user_id: userResourceId
			})
		}
	}

	const identifySegmentUser = (user: User, hasSub: boolean) => {
		if (import.meta.server || import.meta.dev) { return }
		const subscriptionType = () => {
			if (hasSub) {
				return 'premium'
			} else if (user.subscription?.trial_end && new Date(user.subscription.trial_end) > new Date()) {
				return 'trialing B2C'
			} else {
				return 'free'
			}
		}
		window.sgmnt?.identify(user.resource_id, {
			country: user.country,
			email: user.email,
			username: user.username,
			subscription_plan: subscriptionType(),
			registration_time: user.created
		})
	}

	return {
		loadSegment,
		segmentEvent,
		segmentPageView,
		identifySegmentUser
	}
}
