<template lang="pug">
.notification-message
	span.text {{ t('player.notifications.popupPlayer.text') }}
	.action
		SingaButton.is-full-ghost(@click="returnPlayer()") {{ t('player.notifications.popupPlayer.button') }}
</template>

<script setup lang="ts">
import { useSecondScreenStore } from '~/pinia/secondScreen/secondScreenStore'
import { usePlayerStore } from '~/pinia/player/karaokePlayer'

const { t } = useI18n()
const secondScreenStore = useSecondScreenStore()
// const { $audioPlayer } = useNuxtApp()
const karaokeStore = usePlayerStore()
const { setShowPlayer, setLoadingState } = karaokeStore
const emit = defineEmits(['close'])

const returnPlayer = async () => {
	const { closeConnection } = secondScreenStore
	await closeConnection().then(() => {
		setShowPlayer(true)
		setLoadingState(false)
	})
	emit('close')
}
</script>

<style lang="sass" scoped>
.notification-message
	display: flex
	align-items: center
	.text
		padding-right: $spacing-16
	.action
		padding-left: $spacing-16
		border-left: 1px solid $color-grey-60
		button
			padding: 0
			&:hover
				color: $color-grey-40
</style>
