<template lang="pug">
.SongTags(@click.prevent.stop :class="[{ 'song-detail': variant === 'detail', 'not-clickable' : notClickable }]")
	SongTag(v-if="tags.has_original" :notClickable="notClickable" :original="true" :variant="variant" :tooltip="t('tags.original.tooltip')" @clicked="updateFilterByTag('has_original')" :tag="t('tags.original')")
	SongTag(v-if="popular" :notClickable="true" :popular="true" :original="false" :variant="variant" :tooltip="t('tags.popular.tooltip')" :tag="t('tags.popular')")
	SongTag(v-if="tags.has_cover" :notClickable="notClickable" :original="false" :variant="variant" :tooltip="t('tags.vocals.tooltip')" @clicked="updateFilterByTag('has_cover')" :tag="t('tags.vocals')")
	SongTag(v-if="tags.has_duet" :notClickable="notClickable" :original="false" :variant="variant" :tooltip="t('tags.duet.tooltip')" @clicked="updateFilterByTag('has_duet')" :tag="t('tags.duet')")
	SongTag(v-if="tags.has_explicit" :notClickable="notClickable" :original="false" :variant="variant" :tooltip="t('tags.explicit.tooltip')" @clicked="updateFilterByTag('has_explicit')" :tag="t('tags.explicit')")
	SongTag(v-if="tags.has_plus" :notClickable="notClickable" :original="false" :variant="variant" :tooltip="t('tags.melody.tooltip')" @clicked="updateFilterByTag('has_plus')" tag="Melody")
</template>

<script setup lang="ts">
const { t } = useI18n()
const route = useRoute()
const localePath = useLocalePath()

defineProps({
	tags: {
		type: Object,
		default: null
	},
	variant: {
		type: String,
		default: 'list'
	},
	popular: {
		type: Boolean,
		default: false
	},
	notClickable: {
		type: Boolean,
		default: false
	}
})

const inFilterSearch = ref(route.fullPath.includes('/songs'))

const updateFilterByTag = (tag: string) => {
	if (!inFilterSearch.value) {
		const query = ref({} as any)
		query.value[tag] = true
		navigateTo({ path: localePath('/songs/'), query: { ...query.value } })
	}

	switch (tag) {
		case 'has_cover':
			useEventEmit('tagFilterChange:updateHasCover', true)
			break
		case 'has_duet':
			useEventEmit('tagFilterChange:updateHasDuet', true)
			break
		case 'has_explicit':
			useEventEmit('tagFilterChange:updateHasExplicit', true)
			break
		case 'has_original':
			useEventEmit('tagFilterChange:updateHasOriginal', true)
			break
		case 'has_plus':
			useEventEmit('tagFilterChange:updateHasPlus', true)
			break
		default:
			break
	}
}
</script>

<style lang="sass" scoped>
.SongTags
	display: flex
	min-width: 0
	max-width: 322px
	flex-wrap: wrap
	align-items: center
	gap: $spacing-8
</style>
