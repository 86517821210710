import { defineNuxtPlugin } from '#app/nuxt'
import { LazyConsumerModal, LazyConsumerPage, LazyFreemiumModal, LazyStoryblokButton, LazyStoryblokButtons, LazyStoryblokCallout, LazyStoryblokConsumerContactTabs, LazyStoryblokConsumerFeatureGrid, LazyStoryblokConsumerFeatureItem, LazyStoryblokConsumerHeader, LazyStoryblokConsumerProduct, LazyStoryblokConsumerProductList, LazyStoryblokCountryPlaylists, LazyStoryblokDecoratedImage, LazyStoryblokDropdown, LazyStoryblokFooterButton, LazyStoryblokHeader, LazyStoryblokHistoryLeaf, LazyStoryblokHistoryTree, LazyStoryblokHorizontalBlocks, LazyStoryblokImage, LazyStoryblokImageGrid, LazyStoryblokLanderAppTree, LazyStoryblokLanderBusinessAd, LazyStoryblokLanderIntro, LazyStoryblokLanderProducts, LazyStoryblokLanderSearch, LazyStoryblokLanderTextImage, LazyStoryblokLink, LazyStoryblokMarketingBanner, LazyStoryblokMarketingBanners, LazyStoryblokMostSungSongs, LazyStoryblokPerson, LazyStoryblokPopularGenres, LazyStoryblokPopularOriginals, LazyStoryblokPopularPlaylists, LazyStoryblokPopularSongs, LazyStoryblokQuote, LazyStoryblokRecentAdditions, LazyStoryblokRichText, LazyStoryblokRichTextWrapper, LazyStoryblokSongHistory, LazyStoryblokSongRequestModal, LazyStoryblokSpacer, LazyStoryblokTeamGroupList, LazyStoryblokTeamItem, LazyStoryblokTextAndImage, LazyStoryblokTitle, LazyStoryblokTopArtists, LazyStoryblokVenueBanner, LazyStoryblokVenueCityButtonGrid, LazyStoryblokVenueDiscover, LazyStoryblokVenueSearchbar, LazyStoryblokVenueSuggestion, LazyStoryblokVenuesNearMe, LazyStoryblokVenuesTop, LazyStoryblokVerticalItem, LazyStoryblokVideo, LazyIcon } from '#components'
const lazyGlobalComponents = [
  ["ConsumerModal", LazyConsumerModal],
["ConsumerPage", LazyConsumerPage],
["FreemiumModal", LazyFreemiumModal],
["StoryblokButton", LazyStoryblokButton],
["StoryblokButtons", LazyStoryblokButtons],
["StoryblokCallout", LazyStoryblokCallout],
["StoryblokConsumerContactTabs", LazyStoryblokConsumerContactTabs],
["StoryblokConsumerFeatureGrid", LazyStoryblokConsumerFeatureGrid],
["StoryblokConsumerFeatureItem", LazyStoryblokConsumerFeatureItem],
["StoryblokConsumerHeader", LazyStoryblokConsumerHeader],
["StoryblokConsumerProduct", LazyStoryblokConsumerProduct],
["StoryblokConsumerProductList", LazyStoryblokConsumerProductList],
["StoryblokCountryPlaylists", LazyStoryblokCountryPlaylists],
["StoryblokDecoratedImage", LazyStoryblokDecoratedImage],
["StoryblokDropdown", LazyStoryblokDropdown],
["StoryblokFooterButton", LazyStoryblokFooterButton],
["StoryblokHeader", LazyStoryblokHeader],
["StoryblokHistoryLeaf", LazyStoryblokHistoryLeaf],
["StoryblokHistoryTree", LazyStoryblokHistoryTree],
["StoryblokHorizontalBlocks", LazyStoryblokHorizontalBlocks],
["StoryblokImage", LazyStoryblokImage],
["StoryblokImageGrid", LazyStoryblokImageGrid],
["StoryblokLanderAppTree", LazyStoryblokLanderAppTree],
["StoryblokLanderBusinessAd", LazyStoryblokLanderBusinessAd],
["StoryblokLanderIntro", LazyStoryblokLanderIntro],
["StoryblokLanderProducts", LazyStoryblokLanderProducts],
["StoryblokLanderSearch", LazyStoryblokLanderSearch],
["StoryblokLanderTextImage", LazyStoryblokLanderTextImage],
["StoryblokLink", LazyStoryblokLink],
["StoryblokMarketingBanner", LazyStoryblokMarketingBanner],
["StoryblokMarketingBanners", LazyStoryblokMarketingBanners],
["StoryblokMostSungSongs", LazyStoryblokMostSungSongs],
["StoryblokPerson", LazyStoryblokPerson],
["StoryblokPopularGenres", LazyStoryblokPopularGenres],
["StoryblokPopularOriginals", LazyStoryblokPopularOriginals],
["StoryblokPopularPlaylists", LazyStoryblokPopularPlaylists],
["StoryblokPopularSongs", LazyStoryblokPopularSongs],
["StoryblokQuote", LazyStoryblokQuote],
["StoryblokRecentAdditions", LazyStoryblokRecentAdditions],
["StoryblokRichText", LazyStoryblokRichText],
["StoryblokRichTextWrapper", LazyStoryblokRichTextWrapper],
["StoryblokSongHistory", LazyStoryblokSongHistory],
["StoryblokSongRequestModal", LazyStoryblokSongRequestModal],
["StoryblokSpacer", LazyStoryblokSpacer],
["StoryblokTeamGroupList", LazyStoryblokTeamGroupList],
["StoryblokTeamItem", LazyStoryblokTeamItem],
["StoryblokTextAndImage", LazyStoryblokTextAndImage],
["StoryblokTitle", LazyStoryblokTitle],
["StoryblokTopArtists", LazyStoryblokTopArtists],
["StoryblokVenueBanner", LazyStoryblokVenueBanner],
["StoryblokVenueCityButtonGrid", LazyStoryblokVenueCityButtonGrid],
["StoryblokVenueDiscover", LazyStoryblokVenueDiscover],
["StoryblokVenueSearchbar", LazyStoryblokVenueSearchbar],
["StoryblokVenueSuggestion", LazyStoryblokVenueSuggestion],
["StoryblokVenuesNearMe", LazyStoryblokVenuesNearMe],
["StoryblokVenuesTop", LazyStoryblokVenuesTop],
["StoryblokVerticalItem", LazyStoryblokVerticalItem],
["StoryblokVideo", LazyStoryblokVideo],
["Icon", LazyIcon],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
