<template lang="pug">
.AuthInit(ref="el")
	div(v-if="!conversionModal")
		.auth-header
			p.auth-sub {{ t('auth.loginText') }}
		.auth-body
			//- SingaButton.is-full-width.is-regular.button-social.button-apple.has-icon(icon-left="logo-apple") {{ t('auth.apple') }}
			SingaButton.is-full-width.is-regular.button-social.button-facebook(icon-left="logo-facebook" @click="checkFacebookLogin" :disabled="loading") {{ t('auth.facebook') }}
			SingaButton.is-full-width.is-regular.button-social.button-google(@click="googleLoginMethod" :disabled="loading || !isGoogleReady")
				img.icon(src="~/assets/images/logo-google.svg")
				| {{ t('auth.google') }}
			.signup-text-wrapper
				.auth-additional
					span {{ t('auth.emailContinue') }}
			form(@submit.prevent="checkUser(updatedUsername)")
				SingaField(:variant="userNameError !== '' ? 'danger' : ''" :message="userNameError" :label="t('auth.email.label')" labelFor="loginEmail")
					SingaInput.input-regular(
						rounded
						type="text"
						v-model="updatedUsername"
						@update:modelValue="userNameError = ''"
						:placeholder="t('auth.email.placeholder')"
						icon="mail"
						auto-complete="updatedUsername"
						id="loginEmail")
				SingaButton.is-transparent-dark.is-regular.is-full-width(native-type="submit")
					CommonLoadingAnimation(v-if="loading" :timeOut="0" :size="23")
					span(v-else) {{ t('general.next') }}
	.ConversionModal(v-else)
		.conversion-header
			p.conversion-title {{ conversionModalTitle }}
			p.conversion-subtitle {{ t('auth.loginText.conversionModal.subtitle') }}
		.auth-body
			//- SingaButton.is-full-width.is-regular.button-social.button-apple.has-icon(icon-left="logo-apple") {{ t('auth.apple') }}
			SingaButton.is-full-width.is-regular.button-social.button-facebook(v-if="!showEmail" icon-left="logo-facebook" @click="checkFacebookLogin(); closeModal()" :disabled="loading") {{ t('auth.facebook') }}
			SingaButton.is-full-width.is-regular.button-social.button-google(v-if="!showEmail" @click="googleLoginMethod(); closeModal()" :disabled="loading || !isGoogleReady")
				img.icon(src="~/assets/images/logo-google.svg")
				| {{ t('auth.google') }}
			SingaButton.is-transparent-dark.is-regular.is-full-width(v-if="!showEmail" @click="showEmail = !showEmail")
				CommonLoadingAnimation(v-if="loading" :timeOut="0" :size="23")
				span(v-else) {{ t('modal.conversion.email') }}
			form(@submit.prevent="checkUser(updatedUsername)" v-else)
				SingaField(:variant="userNameError !== '' ? 'danger' : ''" :message="userNameError" :label="t('auth.email.label')" labelFor="loginEmail")
					SingaInput.input-regular(
						@update:modelValue="userNameError = ''"
						rounded
						type="text"
						v-model="updatedUsername"
						:placeholder="t('auth.email.placeholder')"
						icon="mail"
						auto-complete="updatedUsername"
						id="loginEmail")
				SingaButton.is-transparent-dark.is-regular.is-full-width(native-type="submit")
					CommonLoadingAnimation(v-if="loading" :timeOut="0" :size="23")
					span(v-else) {{ t('general.next') }}
		p.log-in-text
			| {{ t('modal.conversion.logIn') }}
			|
			NuxtLink.log-in-link(:to="localePath('/login/')" @click="closeModal") {{ t('modal.conversion.logIn.link') }}
</template>

<script setup lang="ts">
import {
	useTokenClient,
	type AuthCodeFlowSuccessResponse,
	type AuthCodeFlowErrorResponse
} from 'vue3-google-signin'

const FacebookNotAvailable = resolveComponent('SongNotificationsFacebookNotAvailable')
const auth = useAuth()
const config = useRuntimeConfig()
const { $singaApi, $oruga } = useNuxtApp()
const userNameError = ref('')
const userStore = useUserStore()
const { segmentEvent } = useSegment()
const { replaceDemoWithFullSong } = useQueueStore()
const { getStoredQueryParams } = useQueryParams()
const updatedUsername = ref('')
const validatedUsername = ref('')
const el = ref<HTMLDivElement>()
const loading = ref(false)
const { t } = useI18n()
const localePath = useLocalePath()
const route = useRoute()

const props = defineProps({
	username: {
		type: String,
		default: ''
	},
	conversionModal: {
		type: Boolean,
		default: false
	},
	action: {
		type: String,
		default: 'finish'
	}
})
const conversionModalTitle = computed(() => {
	return props.action === 'finish' ? t('auth.loginText.conversionModal') : t('auth.loginText.conversionModal.pause')
})

const emit = defineEmits([
	'email-login',
	'email-signup',
	'update-username',
	'update-height'
])

const { onLoaded } = useScript('https://connect.facebook.net/en_US/all.js', {
	bundle: true,
	trigger: 'client'
})

onLoaded(() => {
	FB.init({
		appId: config.public.FACEBOOK_APP_ID,
		autoLogAppEvents: true,
		xfbml: true,
		version: 'v19.0'
	})
})

const closeModal = () => {
	$oruga.modal.closeAll({ action: 'closeAll' })
}

const showEmail = ref(false)
const showFacebookError = () => {
	loading.value = false
	$oruga.notification.open({
		component: FacebookNotAvailable,
		variant: 'warning'
	})
}

const redirectUrl = computed(() => {
	return route.query.redirect ? route.query.redirect.toString() : '/karaoke'
})

// Parse error data from API response for the Segment event
const parseErrorData = (error: any) => {
	if (!error || typeof error !== 'object') {
		return 'Unknown error'
	}
	let errorMessage = ''
	for (const key in error) {
		if (error[key]) {
			errorMessage += `${key}: ${error[key].join(', ')}\n`
		}
	}
	return errorMessage
}

const getFacebookTokens = (response: any) => {
	loading.value = true
	if (!response || !response.authResponse) {
		loading.value = false
		return
	}

	const payload = {
		provider: 'facebook',
		grant_type: 'social_token',
		token: response.authResponse.accessToken,
		expires: new Date(response.authResponse.data_access_expiration_time).toISOString()
	}
	try {
		auth.getSocialTokens(redirectUrl.value, payload).then((res: any) => {
			if (res && Number(res.isRegistration) === 1) {
				$oruga.notification.open({
					message: t('account.created'),
					icon: 'checkmark-outline',
					iconSize: 'medium'
				})
				segmentEvent('User Signed Up', {
					provider: 'Facebook',
					user_id: userStore.user?.resource_id,
					...getStoredQueryParams()
				})
				replaceDemoWithFullSong()
			} else {
				segmentEvent('User Logged In', {
					provider: 'Facebook',
					user_id: userStore.user?.resource_id
				})
			}
			loading.value = false
		})
	} catch (err) {
		$oruga.notification.open({
			message: t('general.error.unknown'),
			variant: 'warning'
		})
		segmentEvent('User Sign Up Error', {
			error: parseErrorData(err),
			provider: 'Facebook'
		})
		console.error(err)
		loading.value = false
	}
}

const facebookLogin = () => {
	FB.login((response) => {
		if (!response) {
			showFacebookError()
			segmentEvent('User Sign Up Error', {
				error: 'Unable to connect to Facebook at login step',
				provider: 'Facebook'
			})
			return
		}
		getFacebookTokens(response)
	})
}

const checkFacebookLogin = () => {
	if (!window.FB) {
		showFacebookError()
		segmentEvent('User Sign Up Error', {
			error: 'Unable to connect to Facebook. SDK error.',
			provider: 'Facebook'
		})
		return
	}
	FB.getLoginStatus((response) => {
		loading.value = true
		if (!response) {
			showFacebookError()
			segmentEvent('User Sign Up Error', {
				error: 'Unable to connect to Facebook at login status step.',
				provider: 'Facebook'
			})
			return
		}
		if (response?.status !== 'connected') {
			facebookLogin()
		} else {
			getFacebookTokens(response)
		}
	})
}

const googleLogin = async (response: AuthCodeFlowSuccessResponse) => {
	loading.value = true

	const expiration = new Date((Number(response.expires_in) * 60) * 60).toISOString()
	const payload = {
		provider: 'google',
		grant_type: 'social_token',
		token: response.access_token,
		expires: expiration
	}

	if (payload) {
		try {
			const response = await auth.getSocialTokens(redirectUrl.value, payload)
			const errorCodes = ['400', '401', '403', '404', '500']
			if (response.status && errorCodes.includes(response.status.toString())) {
				loading.value = false
				$oruga.notification.open({
					message: t('general.error.unknown'),
					variant: 'warning'
				})

				const errorMessage = parseErrorData(response.data)
				console.error(response)
				segmentEvent('User Sign Up Error', {
					error: errorMessage,
					provider: 'Google'
				})
				console.log(errorMessage)
				return
			}
			if (response && Number(response.isRegistration) === 1) {
				$oruga.notification.open({
					message: t('account.created'),
					icon: 'checkmark-outline',
					iconSize: 'medium'
				})
				segmentEvent('User Signed Up', {
					provider: 'Google',
					user_id: userStore.user?.resource_id,
					...getStoredQueryParams()
				})
				replaceDemoWithFullSong()
			} else {
				segmentEvent('User Logged In', {
					provider: 'Google',
					user_id: userStore.user?.resource_id
				})
			}
			loading.value = false
		} catch (err) {
			$oruga.notification.open({
				message: t('general.error.unknown'),
				variant: 'warning'
			})
			loading.value = false
			console.log(err)
		}
	}
}

const handleOnError = (errorResponse: AuthCodeFlowErrorResponse) => {
	loading.value = false
	console.error(errorResponse)
}
const { isReady: isGoogleReady, login: googleLoginMethod } = useTokenClient({
	onSuccess: googleLogin,
	onError: handleOnError
})

const continueEmailFlow = (userExists = false) => {
	if (userExists) {
		emit('email-login')
	} else {
		emit('email-signup')
	}
}

const updateUsername = () => {
	emit('update-username', validatedUsername.value)
}

const validateUsername = (updatedUsername: string) => {
	userNameError.value = ''
	const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i

	// if contains @, check that valid email regex
	if (updatedUsername !== '' && updatedUsername.includes('@')) {
		if (regex.test(updatedUsername)) {
			validatedUsername.value = updatedUsername.trim()
			return true
		} else {
			userNameError.value = t('auth.email.invalid')
			return false
		}
	} else if (updatedUsername !== '') {
		validatedUsername.value = updatedUsername.trim()
		return true
	} else {
		userNameError.value = t('general.errors.empty')
		return false
	}
}

const checkUser = async (updatedUsername: string) => {
	const validated = validateUsername(updatedUsername)
	loading.value = true
	const params: { email?: string, username?: string } = {}

	if (String(updatedUsername).match('@') && validated) {
		params.email = validatedUsername.value
	} else if (validated) {
		params.username = validatedUsername.value
	} else {
		userNameError.value = t('signup.email.errorInvalid')
		loading.value = false
		return
	}
	try {
		const response = await $singaApi.Users.UserExists(params)
		const userExists = response.email || response.username
		updateUsername()
		if (!userExists && !String(updatedUsername).match('@')) {
			userNameError.value = t('signup.email.errorInvalid')
		} else {
			continueEmailFlow(userExists)
		}
	} catch (err: any) {
		const responseData = err.response?._data
		const segmentErrorData = parseErrorData(responseData)

		segmentEvent('User Sign Up Error', {
			error: segmentErrorData,
			provider: 'Singa'
		})

		// I wonder if these are needed as we already have validation in place
		if (responseData?.email) {
			userNameError.value += responseData.email.join(', ')
		} else if (responseData?.username) {
			userNameError.value += responseData.username.join(', ')
		}
	}
	loading.value = false
}

const updateHeightOnResize = useDebounceFn(() => {
	emit('update-height', el.value?.offsetHeight)
}, 100)

onMounted(() => {
	updatedUsername.value = props.username
	emit('update-height', el.value?.offsetHeight)
	window.addEventListener('resize', updateHeightOnResize)
	if (window.FB) {
		FB.init({
			appId: config.public.FACEBOOK_APP_ID,
			autoLogAppEvents: true,
			xfbml: true,
			version: 'v19.0'
		})
	}
})

onUnmounted(() => {
	window.removeEventListener('resize', updateHeightOnResize)
})
</script>

<style lang="sass" scoped>
.signup-text-wrapper
	padding: $spacing-8 0 $spacing-16
	.auth-additional
		color: $color-grey-30
		text-align: center
		line-height: 0.1em
		span
			color: $color-grey-30
			padding: $spacing-16
			display: block
	@media (min-width: $tablet)
		padding: $spacing-24 $spacing-40
		.auth-additional
			border-bottom: 1px solid $color-grey-50
			margin: $spacing-16 0
			span
				display: inline
				background-color: $color-grey-80
.auth-body
	button:not(:last-child)
		margin-bottom: $spacing-16
.button-social
	border-color: transparent
	outline: none
	&:hover
		border-color: transparent
		outline: none
	.icon
		border-radius: $radius-round
		overflow: hidden
.button-facebook
	background-color: #1877F2
	color: white
	:deep(.icon)
		left: $spacing-16 !important
	&:hover
		background-color: #185BB4
	&:active
		background-color: #144890

.button-apple
	background-color: $color-black
	color: white
	width: 100%
	&:hover
		background-color: $color-grey-90
	&:active
		background-color: $color-grey-100
.button-google
	background-color: $color-white
	color: $color-black
	&:hover
		background-color: $color-grey-20
	&:active
		background-color: $color-grey-40
	.icon
		margin-left: $spacing-8 !important
.AuthInit
	position: absolute
	left: 0
	right: 0

.conversion-title
	@include fontSize(3xl)
	@include font(basier, bold)
	text-align: center
	margin-top: 0px

	@media (min-width: $tablet)
		@include fontSize(4xl)

.conversion-header
	display: flex
	flex-direction: column
	justify-content: center
	align-items: center

.conversion-subtitle
	@include fontSize(l)
	@include font(basier, regular)
	margin: $spacing-16 0 $spacing-24 0
	text-align: center

.log-in-text
	text-align: center
	margin-top: $spacing-24
	@include fontSize(s)
	color: $color-grey-50

.log-in-link
	color: $color-grey-50
	text-decoration: underline
	&:hover
		color: $color-grey-40

.ConversionModal
	margin: 0 auto
	max-width: 400px
</style>
