import { useAudioPlayerStore } from '~/pinia/player/audioPlayerStore'

export const useIterable = () => {
	const userStore = useUserStore()
	const audioPlayerStore = useAudioPlayerStore()
	const { $singaApi } = useNuxtApp()
	let iterableInitialized: boolean = false
	let iterableLogout: any = null
	let iterableLoaded: boolean = false

	const getInAppMessages = async () => {
		if (import.meta.server || import.meta.prerender || import.meta.dev) { return }
		const route = useRoute()
		const excludeViews: any = [
			'login',
			'login-forgot',
			'account',
			'account-billing',
			'account-profile',
			'account-settings',
			'account-email',
			'search-results',
			'search-results-search',
			'device',
			'presentation',
			'stripe'
		]
		const routeName = String(route.name).split('___').shift()
		if (!iterableInitialized) { return }
		if (audioPlayerStore.playing) { return }
		if (excludeViews.includes(routeName)) { return }

		try {
			const { request } = window['@iterable/web-sdk'].getInAppMessages(
				{
					displayInterval: 10000,
					count: 20,
					packageName: 'my-website'
				},
				{ display: 'immediate' }
			)
			await request()
		} catch (error) {
			console.log('Error getting in app messages', error)
		}
	}

	const initialize = async () => {
		if (import.meta.prerender || import.meta.dev) { return }
		if (!userStore.user?.resource_id) { return }
		if (iterableInitialized) { return }
		if (!iterableLoaded) { return }
		const config = useRuntimeConfig()
		const key = config.public.iterableKey
		window['@iterable/web-sdk'].config.setConfig({ baseURL: 'https://api.eu.iterable.com/api' })
		const { setUserID, logout } = window['@iterable/web-sdk'].initialize(
			key,
			async () => {
				const response = await $singaApi.Me.JWT.get()
				return response.jwt
			}
		)
		iterableLogout = logout
		try {
			await setUserID(userStore.user?.resource_id)
			iterableInitialized = true
			if (userStore.user?.email) {
				window['@iterable/web-sdk'].updateUser({ dataFields: { email: userStore.user?.email } })
			}
			await getInAppMessages()
		} catch (error) {
			console.log('Error initializing iterable', error)
		}
	}

	const loadIterable = () => {
		if (import.meta.prerender || import.meta.dev) { return }
		const { onLoaded } = useScript({ src: 'https://unpkg.com/@iterable/web-sdk/index.js', async: true }, {
			bundle: false
		})
		onLoaded(() => {
			iterableLoaded = true
			initialize()
		})
	}

	watch(() => userStore.user, () => {
		if (import.meta.server || import.meta.dev || import.meta.prerender) { return }
		if (userStore.user?.resource_id && !iterableInitialized) { initialize() }
		if (userStore.user == null) {
			// console.log('------------ TRY LOGOUT ITERABLE ------------')
			iterableLogout()
			iterableInitialized = false
		}
	})

	if (!import.meta.server && !import.meta.prerender) {
		window.addEventListener('focus', event => getInAppMessages())
	}

	return { loadIterable, initialize, getInAppMessages }
}
