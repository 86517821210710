import { defineStore } from 'pinia'

export const usePreviewPlayerStore = defineStore('previewPlayer', {
	state: (): PreviewPlayerState => ({
		playing: false,
		audio: null as HTMLAudioElement | null,
		currentSong: null as number | null
	}),
	actions: {
		setPlaying(value: boolean) {
			this.playing = value
		},
		setAudio(audio: HTMLAudioElement | null) {
			if (this.audio) {
				this.audio.pause()
			}
			this.audio = audio
		},
		setCurrentSong(song: number | null) {
			this.currentSong = song
		}
	}
})
