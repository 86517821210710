<template lang="pug">
.FreemiumModal(v-if="!hasPremiumSub && blok")
	.modal-card
		header
			.header-container
				.card-header(:style="{'background-color': bgColor}")
					CommonModalBackground
				button.delete(type="button" @click="closeModal()")

			section
				.modal-body
					h1 {{ title }}
					span.text {{ body }}
					SingaButton.is-regular.is-full-width.white(@click="goToSubscribe()") {{ ctaText }}
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useQueueStore } from '~/pinia/queueStore'
import { usePlayerStore } from '~/pinia/player/karaokePlayer'
import { useSecondScreenStore } from '~~/pinia/secondScreen/secondScreenStore'
import { useAudioPlayerStore } from '~/pinia/player/audioPlayerStore'

const { $oruga } = useNuxtApp()

const karaokeStore = usePlayerStore()
const { setLoadingState, setShowPlayer } = karaokeStore
const queueStore = useQueueStore()
const userStore = useUserStore()

const audioPlayerStore = useAudioPlayerStore()
const { pauseAudioPlayer } = audioPlayerStore

const bgColor = ref('#12B961')
const title = ref('')
const body = ref('')
const ctaText = ref('')

enum FreemiumStage {
	DEFAULT = 'default',
	TWO_SONGS = 'Two songs left',
	ONE_SONG = 'One song left',
	NO_SONGS = 'No songs left',
	QUEUE = 'queue',
	PREVIEW = 'preview'
}

const props = defineProps({
	fromQueue: {
		type: Boolean,
		default: false
	},
	blok: {
		required: true,
		type: Object
	}
})

const { hasPremiumSub, numberOfFreeSongsLeft, showDemoPlayer } = storeToRefs(userStore)
const freemiumStage = ref('')
const ctaButtonPath = ref('')

// Set the stage, the background color and the CTA button's path

const checkFreemiumStage = async () => {
	if (props.fromQueue) {
		freemiumStage.value = FreemiumStage.QUEUE
		bgColor.value = props.blok.queue_stage_color
		title.value = props.blok.queue_stage_title
		body.value = props.blok.queue_stage_body
		ctaText.value = props.blok.queue_stage_button_cta
		ctaButtonPath.value = props.blok.queue_stage_button_cta_path
		return
	}

	if (numberOfFreeSongsLeft.value === 2) {
		freemiumStage.value = FreemiumStage.TWO_SONGS
		bgColor.value = props.blok.two_songs_stage_color
		title.value = props.blok.two_songs_stage_title
		body.value = props.blok.two_songs_stage_body
		ctaText.value = props.blok.two_songs_stage_button_cta
		ctaButtonPath.value = props.blok.two_songs_stage_button_cta_path
	} else if (numberOfFreeSongsLeft.value === 1) {
		freemiumStage.value = FreemiumStage.ONE_SONG
		bgColor.value = props.blok.one_song_stage_color
		title.value = props.blok.one_song_stage_title
		body.value = props.blok.one_song_stage_body
		ctaText.value = props.blok.one_song_stage_button_cta
		ctaButtonPath.value = props.blok.one_song_stage_button_cta_path
	} else if (showDemoPlayer.value) {
		freemiumStage.value = FreemiumStage.PREVIEW
		bgColor.value = props.blok.preview_stage_color
		title.value = props.blok.preview_stage_title
		body.value = props.blok.preview_stage_body
		ctaText.value = props.blok.preview_stage_button_cta
		ctaButtonPath.value = props.blok.preview_stage_button_cta_path
	} else if (numberOfFreeSongsLeft.value === 0) {
		freemiumStage.value = FreemiumStage.NO_SONGS
		bgColor.value = props.blok.no_songs_stage_color
		title.value = props.blok.no_songs_stage_title
		body.value = props.blok.no_songs_stage_body
		ctaText.value = props.blok.no_songs_stage_button_cta
		ctaButtonPath.value = props.blok.no_songs_stage_button_cta_path
	} else {
		freemiumStage.value = FreemiumStage.DEFAULT
		bgColor.value = props.blok.default_stage_color
		title.value = props.blok.default_stage_title
		body.value = props.blok.default_stage_body
		ctaText.value = props.blok.default_stage_button_cta
		ctaButtonPath.value = props.blok.default_stage_button_cta_path
	}
}

const secondScreenStore = useSecondScreenStore()
const { closeConnection, setPlayerState } = secondScreenStore
const { isSecondScreenActive } = storeToRefs(secondScreenStore)

// After navigating to the path, close the modal, stop the song and hide the player.
const goToSubscribe = async () => {
	if (isSecondScreenActive.value) {
		await closeConnection().then(() => setPlayerState('interrupted'))
	}
	navigateTo(ctaButtonPath.value, { external: true })
	$oruga.modal.closeAll({ action: 'closeAll' })
	queueStore.stopSong()
	setShowPlayer(false)
	setLoadingState(false)
}

useHead({
	htmlAttrs: {
		class: 'is-clipped'
	}
})

const closeModal = () => {
	$oruga.modal.closeAll({ action: 'closeAll' })
}

checkFreemiumStage()

if (freemiumStage.value !== FreemiumStage.QUEUE && !showDemoPlayer.value) {
	pauseAudioPlayer()
}
</script>

<style scoped lang="sass">
.FreemiumModal
	margin: auto
	padding: $spacing-16

.modal-card
	padding: 0
	border: none
	@media (min-width: $mobile-small)
		max-height: none

.header-container
	overflow: hidden
	position: relative

.card-header
	display: flex
	flex-direction: column
	align-items: center
	justify-content: center
	max-height: 380px

h1
	@include font(basier, bold)
	@include fontSize(3xl)
	padding: $spacing-16 0 $spacing-16 0

.modal-body
	background-color: $color-grey-90
	padding: $spacing-32

.delete
	margin: $spacing-16

.FreemiumModal
	max-width: 768px

.white
	margin-top: $spacing-32
	color: black
	background-color: $color-white

.text
	@include font(basier, regular)
	@include fontSize(m)
	color: $color-grey-30
</style>
