import { defineStore, storeToRefs } from 'pinia'
import { destr } from 'destr'
import type { QueueItem, Variant, Song } from '../types'
import { useMakeId } from '../utils/utils'
import { useAudioPlayerStore } from './player/audioPlayerStore'
import { usePlayerStore } from './player/karaokePlayer'
import { useMediaFileStore } from './mediaFileStore'
import { useSecondScreenStore } from './secondScreen/secondScreenStore'
import { useUserStore } from './userStore'
import { openFreemiumModal, useNuxtApp, useEventEmit, useSegment, usePreviousRoute, useSongs } from '#imports'

const COUNTDOWN_SECONDS = 3

interface SongDetails {
	song: Song
	variant: Variant
	pitch: number
	vocals: boolean
}

export const useQueueStore = defineStore('queueStore', {
	state: (): QueueState => (
		{
			queue: [],
			previousState: [],
			isTransforming: false,
			songPlaying: null,
			songFiles: false,
			isDragging: false,
			addSongModalInfo: {},
			autoplayEnabled: false,
			autoplayTimeout: -1,
			autoplayCountdown: -1,
			countdownTime: COUNTDOWN_SECONDS,
			queueID: '',
			queueHistory: [],
			changedPreferences: [],
			hasAddedSongToQueue: false,
			previewQueue: false,
			isQueueLoading: false
		}
	),

	persist: {
		serializer: {
			deserialize: destr,
			serialize: (state) => {
				const preview = state.previewQueue

				// Bizarrely this logs 'Persist queue' when not logged in, but it still works
				// console.log(preview ? 'Preview queue' : 'Persist queue')

				const toStore = preview ? null : state
				return JSON.stringify(toStore)
			}
		},
		afterRestore: (ctx) => {
			// This fires off the showMiniPlayer if persist plugin restores queue data
			if (ctx.store.$state.queue.length) {
				const { setShowMiniPlayer } = usePlayerStore()
				setShowMiniPlayer(true)
			}
		}
	},
	getters: {
		firstInQueue(state): QueueItem {
			return state.songPlaying as QueueItem || state.queue[0] as QueueItem
		},
		hasMultipleVariants(state): boolean {
			return this.firstInQueue?.entry?.variants.length > 1
		},
		hasSongFiles(state): boolean {
			return state.songFiles !== null
		},
		restOfQueue(state): QueueItem[] {
			const [_, ...rest] = state.queue
			return rest as QueueItem[]
		},
		hasSongs(state): boolean {
			return state.queue.length > 0
		},
		hasSongPlaying(state): boolean {
			return !!state.queue.find((n: any) => n.isPlaying)
		},
		queueLength(state): number {
			const duration = state.queue?.length > 0
				? state.queue?.map((n: any) => n.entry?.duration).reduce((a: number, b: number) => { return a + b }, 0)
				: 0
			return Math.floor(duration / 60)
		},
		queueLengthPrecise(state): number {
			const duration = state.queue.length > 0
				? state.queue.map((n: any) => n.entry.duration).reduce((a: number, b: number) => { return a + b }, 0)
				: 0
			return duration
		},
		restOfQueueLengthPrecice(state): number {
			const duration = this.restOfQueue.length > 0
				? this.restOfQueue.map((n: any) => n.entry.duration).reduce((a: number, b: number) => { return a + b }, 0)
				: 0
			return duration
		},
		showAutoplayCountdownCancel(state): boolean {
			return state.autoplayTimeout > -1
		}
	},
	actions: {
		// Is skipCheck redundant?
		async addToQueue({ song, variant, pitch, vocals }: SongDetails, songLink: string, accessPoint: string, skipCheck: boolean = false, errorMessage: string) {
			const { $oruga } = useNuxtApp()
			if (!song || !variant || pitch === undefined || vocals === undefined) {
				$oruga.notification.open({ message: errorMessage, variant: 'warning' })
				throw new Error(`queueStore: Trying to queue undefined song, variant, pitch or vocals, song: ${song}, variant: ${variant}, pitch: ${pitch}, vocals: ${vocals}`)
			}
			const userStore = useUserStore()
			const karaokeStore = usePlayerStore()
			const { segmentEvent } = useSegment()
			const { setShowMiniPlayer } = karaokeStore
			if (this.queueID === '') {
				this.queueID = await useMakeId()
			}
			if (!skipCheck) {
				if (userStore.numberOfFreeSongsLeft === 0 || (!userStore.hasPremiumSub && userStore.numberOfFreeSongsLeft <= this.queue.length)) {
					openFreemiumModal(true)
					return
				}
			}
			useEventEmit('queueActions:addToQueue')
			const { promptAddedToQueue } = useSongs()
			promptAddedToQueue(song.name, songLink)
			setShowMiniPlayer(true)
			this.queue = [...this.queue, {
				entry: song,
				entryID: await useMakeId(),
				// timeUntil: 0,
				variant,
				pitch,
				vocals,
				isPlaying: false,
				bypassAutoPlay: false
			}]
			const params = {
				access_point: accessPoint,
				song_id: song.resource_id,
				song_name: song.name,
				variant_id: variant.resource_id
			}
			segmentEvent('Song To Queue Added', params)
			if (!this.hasAddedSongToQueue) {
				this.hasAddedSongToQueue = true
			}
		},
		async addBatchToQueue(songs: any[], skipCheck = false, playlistId: string, isOfficial: boolean, accessPoint: string) {
			if (!songs) {
				console.warn('queueStore: Trying to add undefined songs to queue')
				this.isQueueLoading = false
				return
			}
			const userStore = useUserStore()
			const karaokeStore = usePlayerStore()
			const { setShowMiniPlayer } = karaokeStore
			const { segmentEvent } = useSegment()
			if (this.queueID === '') {
				this.queueID = await useMakeId()
			}
			const playlistData = {
				access_point: accessPoint,
				user_id: userStore.user?.resource_id,
				queue_id: this.queueID,
				singlist_id: playlistId,
				source_page: usePreviousRoute(),
				is_official: isOfficial
			}
			segmentEvent('Singlist To Queue Added', playlistData)
			if (!skipCheck) {
				if (userStore.numberOfFreeSongsLeft === 0 || (!userStore.hasPremiumSub && userStore.numberOfFreeSongsLeft < this.queue.length + songs.length)) {
					openFreemiumModal(true)
					return
				}
			}

			const songIds = songs.map(song => song.variant.id)

			const getSongPreferences = async () => {
				const { $singaApi } = useNuxtApp()
				const data = await $singaApi.Me.Preferences.get(songIds)
				return data
			}

			const preferences = await getSongPreferences()

			const queueEntriesPromises = songs.map(async (song: any) => {
				// Find the preference for this song, if there is one
				const preference = preferences.find((pref: any) => pref.variant === song.variant.id)

				// If the song has multiple variants, find the variant that matches the preference
				let matchingVariant = song.variant
				if (song.song.variants && song.song.variants.length > 1 && preference) {
					matchingVariant = song.song.variants.find((variant: any) => variant.id === preference.variant)
				}

				return {
					entry: song.song,
					entryID: await useMakeId(),
					variant: matchingVariant,
					pitch: preference ? preference.pitch : 0,
					// I don't think the batch adding should take the global vocal setting into account,
					// since if that setting is true, the user might have to keep clicking vocals off a lot
					// after adding a singlist to the queue
					vocals: preference ? preference.vocals : false,
					isPlaying: false,
					bypassAutoPlay: false
				}
			})

			const queueEntries = await Promise.all(queueEntriesPromises)

			this.queue = [...this.queue, ...queueEntries] as QueueItem[]
			setShowMiniPlayer(true)
			if (!this.hasAddedSongToQueue) {
				this.hasAddedSongToQueue = true
			}
			this.isQueueLoading = false
		},
		editQueueItem({ entryID, variant, pitch, vocals }: any) {
			this.queue = this.queue.map((n: any) => {
				if (n.entryID === entryID) {
					return {
						...n,
						...(variant !== undefined && { variant }),
						...(pitch !== undefined && { pitch }),
						...(vocals !== undefined && { vocals })
					}
				}
				return n
			})
		},
		addToQueueHistory() {
			this.queueHistory = [this.firstInQueue]
		},
		addToChangedPreferences(entryID: string) {
			if (!this.changedPreferences.includes(entryID)) {
				this.changedPreferences = [...this.changedPreferences, entryID]
			}
		},
		changeCurrentVariant(variant: Variant) {
			const [first, ...rest] = [...this.queue]
			this.queue = [{ ...first, variant }, ...rest]
		},
		setQueue(queue: any) {
			const secondScreenStore = useSecondScreenStore()
			this.queue = [...queue]
			secondScreenStore.sendMessageToSecondScreen({
				method: 'SET_QUEUE_INFO',
				queueInfo: this.queue
			})
		},
		setSongFiles(files: any) {
			this.songFiles = files
		},
		replaceDemoWithFullSong() {
			const { setShowMiniPlayer, setShowPlayer } = usePlayerStore()

			setShowPlayer(false)
			this.stopSong()
			if (this.queueHistory.length) {
				this.queue = [...this.queueHistory]
				setShowMiniPlayer(true)
			}
		},
		async nextSong() {
			const audioPlayerStore = useAudioPlayerStore()
			const secondScreenStore = useSecondScreenStore()
			const userStore = useUserStore()
			const karaokeStore = usePlayerStore()
			const { hasFreeSongsLeft, hasPremiumSub, showDemoPlayer } = storeToRefs(userStore)
			const { isSessionOverThirty } = storeToRefs(audioPlayerStore)

			if (isSessionOverThirty.value && !hasPremiumSub.value && !showDemoPlayer.value) {
				openFreemiumModal()
			}

			if (!hasFreeSongsLeft && !hasPremiumSub) {
				this.stopSong()
				karaokeStore.setLoadingState(false)
				karaokeStore.setShowPlayer(false)
				return
			}
			window.audioPlayer.events.removeEventListener('ended', 'AUTOPLAY_ENDED_LISTENER')
			karaokeStore.setLoadingState(true)

			await audioPlayerStore.logSong()

			const [_, ...restOfQueue] = [...this.queue]
			this.queue = restOfQueue

			secondScreenStore.commandPlayer('finished')
			const mediaFileStore = useMediaFileStore()

			if (this.queue.length === 0) {
				this.stopSong()
				karaokeStore.setLoadingState(false)
				karaokeStore.setShowPlayer(false)
				setTimeout(() => {
					karaokeStore.setShowMiniPlayer(false)
				}, 1000)
			} else {
				const { $audioPlayer } = useNuxtApp()
				const song = this.queue[0]

				secondScreenStore.sendMessageToSecondScreen({ method: 'SECOND_SCREEN_PLAYER_EVENT', playerEvent: 'interrupt' })
				secondScreenStore.sendMessageToSecondScreen({ method: 'SEND_NEXT_UP_DATA', songData: song.entry })
				window.top?.postMessage(JSON.stringify({ method: 'SINGA_PLAYER_STOPPED' }), '*')

				const audioPlayerStore = useAudioPlayerStore()
				mediaFileStore.nullifyFiles()
				audioPlayerStore.updateCurrentSeconds(0)
				audioPlayerStore.resetSessionTimer()
				audioPlayerStore.setPlaying(false)
				$audioPlayer.pause()
				$audioPlayer.seek(0)
				useEventEmit('player:startPlayer', { countdown: true, bypass: false })
			}
			useEventEmit('player:destroyPlayer')
			if (!this.hasSongs) {
				mediaFileStore.nullifyFiles()
				karaokeStore.setShowPlayer(false)
			}
		},
		removeFromQueue(entry: any, justEntryId = false) {
			if (justEntryId) {
				this.queue = [...this.queue].filter(item => item.entryID !== entry)
				return
			} else
				this.queue = [...this.queue].filter(item => item.entryID !== entry.entryID)
		},
		removeCurrentFromQueue() {
			this.queue.splice(0, 1)
		},
		emptyQueue() {
			this.queue.splice(1)
		},
		nullifySongPlaying() {
			this.songPlaying = null
			if (this.queue[0]) { this.queue[0].isPlaying = false }
		},
		songStarted() {
			if (!this.hasSongPlaying) {
				const [first, ...rest] = [...this.queue]
				this.queue = [
					{ ...first, isPlaying: true },
					...rest.map((n) => {
						return { ...n, isPlaying: false }
					})
				]
			}
		},
		stopSong() {
			const { $audioPlayer } = useNuxtApp()
			const mediaFileStore = useMediaFileStore()
			const audioPlayerStore = useAudioPlayerStore()
			mediaFileStore.nullifyFiles()
			audioPlayerStore.updateCurrentSeconds(0)
			audioPlayerStore.setPlaying(false)
			$audioPlayer.pause()
			$audioPlayer.seek(0)
			this.queue = [...this.queue.map((n: any) => {
				return { ...n, playing: false }
			})]
			this.removeCurrentFromQueue()
			// useEventEmit('player:destroyPlayer') // Redundant?
		},
		rewindSong() {
			const { $audioPlayer } = useNuxtApp()

			const audioPlayerStore = useAudioPlayerStore()
			audioPlayerStore.updateCurrentSeconds(0)
			useEventEmit('player:seekAudioPlayer', { timeStamp: 0, clear: true })
			$audioPlayer.seek(0)
			this.queue = [...this.queue.map((n: any) => {
				return { ...n, playing: false }
			})]
		},
		updateQueueItemPitch(pitch: number) {
			if (this.queue.length > 0) {
				this.queue[0].pitch = pitch
			}
		},
		async playNow({ song, variant, pitch, vocals }: any) {
			if (!song) {
				console.warn('queueStore: Trying to play undefined song')
				return
			}
			const audioPlayerStore = useAudioPlayerStore()
			const { isAudioPlaying } = storeToRefs(audioPlayerStore)
			if (isAudioPlaying.value) {
				await audioPlayerStore.logSong()
			}
			this.stopSong()
			this.queue = [{
				entry: song,
				entryID: await useMakeId(),
				variant,
				pitch,
				vocals,
				isPlaying: true,
				bypassAutoPlay: true
			}, ...this.queue]
			if (!this.hasAddedSongToQueue) {
				this.hasAddedSongToQueue = true
			}
		}
	}
})
