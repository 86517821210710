<template lang="pug">
div
	.dropdown-item(aria-role="listitem" @click="facebookShare")
		.btn-wrap
			SingaIcon(icon="logo-facebook-full")
			span {{ t('general.facebook') }}
	.dropdown-item(@click="twitterShare")
		.btn-wrap
			SingaIcon(icon="logo-twitter")
			span {{ t('general.twitter') }}
	.dropdown-item(aria-role="listitem" @click="copyUrl")
		.btn-wrap
			SingaIcon(icon="link")
			span {{ t('general.copyUrl') }}
</template>

<script setup lang="ts">
const FacebookNotAvailable = resolveComponent('SongNotificationsFacebookNotAvailable')

const { $oruga } = useNuxtApp()
const { t } = useI18n()
const { segmentEvent } = useSegment()

const emit = defineEmits(['share'])

const props = defineProps({
	externalUrl: {
		type: String,
		default: () => null
	},
	playlistData: {
		type: Object,
		default: () => null
	}
})
const url: Ref<string> = ref('')
const config = useRuntimeConfig()

watch(() => props.externalUrl, () => {
	url.value = props.externalUrl
})

const copyUrl = () => {
	navigator.clipboard.writeText(url.value).then(() =>
		$oruga.notification.open({
			message: t('general.copyUrl.copied'),
			icon: 'checkmark-outline',
			iconSize: 'medium'
		})
	)
	useEventEmit('queue:contextMenu', false)
	emit('share')
	if (props.playlistData) {
		segmentEvent('Singlist Shared', { shared_by: 'copy link', ...props.playlistData })
	}
}

const twitterShare = () => {
	if (import.meta.server) {
		return
	}
	window.open(`https://twitter.com/intent/tweet?text=Sing ${url.value} ${t('general.twitter.shared')}`, '_blank')
	if (props.playlistData) {
		segmentEvent('Singlist Shared', { shared_by: 'Twitter', ...props.playlistData })
	}
	useEventEmit('queue:contextMenu', false)
	emit('share')
}

const facebookShare = () => {
	if (import.meta.server) {
		return
	}
	if (!window.FB) {
		$oruga.notification.open({
			component: FacebookNotAvailable,
			variant: 'warning'
		})
		useEventEmit('queue:contextMenu', false)
		emit('share')
		return
	}
	FB.ui({
		method: 'share',
		href: url.value
	})
	useEventEmit('queue:contextMenu', false)
	emit('share')
	if (props.playlistData) {
		segmentEvent('Singlist Shared', { shared_by: 'Facebook', ...props.playlistData })
	}
}

onMounted(() => {
	if (!props.externalUrl) {
		url.value = window.location.href
	} else {
		url.value = props.externalUrl
	}

	if (window.FB) {
		FB.init({
			appId: config.public.FACEBOOK_APP_ID,
			autoLogAppEvents: true,
			xfbml: true,
			version: 'v15.0'
		})
	}
})
</script>

<style lang="sass" scoped>
.btn-wrap
	display: flex
	align-items: center
	gap: 8px
</style>
