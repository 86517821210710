export const useMetaTags = () => {
	const { locale } = useI18n()
	const setMetaImg = (img?: string, width?: number, height?: number) => {
		const config = useRuntimeConfig()

		const defaultImages: { [key: string]: string } = {
			fi: `${config.public.SINGA_RESOLVE_PREFIX}/img/share_image_fi.png`,
			sv: `${config.public.SINGA_RESOLVE_PREFIX}/img/share_image_se.png`
		}
		const localeValue = locale.value as string

		if (!img) {
			img = defaultImages[localeValue] || `${config.public.SINGA_RESOLVE_PREFIX}/img/share_image.png`
		}

		return [
			{ key: 'og:image', property: 'og:image', content: img },
			{ key: 'twitter:image', name: 'twitter:image', content: img },
			{ key: 'twitter:card', property: 'twitter:card', content: 'summary_large_image' },
			{ key: 'og:image:width', property: 'og:image:width', content: width },
			{ key: 'og:image:height', property: 'og:image:height', content: height }
		]
	}

	const setMetaTitle = (title: string) => {
		return [
			{ key: 'og:title', property: 'og:title', content: title },
			{ key: 'twitter:title', property: 'twitter:title', content: title }
		]
	}

	const setMetaDescription = (desc: string) => {
		return [
			{ key: 'description', name: 'description', content: desc },
			{ key: 'og:description', property: 'og:description', content: desc },
			{ key: 'twitter:description', property: 'twitter:description', content: desc }
		]
	}

	interface SongMeta {
		preview: string
		duration: string
		artists: Artist[]
	}
	const setSongMetaTags = ({ preview, duration, artists }: SongMeta) => {
		const config = useRuntimeConfig()
		const artistItems = artists.map(artist => ({
			key: 'music.musician',
			name: 'music.musician',
			content: `${config.public.SINGA_RESOLVE_PREFIX}/artists/${artist.slug}/${artist.hash}/`
		}))
		return [
			{ key: 'og:type', property: 'og:type', content: 'music.song' },
			{ key: 'og:audio', property: 'og:audio', content: preview },
			{ key: 'og:audio:secure_url', property: 'og:audio:secure_url', preview },
			{ key: 'og:audio:type', property: 'og:audio:type', content: 'audio/mpeg' },
			{ key: 'og:audio:duration', property: 'og:audio:duration', content: duration },
			...artistItems
		]
	}

	const setPlaylistMetaTags = (songs: Song[], songsCompleteAmount: string) => {
		const config = useRuntimeConfig()
		const songItems = songs.map((song, index) => {
			const { getArtistSlugs } = useArtistNames(song.song.artists)
			const artistNameSlug = getArtistSlugs()
			return [
				{ key: 'music:song', name: 'music:song', content: `${config.public.SINGA_RESOLVE_PREFIX}/artists/${artistNameSlug}/${song.song.slug}/${song.song.hash}/` },
				{ key: 'music:song:disc', name: 'music:song:disc', content: index.toString() },
				{ key: 'music:song:track', name: 'music:song:track', content: index.toString() }
			]
		}).flat()
		return [
			{ key: 'og:type', property: 'og:type', content: 'music.playlist' },
			...songItems,
			{ key: 'music:song_count', property: 'music:song_count', content: songsCompleteAmount }
		]
	}

	return {
		setMetaImg,
		setMetaTitle,
		setMetaDescription,
		setSongMetaTags,
		setPlaylistMetaTags
	}
}
