<template lang="pug">
.FavoriteButton
	SingaButton.is-small.is-transparent-dark.detail-like.SongFavoriteButton(
		v-if="!loadingFavorite"
		:icon-left="favorited ? 'heart' : 'heart-outline'"
		@click="toggleFavorite"
		:disabled="loadingFavorite"
		:class="{ 'is-icon': isJustIcon }"
		)
	CommonLoadingAnimation.spinner(v-else :timeOut="0" :size="28")
</template>

<script setup lang="ts">
const emit = defineEmits(['onChange'])
const { getFavoriteStatus, loadingFavorite, favorited, toggleFavoriteStatus } = useFavorites()

const props = defineProps({
	id: {
		type: Number,
		required: true
	},
	resourceId: {
		type: String,
		required: true
	},
	standalone: {
		type: Boolean,
		default: false
	},
	title: {
		type: String,
		required: true
	},
	link: {
		type: String,
		required: true
	},
	playlistIsOfficial: {
		type: Boolean,
		required: false,
		default: false
	},
	playlistResourceId: {
		type: String,
		required: false,
		default: null
	},
	accessPoint: {
		type: String,
		required: true
	},
	isJustIcon: {
		type: Boolean,
		required: false,
		default: false
	}
})
const isPlaylistPage = computed(() => {
	const route = useRoute()
	return route.fullPath.includes('playlist')
})

const toggleFavorite = async () => {
	const segmentData = {
		access_point: props.accessPoint,
		song_id: props.resourceId,
		song_name: props.title
	}
	if (loadingFavorite.value) {
		return
	}
	await toggleFavoriteStatus('songs', props.id, props.title, props.link, false, segmentData)
	emit('onChange')
	if (favorited.value && isPlaylistPage.value) {
		useSinglistSongFavorited(props.resourceId, props.playlistResourceId, 'Song favorite button', props.playlistIsOfficial)
	}
}

defineExpose({
	getFavoriteStatus
})

favorited.value = getFavoriteStatus('songs', props.id) || null
onMounted(async () => {
	// When i18n reloads the page, the component doesn't start listening to the event for some reason
	// Waiting for the next tick fixes it
	await nextTick()
	useEventOn('song:getFavoriteStatus', (id) => {
		if (id === props.id) {
			favorited.value = getFavoriteStatus('songs', props.id) || null
			emit('onChange')
		}
	})
})
onUnmounted(() => {
	useEventOff('song:getFavoriteStatus')
})
</script>

<style lang="sass" scoped>
.FavoriteButton
	display: flex
	align-items: stretch
	justify-content: center
.spinner
	display: flex
	justify-content: center
	align-items: center
	width: 61px
.is-icon
	max-width: 52px
</style>
