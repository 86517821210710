export const useSearch = () => {
	const searchRequest = async (searchTerm: any, params: any) => {
		if (import.meta.prerender) { return }

		const { contentMarket } = useGeoLocationStore()
		const { $singaApi } = useNuxtApp()

		params = { ...params, ...contentMarket }
		return await $singaApi.Search.get({ search: searchTerm, ...params })
	}
	return {
		searchRequest
	}
}
