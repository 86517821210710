<template lang="pug">
div.lower-index
	div(v-if="!reportingView")
		span.settings-title {{ t('player.songSettings') }}
		.settings-row.version-settings.clickable-field.version-multi(v-if="hasMultipleVariants")
			SingaField(horizontal grouped labelFor="versionSelect" :label="t('settings.version')")
			SingaButton.is-ghost.version-title(id="versionSelect" @click="changeVariant()") {{ firstInQueue?.variant?.catalog.name }}
			SingaButton.version-button.is-ghost(v-if="hasMultipleVariants" id="versionSelect" @click="changeVariant()" icon-right="swap-horizontal-outline")
		.settings-row.version-settings(v-else)
			SingaField.version-field(horizontal :label="t('settings.version')")
			span.version-title.only-version {{ firstInQueue?.variant?.catalog.name }}

		.settings-row.regular.setting-toggle.clickable-field(v-if="hasVocals")
			SingaField(@click="toggleVocals(!settings.vocals)")
				SingaSwitch(:modelValue="settings.vocals" :label="t('settings.vocals')" @toggle="toggleVocals")
		.settings-row.regular.setting-toggle(v-else)
			SingaField(horizontal :label="t('settings.vocals')")
				span.na-text {{ t('general.notAvailable') }}

		.settings-row(:class="{'disabled-demo-player': showDemoPlayer}")
			SingaField.pitch-and-volume-field(grouped :label="t('settings.pitch')" v-if="firstInQueue")
				.number-input
					SingaButton.is-full-ghost(icon-left="remove" @click="decrementPitch()" :disabled="settings.pitch === -4 || showDemoPlayer")
					span {{ settings.pitch }}
					SingaButton.is-full-ghost(icon-left="add" @click="incrementPitch()" :disabled="settings.pitch === 4 || showDemoPlayer")

		.settings-row.clickable-field(:class="{'disabled-demo-player': showDemoPlayer}")
			SingaField.report-song(horizontal labelFor="reporting" :label="t('songError.report.subtitle')")
				SingaButton.is-icon.report-button(:icon-right="'chevron-forward'" id="reporting" @click="reportingView = true")

		span.settings-title.playback-settings-title {{ t('player.playbackSettings') }}
		.settings-row.regular.clickable-field.setting-toggle(:class="{'disabled-demo-player': showDemoPlayer }")
			SingaField(
				v-if="!showDemoPlayer && hasPremiumSub"
				@click="toggleAutoPlay(!autoPlay)"
			)
				SingaSwitch(
					:modelValue="autoPlay"
					:label="t('player.queueAutoPlay')"
					@toggle="toggleAutoPlay"
				)
			SingaField(v-else)
				SingaTooltip.tooltip(:content="t('player.queueAutoplay.tooltip.demo')" align="end")
					SingaSwitch(
						:modelValue="false"
						:label="t('player.queueAutoPlay')"
						:disabled="true"
					)

		.settings-row(:class="{'disabled-demo-player': showDemoPlayer}")
			SingaField.pitch-and-volume-field(:label="t('settings.volume')")
				.volume-slider-wrap
					SingaVolumeSlider(v-model="volumeAmount" :disabled="showDemoPlayer")
					PlayerVolumeIndicator(:volumeAmount="settings.volume")

	div(v-else)
		SongErrorReporting(:song="firstInQueue" @close="reportingView = false")

	.demo-player-cta(v-if="showDemoPlayer")
		SingaButton.is-primary.is-regular(@click="openModal()")
			span(v-if="$viewport.isGreaterThan('mobile')") {{ isOutOfFreeSongs ? t('general.getPremium') : t('settings.demoPlayer.cta') }}
			span(v-else) {{ t('settings.demoPlayer.cta.download') }}
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useDebounceFn } from '@vueuse/core'
import SongErrorReporting from './SongErrorReporting.vue'
import { useAudioPlayerStore } from '~/pinia/player/audioPlayerStore'
import { useQueueStore } from '~/pinia/queueStore'
import { useSecondScreenStore } from '~~/pinia/secondScreen/secondScreenStore'

const DownloadApp = resolveComponent('ModalsDownloadApp')

const { t } = useI18n()

const { $audioPlayer, $oruga } = useNuxtApp()

const localePath = useLocalePath()

const { queueItemSelection } = useVariantSelection()
const secondScreenStore = useSecondScreenStore()
const mediaStore = useMediaFileStore()
const { nullifyFiles, setVocals } = useMediaFileStore()
const queueStore = useQueueStore()
const audioStore = useAudioPlayerStore()

const { settings } = storeToRefs(mediaStore)
const { editQueueItem } = useQueueStore()
const { firstInQueue, hasMultipleVariants } = storeToRefs(queueStore)
const { isAudioPlaying } = storeToRefs(audioStore)
const hasVocals = computed(() => firstInQueue?.value?.variant?.has_cover)

const { changeCurrentVariant } = queueStore
const { isSecondScreenActive } = storeToRefs(secondScreenStore)
const { setAutoPlay } = useUserStore()
const userStore = useUserStore()
const { showDemoPlayer, autoPlay, hasPremiumSub, isOutOfFreeSongs, user } = storeToRefs(userStore)

const reportingView = ref(false)

const openModal = () => {
	if (isOutOfFreeSongs.value) {
		navigateTo('https://singa.com/choose-plan', { external: true })
	} else if (!user.value) {
		navigateTo(localePath('/login'))
	} else {
		const { $audioPlayer } = useNuxtApp()
		$audioPlayer.pause()
		$oruga.modal.open({
			component: DownloadApp,
			scroll: 'keep'
		})
	}
}

const toggleAutoPlay = (value: boolean) => {
	setAutoPlay(value)
}

const debouncedToggleVocals = useDebounceFn((value: boolean) => {
	if (firstInQueue.value) {
		editQueueItem({ entryID: firstInQueue.value.entryID, vocals: value })
		setVocals(value)
	}
}, 300)

const toggleVocals = (value: boolean) => {
	settings.value.vocals = value
	debouncedToggleVocals(value)
}

const volumeAmount = computed({
	get: () => settings.value.volume,
	set: async (value) => {
		settings.value.volume = value
		// set audioplayer volume
		const newVolume = value / 100.0
		if (!window.audioPlayer.initialized) {
			await new Promise(resolve => setTimeout(resolve, 200))
		}
		$audioPlayer.setVolume(newVolume)
	}
})

let startVolume: number | null = null

watch(volumeAmount, (newVolume, oldVolume) => {
	if (startVolume === null) {
		startVolume = oldVolume
	}
})

watchDebounced(
	volumeAmount,
	(newVolume) => {
		if (startVolume !== null) {
			const eventParams = {
				song_id: firstInQueue?.value?.entry.resource_id,
				popout_player: isSecondScreenActive.value,
				session_id: firstInQueue?.value?.entryID,
				previous_volume: startVolume,
				current_volume: newVolume
			}
			const { segmentEvent } = useSegment()
			segmentEvent('Player Playback Volume Adjustment Completed', eventParams)
			startVolume = null
		}
	},
	{ debounce: 300 }
)

watch(() => settings.value.volume, (newValue) => {
	volumeAmount.value = newValue
})

const decrementPitch = () => {
	settings.value.pitch = Math.max(settings.value.pitch - 1, -4)
}
const incrementPitch = () => {
	settings.value.pitch = Math.min(settings.value.pitch + 1, 4)
}

const changeVariant = async () => {
	if (!firstInQueue.value) return
	const wasPlaying = isAudioPlaying.value
	$audioPlayer.pause()

	const previousVariantDetails = {
		catalog_name: firstInQueue.value.variant.catalog.name,
		resource_id: firstInQueue.value.variant.resource_id
	}
	try {
		const queueItem = await queueItemSelection(firstInQueue.value.entry, true, previousVariantDetails, t('song.error.play'))
		if (!queueItem && wasPlaying) {
			$audioPlayer.play()
			return
		}
		if (queueItem?.id) {
			// Nullifying the files is done to ensure the change starts with a clean slate, including the visuals, lyrics and all
			nullifyFiles()
			changeCurrentVariant(queueItem)
			useEventEmit('player:startPlayer', { countdown: false, bypass: true })
		}
	} catch (error) {
		console.error('Error changing variant:', error)
		if (wasPlaying) {
			$audioPlayer.play()
		}
	}
}

useEventOn('songSettings:closeErrorReporting', () => {
	reportingView.value = false
})

watch(() => settings.value.pitch, async (newValue) => {
	if (firstInQueue.value) {
		useEventEmit('player:userUpdatedPitch', newValue)
		if (!isAudioPlaying.value) {
			mediaStore.setPitch(settings.value.pitch)
		}
	}
})
</script>

<style scoped lang="sass">
.volume-slider-wrap
	position: relative
	z-index: 0
	width: 100%
.second-screen-toggle-link
	margin: 0
	padding: $spacing-8 $spacing-16
.second-screen-toggle-icon-wrapper
	padding: $spacing-8 $spacing-16
.second-screen-toggle-icon
	display: flex
	color: $color-grey-50
	transition: .2s
	&:hover
		cursor: pointer
		color: $color-grey-70
.link-danger
	a
		color: $color-red-50
		&:hover
			color: $color-red-70
.settings-wrap
	@include backdropBlur(regular)
	.field.is-horizontal
		align-items: center
.settings-row
	position: relative
	background-color: $transparent-white-8
	margin: $spacing-8 0 $spacing-8 0
	padding: 14px 20px
	border-radius: $radius-default
	&:hover
		&.clickable-field
			background-color: $transparent-white-12
	&:last-child
		margin-bottom: 0
	&.regular
		height: 52px
	&.setting-toggle

		:deep(.trigger)
			justify-content: space-between
			width: 100%
		.field
			display: flex
			height: 100%
		:deep(.field-label)
			margin-bottom: 0
	@media (min-width: $tablet)
			.field
				height: 100%
.number-input
	width: 100%
	height: 40px
	display: flex
	align-items: center
	justify-content: space-between
	background-color: $transparent-white-12
	border-radius: $radius-round
	button
		color: $transparent-white-40
		&:hover, &:focus, &:active
			color: white
	.button:focus:not(:active):first-child, .button.is-focused:not(:active):first-child
		border-radius: 100px 0 0 100px
	.button:focus:not(:active):last-child, .button.is-focused:not(:active):last-child
		border-radius: 0 100px 100px 0

.version-button
	padding: 0
	color: $color-grey-30
	position: relative
	z-index: 1
	&:hover
		color: $color-grey-30

.is-ghost
	&:hover
		background-color: transparent !important
		text-decoration: none !important

.version-settings
	display: flex
	justify-content: space-between
	flex-direction: row
	:deep(.field)
		width: 33.33%
		display: block
		margin-bottom: 0
	:deep(.field-label)
		margin-right: 0
	label
		width: 100%
.lower-index
	z-index: -10 !important
	position: relative

.na-text
	color: $color-grey-40
	padding: 0 0 0 $spacing-16

.small-uppercase
	text-transform: uppercase

.settings-title
	color: $color-grey-30
	@include fontSize(xl)
	@include font(basier, semiBold)
	display: none
	@media (min-height: 600px)
		display: block

.playback-settings-title
	padding: $spacing-8 0 0 0

.demo-player-cta
	display: flex
	flex-direction: column
	align-items: center
	justify-content: center
	border-radius: $radius-default
	width: 100%
	gap: $spacing-16
	position: absolute
	bottom: 0
	padding: $spacing-16 $spacing-16 51px
	button
		box-shadow: 0 0 80px 100px #0000004d
.demo-cta-text
	@include font(basier, regular)
	@include fontSize(s)
	color: $color-grey-30
	text-align: center

.disabled-demo-player
	opacity: .3

.second-screen-playing
	:deep(.is-horizontal .field-label)
		flex: 2

.report-song
	display: flex
	flex-direction: row
	:deep(.field-label)
		flex-grow: 20
		margin-right: 0
	:deep(.field-body)
		flex-shrink: 1

:deep(label)
	color: $color-grey-30

.pitch-and-volume-field
	:deep(label)
		margin-bottom: $spacing-4

:deep(.wrap)
	cursor: default !important

.report-button
	position: relative
	z-index: 1
	:deep(.iconify)
		color: $color-grey-50

.version-title
	color: $color-grey-30
	@include font(basier, medium)
	margin: 0

.only-version
	padding: 0
	color: $color-grey-50
	cursor: default

.clickable-field
	cursor: pointer !important
	:deep(label)
		cursor: pointer !important

.settings-row:not(.clickable-field)
	cursor: default !important

:deep(.switch)
	width: 100% !important
	display: flex
	justify-content: space-between

.version-multi
	display: flex
	justify-content: space-between
	position: relative
	margin-bottom: 0
	align-items: center
	.version-title
		position: absolute
		left: 50%
		transform: translateX(-50%)

	.version-button, .version-title
		width: 33.33%
	.version-button
		:deep(.button-wrapper)
			margin-left: auto
</style>

<style lang="sass">
.settings-wrap
	.field-body
		.field
			display: flex
			justify-content: flex-end
</style>
