import { createApiWrapper } from '../utils/apiUtils'

export default defineNuxtPlugin({
	enforce: 'pre',
	parallel: true,
	setup() {
		return {
			provide: {
				singaApi: createApiWrapper()
			}
		}
	}
})
