// @ts-ignore
import SongVersions from '../components/song/SongVersions.vue'
import { usePreviewPlayerStore } from '~/pinia/player/previewPlayerStore'
import type { Variant, Song, VariantPreference } from '~/types'
import { useNuxtApp } from '#imports'

type Nullable<T> = T | null
type SongParams = {
	name: string
	id: string
}
/**
 * @typedef {Variant} Variant
 * @typedef {Song} Song
 */

export const useVariantSelection = () => {
	const { $oruga, $singaApi } = useNuxtApp()
	const variantStore = useVariantStore()
	const { incrementVariantSelectionCount } = variantStore
	const { isVersionAutoselectEnabled, getCount: autoselectionCount, automaticVersionSelectTreshold, versionAutoselect } = storeToRefs(variantStore)

	/**
	 * Logic to select the song's variant for the player.<br>
	 * If the song has only one variant, it will be selected automatically.<br>
	 * If one the song variants is marked as default, it will be selected automatically.<br>
	 * If the song has multiple variants, the user will be prompted to select one.<br>
	 * Selected variant and it's preferences will be saved to the user's preferences.
	 *
	 * @param {Variant[]} variantList - List of song variants to select from
	 * @param {SongParams} song - Song params passed to Segment events
	 * @returns {Promise<Variant>} - Selected variant and updated preferences
	 */
	const getVariantWithSelectionModal = async (variantList: Variant[], song: SongParams) => {
		if (import.meta.prerender) { return }

		const userStore = useUserStore()
		const { isLoggedIn } = useAuth()
		const fallbackPreferences = { pitch: 0 }

		const { contentMarket } = useGeoLocationStore()
		variantList = variantList.filter(variant => variant.available_markets.includes(contentMarket.market || ''))
		if (variantList.length === 0) {
			return null
		}
		const getPreferences = async (variantIds: number[]) => {
			if (userStore.showDemoPlayer) {
				return fallbackPreferences
			} else {
				const response = await $singaApi.Me.Preferences.get(variantIds)
				return response
			}
		}

		if (variantList.length === 1) {
			const variant = variantList[0]
			const preferences = await getPreferences([variant.id])
			return { ...variant, user_preferences: preferences[0] }
		}

		const preferences = await getPreferences(variantList.map(variant => variant.id))
		const defaultPreference = preferences && preferences.length > 0 ? preferences.find((preference: VariantPreference) => preference.is_default === true) : undefined

		let defaultVariant
		if (defaultPreference) {
			defaultVariant = variantList.find(variant => variant.id === defaultPreference.variant)
		}
		if (defaultVariant) {
			return {
				...defaultVariant,
				user_preferences: defaultPreference
			}
		}

		if (isVersionAutoselectEnabled.value) {
			incrementVariantSelectionCount()
			const originalVariant = variantList.find(variant => variant.is_original)
			if (originalVariant) {
				return {
					...originalVariant,
					user_preferences: defaultPreference
				}
			}

			const popularVariants = variantList.filter(variant => variant.popularity)
			if (popularVariants && popularVariants.length > 0) {
				const mostPopularVariant = popularVariants.reduce((prev, current) => {
					return (!prev || parseFloat(current.popularity) > parseFloat(prev.popularity)) ? current : prev
				}, popularVariants[0])
				if (mostPopularVariant) {
					return {
						...mostPopularVariant,
						user_preferences: defaultPreference
					}
				}
			}

			const variantIsPlus = variantList.find(variant => variant.is_plus)
			if (variantIsPlus) {
				return {
					...variantIsPlus,
					user_preferences: defaultPreference
				}
			}

			const variantHasCover = variantList.find(variant => variant.has_cover)
			if (variantHasCover) {
				return {
					...variantHasCover,
					user_preferences: defaultPreference
				}
			}

			return { ...variantList[0], user_preferences: defaultPreference }
		}

		const modalResult = $oruga.modal.open({
			component: SongVersions,
			props: { versions: variantList, song },
			width: 1088
		})

		const result = await modalResult.promise
		if (!result?.version) {
			return undefined
		}
		if (isLoggedIn) {
			await $singaApi.Me.Preferences.put(result.version.id, {
				is_default: true
			})
		}
		return result.version
	}
	/**
	 * Logic to select the song's variant for the player.<br>
	 * If `setManually` is true, the user will be prompted to select a variant even if the song has only one variant.
	 *
	 * @param {Song} song - The song to queue
	 * @param {boolean} setManually - If variant is changed manually by the user from player settings. A setManually parameter was added to enable opening the modal mid-song
	 */
	interface PreviousVariantDetails {
		catalog_name: string
		resource_id: string
	}
	const queueItemSelection = async (song: Song, setManually = false, previousVariantDetails?: PreviousVariantDetails, errorMessage?: string) => {
		if (!song) {
			console.warn('queueItemSelection: undefined song')
			return
		}
		const userStore = useUserStore()
		const { isLoggedIn } = useAuth()
		const queueStore = useQueueStore()

		if (setManually) {
			const modalResult = $oruga.modal.open({
				component: SongVersions,
				props: { versions: song.variants, song: { name: song.name, id: song.resource_id } },
				width: 1088
			})

			const result = await modalResult.promise
			if (!result?.version) {
				return undefined
			}

			if (!userStore.showDemoPlayer) {
				if (isLoggedIn) {
					await $singaApi.Me.Preferences.put(result.version.id, {
						is_default: true
					})
				}

				const { segmentEvent } = useSegment()
				segmentEvent('Player Song Version Changed', {
					new_catalog_name: result.version.catalog.name,
					new_variant_id: result.version.resource_id,
					previous_catalog_name: previousVariantDetails?.catalog_name,
					song_id: song.resource_id,
					previous_variant_id: previousVariantDetails?.resource_id,
					song_name: song.name,
					access_point: 'Song version selector',
					session_id: queueStore.firstInQueue?.entryID
				})
			}

			return result.version
		} else {
			const selectedVariant = await getVariantWithSelectionModal(song.variants, { name: song.name, id: song.resource_id })
			if (!selectedVariant) {
				$oruga.notification.open({
					message: errorMessage,
					variant: 'warning'
				})
				return
			}

			const preferences = !userStore.showDemoPlayer ? selectedVariant.user_preferences : undefined

			const mediaStore = useMediaFileStore()
			const { settings } = storeToRefs(mediaStore)

			const vocalsPreference = preferences?.track === 'cover' ? true : preferences?.track === 'karaoke' ? false : settings.value.globalVocals

			const queueItem = {
				song,
				pitch: preferences?.pitch || 0,
				vocals: vocalsPreference,
				variant: selectedVariant
			}
			return queueItem
		}
	}

	watch(autoselectionCount, () => {
		if (autoselectionCount.value === automaticVersionSelectTreshold.value) {
			versionAutoselect.value = false
		}
	})

	return { getVariantWithSelectionModal, queueItemSelection }
}

export const useVariantPreview = () => {
	const { $previewPlayer } = useNuxtApp()
	const currentlyPlayingVersion: Ref<Nullable<number>> = ref(null)
	const progress = ref(0)
	const progressInterval = ref<ReturnType<typeof setInterval> | null>(null)
	const previewEnded = ref(false)

	const stopCheckingPreview = () => {
		if (progressInterval.value) {
			clearInterval(progressInterval.value)
			progressInterval.value = null
			progress.value = 0
		}
	}

	/**
	 * Play the preview audio for a song version passed as a parameter
	 *
	 * @param {Variant} songVersion -	The song version to play the preview audio
	 * @returns {Promise} - A promise that resolves when the audio is loaded
	 * @throws {Error} - Error in playing the audio or loading the audio
	 */
	type SongProp = {
		id: string
		name: string
	}
	type AccessPoint = 'Song list item grid' | 'Song list item row' | 'Song version selector'

	const playPreview = async (songVersion: Variant, song: SongProp, accessPoint: AccessPoint) => {
		previewEnded.value = false
		if (!window.audioPlayer.initialized) {
			await new Promise(resolve => setTimeout(resolve, 200))
		}
		const userStore = useUserStore()
		try {
			const audioFile = songVersion.preview_vocals ? songVersion.preview_vocals : songVersion.preview
			await $previewPlayer.load(audioFile, 'preview')
			const totalDuration = 30

			const updateProgress = () => {
				const currentTime = $previewPlayer.getCurrentTime()
				if (currentTime > 0) {
					progress.value = (currentTime / totalDuration) * 100
				}
			}

			progress.value = 0
			$previewPlayer.play()
			const previewPlayerStore = usePreviewPlayerStore()

			previewPlayerStore.setCurrentSong(songVersion.id)

			if (import.meta.client) {
				// Update progress every 100ms
				progressInterval.value = setInterval(updateProgress, 100)
			}

			// Stop updating progress when the preview ends
			$previewPlayer.events.addEventListener('ended', () => {
				stopCheckingPreview()
				previewEnded.value = true
				previewPlayerStore.setCurrentSong(null)
			})

			currentlyPlayingVersion.value = songVersion.id

			const { segmentEvent } = useSegment()
			if (!userStore.showDemoPlayer) {
				const queueStore = useQueueStore()
				segmentEvent('Player Song Version Previewed', {
					song_id: songVersion.resource_id,
					song_name: song.name,
					variant_id: songVersion.id,
					access_point: accessPoint,
					session_id: queueStore.firstInQueue?.entryID
				})
			}
		} catch (error) {
			console.log('Preview audio error', error)
			return error
		}
	}

	/**
	 * Reset the currentlyPlayingVersion to null
	 */
	const resetPreview = () => {
		currentlyPlayingVersion.value = null
	}

	/**
	 * Stop the variant audio preview player and reset the currentlyPlayingVersion
	 */
	const stopPreview = () => {
		if ($previewPlayer) {
			$previewPlayer.stop()
		}
		resetPreview()
		stopCheckingPreview()
		const previewPlayerStore = usePreviewPlayerStore()
		previewPlayerStore.setCurrentSong(null)
	}
	onUnmounted(() => stopCheckingPreview)

	return { playPreview, stopPreview, currentlyPlayingVersion, resetPreview, progress, previewEnded }
}
