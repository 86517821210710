import mitt from 'mitt'
import type { Emitter } from 'mitt'
import type { ApplicationEvents } from '~/types/applicationEvents'

const emitter: Emitter<ApplicationEvents> = mitt<ApplicationEvents>()

export const useEventEmit = <K extends keyof ApplicationEvents>(type: K, payload?: ApplicationEvents[K]) => {
	emitter.emit(type, payload as ApplicationEvents[K])
}

export const useEventOn = emitter.on
export const useEventOff = emitter.off

if (process.env.NODE_ENV === 'development') {
	emitter.on('*', (type, e) => console.log('📬 Event:', type, e))
}
