import { defineStore } from 'pinia'
import * as Sentry from '@sentry/vue'
import type { User } from '../types'
import { useNuxtApp } from '#imports'

export const useUserStore = defineStore('user', () => {
	const user = ref(null) as Ref<User | null>
	const userFound = ref(false)
	const loading = ref(false)
	const error = ref(null)
	const autoPlay = ref(true)
	const useSongGrid = ref(false)
	const favorites = ref({
		songs: [] as number[],
		artists: [] as number[],
		playlists: [] as number[]
	})

	function $reset() {
		user.value = null
		userFound.value = false
		loading.value = false
		error.value = null
		autoPlay.value = true
		useSongGrid.value = false
		favorites.value = {
			songs: [],
			artists: [],
			playlists: []
		}
	}
	const username = computed(() => user?.value?.username)
	const getUseSongGrid = computed(() => useSongGrid.value)
	const userResourceId = computed(() => user?.value?.resource_id)
	const userCountry = computed(() => user?.value?.country)
	const favoritedSongs = computed(() => favorites.value.songs)
	const favoritedArtists = computed(() => favorites.value.artists)
	const favoritedPlaylists = computed(() => favorites.value.playlists)
	const queueAutoPlay = computed(() => autoPlay.value)
	/**
	 * Returns the user country from the state to be used in the content requests
	 *
	 * @param state - User store state
	 */
	const userCountryAsObject = computed(() => {
		const country = user?.value?.country
		return { market: country }
	})
	const hasFreeSongsLeft = computed(() => user.value && user.value?.limits?.free_songs_left > 0)
	const hasPremiumSub = computed(() => user.value && user.value.subscription && user.value.subscription.is_active)
	const numberOfFreeSongsLeft = computed(() => user.value?.limits?.free_songs_left || 0)
	const isOutOfFreeSongs = computed(() => user.value && user.value.limits && user.value.limits.free_songs_left === 0)
	const hasSubOrFreeSongsLeft = computed(() => { return hasPremiumSub.value || hasFreeSongsLeft.value })
	const showDemoPlayer = computed(() => {
		return !user.value || useDevice().isMobile || (!hasPremiumSub.value && numberOfFreeSongsLeft.value === 0)
	})
	const isFreemiumDemoPlayer = computed(() => { return user.value && !useDevice().isMobile && showDemoPlayer.value })

	function getAutoPlay() {
		const autoPlayCookie = useCookie<boolean>('autoPlay')
		if (autoPlayCookie.value !== null && autoPlayCookie.value !== undefined) {
			autoPlay.value = autoPlayCookie.value
		} else if (!hasPremiumSub.value) {
			autoPlay.value = false
		} else {
			autoPlay.value = true
		}
		return autoPlay.value
	}

	/** Get all the user's favorites */
	async function getAllFavorites(tokens = null) {
		const { $singaApi } = useNuxtApp()
		try {
			const response = await $singaApi.Favorites.list({ tokens })
			favorites.value.songs = response.songs
			favorites.value.artists = response.artists
			favorites.value.playlists = response.playlists
		} catch (err) {
			console.error('Failed to fetch favorites:', err)
		}
	}
	/**
		 * Loads the Me object from the API and sets the results to the user store state
		 *
		 * @throws {Error} - Error from the API request
		 * @param getFavorites - Whether to get the user's favorites
	*/
	async function getUser(getFavorites = false, tokens = null) {
		if (import.meta.prerender) { return }

		loading.value = true
		try {
			const { $singaApi } = useNuxtApp()
			const response = await $singaApi.Me.get({ tokens })
			user.value = response
			if (user.value) {
				if (getFavorites) {
					await getAllFavorites(tokens)
				}
				if (import.meta.client) {
					const { isDesktop } = useDevice()
					const { identifyFullstoryUser } = useFullstory()
					const { identifySegmentUser } = useSegment()
					identifySegmentUser(user.value, hasPremiumSub.value ?? false)
					if (isDesktop && window.FS && identifyFullstoryUser) {
						identifyFullstoryUser({
							resource_id: user.value.resource_id,
							name: user.value.name,
							username_str: user.value.username,
							email: user.value.email
						})
					}
					getAutoPlay()
				}
				Sentry?.setUser({
					id: user.value.resource_id,
					username: user.value.username,
					country: user.value.country,
					limits: user.value.limits,
					offboarded: user.value.offboarded,
					subscription: user.value.subscription,
					payment_receipts_enabled: user.value.payment_receipts_enabled,
					payment_failed: user.value.payment_failed,
					eligible_for_trial: user.value.eligible_for_trial,
					canonical_url: user.value.canonical_url
				})
			}
			loading.value = false
			return response
		} catch (err: any) {
			error.value = err
			loading.value = false
			console.error(err)
			return err
		}
	}

	/**
		 * Sets the use grid preference
		 *
		 * @param enable - Whether to use the grid or not
		 */
	function setUseSongGrid(enable: boolean) {
		useSongGrid.value = enable
	}
	/**
		 * Toggles the use grid preference
		 */
	function toggleUseSongGrid() {
		useSongGrid.value = !useSongGrid.value
	}
	/**
		 * Sets the user state
		 *
		 * @param payload - User object
		 */
	async function setUser(payload: User | null) {
		user.value = await payload

		if (user.value) {
			const geolocStore = useGeoLocationStore()
			geolocStore.setGeoLocation(user.value.country)
		}
	}
	/**
		 * Sets the user's auto play preference
		 *
		 * @param autoPlay - Whether to auto play or not
		 */
	function setAutoPlay(autoplay: boolean) {
		autoPlay.value = autoplay
		const autoPlayCookie = useCookie<boolean>('autoPlay', { maxAge: 365 * 24 * 60 * 60 })
		autoPlayCookie.value = autoplay
	}
	/**
		 * Adds a favorite to the user's favorites
		 *
		 * @param favoriteGroup - The group (songs/artists/playlists) to add the favorite to
		 * @param favoriteId - The favorite's id
		 */
	function addFavorite(favoriteGroup: 'songs' | 'artists' | 'playlists', favoriteId: number) {
		favorites.value[favoriteGroup].push(favoriteId)
	}
	/**
	 * Deletes a favorite from the user's favorites
	 *
	 * @param favoriteGroup - The group (songs/artists/playlists) to delete the favorite from
	 * @param favoriteId - The favorite's id
	 */
	function deleteFavorite(favoriteGroup: 'songs' | 'artists' | 'playlists', favoriteId: number) {
		const index: number = favorites.value[favoriteGroup].indexOf(favoriteId)
		delete favorites.value[favoriteGroup][index]
	}
	return { $reset, userFound, user, loading, error, autoPlay, useSongGrid, favorites, username, getUseSongGrid, userResourceId, userCountry, favoritedSongs, favoritedArtists, favoritedPlaylists, queueAutoPlay, userCountryAsObject, hasFreeSongsLeft, isOutOfFreeSongs, hasPremiumSub, numberOfFreeSongsLeft, hasSubOrFreeSongsLeft, showDemoPlayer, isFreemiumDemoPlayer, getAllFavorites, getUser, setUseSongGrid, toggleUseSongGrid, setUser, setAutoPlay, getAutoPlay, addFavorite, deleteFavorite }
})
