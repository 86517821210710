import { defineStore } from 'pinia'
import { useWakeLock } from '@vueuse/core'

export const usePlayerStore = defineStore({
	id: 'KaraokePlayerStore',
	state: () => {
		return {
			showPlayer: false,
			playerState: 'interrupted',
			playerLoading: false,
			showMiniPlayer: false
		}
	},
	actions: {
		async setShowPlayer(bool: boolean) {
			this.showPlayer = bool

			const { segmentEvent } = useSegment()
			const { isSupported, request, release } = useWakeLock()
			if (bool && isSupported.value) {
				await request('screen')
				// console.log('Wake lock active')
			} else if (!bool && isSupported.value) {
				await release()
				// console.log('Wake lock released')
			}
			const queueStore = useQueueStore()
			segmentEvent(bool ? 'Player Expanded' : 'Player Minimized', { session_id: queueStore.firstInQueue?.entryID })
		},
		setShowMiniPlayer(bool: boolean) {
			this.showMiniPlayer = bool
		},
		setLoadingState(bool: boolean) {
			this.playerLoading = bool
		}
	}
})
