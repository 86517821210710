<template lang="pug">
.PasswordReset(ref="el")
	.auth-header
		p.auth-sub {{ t('auth.email.enter') }}
	.auth-body
		form.reset-password(@submit.prevent="resetPassword()")
			SingaField(:message="emailError" messageClass="error" :label="t('auth.email.label')" labelFor="loginEmail")
				SingaInput.input-regular(
					ref="emailInputElem"
					rounded
					v-model="usernameOrEmail"
					@update:modelValue="emailError = ''"
					:placeholder="t('auth.email.placeholder')"
					icon="mail"
					id="loginEmail")
			SingaSubmitButton.is-full-width.is-transparent-dark.is-regular(native-type="submit" :isLoading="isResetLoginLoading" :buttonCTA="t('auth.password.reset')")
		.link
			a(@click="cancelLogin") {{ t('general.cancel') }}
</template>

<script setup lang="ts">
const { $singaApi } = useNuxtApp()
const isResetLoginLoading = ref<boolean>(false)
const emailError = ref<string>('')
const usernameOrEmail = ref<string>('')
const el = ref<HTMLDivElement>()
const { t } = useI18n()

const props = defineProps({
	username: {
		type: String,
		default: ''
	}
})

const emit = defineEmits([
	'reset-success',
	'reset-cancel',
	'update-height'
])

const resetPassword = async () => {
	isResetLoginLoading.value = true
	try {
		const response = await $singaApi.Me.Reset.Password.getResetToken(usernameOrEmail.value)
		if (response) {
			emit('reset-success')
		}
	} catch (error) {
		emailError.value = 'No account found'
		console.log(error)
	} finally {
		isResetLoginLoading.value = false
	}
}

const cancelLogin = () => {
	emit('reset-cancel')
}
const updateHeightOnResize = useDebounceFn(() => {
	emit('update-height', el.value?.offsetHeight)
}, 100)

onMounted(() => {
	window.addEventListener('resize', updateHeightOnResize)
	emit('update-height', el.value?.offsetHeight)
	usernameOrEmail.value = props.username
})

onUnmounted(() => {
	window.removeEventListener('resize', updateHeightOnResize)
})
</script>

<style lang="sass" scoped>
.reset-password
	margin-bottom: $spacing-16
.PasswordReset
	position: absolute
	left: 0
	right: 0
</style>
