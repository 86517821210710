<template lang="pug">
.EmailLogin(ref="el")
	.auth-header
		template(v-if="props.userExists")
			p.auth-sub(v-html="t('auth.signup.existing')")
		template(v-else)
			p.auth-sub(v-html="t('auth.signup.newAccount')")
	.auth-body
		template(v-if="props.userExists")
			SingaField(:label="t('auth.email.label')" labelFor="loginEmail")
				SingaInput.input-regular(
					rounded
					type="text"
					v-model="updatedUsername"
					:placeholder="t('auth.email.placeholder')"
					icon="mail"
					size="is-small"
					disabled
					@update:modelValue="updateUsername"
					id="loginEmail")
			SingaField(:variant="loginError !== '' ? 'danger' : ''" :message="loginError" :label="t('auth.password.label')" labelFor="loginPassword")
				.input-wrapper
					SingaInput.password-input(
						ref="passwordInputElem"
						@keyup.enter="login"
						rounded
						:type="revealPassword ? 'text' : 'password'"
						v-model="password"
						@update:modelValue="clearLoginAndAuthError()"
						:placeholder="t('auth.password.placeholder')"
						icon="key"
						id="loginPassword"
						autocomplete="current-password"
						)
					SingaIcon.icon-password-reveal(v-if="!loginError" @click="revealPassword = !revealPassword" :icon="revealPassword ? 'eye-off-outline' : 'eye-outline'")

			SingaSubmitButton.is-full-width.is-transparent-dark.is-regular(:disabled="loginError !== ''" @click="login" :isLoading="isPasswordLoginLoading" :buttonCTA="t('modal.conversion.logIn.link')")
			.link
				a(@click="cancelLogin") {{ t('general.cancel') }}
		template(v-else)
			SingaField(:message="emailErrors" messageClass="error" :variant="emailErrors !== '' ? 'danger' : ''" :label="t('auth.email.label')" labelFor="loginEmail")
				SingaInput.input-regular(
					rounded
					v-on:focusout="validateEmail"
					type="text"
					v-model="emailField"
					:placeholder="t('auth.email.placeholder')"
					@update:modelValue="emailError = ''"
					icon="mail"
					disabled
					id="loginEmail")
			SingaField(:message="emailConfirmError" messageClass="error" :variant="emailConfirmError !== '' ? 'danger' : ''" :label="t('auth.email.confirm.label')" labelFor="loginEmailConfirm")
				SingaInput.input-regular(
					rounded
					ref="confirmEmailElem"
					v-on:focusout="validateEmailConfirm"
					@update:modelValue="emailConfirmError = ''"
					type="text"
					v-model="emailConfirm"
					:placeholder="t('auth.signup.confirmPlaceholder')"
					icon="mail"
					id="loginEmailConfirm")
			SingaField(:message="passWordErrors" messageClass="error" :variant="passWordErrors !== '' ? 'danger' : ''" :label="t('auth.password.label')" labelFor="password")
				.input-wrapper
					SingaInput.password-input(
						ref="passwordInputElem"
						v-on:focusout="validatePassword"
						@keyup.enter="signup"
						@update:modelValue="passwordError = ''"
						rounded
						:type="revealPassword ? 'text' : 'password'"
						v-model="password"
						:placeholder="t('signup.password.createPassword')"
						icon="key"
						id="password"
						autocomplete="new-password")
					SingaIcon.icon-password-reveal(v-if="!passWordErrors" @click="revealPassword = !revealPassword" :icon="revealPassword ? 'eye-off-outline' : 'eye-outline'")

			SingaSubmitButton.is-full-width.is-transparent-dark.is-regular(
				:disabled="!canSubmit"
				@click="signup"
				:isLoading="isPasswordLoginLoading || isLoggedIn"
				:buttonCTA="t('auth.signup.createCTA')")
			.link
				a(@click="cancelLogin") {{ t('general.cancel') }}
</template>

<script setup lang="ts">
import { useDebounceFn } from '@vueuse/core'

const { registerUser, authError, clearLoginError, getTokens, isLoggedIn } = useAuth()
const userStore = useUserStore()
const queueStore = useQueueStore()

const { replaceDemoWithFullSong } = queueStore
const { segmentEvent } = useSegment()

const updatedUsername = ref('')
const emailConfirm = ref('')
const emailField = ref('')
const el = ref<HTMLDivElement>()
const { t } = useI18n()
const loginError = ref('')
const emailError = ref('')
const emailConfirmError = ref('')
const passwordError = ref('')
const signupError = ref('')

const props = defineProps({
	username: {
		type: String,
		required: true
	},
	userExists: {
		type: Boolean,
		required: true
	}
})
const emit = defineEmits([
	'reset-cancel',
	'update-username',
	'update-height'

])
const cancelLogin = () => {
	emit('reset-cancel')
}

const clearLoginAndAuthError = () => {
	clearLoginError()
	loginError.value = ''
}

const isPasswordLoginLoading = ref(false)
const route = useRoute()
const password = ref('')
const passwordInputElem = ref<HTMLInputElement | null>(null)
const confirmEmailElem = ref<HTMLInputElement | null>(null)

const canSubmit = computed(() => {
	// are not empty
	return !isPasswordLoginLoading.value && !passwordError.value && !emailError.value && !emailConfirmError.value && password.value !== '' && emailField.value !== '' && emailConfirm.value !== '' && !isLoggedIn.value
})

const redirectUrl = computed(() => {
	return route.query.redirect ? route.query.redirect.toString() : '/karaoke'
})
// create a getter with argument, check if authApiError[argument] exists
const getApiError = computed(() => {
	return (fieldName: string) => {
		// return if authApiError is not Null and has the field name
		const error = authError.value as Record<string, any>
		return error && error[fieldName] ? error[fieldName] : []
	}
})

// merge the errors from the API and the local validation
const emailErrors = computed(() => {
	return [emailError.value, ...getApiError.value('email')].filter(e => e !== '').join('\n')
})
const passWordErrors = computed(() => {
	return [passwordError.value, ...getApiError.value('password')].filter(e => e !== '').join('\n')
})

const login = async () => {
	isPasswordLoginLoading.value = true
	console.log('login started')
	await getTokens({ username: props.username, email: undefined, password: password.value }, redirectUrl.value)
	if (authError.value) {
		loginError.value = t('signup.password.authError')
	} else {
		segmentEvent('User Logged In', {
			provider: 'Singa',
			user_id: userStore.user?.resource_id
		})
		replaceDemoWithFullSong()
	}
	isPasswordLoginLoading.value = false
	clearLoginError()
}
// Email confirm (see that matches the latter input)
const validateEmailConfirm = () => {
	emailConfirmError.value = ''
	if (emailField.value !== emailConfirm.value) {
		emailConfirmError.value = t('signup.email.confirmError')
	}
}

const updateUsername = () => {
	emit('update-username', updatedUsername.value)
}

const revealPassword = ref(false)

// is Email valid (regex)
const validateEmail = () => {
	emailError.value = ''
	const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
	if (!regex.test(emailField.value)) {
		emailError.value = t('signup.email.errorInvalid')
	}
	if (emailField.value !== '') {
		validateEmailConfirm()
	}
}

// Password (min length 8 characters), no spaces at the beginning or end
const validatePassword = () => {
	passwordError.value = ''
	if (password.value.length < 8) {
		passwordError.value += t('signup.password.errorLimit')
	}
	// if password starts or ends with a space
	if (password.value.startsWith(' ') || password.value.endsWith(' ')) {
		passwordError.value += t('signup.password.errorSpaces')
	}
}

const signup = async () => {
	signupError.value = ''
	validateEmail()
	validateEmailConfirm()
	validatePassword()
	if (!canSubmit.value) {
		return
	}
	await registerUser({ email: emailField.value, username: undefined, password: password.value }, redirectUrl.value, t('auth.signup.success'))
	if (!authError.value) {
		isPasswordLoginLoading.value = true
		const { getStoredQueryParams } = useQueryParams()
		segmentEvent('User Signed Up', {
			provider: 'Singa',
			user_id: userStore.user?.resource_id,
			...getStoredQueryParams()
		})
		replaceDemoWithFullSong()
	} else if (authError.value) {
		const { $oruga } = useNuxtApp()
		signupError.value = typeof authError.value === 'string' ? authError.value : JSON.stringify(authError.value)
		// Add Segment event for signup error
		$oruga.notification.open({
			position: 'top-right',
			variant: 'warning',
			icon: 'alert-circle-outline',
			iconSize: 'medium',
			message: t('auth.signup.error')
		})
		isPasswordLoginLoading.value = false
	}
	isPasswordLoginLoading.value = false
}

const updateHeightOnResize = useDebounceFn(() => {
	emit('update-height', el.value?.offsetHeight)
}, 100)

onMounted(() => {
	emit('update-height', el.value?.offsetHeight)
	window.addEventListener('resize', updateHeightOnResize)
	if (props.userExists) {
		updatedUsername.value = props.username
		passwordInputElem.value?.focus()
	} else {
		emailField.value = props.username
		confirmEmailElem.value?.focus()
	}
})

onUnmounted(() => {
	window.removeEventListener('resize', updateHeightOnResize)
	clearLoginError()
})
</script>

<style lang="sass" scoped>
.auth-sub
	white-space: break-spaces !important
.wrap
	position: relative
.link
	margin-top: $spacing-16
.EmailLogin
	position: absolute
	left: 0
	right: 0

:deep(input::-ms-reveal)
	display: none

.input-wrapper
	position: relative
	background-color: $transparent-white-12
	border-radius: $radius-round !important
	width: 100%
	padding: 5px 0 0 0 !important
	height: 50px

.icon-password-reveal
	color: $color-grey-30
	width: $spacing-24 !important
	height: $spacing-24 !important
	cursor: pointer !important
	position: absolute
	right: $spacing-24
	top: 50%
	transform: translateY(-50%)
	:hover
		color: $color-grey-50

:deep(.iconify--ion)
	width: $spacing-24 !important
	height: $spacing-24 !important
	color: $color-grey-30

:deep(.is-rounded)
	border-radius: $radius-round !important
</style>
