
// @ts-nocheck


export const localeCodes =  [
  "en",
  "us",
  "fi",
  "sv",
  "de"
]

export const localeLoaders = {
  en: [
    {
      key: "locale_en_46json_2f3fc6f3",
      load: () => import("#nuxt-i18n/2f3fc6f3" /* webpackChunkName: "locale_en_46json_2f3fc6f3" */),
      cache: true
    }
  ],
  us: [
    {
      key: "locale_en_US_46json_a46f153b",
      load: () => import("#nuxt-i18n/a46f153b" /* webpackChunkName: "locale_en_US_46json_a46f153b" */),
      cache: true
    }
  ],
  fi: [
    {
      key: "locale_fi_46json_c78e816f",
      load: () => import("#nuxt-i18n/c78e816f" /* webpackChunkName: "locale_fi_46json_c78e816f" */),
      cache: true
    }
  ],
  sv: [
    {
      key: "locale_sv_46json_51ba8059",
      load: () => import("#nuxt-i18n/51ba8059" /* webpackChunkName: "locale_sv_46json_51ba8059" */),
      cache: true
    }
  ],
  de: [
    {
      key: "locale_de_DE_46json_c4fafa7e",
      load: () => import("#nuxt-i18n/c4fafa7e" /* webpackChunkName: "locale_de_DE_46json_c4fafa7e" */),
      cache: true
    }
  ]
}

export const vueI18nConfigs = []

export const nuxtI18nOptions = {
  restructureDir: false,
  experimental: {
    localeDetector: "",
    switchLocalePathLinkSSR: false,
    autoImportTranslationFunctions: false,
    typedPages: true,
    typedOptionsAndMessages: false,
    generatedLocaleFilePathFormat: "absolute",
    alternateLinkCanonicalQueries: false,
    hmr: true
  },
  bundle: {
    compositionOnly: true,
    runtimeOnly: false,
    fullInstall: true,
    dropMessageCompiler: false,
    optimizeTranslationDirective: true
  },
  compilation: {
    strictMessage: true,
    escapeHtml: false
  },
  customBlocks: {
    defaultSFCLang: "json",
    globalSFCScope: false
  },
  locales: [
    {
      code: "en",
      language: "en",
      name: "English",
      files: [
        {
          path: "/app/locales/en.json",
          cache: undefined
        }
      ]
    },
    {
      code: "us",
      language: "en-US",
      name: "English US",
      files: [
        {
          path: "/app/locales/en_US.json",
          cache: undefined
        }
      ]
    },
    {
      code: "fi",
      language: "fi-FI",
      name: "Suomi",
      files: [
        {
          path: "/app/locales/fi.json",
          cache: undefined
        }
      ]
    },
    {
      code: "sv",
      language: "sv-SE",
      name: "Svenska",
      files: [
        {
          path: "/app/locales/sv.json",
          cache: undefined
        }
      ]
    },
    {
      code: "de",
      language: "de-DE",
      name: "Deutsch",
      files: [
        {
          path: "/app/locales/de_DE.json",
          cache: undefined
        }
      ]
    }
  ],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  trailingSlash: false,
  defaultLocaleRouteNameSuffix: "default",
  strategy: "prefix",
  lazy: true,
  langDir: "./locales",
  rootRedirect: undefined,
  detectBrowserLanguage: {
    alwaysRedirect: true,
    cookieCrossOrigin: false,
    cookieDomain: null,
    cookieKey: "i18n_redirected",
    cookieSecure: false,
    fallbackLocale: "",
    redirectOn: "root",
    useCookie: true
  },
  differentDomains: false,
  baseUrl: "",
  customRoutes: "page",
  pages: {},
  skipSettingLocaleOnNavigate: false,
  types: "composition",
  debug: false,
  parallelPlugin: false,
  multiDomainLocales: false,
  i18nModules: []
}

export const normalizedLocales = [
  {
    code: "en",
    language: "en",
    name: "English",
    files: [
      {
        path: "/app/locales/en.json",
        cache: undefined
      }
    ]
  },
  {
    code: "us",
    language: "en-US",
    name: "English US",
    files: [
      {
        path: "/app/locales/en_US.json",
        cache: undefined
      }
    ]
  },
  {
    code: "fi",
    language: "fi-FI",
    name: "Suomi",
    files: [
      {
        path: "/app/locales/fi.json",
        cache: undefined
      }
    ]
  },
  {
    code: "sv",
    language: "sv-SE",
    name: "Svenska",
    files: [
      {
        path: "/app/locales/sv.json",
        cache: undefined
      }
    ]
  },
  {
    code: "de",
    language: "de-DE",
    name: "Deutsch",
    files: [
      {
        path: "/app/locales/de_DE.json",
        cache: undefined
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false
export const hasPages = true

export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18nInternal"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
/** client **/

/** client-end **/