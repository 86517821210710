import { useUserStore } from '../pinia/userStore'

export const useUser = () => {
	const userStore = useUserStore()
	const { isLoggedIn } = useAuth()

	const hasPremiumOrFreeSongs = ref(false)
	if (userStore.hasFreeSongsLeft || userStore.hasPremiumSub) {
		hasPremiumOrFreeSongs.value = true
	}

	return { isUserAuthorised: isLoggedIn, hasPremiumOrFreeSongs }
}
