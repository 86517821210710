<template lang="pug">
div
	.columns.is-mobile.is-multiline(v-if="layout === 'column'")
		.column.is-6-mobile-small.is-4-mobile.is-3-tablet.is-2-desktop.is-2-widescreen.is-2-fullhd(v-if="type !== 'Genre'" v-for="n in listAmount" :key="n")
			.column-item.skeleton-item(v-if="type === 'Song' || type === 'Playlist'")
				.skeleton-img
				.skeleton-texts
					.skeleton-song
					.skeleton-artist(v-if="type === 'Song'")
			.column-item.artist-item.skeleton-item(v-else-if="type === 'Artist'")
				.skeleton-artist-img
				.skeleton-texts
					.skeleton-song
		.column.is-6-mobile.is-3-tablet.is-3-desktop.is-2-widescreen.is-2-fullhd(v-if="type === 'Genre'" v-for="n in listAmount" :key="n")
			.genre-item.skeleton-item
				svg.genre-shape(width='83' height='90' viewbox='0 0 83 90' fill="rgba(255, 255, 255, 0.04)" xmlns='http://www.w3.org/2000/svg')
					g(clip-path='url(#clip0_75:6866)')
						path(d='M50.9188 6.84618C62.1749 -8.78664 50.6321 -29.0976 37.8319 -37.2604C13.5772 -52.7313 -7.55758 -43.754 -28.6297 -18.7155C-49.7034 6.32226 -51.8742 39.171 -33.4739 54.6578C-24.9922 61.7955 -13.3885 66.0743 -4.46916 64.072C9.85259 60.8481 14.1832 46.6326 18.4735 35.4531C24.2097 20.5024 40.8117 20.8838 50.9188 6.84618Z')

	.rows(v-else-if="layout === 'row'")
		.row-wrap(:class="{ 'queue-row': isQueue }")
			.row-item(v-if="type === 'Song'" v-for="(n, index) in listAmount" :key="n")
				span.list-number(v-if="blockName === 'Top Songs'") {{ index + 1}}
				.skeleton-row-img.row-skeleton-item
				.skeleton-texts
						.skeleton-song.row-skeleton-item
						.skeleton-artist.row-skeleton-item
			.seeAllButton(:class="{ 'numbered-list': blockName === 'Top Songs' }")
				.skeleton-row-img.row-skeleton-item
				span.see-all-text {{ t('songs.seeAll') }}
	.rectangle(v-if="type==='Rectangle'")
		.skeleton-rectangle.row-skeleton-item(:style="{ width: `${dimensions.width}px`, height: `${dimensions.height}px` }")
</template>

<script setup lang="ts">
const { t } = useI18n()

defineProps({
	type: {
		type: String as PropType<'Artist' | 'Song' | 'Playlist' | 'Genre' | 'Rectangle'>,
		required: true
	},
	listAmount: {
		type: Number,
		default: 1
	},
	animated: {
		type: Boolean,
		default: true
	},
	layout: {
		type: String,
		default: 'column'
	},
	blockName: {
		type: String,
		default: ''
	},
	dimensions: {
		type: Object,
		default: () => ({ width: 0, height: 0 })
	},
	isQueue: {
		type: Boolean,
		default: false
	}
})
</script>

<style scoped lang="sass">
@keyframes shimmer
	0%
		background-position: -200px -200px
	100%
		background-position: 200px 200px

.skeleton-item
	background: linear-gradient(to bottom right, rgba(255, 255, 255, 0) 8%, rgba(255, 255, 255, 0.06) 18%, rgba(255, 255, 255, 0) 33%)
	background-size: 400% 400%
	animation: shimmer 1.5s infinite linear

.row-skeleton-item
	background: linear-gradient(to bottom right, rgba(255, 255, 255, 0) 8%, rgba(255, 255, 255, 0.06) 18%, rgba(255, 255, 255, 0) 33%)
	background-size: 800% 800%
	animation: shimmer 1.5s infinite linear

.column-item
	background-color: $transparent-white-4
	border-radius: $radius-default
	width: 100%
	display: flex
	flex-direction: column
	justify-content: flex-start
	align-items: center
	padding: 12px

.genre-item
	background-color: $transparent-white-4
	border-radius: $radius-default
	min-width: 100px
	min-height: 128px
	display: flex
	flex-direction: column
	justify-content: flex-start
	align-items: center
	padding: $spacing-16
	position: relative

.skeleton-img
	width: 100%
	min-height: 109px
	min-width: 109px
	aspect-ratio: 1 / 1
	background-color: $transparent-white-4
	border-radius: $spacing-4

.skeleton-artist-img
	width: 100%
	aspect-ratio: 1 / 1
	border-radius: 50%
	background-color: $transparent-white-4

.skeleton-texts
	width: 100%
	display: flex
	flex-direction: column
	height: 81px
	padding-top: $spacing-16

.artist-item
	padding: 20px
	.skeleton-texts
		padding-top: $spacing-24
		align-items: center

.skeleton-song
	width: 85px
	height: 18px
	border-radius: $spacing-4
	background-color: $transparent-white-4
	margin-bottom: $spacing-16
	@media (max-width: 400px)
		width: 68px

.skeleton-artist
	width: 115px
	height: 15px
	border-radius: $spacing-4
	background-color: $transparent-white-4
	margin-bottom: $spacing-8
	align-content: center
	@media (max-width: 400px)
		width: 92px

.genre-shape
	position: absolute
	top: 0
	left: 0
	border-radius: $radius-default

.row-wrap
	@media (min-width: $tablet)
		padding: $spacing-16
		background-color: $transparent-white-4
		&.queue-row
			padding: 0
			background-color: transparent

.row-item
	padding: $spacing-16
	display: flex
	border-radius: $spacing-4
	.skeleton-texts
		justify-content: space-between
		flex-direction: column
		margin-left: $spacing-16
		padding: $spacing-4 0
		height: 48px
		max-width: 130px
		.skeleton-song, .skeleton-artist
			margin: 0
	.list-number
		color: $color-grey-60
		width: $spacing-16
		margin-right: $spacing-16
		padding: $spacing-4
		display: flex
		align-items: center
		justify-content: flex-end

.skeleton-row-img
	background-color: $transparent-white-4
	border-radius: $spacing-4
	width: 48px
	height: 48px

.seeAllButton
	display: flex
	align-items: center
	padding: $spacing-16
	&.numbered-list
		padding-left: $spacing-48 !important
	.see-all-text
		margin-left: $spacing-16
		@include font(basier, medium)
		@include fontSize(s)
		color: $color-grey-30

.skeleton-rectangle
	border-radius: $radius-default
	background-color: $transparent-white-4

.queue-row
	.seeAllButton
		display: none
</style>
