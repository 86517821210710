export function useArtistNames(artists: Artist[]) {
	const isValidArtistsArray = Array.isArray(artists) && artists.length > 0 && artists.every(artist => typeof artist.name === 'string')
	const artistError = 'useArtistNames: no artists array provided or invalid artist array'

	const getMainArtists = (asArray = false) => {
		if (!isValidArtistsArray) {
			console.error(artistError)
			return asArray ? [''] : ''
		}
		const mainArtists = artists
			.filter(artist => !artist.is_featuring)
			.sort((a, b) => a.name.localeCompare(b.name))
			.map(artist => artist.name)
		return asArray ? mainArtists : mainArtists.join(', ')
	}

	const getFeaturingArtists = (asArray = false) => {
		if (!isValidArtistsArray) {
			console.error(artistError)
			return asArray ? [''] : ''
		}
		const featuringArtists = artists
			.filter(artist => artist.is_featuring)
			.sort((a, b) => a.name.localeCompare(b.name))
			.map(artist => artist.name)
		return asArray ? featuringArtists : featuringArtists.join(', ')
	}

	const getAllArtists = (asArray = false) => {
		if (!isValidArtistsArray) {
			console.error(artistError)
			return asArray ? [''] : ''
		}
		const sortedArtists = [...artists]
			.sort((a, b) => a.name.localeCompare(b.name))
			.map(artist => artist.name)
		return asArray ? sortedArtists : sortedArtists.join(', ')
	}

	const getArtistSlugs = () => {
		if (!isValidArtistsArray) {
			console.error(artistError)
			return ''
		}
		return artists
			.map(artist => artist.slug)
			.join(',')
	}

	return {
		getMainArtists,
		getFeaturingArtists,
		getAllArtists,
		getArtistSlugs
	}
}
