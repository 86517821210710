/*
	player
		- init player
		- start player
		- stop player

		- player progress/animation

		- seek
		- pause
		- full screen
		- song logging

		- minimize player
			- play on background
			- maximise (show again)
			- autoplay?
			- queue handling
			- current song
*/
import adapter from 'webrtc-adapter'
import { storeToRefs } from 'pinia'
import { useUserStore } from '../pinia/userStore'
import { useQueueStore } from '../pinia/queueStore'
import { usePlayerStore } from '../pinia/player/karaokePlayer'
import { useSecondScreenStore } from '../pinia/secondScreen/secondScreenStore'
import { useAudioPlayerStore } from '../pinia/player/audioPlayerStore'
import { openFreemiumModal } from './useFreemiumModal'
import { ModalsDownloadApp, AuthCard } from '#components'
import { useNuxtApp } from '#imports'

export const useSingaPlayer = () => {
	const { $audioPlayer, $oruga } = useNuxtApp()
	const bypassAutoPlay = ref(false)

	const karaokeStore = usePlayerStore()
	const queueStore = useQueueStore()
	const secondScreenStore = useSecondScreenStore()
	const audioPlayerStore = useAudioPlayerStore()
	const userStore = useUserStore()
	const { showDemoPlayer, isOutOfFreeSongs } = storeToRefs(userStore)
	const { isSessionOverThirty, isAudioPlaying, roundedDuration } = storeToRefs(audioPlayerStore)
	const { isSecondScreenActive } = storeToRefs(secondScreenStore)
	const { stopPreview } = useVariantPreview()

	const player = ref(null as any)
	let SingaPlayer = null as any
	const fullScreen = ref(false)
	const hidePlayer = ref(false)
	const playerRatio = ref(null as any)
	const playerRatioOffset = ref(null as any)
	const isPlayerFullscreen = ref(false)
	const useCountdown = ref(false)

	const playerRefDiv = ref(null as any)

	const { hasFreeSongsLeft, hasPremiumSub } = storeToRefs(userStore)

	const { songStarted } = queueStore

	const addPlayerEventListeners = () => {
		player.value.onEvent('loading', () => {
			useEventEmit('playerEvent:loading')
		})
		player.value.onEvent('ready', () => {
			useEventEmit('playerEvent:ready')
		})
		player.value.onEvent('start', () => {
			useEventEmit('playerEvent:start')
		})
		player.value.onEvent('pause', () => {
			useEventEmit('playerEvent:pause', isSecondScreenActive.value)
		})
		player.value.onEvent('resume', () => {
			useEventEmit('playerEvent:resume', isSecondScreenActive.value)
		})
		player.value.onEvent('finished', () => {
			useEventEmit('playerEvent:finished')
		})
		player.value.onEvent('shouldlog', () => {
			useEventEmit('playerEvent:shouldlog')
		})
		player.value.onEvent('fullscreenError', () => {
			useEventEmit('playerEvent:fullscreenError')
		})
		player.value.onEvent('error', () => {
			useEventEmit('playerEvent:error')
		})
	}

	const removePlayerEventListeners = () => {
		useEventOff('playerEvent:loading')
		useEventOff('playerEvent:ready')
		useEventOff('playerEvent:start')
		useEventOff('playerEvent:pause')
		useEventOff('player:ended')
		useEventOff('playerEvent:resume')
		useEventOff('playerEvent:finished')
		useEventOff('playerEvent:interruptedOrCompleted')
		useEventOff('playerEvent:shouldlog')
		useEventOff('playerEvent:fullscreenError')
		useEventOff('playerEvent:error')
		useEventOff('player:bypassAutoPlay')
		useEventOff('player:countdown')
		useEventOff('player:destroyPlayer')
	}
	removePlayerEventListeners()

	const playerOpenedEvent = (isSecondscreen = false) => {
		if (queueStore.firstInQueue && !showDemoPlayer.value) {
			const { segmentEvent } = useSegment()
			segmentEvent('Player Opened', {
				fullscreen_mode: false,
				song_id: queueStore.firstInQueue.entry.resource_id,
				variant_id: queueStore.firstInQueue.variant.resource_id,
				popout_player: isSecondscreen,
				session_id: queueStore.firstInQueue.entryID
			})
		}
	}

	const closeMicConnection = () => {
		if (window.stream) {
			window.mediaStreamSourceConnected = null
			window.stream.getTracks().forEach(function (track) {
				track.stop()
			})
			window.stream = null
		}
		if (window.analyser) {
			window.analyser.disconnect()
			window.analyser = null
		}
		if (window.mediaStreamSource) {
			window.mediaStreamSource.disconnect()
			window.mediaStreamSource = null
		}
		if (window.audioContext1) {
			window.audioContext1.close().then(function () {
				window.audioContext1 = null
			})
		}
		window.notPermitted = null
	}

	/**
	 * Destroys the player, removes frequenttimeupdate event listener and resets the session timer
	 * @param {Boolean} hideFreemiumModal - if true, the freemium modal will not be shown
	 */
	const destroyPlayer = (hideFreemiumModal = false) => {
		if (!hideFreemiumModal && !hasPremiumSub.value && isSessionOverThirty.value && !showDemoPlayer.value) { openFreemiumModal() }
		window.audioPlayer.events.removeEventListener('frequenttimeupdate', 'PLAYER_CONTROLS_LISTENER')
		if (player.value) {
			player.value.destroy()
			player.value = null
		}
	}

	const initPlayer = async (isSecondScreen = false) => {
		if (import.meta.client) {
			const SingaPlayerImportPromise = import('~/player/singaplayer.min.js')
			if (hasFreeSongsLeft.value || hasPremiumSub.value || showDemoPlayer.value) {
				try {
					const result = await SingaPlayerImportPromise
					SingaPlayer = result.SingaPlayer
				} catch (error) {
					console.log(error)
					if (!showDemoPlayer.value) {
						const { segmentEvent } = useSegment()
						segmentEvent('Player Error', {
							description: 'Player initialization failed',
							song_id: queueStore.firstInQueue?.entry.resource_id,
							popout_player: isSecondScreen,
							session_id: queueStore.firstInQueue?.entryID
						})
					}
				}
			}
		}
	}

	const initializePlayer = async () => {
		await initPlayer()
		document.addEventListener('fullscreenchange', () => {
			fullScreen.value = document.fullscreenElement !== undefined
			isPlayerFullscreen.value = !isPlayerFullscreen.value
			if (isPlayerFullscreen.value && !isSecondScreenActive.value && !showDemoPlayer.value) {
				const { segmentEvent } = useSegment()
				segmentEvent('Player Opened', {
					fullscreen_mode: true,
					song_id: queueStore.firstInQueue?.entry.resource_id,
					variant_id: queueStore.firstInQueue?.variant.resource_id,
					popout_player: isSecondScreenActive.value,
					session_id: queueStore.firstInQueue?.entryID
				})
			}
		})
	}

	/**
	 * Player setup and event listeners
	 * @param {Object} - Media files for player
	 * @param {Boolean} playAfterSetup - Start playing karaoke immidiately after setup is ready
	 * @param {Boolean} isSecondScreen - true if the player is being created for the pop out player
	 * @param {Boolean} exitSecondScreen - true if the player is being destroyed from the second screen
	 * @param {Boolean} countdown - Enable countdown before playing the song
	 * @param {Boolean} bypass - Bypass autoplay
	 */
	const setupPlayer = async ({ images, audio, lyrics, song, enableMidiPlayer }: any,
		isSecondScreen = false,
		exitSecondScreen = false,
		countdown = false,
		bypass = false) => {
		// root cause why there are no song passed to setupPlayer?
		if (!song) {
			console.error('playerControls: No song provided to setupPlayer')
			useEventEmit('playerEvent:error')
			return
		}
		useCountdown.value = countdown
		await initializePlayer()
		if (player.value !== null) {
			destroyPlayer(exitSecondScreen)
		}
		hidePlayer.value = true
		bypassAutoPlay.value = bypass
		const { outroStart } = useLyrics()
		song.variant.outroStart = outroStart(lyrics)
		const { isMobile } = useDevice()

		const isPremium = () => {
			if (song.entry.has_duet || isMobile) {
				return false
			} else {
				return song.variant.is_plus
			}
		}

		const songObjForSetup = {
			...song.entry,
			audio,
			lyrics,
			premium: isPremium()
		}

		const playerWidth = Math.min((screen as any).width, 1920)
		const ratio = isMobile ? ((screen as any).height - 260) / (screen as any).width : ((screen as any).height / (screen as any).width)
		const playerHeight = playerWidth * ratio
		const midiplayer = enableMidiPlayer

		const playerSetup = {
			width: playerWidth,
			height: playerHeight,
			song: songObjForSetup,
			debug: {
				debug: false,
				fps: false,
				mute: true
			},
			slideOrCam: {},
			devices: {},
			recordingDisabled: true,
			disclaimerText: {
				text: ''
			},
			slideshowOn: true,
			controls: {
				show: false
			},
			slideshow: { images },
			fps: {
				// advancedTiming: false,
				forceSingleUpdate: true
				// forceSingleRender: false,
				// dropFrames: false,
				// desiredFps: 60
			},
			fullscreenStart: true,
			startPaused: true,
			midi: {
				enabled: midiplayer
			}
		}
		window.PhaserGlobal = {}

		window.PhaserGlobal.disableWebAudio = true
		window.notPermitted = 1 // disable microphone
		player.value = new SingaPlayer(playerRefDiv.value, playerSetup)
		playerOpenedEvent()
		if (!isSecondScreen) {
			addPlayerEventListeners()
			player.value.start()
		}
	}

	const setTimeStamp = (timeStamp: number, clear: boolean) => {
		player.value?.dispatch({ signal: 'pos', pos: (timeStamp * 1000) })
		if (clear) {
			// player.value?.dispatch({ signal: 'clearLyrics' })
		}
	}

	useEventOn('player:seeked', (isSecondScreen) => {
		const timeStamp = window.audioPlayer.getCurrentTime()
		player.value?.dispatch({ signal: 'pos', pos: (timeStamp * 1000) })
		player.value?.dispatch({ signal: 'clearLyrics' })
		if (isSecondScreen) {
			secondScreenStore.sendMessageToSecondScreen({ method: 'PLAYER_SEEKED', timeStamp })
		}
	})

	watch(() => playerRefDiv.value, async () => {
		if (playerRefDiv.value) {
			await initPlayer()
			document.addEventListener('fullscreenchange', () => {
				fullScreen.value = document.fullscreenElement !== undefined
				isPlayerFullscreen.value = !isPlayerFullscreen.value
				if (isPlayerFullscreen.value && !isSecondScreenActive.value && !showDemoPlayer.value) {
					const { segmentEvent } = useSegment()
					segmentEvent('Player Opened', {
						fullscreen_mode: true,
						song_id: queueStore.firstInQueue?.entry.resource_id,
						variant_id: queueStore.firstInQueue?.variant.resource_id,
						popout_player: isSecondScreenActive.value,
						session_id: queueStore.firstInQueue?.entryID
					})
				}
			})

			const ratio = ((screen as any).height / (screen as any).width) * 100
			const ratioOffset = (100 - ratio) / 2
			playerRatio.value = ratio
			playerRatioOffset.value = ratioOffset

			window.notPermitted = 1

			useEventOn('playerEvent:loading', (isSecondScreen) => {
				console.log('player client loading')
				if (!isAudioPlaying.value) {
					karaokeStore.setLoadingState(true)
				}
				if (isSecondScreen) {
					secondScreenStore.setPlayerState('loading')
				}
			})

			useEventOn('playerEvent:ready', (isSecondScreen) => {
				console.log('player client ready')
				if (isSecondScreen) {
					secondScreenStore.setPlayerState('ready')
				}
				if (!showDemoPlayer.value) {
					const { segmentEvent } = useSegment()
					segmentEvent('Player Ready', {
						song_id: queueStore.firstInQueue?.entry.resource_id,
						popout_player: isSecondScreen,
						session_id: queueStore.firstInQueue?.entryID
					})
				}
				const { queueAutoPlay } = useUserStore()
				if (!queueAutoPlay) {
					karaokeStore.setLoadingState(false)
				}
			})

			useEventOn('playerEvent:start', async (isSecondScreen) => {
				const skipSilentIntro = queueStore.firstInQueue?.variant?.start / 1000
				// Don't set the time if the song has already gone past the intro (to avoid second screen issues)
				if (!showDemoPlayer.value && audioPlayerStore.currentSeconds < skipSilentIntro) {
					$audioPlayer.setStartingTime(skipSilentIntro)
				}

				stopPreview()
				if (!showDemoPlayer.value) {
					await audioPlayerStore.logSong()
				}
				if (!isSecondScreen) {
					window.audioPlayer.events.addEventListener('frequenttimeupdate', (timeStamp: any) => {
						player.value?.dispatch({ signal: 'pos', pos: (timeStamp * 1000) })
					}, 'PLAYER_CONTROLS_LISTENER')
				} else {
					// window.audioPlayer.events.addEventListener('frequenttimeupdate', (timeStamp: any) => {
					// 	secondScreenStore.sendMessageToSecondScreen({ method: 'SET_PLAYER_TIME_STAMP', timeStamp })
					// }, 'PLAYER_CONTROLS_LISTENER')
				}
				window.audioPlayer.events.addEventListener('ended', () => {
					useEventEmit('playerEvent:interruptedOrCompleted')

					console.log('ended')
					// destroyPlayer() This will be called in next song, is that enough?
					secondScreenStore.setPlayerState('finished')
					useEventEmit('player:ended')
					window.audioPlayer.events.removeEventListener('ended', 'AUTOPLAY_ENDED_LISTENER')
				}, 'AUTOPLAY_ENDED_LISTENER')

				if (isSecondScreen) {
					secondScreenStore.setPlayerState('started')
				}

				const { queueAutoPlay } = useUserStore()
				const playSong = () => {
					// Don't play if auto play is set to false, or if it's not bypassed
					if (queueAutoPlay || bypassAutoPlay.value) {
						$audioPlayer.play()
						songStarted()
					}
					if (!showDemoPlayer.value) {
						const { segmentEvent } = useSegment()
						segmentEvent('Player Playback Started', {
							song_id: queueStore.firstInQueue?.entry.resource_id,
							popout_player: isSecondScreen,
							session_id: queueStore.firstInQueue?.entryID
						})
					}
					bypassAutoPlay.value = false
				}

				// Play song after countdown
				useEventOn('player:bypassAutoPlay', () => {
					setTimeout(() => {
						playSong()
					}, queueStore.countdownTime * 1000)
					bypassAutoPlay.value = true
				})

				// Don't start countdown if autoplay is false, or not set to be bypassed
				if (useCountdown.value && (queueAutoPlay || bypassAutoPlay.value)) {
					useEventEmit('player:countdown')
					setTimeout(() => {
						playSong()
					}, queueStore.countdownTime * 1000)
				} else {
					if (isSecondScreen && !queueAutoPlay) {
						secondScreenStore.sendMessageToSecondScreen({ method: 'MANUAL_AUDIO_TOGGLE', paused: true }) // TODO: Display countdown when the play button is manually clicked
						return
					}
					player.value?.dispatch({ signal: 'clearLyrics' })
					playSong()
				}
			})

			useEventOn('playerEvent:pause', (isSecondScreen) => {
				const { isMobile } = useDevice()
				if (isSecondScreen) {
					secondScreenStore.setPlayerState('pause')
				}
				if (isOutOfFreeSongs.value) {
					openFreemiumModal()
				} else if (showDemoPlayer.value) {
					$oruga.modal.open({
						component: isMobile ? ModalsDownloadApp : AuthCard,
						scroll: 'keep',
						props: {
							type: 'conversionModal',
							action: 'pause'
						}
					})
				}
			})

			useEventOn('player:ended', (isSecondScreen) => {
				console.log('player client end')
				if (isSecondScreen) {
					secondScreenStore.setPlayerState('ended')
				}
			})

			useEventOn('playerEvent:resume', (isSecondScreen) => {
				console.log('player client resume')
				stopPreview()
				if (isSecondScreen) {
					secondScreenStore.setPlayerState('resume')
				}
			})

			useEventOn('playerEvent:finished', (isSecondScreen) => {
				console.log('player client finish')
				if (isSecondScreen) {
					secondScreenStore.setPlayerState('finished')
				}
				if (player.value) {
					player.value.destroy()
					player.value = null
					if (window.stream) {
						closeMicConnection()
					}
				}
			})

			useEventOn('playerEvent:interruptedOrCompleted', (isSecondScreen) => {
				if (isSecondScreen) {
					secondScreenStore.setPlayerState('interrupt')
				}
				const { segmentEvent } = useSegment()
				const eventName = roundedDuration.value > queueStore.firstInQueue?.variant?.outroStart ? 'Player Playback Completed' : 'Player Playback Interrupted'
				console.log('eventName', eventName)
				const eventProps = {
					song_id: queueStore.firstInQueue?.entry.resource_id,
					popout_player: isSecondScreen,
					position: roundedDuration.value,
					session_id: queueStore.firstInQueue?.entryID,
					song_length: Number(queueStore.firstInQueue?.entry.duration)
				}
				segmentEvent(eventName, { ...eventProps })
				segmentEvent('Player Closed', { ...eventProps })
			})

			useEventOn('playerEvent:shouldlog', () => {
				console.log('player client shouldlog')
			})

			useEventOn('playerEvent:fullscreenError', () => {
				console.log('player client fullscreenError')
				player.value?.dispatch({ signal: 'pause' })
			})

			useEventOn('playerEvent:error', (isSecondScreen) => {
				console.log('player client error')
				if (!showDemoPlayer.value) {
					const { segmentEvent } = useSegment()
					segmentEvent('Player Error', {
						description: 'Player error',
						song_id: queueStore.firstInQueue?.entry.resource_id,
						popout_player: isSecondScreen,
						session_id: queueStore.firstInQueue?.entryID
					})
				}
			})
			useEventOn('player:destroyPlayer', () => {
				const { isMobile } = useDevice()
				console.log('on destroy player')
				if (player.value) {
					player.value.destroy()
					player.value = null
					if (window.stream) {
						closeMicConnection()
					}
					if (isOutOfFreeSongs.value) {
						openFreemiumModal()
					} else if (showDemoPlayer.value) {
						karaokeStore.setShowMiniPlayer(false)
						$oruga.modal.open({
							component: isMobile ? ModalsDownloadApp : AuthCard,
							scroll: 'keep',
							props: {
								type: 'conversionModal',
								action: 'finish'
							}
						})
					}
				}
			})
		}
	})

	watch(() => audioPlayerStore.isSessionOverThirty, async (value: boolean) => {
		if (value && !showDemoPlayer.value) {
			const { segmentEvent } = useSegment()
			const { getStoredQueryParams } = useQueryParams()
			segmentEvent('Player Songplay Reportable', {
				fullscreen_mode: document.fullscreenElement !== null,
				song_id: queueStore.firstInQueue?.entry.resource_id,
				variant_id: queueStore.firstInQueue?.variant.resource_id,
				pitch: queueStore.firstInQueue?.pitch,
				popout_player: isSecondScreenActive.value,
				position: roundedDuration.value,
				queue_id: queueStore.queueID,
				song_length: Number(queueStore.firstInQueue?.variant.duration),
				vocals_on: queueStore.firstInQueue?.vocals,
				session_id: queueStore.firstInQueue?.entryID,
				...getStoredQueryParams()
			})
			await audioPlayerStore.logSong()
		}
	})

	// Set the loading state to false when the audio starts playing
	watch(() => isAudioPlaying.value, () => {
		if (isAudioPlaying.value) {
			karaokeStore.setLoadingState(false)
		}
	})

	const setupMediaStream = () => {
		function errorMsg(msg: string, error: any) {
			window.notPermitted = 1
			console.log('message: ', msg)
			if (typeof error !== 'undefined') {
				console.error('error: ', error)
			}
		}
		const constraints: any = ref()
		if (adapter.browserDetails.browser !== 'Not a supported browser.') {
			constraints.value = window.constraints = {
				audio: true,
				video: false
			}
		}
		if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
			navigator.mediaDevices.getUserMedia(constraints.value)
				.then(function (stream) {
					window.stream = stream
					window.AudioContext = window.AudioContext || window.webkitAudioContext
					if (!window.audioContext1) {
						window.audioContext1 = new AudioContext()
					}
					window.mediaStreamSource = window.audioContext1.createMediaStreamSource(window.stream)
					window.analyser = window.audioContext1.createAnalyser()
					window.analyser.fftSize = 2048
					window.analyser.smoothingTimeConstant = 1
					window.mediaStreamSource.connect(window.analyser)

					if (!window.mediaStreamSource || !window.analyser) {
						window.notPermitted = 1
						if (!window.mediaStreamSource) {
							console.log('missing media stream source')
						}
						if (!window.analyser) {
							console.log('missing analyser')
						}
					}
					window.mediaStreamSourceConnected = 1
				})
				.catch(function (error) {
					if (error.name === 'ConstraintNotSatisfiedError') {
						errorMsg('The resolution ' + constraints.video.width.exact + 'x'
							+ constraints.video.width.exact + ' px is not supported by your device.', error)
					} else if (error.name === 'PermissionDeniedError') {
						errorMsg('Permissions have not been granted to use your camera and '
							+ 'microphone, you need to allow the page access to your devices in '
							+ 'order for it to work.', error)
					}
					errorMsg('getUserMedia error: ' + error.name, error)
				})
		} else {
			window.notPermitted = 1
		}
	}

	return { initPlayer, setupPlayer, playerRefDiv, player, setTimeStamp, destroyPlayer, setupMediaStream }
}
