import { useStoryblokApi, useStoryblokBridge } from '@storyblok/vue'
import type { ISbStoriesParams, StoryblokBridgeConfigV2, ISbStoryData } from '@storyblok/vue'
import { useState, onMounted } from '#imports'
import type { NuxtError } from '#app'

export const useStoryblokFetch = async (
	url: string,
	apiOptions: ISbStoriesParams = {},
	bridgeOptions: StoryblokBridgeConfigV2 = {}
) => {
	const storyblokApiInstance = useStoryblokApi()
	const { locale } = useI18n()
	const { $preview } = useNuxtApp()
	const uniqueKey = computed(() => `${JSON.stringify(apiOptions)}${url}${locale.value}`)
	const story = useState<ISbStoryData | null>(`${uniqueKey.value}-state`)
	const storyError = ref<NuxtError<unknown> | null>(null)
	onMounted(() => {
		if (!import.meta.prerender && $preview && story.value && story.value.id) {
			useStoryblokBridge(
				story.value.id,
				evStory => (story.value = evStory),
				bridgeOptions
			)
		}
	})

	const nuxt = useNuxtApp()

	const { data, error, execute }: any = await useLazyAsyncData(uniqueKey.value, () => {
		return storyblokApiInstance.get(
			`cdn/stories/${url}`,
			{ ...apiOptions, language: locale.value }
		)
	}, { getCachedData: key => nuxt.payload.data[key] || nuxt.static.data[key] })
	if (data) {
		story.value = data.value?.data.story
	}
	if (error) {
		storyError.value = error.value
	}
	watch(error, async () => {
		storyError.value = error.value
	})
	watch(data, async () => {
		story.value = data.value?.data.story
	})
	watch(locale, async (newLocale, oldLocale) => {
		if (newLocale !== oldLocale) {
			await execute()
		}
	})
	return { story, storyError }
}
