import { defineStore } from 'pinia'
import type { DiscoverStore } from '~/types/state'
import type { Song } from '~~/types'

export const useDiscoverStore = defineStore({
	id: 'discoverStore',
	state: (): DiscoverStore => {
		return {
			'Song History': { expanded: false, items: [] },
			'Top Genres': { expanded: false, items: [] },
			'Top Songs': { expanded: false, items: [] },
			'Trending Songs': { expanded: false, items: [] },
			'Popular Playlists': { expanded: false, items: [] },
			'Top Artists': { expanded: false, items: [] },
			'Latest Additions': { expanded: false, items: [] },
			'Recommended Singlists': { expanded: false, items: [] },
			'Top Singlists': { expanded: false, items: [] },
			'Top Originals': { expanded: false, items: [] }
		}
	},
	actions: {
		clearAll() {
			this['Song History'].items = []
			this['Top Genres'].items = []
			this['Top Songs'].items = []
			this['Trending Songs'].items = []
			this['Popular Playlists'].items = []
			this['Top Artists'].items = []
			this['Latest Additions'].items = []
			this['Recommended Singlists'].items = []
			this['Top Singlists'].items = []
			this['Top Originals'].items = []
		},
		setItems(itemName: keyof DiscoverStore, items: any[]) {
			this[itemName].items = items
		},
		setExpanded(itemName: keyof DiscoverStore, expanded: boolean) {
			this[itemName].expanded = expanded
		},
		getExpanded(itemName: keyof DiscoverStore) {
			return this[itemName].expanded
		},
		getItem(itemName: keyof DiscoverStore) {
			return this[itemName]
		},
		addEntryToSongHistory(item: Song) {
			this['Song History'].items?.unshift(item)
		}
	}
})
