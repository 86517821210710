import type { Ref } from 'vue'
import type { Song } from '~/types'
import { useRoute } from '#imports'

export const useSonglists = ({ songlistApiResource, params }: any) => {
	const route = useRoute()

	const initialLoad: Ref<boolean> = ref(false)
	const apiResource: Ref<Function> = ref(songlistApiResource)
	const filterString: Ref<string> = ref('')

	const geoLocationStore = useGeoLocationStore()
	const { contentMarket } = storeToRefs(geoLocationStore)

	/**
	 * Set the API resource to use to fetch the list of songs
	 *
	 * @param {Function} resource - The API resource to use to fetch the list of songs
	 */
	const setSongListResource = (resource: any) => {
		apiResource.value = resource
	}

	/**
	 * Load list of songs from the API from the given API resource and set the results to the results ref
	 *
	 * @param {boolean} search - If true, reset the page and results
	 * @param {Object} query - Any additional query params to pass to the API request
	 * @param {String} id - Artist or playlist id to load songs for the ID
	 * @throws {Error} - Error in the API request
	 */
	const loadSongs = async (query?: any, detailId?: number, clearResults: boolean = false, previousResults: Song[] = []): Promise<LoadSongsResult | undefined> => {
		if (!apiResource.value || import.meta.prerender) { return }
		try {
			let page = 1
			let nextPage = false
			let results = []
			let count = 0

			let currentParams = {
				page_size: params?.page_size || 25,
				sort: route.query.sort || params?.sort || ''
			}

			if (query?.page) { page = query.page }

			currentParams = {
				...currentParams,
				...contentMarket.value,
				...query,
				page,
				search: filterString.value
			}

			const response = await apiResource.value(currentParams, detailId)
			if (contentMarket.value.market && response && response.results) {
				for (const key in response.results) {
					const song = 'song' in response.results[key] ? response.results[key].song : response.results[key] as Song
					song.geoblocked = !song.available_markets.includes(contentMarket.value.market)
				}
			}
			nextPage = response.next !== '' && response.next !== null ? true : false
			results = clearResults ? [...response.results] : [...previousResults, ...response.results]
			count = response.count
			// An escape hatch to stop possible infinite loops
			if (!response || (response.results && !response.results.length)) {
				return
			}
			return {
				results,
				page,
				nextPage,
				count
			}
		} catch (error) {
			console.error(error)
			return { results: [], page: 1, nextPage: false, count: 0, error: 'An error occurred' }
		}
	}
	return {
		filterString, loadSongs, initialLoad, setSongListResource
	}
}
