<template lang="pug">
.loading-animation(:class="{'active': showLoadingSpinner}")
	.spinner(v-if="$props.type === 'spinner'")
		svg(:width="size" :height="size" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg")
			path(:d="`M48 24c0 13.255-10.745 24-24 24S0 37.255 0 24 10.745 0 24 0s24 10.745 24 24ZM7.2 24c0 9.278 7.522 16.8 16.8 16.8S40.8 33.278 40.8 24 33.278 7.2 24 7.2 7.2 14.722 7.2 24Z`" fill="#1D1D1D")
			path(:d="`M38.425 38.425c1.406 1.406 3.711 1.42 4.891-.181A24 24 0 0 0 27.586.269C25.622-.028 24 1.612 24 3.6s1.63 3.561 3.573 3.984a16.8 16.8 0 0 1 10.56 25.497c-1.074 1.673-1.114 3.938.292 5.344Z`" fill="#12B961")
	.wrap(v-if="$props.type === 'dots'")
		- for (var i = 0; i < 3; i++)
			.dot
</template>

<script setup lang="ts">
import { useTimeout } from '@vueuse/core'

const props = defineProps({
	size: {
		type: Number,
		default: 48
	},
	timeOut: {
		type: Number,
		default: 400
	},
	type: {
		type: String,
		default: 'spinner'
	}
})

const loadingStartTime = ref<number | null>(null)
const showLoadingSpinner = ref(false)
const { ready: shouldShowSpinner, start: startLoadingTimer, stop: stopLoadingTimer } = useTimeout(props.timeOut, { controls: true })

watch(shouldShowSpinner, (show) => {
	if (show && loadingStartTime.value) {
		showLoadingSpinner.value = true
	}
})

onMounted(() => {
	loadingStartTime.value = Date.now()
	startLoadingTimer()
})

onUnmounted(() => {
	stopLoadingTimer()
	showLoadingSpinner.value = false
	loadingStartTime.value = null
})
</script>

<style lang="sass" scoped>
.loading-animation
	opacity: 0
	visibility: hidden
	transition: all 100ms
	&.active
		opacity: 1
		visibility: visible

svg
	transform-origin: center center
	animation: .9s spin infinite linear
@keyframes spin
	from
		transform: rotate(0deg)
	to
		transform: rotate(360deg)

.dot
	display: inline-block
	width: 8px
	height: 8px
	background: white
	border-radius: 100%
	animation: dotAnimation 1000ms ease infinite alternate
	&:nth-child(2)
		animation-delay: 300ms
	&:nth-child(3)
		animation-delay: 600ms
	&:not(:last-child)
		margin-right: 4px
.spinner
	display: flex
	justify-content: center
@keyframes dotAnimation
	0%
		opacity: 0
		transform: scale(0.7)
	100%
		opacity: 1
		transform: scale(1)
</style>
