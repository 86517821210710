<template lang="pug">
TooltipProvider(:delay-duration="0")
	TooltipRoot(disable-closing-trigger)
		TooltipTrigger(as-child :reference="thumb?.$el")
			SliderRoot.SliderRoot(
				v-model="sliderValue"
				:max="max"
				:step="step"
				@valueCommit="onValueCommit"
			)
				SliderTrack.SliderTrack(
					ref="sliderTrack"
					:class="[{ 'disabled': disabled, 'dragging': dragging }]"
					@pointerdown="onTrackClick"
				)
					SliderRange.SliderRange
				SliderThumb.SliderThumb(
					ref="thumb"
					v-show="showThumb"
					aria-label="Volume"
					:disabled="disabled"
					@pointerdown="onDragStart"
					@pointerup="onDragEnd"
					:class="{ 'dragging': dragging }"
				)
		TooltipPortal(defer)
			TooltipContent.content(v-show="showThumb" :avoid-collisions="true" position-strategy="absolute" side="top" update-position-strategy="always") {{ toolTipValue }}
				TooltipArrow.arrow
</template>

<script setup lang="ts">
import { SliderRange, SliderRoot, SliderThumb, SliderTrack, TooltipPortal, TooltipContent, TooltipProvider, TooltipRoot, TooltipTrigger, TooltipArrow } from 'reka-ui'
import { useEventListener } from '@vueuse/core'

const props = withDefaults(defineProps<{
	value: number
	tooltipAlways: boolean
	max: number
	step: number
	customFormatter: (val: number)=> string
	disabled: boolean
	hideThumbBeforeProgress: boolean
	letDragToZero: boolean
}>(), {
	customFormatter: (val: number) => `${val}`,
	hideThumbBeforeProgress: false,
	letDragToZero: true
})

const emit = defineEmits(['change', 'drag-end', 'drag-start', 'dragging'])

const showThumb = computed(() => !props.hideThumbBeforeProgress || props.value > 0)
const dragging = ref(false)
const draggedSeconds = ref(props.value)
const previousValue = ref(props.value)

const toolTipValue = computed(() => dragging.value ? props.customFormatter(draggedSeconds.value) : props.customFormatter(props.value))

const sliderTrack = useTemplateRef('sliderTrack')
const thumb = useTemplateRef('thumb')
const sliderValue = ref(dragging.value ? [draggedSeconds.value] : [props.value])

watch(() => props.value, (value) => {
	if (!dragging.value && Math.abs(value - sliderValue.value[0]) > 0.1) {
		sliderValue.value = [value]
	}
})

const onValueCommit = (value: number[]) => {
	emit('change', value[0])
	sliderValue.value = [value[0]]
}

const calculateValue = (clientX: number) => {
	if (!sliderTrack.value) return 0
	const rect = sliderTrack.value.$el.getBoundingClientRect()
	const newValue = ((clientX - rect.left) / rect.width) * props.max
	return props.letDragToZero ? newValue : Math.max(newValue, 1)
}

const onDragging = (event: PointerEvent) => {
	if (dragging.value) {
		const newValue = calculateValue(event.clientX)
		draggedSeconds.value = newValue
		sliderValue.value = [newValue]
		emit('dragging', newValue)
	}
}

const commitValue = async (clientX: number) => {
	const newValue = calculateValue(clientX)
	const shouldClear = newValue < previousValue.value && (Math.abs(newValue - previousValue.value) > 0.5)
	previousValue.value = newValue
	emit('change', newValue, shouldClear)
	await nextTick()
	sliderValue.value = [newValue]
	draggedSeconds.value = newValue
}

const onDragEnd = (event: PointerEvent) => {
	if (!dragging.value) return
	dragging.value = false
	commitValue(event.clientX)
	emit('drag-end')
}

const onTrackClick = (event: PointerEvent) => {
	if (!dragging.value && sliderTrack.value) {
		dragging.value = true
		emit('drag-start')
		commitValue(event.clientX)
	}
}

const onDragStart = () => {
	dragging.value = true
	emit('drag-start')
}
onMounted(() => {
	useEventListener(window, 'pointermove', onDragging, { passive: true })
	useEventListener(window, 'pointerup', onDragEnd, { passive: true })

	if (sliderTrack.value) {
		useEventListener(sliderTrack.value.$el, 'pointerup', onDragEnd, { passive: true })
	}
})

onUnmounted(() => {
	if (sliderTrack.value) {
		sliderTrack.value.$el.removeEventListener('pointerup', onDragEnd)
	}
	window.removeEventListener('pointermove', onDragging)
	window.removeEventListener('pointerup', onDragEnd)
})
</script>

<style lang="sass" scoped>
.mini-player
	padding: 0.75em 0
	margin: -0.75em 0
.SliderRoot
	position: relative
	display: flex
	align-items: center
	user-select: none
	touch-action: none
	width: 100%

.SliderTrack
	background-color: $transparent-white-12
	position: relative
	flex-grow: 1
	height: $spacing-8
	cursor: pointer
	&.dragging
		cursor: grabbing
	&.disabled
		cursor: not-allowed !important
	&::before
		content: ''
		position: absolute
		top: -$spacing-8
		bottom: -$spacing-8
		left: 0
		right: 0
		background-color: transparent
.SliderRange
	position: absolute
	background-color: $color-green-60
	height: 100%
	&.disabled
		cursor: not-allowed

.SliderThumb
	transition: opacity 0.1s
	height: $spacing-16
	width: $spacing-16
	border-radius: $radius-round
	overflow: hidden
	background-color: black
	border: 1px solid white
	cursor: pointer
	position: absolute
	&.dragging
		cursor: grabbing

:deep(.content)
	animation: fadeIn 0.25s ease-out
	background: $color-green-60
	color: black
	width: auto
	padding: 6px 12px
	border-radius: $spacing-8
	font-size: 0.85rem
	box-shadow: 0px 1px 2px 1px rgba(0, 1, 0, 0.2)
	white-space: nowrap
	z-index: 9999
	.arrow
		fill: $color-green-60
		margin-bottom: $spacing-4
</style>
