import type { NitroFetchOptions } from 'nitropack'

export function useRawFetchProxy(
	url: string,
	options: NitroFetchOptions<string & {}> = {}
) {
	return $fetch(url, {
		...options
	})
}
