export const useQueueSearch = () => {
	const queueSearchTerm = ref('')
	const loading = ref(false)
	const songs = ref<Song[]>([])
	const nextPage = ref('' as string | null)
	const page = ref(1)
	const params = ref({ page: 1, type: 'songs', page_size: 25 })

	const hasNextPage = computed(() => { return !!nextPage.value })

	/**
	 * Set the queue search term to be used in the API request
	 *
	 * @param {String} string - The queue search term
	 */
	const setSearchFilter = (string: string) => {
		queueSearchTerm.value = string
	}

	/**
	 * Load songs for the queue search term, load the next page of results if `reset` is false
	 *
	 * @param {boolean} reset - Reset the queue search results
	 * @throws {Error} - API request error
	 */
	const loadSongs = async (reset: boolean) => {
		const { searchRequest } = useSearch()
		if (reset) {
			page.value = 1
			songs.value = []
			nextPage.value = null
		}
		loading.value = true
		try {
			params.value.page = page.value
			const results = await searchRequest(queueSearchTerm.value, params.value)
			nextPage.value = results.next
			if (results && results.songs && results.songs.items) {
				songs.value = [...songs.value, ...results.songs.items]
			}
			page.value++
		} catch (err: any) {
			console.log(err)
		} finally {
			loading.value = false
		}
	}

	watch(() => queueSearchTerm.value, async (value) => {
		loading.value = true
		await loadSongs(true)
	})

	return {
		searchString: queueSearchTerm,
		songs,
		loading,
		loadSongs,
		hasNextPage,
		nextPage,
		setSearchFilter
	}
}
