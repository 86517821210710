<template lang="pug">
.notification-message
	a(@click="openLink(itemLink)") {{ itemTitle }}
	span(v-if="removed") {{ t('general.favorite.removed') }}
	span(v-else) {{ t('general.favorite.added') }}
</template>

<script setup lang="ts">
const { t } = useI18n()

const emit = defineEmits(['close'])

defineProps({
	itemLink: {
		type: String,
		required: true
	},
	itemTitle: {
		type: String,
		required: true
	},
	removed: {
		type: Boolean,
		required: true
	}
})

const openLink = (link: string) => {
	navigateTo(link)
	emit('close')
}
</script>
