import { defineStore } from 'pinia'

interface CountryStore {
	country: string | undefined
	city: string | undefined
	error: any
	cities: any[]
}

export const useGeoLocationStore = defineStore('geoLocationStore', {
	state: (): CountryStore => ({
		country: undefined,
		city: undefined,
		error: '',
		cities: []
	}),
	getters: {
		contentMarket: (state) => {
			const userStore = useUserStore()
			const country = userStore.user ? userStore.userCountry : state.country
			return { market: country }
		}
	},
	actions: {
		/**
		 * Set the country code to the geoLocationStore state
		 *
		 * @param {String | undefined} country - The ISO 3166-1 alpha-2 country code to set
		 * @example setGeoLocation('FI')
		 */
		setGeoLocation(country: string | undefined) {
			this.country = country
		},
		/**
		 * Set the country code to the geoLocationStore state
		 *
		 * @param {String | undefined} city - City name in English
		 * @example setGeoLocation('Helsinki')
		 */
		setGeoCity(city: string | undefined) {
			this.city = city
		},
		/**
		 * Set the error to the geoLocationStore state
		 *
		 * @param error - Error returned from the MaxMind GeoIP2 reader
		 */
		setGeoLocationError(error: any) {
			this.error = error
		},
		setCities(cities: any[]) {
			this.cities = cities
		},
		getCities() {
			return this.cities
		}
	}
})
