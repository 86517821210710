<template lang="pug">
mixin songListItem
	.song-list-item(v-if="song" @click="playOrShowModal(song)" :class="{'menu-active': contextMenuOpen, 'not-available': song.geoblocked || hasNoVariants }")
		.song-list-column(v-if="layout === 'column'" @click="sendDiscoverEvent(song)")
			.song-img
				nuxt-img(:src="image" preset="listColumn" :alt="imageAltText" loading="lazy")
				client-only
					SingaProgressCircle.preview-button-progress(v-if="playingTheCurrentVersion && !previewEnded && progress < 99" @click.stop="stopPreview(); previewClicked = false" color-back="rgba(255, 255, 255, 0.12)" :value="progress" :class="{'hide-preview': isAudioPlaying, 'ios-hover': isIos }")
					SingaButton.is-rounded.preview-button(v-else icon-left="play" tag="div") {{ t('song.previewKaraoke') }}
				span.not-available-text(v-if="song.geoblocked") {{t('song.notAvailable')}}
				span.not-available-text(v-if="hasNoVariants") {{ t('song.notAvailable.variants') }}
			.song-info
				span.song-name {{ songName }}
				.song-info-bottom
					.checkbox-wrap(v-if="hasOriginalTag")
						SingaTooltip(:content="t('tags.original.tooltip')")
							img.original-checkmark(src="/img/original_checkmark.svg" :alt="t('tags.original.altText')")
					.song-artist-wrap
						NuxtLink.song-artist(@click.stop.prevent @click="sendDiscoverEvent(song)" :to="localePath(`/artists/${artist.slug}/${artist.hash}`)" :title="artist.name" v-for="(artist, i) in sortedArtists" :key="i + artist.slug")
							| {{ artistNames[i] }}
							span.song-artist-divider(v-if="i+1 != song.artists.length") ,&nbsp;
					LazySongContextMenu.right(:discoverPageEvent="discoverPageEvent" :song="song" :link="link" :is-list-item="true" :id="song.id" :playlistResourceId="playlistResourceId" :playlistIsOfficial="playlistIsOfficial" layout="column" @set-active="setContextMenu(true)" @reset="setContextMenu(false)" @playPreviewClick="playPreviewClick" accessPoint="Song column list item")

		.song-list-row(v-else :class="{ 'preview-playing': playingTheCurrentVersion && !previewEnded }")
			span.not-available-text(v-if="song.geoblocked") {{ t('song.notAvailable') }}
			span.not-available-text(v-if="hasNoVariants") {{ t('song.notAvailable.variants') }}
			.left
				.song-img
					nuxt-img(:src="image" preset="listRow" :alt="imageAltText" loading="lazy")
					.song-img-overlay
						SingaIcon.play-icon(icon="play" size="large")
				.song-info
					span.song-name {{ songName }}
					.song-info-bottom
						.checkbox-wrap(v-if="hasOriginalTag")
							SingaTooltip(:content="t('tags.original.tooltip')")
								img.original-checkmark(src="/img/original_checkmark.svg" :alt="t('tags.original.altText')")
						.song-artist-wrap
							NuxtLink.song-artist(@click.stop.prevent :to="localePath(`/artists/${artist.slug}/${artist.hash}`)" :title="artist.name" v-for="(artist, i) in sortedArtists" :key="i + artist.slug")
								| {{ artistNames[i] }}
								span.song-artist-divider(v-if="i+1 != song.artists.length") ,&nbsp;
				.song-features
					LazySongTags(v-if="hasSongTags" :tags="songTags")
					LazySongGenres(:genres="song.genres")
			.right
				client-only
					template(v-if="!showDemoPlayer")
						SingaButton.is-transparent-dark.row-preview(v-if="!playingTheCurrentVersion || previewEnded" @click="playPreviewClick('Song list item row')" :disabled="isAudioPlaying")
							span {{ isPreviewPlaying ? t('song.stopPreview') : t('song.preview') }}
						SingaProgressBar.row-preview(v-if="playingTheCurrentVersion && !previewEnded && progress < 99" @click.stop="stopPreview()" :completed="progress" :isSongListItem="true")
							span {{ t('song.preview') }}
				SingaButton.is-transparent-dark.queue-button(v-if="isUserAuthorised && ($viewport.isGreaterThan('mobile') || closeAfterAction)" @click.stop.prevent="addSongToQueue(song)")
					span.font-xs {{ t('song.addToQueue') }}
				LazySongFavoriteButton(v-if="isUserAuthorised" ref="favoriteButton" @click.stop.prevent @on-change="$emit('favoriteChanged')" :id="song.id" :playlistIsOfficial="playlistIsOfficial" :playlistResourceId="playlistResourceId" :resourceId="song.resource_id" :title="song.name" :link="favoritesLink" :standalone="true" accessPoint="Song row list item")
				LazySongDeleteButton.deleteButton(v-if="entry && playlist && editable" @click.stop.prevent :entry="entry" :playlist="playlist" accessPoint="Context menu")
				LazySongContextMenu.right.hide-on-modal(:song="song" :link="link" :entry="entry" :playlist="playlist" :playlistResourceId="playlistResourceId" :playlistIsOfficial="playlistIsOfficial" :id="song.id" position="bottom-left" layout="row" @set-active="setContextMenu(true)" @reset="setContextMenu(false)" accessPoint="Song row list item")

li(v-if="!removeListItem" :class="{'list-number': numberedList}")
	+songListItem
div(v-else)
	+songListItem
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import type { PropType } from 'vue'
import { useQueueStore } from '../../pinia/queueStore'
import { usePreviewPlayerStore } from '../../pinia/player/previewPlayerStore'
import type { Song } from '~~/types'
import { usePlaySong } from '~/composables/useQueue'
import { useAudioPlayerStore } from '~/pinia/player/audioPlayerStore'

const userStore	= useUserStore()
const { showDemoPlayer } = storeToRefs(userStore)
const SongConfirmation = resolveComponent('ModalsSongConfirmation')

const queueStore = useQueueStore()
const useImage = useGetImageSize()
const playSong = usePlaySong
const audioStore = useAudioPlayerStore()
const { isAudioPlaying } = storeToRefs(audioStore)

const { t } = useI18n()
const localePath = useLocalePath()

const { $oruga, $viewport } = useNuxtApp()
const { isIos } = useDevice() // iOS double click fix

const { isUserAuthorised } = useUser()

const { addToQueue } = queueStore
const { queueItemSelection } = useVariantSelection()
const { playPreview, stopPreview, progress, previewEnded } = useVariantPreview()

const previewPlayerStore = usePreviewPlayerStore()

const previewClicked = ref(false)

watch(previewEnded, (value) => {
	if (value) {
		previewClicked.value = false
	}
})

const props = defineProps({
	song: {
		type: Object as PropType<Song>,
		required: true
	},
	playlist: {
		type: Object,
		required: false,
		default: null
	},
	layout: {
		type: String,
		default: 'row'
	},
	entry: {
		type: Object,
		required: false,
		default: null
	},
	editable: {
		type: Boolean,
		default: true
	},
	closeAfterAction: {
		type: Boolean,
		default: false
	},
	numberedList: {
		type: Boolean,
		default: false
	},
	removeListItem: {
		type: Boolean,
		default: false
	},
	playlistIsOfficial: {
		type: Boolean,
		required: false,
		default: false
	},
	playlistResourceId: {
		type: String,
		required: false,
		default: null
	},
	discoverPageEvent: {
		type: String,
		default: ''
	}
})

const { getAllArtists, getArtistSlugs } = useArtistNames(props.song.artists)
const sortedArtists = ref([...props.song.artists].sort((a, b) => a.name.localeCompare(b.name)))

const artistNames = ref(getAllArtists(true))

const imageAltText = ref(t('song.imageAltText', { title: `${props.song.name} - ${[...artistNames.value]}` }))

const playingTheCurrentVersion = computed(() => {
	if (props.song.variants.length > 0) {
		return previewPlayerStore.currentSong === props.song.variants[0].id
	}
	return false
})

const hasNoVariants = computed(() => {
	return props.song.variants.length === 0
})

const isPreviewPlaying = computed(() => {
	return previewPlayerStore.playing && previewPlayerStore.currentSong === props.song.variants[0]?.id
})

const songName = ref(props.song.name)
const favoriteButton = ref()
const contextMenuOpen = ref(false)
const image = ref(useImage(props.song.image, 'medium', 'song', true))
const artistSlug = getArtistSlugs()
const link = `/artists/${artistSlug}/${props.song.slug}/${props.song.hash}`

const favoritesLink = localePath(link)
const { segmentEvent } = useSegment()

const songTags = {
	has_cover: props.song.has_cover,
	has_duet: props.song.has_duet,
	has_explicit: props.song.has_explicit,
	has_original: props.song.has_original,
	has_plus: props.song.has_plus
}
const hasSongTags = computed(() => {
	return Object.values(songTags).some(tag => tag)
})

const hasOriginalTag = computed(() => {
	return songTags.has_original
})

const addSongToQueue = async (song: Song) => {
	if (props.closeAfterAction) { $oruga.modal.closeAll({ action: 'closeAll' }) }

	if (!song) {
		console.warn('SongListItem: Trying to queue undefined song')
		return
	}
	const queueItem = await queueItemSelection(song, undefined, undefined, t('song.error.play'))
	if (!queueItem) { return }
	await addToQueue(queueItem, link, 'Song list row item button', false, t('song.error.queue'))
}

const setContextMenu = (value: boolean) => {
	contextMenuOpen.value = value
}

// This is here to make sure the song name/image is updated when the song prop changes (in song history block)
watchEffect(() => {
	songName.value = props.song.name
	image.value = useImage(props.song.image, 'medium', 'song', true)
})

const openModal = (song: Song, isRequestModal = false) => {
	$oruga.modal.open({
		component: SongConfirmation,
		width: 768,
		props: {
			song,
			isRequestModal
		}
	})
}

// Show a confirmation modal before playing if a song is playing and the user hasn't dismissed the modal
const playOrShowModal = (song: Song) => {
	if (previewClicked.value) {
		previewClicked.value = false
		return
	}
	if (!song) {
		console.warn('SongListItem: Trying to play undefined song')
		return
	}
	const dismissSongConfirmation = useCookie<boolean>('dismissSongConfirmation')
	if (!isAudioPlaying.value || dismissSongConfirmation.value) {
		playSong(song, props.closeAfterAction, t('song.error.play'))
	} else {
		openModal(song, props.closeAfterAction)
	}
}
type AccessPoint = 'Song list item grid' | 'Song list item row' | 'Song version selector'

const playPreviewClick = (clickLocation: AccessPoint) => {
	if (isPreviewPlaying.value) {
		previewClicked.value = false
		stopPreview()
	} else {
		previewClicked.value = true
		playPreview(props.song.variants[0], { id: props.song.resource_id, name: props.song.name }, clickLocation)
	}
}

const sendDiscoverEvent = (song: Song) => {
	if (props.discoverPageEvent.length > 0) {
		segmentEvent(props.discoverPageEvent, { song_id: song.resource_id, song_name: song.name })
	}
}

useEventOn('listItem:closeMenu', (id) => {
	if (id === props.song.id) {
		setContextMenu(false)
	}
})

onUnmounted(() => {
	if (previewPlayerStore.playing && playingTheCurrentVersion.value) {
		stopPreview()
	}
	useEventOff('listItem:closeMenu')
})
</script>

<style lang="sass" scoped>
.song-list-row
	border-radius: $radius-default
	width: 100%
	display: flex
	align-items: center
	justify-content: space-between
	position: relative
	padding: $spacing-16
	gap: $spacing-16
	&.preview-playing
		background-color: $transparent-white-8
		.right
			display: flex !important

	:deep(.context-menu-button)
		height: 40px
	.SongTags, .SongGenres
		display: none

	.FavoriteButton, .queue-button, .deleteButton
		display: none
	.left
		display: flex
		align-items: center
		justify-content: flex-start
		min-width: 0
	.right
		align-items: center
		display: flex
		button
			border-radius: $radius-default
		:not(:last-child)
			min-height: 40px
			margin-right: $spacing-8
	.song-img
		width: 48px
		margin-right: $spacing-16
		background-color: $transparent-white-4
		flex-shrink: 0
		position: relative
		img
			width: 100%
	.song-img-overlay
		position: absolute
		top: 0
		left: 0
		width: 100%
		height: 100%
		display: flex
		align-items: center
		justify-content: center
		background: $transparent-black-56
		transition: all 200ms
		&.hide-preview
			display: none
		&:not(.playing-preview)
			opacity: 0
			visibility: hidden
		.icon
			transform: scale(1)
			transition: transform 100ms
		&:hover
			.icon
				transform: scale(1.1)
	.song-name
		@include font(basier, medium)
		color: $color-grey-30
		overflow: hidden
		white-space: nowrap
		text-overflow: ellipsis
		display: block
	.song-artists
		min-width: 0
		overflow: hidden
		white-space: nowrap
		text-overflow: ellipsis
		padding-right: $spacing-4
	.song-artist
		color: $color-grey-60
		&:hover
			color: $color-grey-50
	.song-info-bottom
		text-overflow: ellipsis
		overflow: hidden
		color: $color-grey-60
	button.is-small
		height: 40px
	.song-info
		padding-right: $spacing-16
		max-width: calc(100% - 64px)
		@media (min-width: $tablet)
			width: 200px
		@media (min-width: $desktop)
			width: 300px

li
	counter-increment: song-counter
	list-style-type: none

li.list-number
	display: flex
	align-items: center
	position: relative
	&::before
		content: counter(song-counter)
		color: $color-grey-60
		width: $spacing-16
		text-align: center
		margin-right: $spacing-16
		position: absolute
		left: $spacing-16
	.song-list-item
		container-type: inline-size
		width: 100%
		padding-left: $spacing-32

.song-list-item
	border-radius: $radius-default
	container-type: inline-size
	&:focus-within
		background-color: $transparent-white-8
	&:not(:only-child):not(.menu-active)
		&:hover
			.song-list-row
				&::after
					background-color: transparent
	&:hover
		cursor: pointer

	@media (min-width: $tablet)
		&:not(:only-child):not(:last-child)
			.song-list-row
				&::after
					left: 82px
					width: calc(100% - 80px)
		&:last-child
			.song-list-row
				&:after
					display: none
		&:hover
			background-color: $transparent-white-8
			.song-img-overlay
				opacity: 1
				visibility: visible
		&:focus, &:active, &:focus-within
			background-color: $transparent-white-8

	@container (min-width: 500px)
		.song-list-row
			.queue-button
				display: flex
	@container (min-width: 600px)
		.row-preview
			display: flex
	@container (min-width: 768px)
		.song-list-row
			padding: $spacing-16
			.right
				display: none
			.SongTags, .SongGenres, .FavoriteButton, .deleteButton
				display: flex
			&:hover, &:focus, &:active, &:focus-within
				.right
					display: flex
	@container (max-width: 1000px)
		.song-list-row
			&:hover
				.SongGenres
					display: none !important
.menu-active
	background-color: $transparent-white-8
	.song-list-row
		.right
			display: flex
.not-available
	pointer-events: none
	.song-img img, .song-info
		opacity: 0.3
.song-list-column
	background-color: $transparent-white-4
	padding: 12px
	width: 100%
	display: inline-block
	border-radius: $radius-default
	width: 100%
	.preview-button
		position: absolute
		display: none
		&.ios-hover
			display: flex
			opacity: 0.01
		&:before
			position: absolute
			content: ''
			border-radius: 999px
			box-shadow: $shadow-default
			opacity: 0
			width: 100%
			height: 100%
	&:hover
		.preview-button
			touch-action: auto
			visibility: visible
			display: flex
			height: 40px
			color: $color-grey-30
			@include fontSize(xs)
			background-color: $transparent-black-72
			padding: $spacing-8 $spacing-16 $spacing-8 $spacing-16 !important
			border: none
			&.hide-preview
				display: none
			&:hover
				&:before
					opacity: 1
			&.ios-hover
				opacity: 1
	.preview-button-progress
		position: absolute
		color: $color-grey-30
		&:hover
			color: $color-grey-20
	&:hover, &:focus
		background-color: $transparent-white-12
	.song-img, .song-img img
		width: 100%
	.song-img
		position: relative
	.song-name, .song-artist
		@include font(basier, medium)
	.song-name
		color: $color-grey-30
		overflow: hidden
		white-space: nowrap
		text-overflow: ellipsis
		display: block
	.song-artist
		color: $color-grey-60
		&:hover
			color: $color-grey-50
	.song-info-bottom
		padding-top: $spacing-4
		.right
			margin-left: auto
	.song-dropdown-button
		width: 28px
		height: 20px
		padding: 0
		box-shadow: none !important
	.song-info
		padding: $spacing-16 0 4px 0
	.song-artist-wrap
		position: relative
		top: -1px
.song-info-bottom
	display: flex
	align-items: center
.not-available-text
	position: absolute
	top: 0
	left: 0
	width: 100%
	height: 100%
	display: flex
	align-items: center
	justify-content: center
	padding: $spacing-8
	text-align: center
	@include font(basier, medium)
	@media (min-width: $tablet)
		padding: $spacing-16
.menu-active
	.song-list-column
		background-color: $transparent-white-12
.see-all
	border: 1px solid $transparent-white-16
	width: 100%
	height: auto
	display: flex
	align-items: center
	justify-content: center
	padding: $spacing-16
	span
		color: $color-grey-30

.song-img
	height: auto
	border-radius: $radius-default
	aspect-ratio: 1/1
	overflow: hidden
	display: flex
	justify-content: center
	align-items: center

.song-artist-wrap
	white-space: nowrap
	text-overflow: ellipsis
	min-width: 0
	overflow: hidden
	color: $color-grey-60

.song-features
	display: flex
	gap: $spacing-24

.row-preview
	display: none
	height: 40px
	width: 109px

.original-checkmark
	width: $spacing-16 !important
	height: $spacing-16 !important

.checkbox-wrap
	display: block
	width: 16px
	height: 16px
	margin-right: $spacing-4
	position: relative
	top: -2px
	flex-shrink: 0

:deep(button.trigger)
	min-width: $spacing-16 !important
	min-height: $spacing-16 !important
</style>

<style lang="sass">
.SongListItemRow
	.like-song
		svg
			path
				stroke: $color-grey-30
		&:hover, &:active
			svg
				path
					fill: $color-grey-30
</style>
