import { useProgrammatic } from '@oruga-ui/oruga-next'
import type { Credentials } from '../types'
import { useUserStore } from '../pinia/userStore'
import { useQueueStore } from '../pinia/queueStore'
import { useAudioPlayerStore } from '../pinia/player/audioPlayerStore'
import { useMediaFileStore } from '../pinia/mediaFileStore'
import { usePlayerStore } from '../pinia/player/karaokePlayer'
import { locales } from '../utils/constants'
import { useNuxtApp, useLocalePath, useSegment } from '#imports'

const { oruga } = useProgrammatic()

type Nullable<T> = T | null

export const useAuth = () => {
	const loading = ref(false)
	const authError: Ref<object | null> = ref(null)
	const authCookie = useTokenCookie()
	const userStore = useUserStore()
	const isLoggedIn: Ref<boolean> = useState('isLoggedIn', () => false)
	const isInitUserLoaded: Ref<boolean> = useState('isInitUserLoaded', () => false)
	const localePath = useLocalePath()
	const availableLocales = locales.map((locale: Locale) => `/${locale.code}/`)

	const reset = () => {
		loading.value = false
		authError.value = null
	}

	/**
	 * Sets the tokens to auth store state and to the cookie
	 * @param {Tokens} tokens - Tokens object or null
	 */
	const setTokens = (tokens: any, forceRemove: boolean = false) => {
		if (!forceRemove && (tokens === null || tokens === undefined)) {
			return
		}
		authCookie.value = tokens === null ? undefined : tokens
		refreshCookie('sng_tokens')
	}

	const login = async () => {
		if (authCookie.value === undefined) {
			isLoggedIn.value = false
			isInitUserLoaded.value = true
			return
		}
		await userStore.getUser(true)
		if (userStore.user) {
			isLoggedIn.value = true
		} else {
			isLoggedIn.value = false
		}
		isInitUserLoaded.value = true
	}

	/**
	 * Logs out the user and resets the user store and clears tokens
	 *
	 * @param {Nullable<Boolean>} loggedOutByUser - if true, redirects to home page after logout
	 * @param {Function} [translate] - optional function to translate the logout message
	 */
	// optional argument translate
	const logout = (loggedOutByUser: Nullable<boolean>, translation?: string) => {
		if (import.meta.prerender) { return }
		isLoggedIn.value = false

		const queueStore = useQueueStore()
		const audioPlayer = useAudioPlayerStore()
		const { $audioPlayer } = useNuxtApp()
		const mediaFileStore = useMediaFileStore()
		const karaokeStore = usePlayerStore()
		const variantStore = useVariantStore()
		const { setShowMiniPlayer } = karaokeStore
		setTokens(undefined, true)
		reset()
		setShowMiniPlayer(false)
		queueStore.$reset()
		audioPlayer.$reset()
		mediaFileStore.$reset()
		variantStore.$reset()
		if ($audioPlayer) {
			queueStore.stopSong()
		}
		userStore.$reset()

		const { clearAll } = useDiscoverStore()
		clearAll()

		if (loggedOutByUser) {
			const { userResourceId } = userStore
			const { segmentEvent } = useSegment()
			const { resetFullStory } = useFullstory()
			segmentEvent('User Logged Out', {
				user_id: userResourceId
			})
			resetFullStory()
			if (translation) {
				oruga.notification.open({
					message: translation,
					icon: 'checkmark-outline',
					iconSize: 'medium',
					closable: true
				})
			}
		}
		reloadNuxtApp({ path: localePath('/') })
	}

	/**
	* Clears the auth store state's login error message
	*/
	const clearLoginError = () => {
		authError.value = null
	}

	/**
	 * Sends the credentials to the Singa OAuth endpoint to authenticate the user. Then checks if user has completed onboarding and shows modal if not
	 *
	 * @param {Credentials} credentials - Credentials object
	 * @param {String} [redirectUrl="/"]  - route to redirect to after login, defaults to root
	 * @returns {Promise} - Response from the `$singaApi.Connections.getTokens` endpoint
	 * @throws {Error} - Error returned from the `$singaApi.Connections.getTokens` request
	*/
	const getTokens = async (credentials: Credentials, redirectUrl = '/karaoke', getFavorites = true) => {
		if (import.meta.prerender) { return }
		loading.value = true
		try {
			const { $singaApi } = useNuxtApp()
			const response: any = await $singaApi.Auth.getTokens(credentials)
			setTokens(response)
			loading.value = false
			await userStore.getUser(getFavorites, response)
			oruga.modal.closeAll({ action: 'closeAll' })
			const { clearAll } = useDiscoverStore()
			clearAll()
			await login()
			const url = availableLocales.some(locale => redirectUrl.startsWith(locale)) ? redirectUrl : localePath(redirectUrl)
			await navigateTo(url)
			return response
		} catch (err: any) {
			console.log('getTokens error', err.response)
			loading.value = false
			authError.value = err?.response?._data?.error_description || 'Something went wrong'
			return err
		}
	}
	/**
	 * Sends the payload from a social login to the Singa OAuth endpoint
	 *
	 * @param {String} [redirectUrl="/"] - route to redirect to after login, defaults to root
	 * @param {Credentials} credentials - payload from the social connection to send to the login endpoint
	 * @param {Function} [translate] - optional function to translate the signup message
	 * @returns {Promise} - Response from the `$singaApi.Connections.getTokens` endpoint
	 * @throws {Error} - Error returned from the `$singaApi.Connections.getTokens` request
	 */
	const registerUser = async (credentials: Credentials, redirectUrl = '/karaoke', translation: string | null, getFavorites = true) => {
		if (import.meta.prerender) { return }
		loading.value = true
		const { $singaApi } = useNuxtApp()
		try {
			await $singaApi.Auth.register(credentials)
			const tokenResponse = await getTokens({ username: credentials.email, email: undefined, password: credentials.password }, redirectUrl, getFavorites)
			setTokens(tokenResponse)
			loading.value = false
			if (translation !== null) {
				oruga.notification.open({
					message: translation,
					icon: 'checkmark-outline',
					iconSize: 'medium'
				})
			}
			return tokenResponse
		} catch (err: any) {
			loading.value = false
			authError.value = err?.response?._data
			return err
		}
	}

	const getSocialTokens = async (redirectUrl = '/karaoke', payload: object, getFavorites = true) => {
		if (import.meta.prerender) { return }
		loading.value = true
		try {
			const { $singaApi } = useNuxtApp()
			const response: any = await $singaApi.Connections.getTokens(payload)
			setTokens(response)
			loading.value = false
			await userStore.getUser(getFavorites, response)
			const url = availableLocales.some(locale => redirectUrl.startsWith(locale)) ? redirectUrl : localePath(redirectUrl)
			await navigateTo(url)
			oruga.modal.closeAll({ action: 'closeAll' })
			const { clearAll } = useDiscoverStore()
			clearAll()
			await login()
			return response
		} catch (err: any) {
			loading.value = false
			authError.value = err.response
			return err
		}
	}

	/**
	 *	Refreshes the access token
	* If the auth store state has a refresh promise, returns the existing promise
	* Or if the auth store state has a refresh token, sends the refresh token to the Singa OAuth endpoint to get new tokens
	* Otherwise, user will be logged out
	*
	*	@returns {Promise} - Promise with the new tokens or error if refresh failed or no refresh token is available (logout)
	*	@throws {Error} - Error returned from the `$singaApi.Auth.getTokens` request
	*/
	const refreshTokens = async (cookies: any) => {
		if (import.meta.prerender) { return }
		if (authCookie.value?.refresh_token) {
			const body = {
				refresh_token: authCookie.value!.refresh_token
			}
			let cookieHeader = ''
			if (typeof cookies === 'string') {
				cookieHeader = cookies
			} else if (typeof cookies === 'object') {
				cookieHeader = JSON.stringify(cookies)
			}
			try {
				const response: any = await $fetch('/api/oauth/token', {
					body: { ...body },
					method: 'POST',
					credentials: 'include',
					headers: {
						Cookie: cookieHeader
					}
				})
				return response
			} catch (err) {
				console.log('Refresh token error', err)
				const nuxtApp = useNuxtApp()
				nuxtApp.runWithContext(() => {
					logout(false)
				})
				return err
			}
		} else {
			const nuxtApp = useNuxtApp()
			nuxtApp.runWithContext(() => {
				logout(false)
			})
		}
	}
	return { login, getTokens, setTokens, refreshTokens, getSocialTokens, clearLoginError, registerUser, logout, isLoggedIn, loading, authError, authCookie, reset, isInitUserLoaded }
}
