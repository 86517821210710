import { useNuxtApp } from '#imports'

export const useSongHistory = () => {
	const { $singaApi } = useNuxtApp()

	const songlistApiResource = $singaApi.Me.SongHistory.list

	const authCookie = useTokenCookie()
	const tokens = authCookie.value

	const { loadSongs, initialLoad } = useSonglists({ songlistApiResource, params: { tokens, page_size: 25 } })

	return {
		loadSongs,
		initialLoad
	}
}
