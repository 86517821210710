import { useNuxtApp } from '#imports'

export const useUserPlaylists = () => {
	const { $singaApi } = useNuxtApp()

	/**
	 * Load the list of user's playlists
	 *
	 * @param {Object} params - Parameters for the playlist request
	 * @returns {Promise<PlaylistResponse>}
	 * @throws {Error} - API request error
	 */
	const loadUserPlaylists = async (params: { page: number, page_size?: number } = { page: 1, page_size: 25 }) => {
		const { page, page_size = 25 } = params
		const { loadPlaylists } = usePlaylistLists($singaApi.Me.Playlists.list, { page_size })

		try {
			const response = await loadPlaylists({ page })
			return {
				results: response.results,
				hasNextPage: response.hasNextPage,
				count: response.count
			}
		} catch (err) {
			return {
				results: [],
				hasNextPage: false,
				count: 0,
				error: err
			}
		}
	}

	return {
		loadUserPlaylists
	}
}
