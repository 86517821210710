<template lang="pug">
SingaContextMenu(:id="uniqueId" :key="uniqueId" @toggle="toggle" v-bind="isQueueItem ? { portalControlId: '#queue-wrap' } : (isMiniPlayer ? { portalControlId: '#mini-player' } : '')")
	template(v-slot:trigger)
		SingaButton.is-small.context-menu-list-button(
			v-if="props.isListItem || props.isMiniPlayer"
			:class="[ { 'mini-player': props.isMiniPlayer }, isOpen ? 'is-transparent-dark' : 'is-ghost']"
			@click.stop.prevent="toggle" aria-label="open or close menu"
			icon-right="ellipsis-horizontal")
		SingaButton.is-small.is-transparent-dark.context-menu-button(v-else @click.stop.prevent="toggle" aria-label="open or close menu" icon-right="ellipsis-horizontal")
	template(v-slot:content)
		template(v-if="isUserAuthorised")
			DropdownMenuItem.dropdown-item(v-if="!isQueueItem && !isMobile" aria-role="listitem" @click="addSongToQueue(song)") {{ t('song.addToQueue') }}

			// Add to singlist sub-menu
			DropdownMenuSub(v-model:open="playilstMenuOpen" @update:open="togglePlaylistMenu()")
				DropdownMenuSubTrigger.dropdown-item(aria-role="listitem") {{ t('song.addToPlaylist') }}
				DropdownMenuPortal(v-bind="portalIdControl")
					DropdownMenuSubContent.menu-content(:side-offset="2" :align-offset="-5")
						// New playlist sub
						DropdownMenuSub(v-model:open="newPlaylistOpen")
							DropdownMenuSubTrigger.dropdown-item.new-singlist-trigger(aria-role="listitem")
								span {{ t('playlist.new') }}
								SingaIcon.center(icon="add-outline")
							DropdownMenuPortal(v-bind="portalIdControl")
								DropdownMenuSubContent.menu-content(
									:side-offset="2"
									:align-offset="-5")
									.new-playlist-wrap
										form(@submit.prevent="createNewPlaylist")
											SingaField.input-label(:label="t('playlist.giveName')")
												SingaInput.is-rounded(ref="playlistInputElem" v-model="playlistTitle" :placeholder="placeholderTitle")
											SingaSubmitButton.is-small.is-full-width.is-primary(:isLoading="loading" native-type="submit" :buttonCTA="t('general.create')")

						DropdownMenuItem.scrollable.playlists
							template(v-if="loading && !scrollToLoad")
								CommonLoadingAnimation.center
							template(v-else v-for="playlist in playlists" :key="playlist.id")
								DropdownMenuItem.dropdown-item(role="listitem" @click="saveToPlaylist(playlist)") {{ playlist.title }}
							.load-more-wrap(ref="loadMoreTrigger" v-if="hasNextPage")
								SingaButton.is-small.is-transparent-dark(@click.stop.prevent="loadMoreContent()" v-if="hasNextPage && !scrollToLoad && !loading") {{ t('general.loadMore') }}
								CommonLoadingAnimation(v-else-if="scrollToLoad && loading")

			DropdownMenuItem.dropdown-item(v-if="entry && playlist && playlist.user.id === userStore.user?.id" @click="deleteSongFromSinglist()") {{ t('contextMenu.mobile.removeSong') }}
			DropdownMenuItem.dropdown-item(v-if="loadingFavoriteStatus")
				CommonLoadingAnimation(:timeOut="0" type="dots")
			template(v-else)
				DropdownMenuItem.dropdown-item(v-if="!favorited" aria-role="listitem" @click="toggleFavoriteStatus('songs', song.id, song.name, favoritesLink, true, segmentData); closeAll(); sendFavoriteEvent()" :disabled="loadingFavorite") {{ t('contextMenu.favorites.add') }}
				DropdownMenuItem.dropdown-item(v-else aria-role="listitem" @click="toggleFavoriteStatus('songs', song.id, song.name, favoritesLink, true, segmentData); closeAll()" :disabled="loadingFavorite") {{ t('contextMenu.favorites.remove') }}
		.dropdown-item(v-if="accessPoint === 'Song column list item'" :class="{ 'disabled' : isAudioPlaying}" @click="playPreviewClick()") {{ isPreviewPlaying ? t('song.stopPreview') : t('song.preview') }}
		DropdownMenuItem.dropdown-item(v-if="!isSongDetailPage && !isMiniPlayer" aria-role="listitem" @click="openDetailPage()") {{ t('contextMenu.openDetail.song') }}

		// Artists submenu
		DropdownMenuItem.dropdown-item.link(v-if="song.artists.length === 1" :asChild="true")
			NuxtLink(aria-role="listitem" :to="localePath(`/artists/${song.artists[0].slug}/${song.artists[0].hash}`)") {{ t('contextMenu.openDetail.artist') }}

		DropdownMenuSub(v-else)
			DropdownMenuSubTrigger.dropdown-item(aria-role="listitem") {{ t('contextMenu.openDetail.artist') }}
			DropdownMenuPortal(v-bind="portalIdControl")
				DropdownMenuSubContent.menu-content(:side-offset="2" :align-offset="-5")
					template(v-for="artist in song.artists" :key="artist.id")
						NuxtLink.dropdown-item(aria-role="listitem" :to="localePath(`/artists/${artist.slug}/${artist.hash}`)") {{ artist.name }}

		DropdownMenuItem.dropdown-item(v-if="hasSongCredits" aria-role="listitem" @click="showCredits(song)") {{ t('song.credits.menu') }}

		// Share submenu
		DropdownMenuSub
			DropdownMenuSubTrigger.dropdown-item(aria-role="listitem") {{ t('contextMenu.share') }}
			DropdownMenuPortal(v-bind="portalIdControl")
				DropdownMenuSubContent.menu-content(:side-offset="2" :align-offset="-5")
					CommonShareButtons(:external-url="url" @share="closeAll()")
</template>

<script setup lang="ts">
import {
	DropdownMenuItem,
	DropdownMenuPortal,
	DropdownMenuSub,
	DropdownMenuSubContent,
	DropdownMenuSubTrigger
} from 'reka-ui'
import { useInfiniteScroll } from '@vueuse/core'
import { usePreviewPlayerStore } from '../../pinia/player/previewPlayerStore'
import PlaylistCreated from '../song/notifications/PlaylistCreated.vue'
import { useAudioPlayerStore } from '~/pinia/player/audioPlayerStore'
import type { Song } from '~/types'
import { useUserPlaylists } from '~~/composables/library/useUserPlaylists'
import { openSongCreditsModal } from '~~/composables/useSongCreditsModal'
import AddedToPlaylist from '~~/components/song/notifications/AddedToPlaylist.vue'

const { $singaApi, $oruga } = useNuxtApp()
const { isUserAuthorised } = useUser()

const { t } = useI18n()
const queueStore = useQueueStore()

const { addToQueue } = queueStore
const { queueItemSelection } = useVariantSelection()

const isOpen = ref(false)
const playilstMenuOpen = ref(false)
const newPlaylistOpen = ref(false)

const loadMoreTrigger = ref()

const userStore = useUserStore()
const previewPlayerStore = usePreviewPlayerStore()

const { loadUserPlaylists } = useUserPlaylists()
const playlists = ref<Playlist[]>([])
const hasNextPage = ref(false)
const page = ref(1)
const loading = ref(false)

const { getFavoriteStatus, loadingFavorite, toggleFavoriteStatus, favorited } = useFavorites()

const audioStore = useAudioPlayerStore()
const { isAudioPlaying } = storeToRefs(audioStore)

const { setItem } = useContentCacheStore()

const uniqueId = useId()

const placeholderTitle = ref(t('playlist.placeHolder'))

const props = defineProps({
	song: {
		type: Object as PropType<Song>,
		required: true
	},
	isListItem: {
		type: Boolean,
		default: false
	},
	entry: {
		type: Object,
		required: false
	},
	playlist: {
		type: Object,
		required: false
	},
	link: {
		type: String,
		required: true
	},
	isSongDetailPage: {
		type: Boolean,
		default: false
	},
	isQueueItem: {
		type: Boolean,
		default: false
	},
	isMiniPlayer: {
		type: Boolean,
		default: false
	},
	playlistIsOfficial: {
		type: Boolean,
		required: false,
		default: false
	},
	playlistResourceId: {
		type: String,
		required: false,
		default: null
	},
	accessPoint: {
		type: String,
		required: true
	},
	discoverPageEvent: {
		type: String,
		default: ''
	}
})

const portalIdControl = computed(() => {
	if (props.isMiniPlayer) {
		return { to: '#mini-player' }
	} else if (props.isQueueItem) {
		return { to: '#queue-wrap' }
	}
	return ''
})

const hasSongCredits = computed(() => {
	return props.song.copyrights.length > 0
})

const segmentData = ref({
	access_point: props.accessPoint,
	song_id: props.song.resource_id,
	song_name: props.song.name
})

const playlistTitle = ref('')
const loadingFavoriteStatus = ref(false)
const playlistInputElem = ref(null as any)

const showCredits = (song: Song) => {
	const creditsMap = song.copyrights.reduce((acc, copyright) => {
		if (!acc[copyright.role]) {
			acc[copyright.role] = []
		}
		acc[copyright.role].push(copyright.name)
		return acc
	}, {} as Record<string, string[]>)

	const credits = Object.keys(creditsMap).reduce((acc, role) => {
		acc[role] = creditsMap[role].join(', ')
		return acc
	}, {} as Record<string, string>)

	openSongCreditsModal(credits)
}

const emit = defineEmits(['reset', 'setActive', 'playPreviewClick'])
const { isMobile } = useDevice()
const isPlaylistPage = computed(() => {
	const route = useRoute()
	return route.fullPath.includes('playlist')
})

const sendFavoriteEvent = () => {
	if (!favorited.value && isPlaylistPage.value) {
		useSinglistSongFavorited(props.song.resource_id, props.playlistResourceId, props.accessPoint, props.playlistIsOfficial)
	}
}

const deleteSongFromSinglist = async () => {
	if (!props.playlist || !props.entry) {
		return
	}
	loading.value = true
	const id = props.playlist.id
	const params = {
		headers: { 'X-HTTP-Method-Override': 'DELETE' },
		body: {
			variants: [{
				variant: props.entry?.variant.id,
				positions: [props.entry?.position]
			}]
		}
	}
	const response = await $singaApi.Playlists.deleteVariant(id, params)
	if (response.error) {
		console.error(response.error)
	} else {
		useEventEmit('libraryPlaylists:deletePlaylistSong')
		useEventEmit('libraryPlaylist:updateUserPlaylists', true)
		const { segmentEvent } = useSegment()

		const playlistData = {
			access_point: props.accessPoint,
			country: userStore.user?.country,
			user_id: userStore.user?.resource_id,
			singlist_id: props.playlist.resource_id,
			song_id: props.entry.id,
			variant_id: props.entry.variant.id
		}
		segmentEvent('Singlist Song Deleted', playlistData)
	}
	loading.value = false
}

const localePath = useLocalePath()
const scrollToLoad = ref(false)

const favoritesLink = computed(() => localePath(props.link))
const url = `https://${import.meta.client ? window.location.host : ''}${localePath(props.link)}`

const openDetailPage = () => {
	setItem('song', props.song)
	navigateTo(localePath(props.link))
}

const closeDropdown = () => {
	useEventEmit('contextMenu:close')
}

const togglePlaylistMenu = async () => {
	if (!playilstMenuOpen.value) return

	scrollToLoad.value = false
	page.value = 1
	loading.value = true
	try {
		const result = await loadUserPlaylists({ page: page.value })
		playlists.value = result.results
		hasNextPage.value = result.hasNextPage
	} finally {
		loading.value = false
	}
}

const loadMoreContent = async () => {
	if (loading.value || !hasNextPage.value) return

	scrollToLoad.value = true
	loading.value = true
	try {
		page.value++
		const result = await loadUserPlaylists({ page: page.value })
		playlists.value = [...playlists.value, ...result.results]
		hasNextPage.value = result.hasNextPage
	} finally {
		loading.value = false
	}
}

useInfiniteScroll(
	loadMoreTrigger,
	async () => {
		if (hasNextPage.value && !loading.value && scrollToLoad.value) {
			await loadMoreContent()
		}
	},
	{ distance: 10, interval: 1000 }
)

const { segmentEvent } = useSegment()
const saveToPlaylist = (playlist: Playlist) => {
	$singaApi.Playlists.addVariant({
		id: playlist.id,
		params: { variants: [props.song.variants[0].id] }
	}).then(() => {
		const lengthCheckedTitle = useTruncate(playlist.title)
		$oruga.notification.open({
			component: AddedToPlaylist,
			props: {
				songLink: props.link,
				songName: props.song.name,
				playlistTitle: lengthCheckedTitle,
				playlistLink: `/playlists/${playlist.slug}/${playlist.hash}`
			},
			icon: 'checkmark-outline',
			iconSize: 'medium'
		})
		const playlistData = {
			access_point: props.accessPoint,
			country: userStore.user?.country,
			user_id: userStore.user?.resource_id,
			singlist_id: playlist.resource_id,
			song_id: props.song.id,
			variant_id: props.song.variants[0].id
		}
		segmentEvent('Singlist Song Added', playlistData)
	}).catch((e: any) => {
		console.log(e)
		$oruga.notification.open({
			message: t('song.generalError'),
			variant: 'warning'
		})
	})
	// if (props.closeAfterAction) { $oruga.modal.closeAll()  }
	closeDropdown()
	emit('reset')
	playlistTitle.value = ''
}

const closeContextMenu = () => {
	emit('reset')
}

const closeAll = () => {
	playilstMenuOpen.value = false
	newPlaylistOpen.value = false
	closeDropdown()
	closeContextMenu()
}

const createNewPlaylist = async () => {
	if (!playlistTitle.value) {
		playlistTitle.value = placeholderTitle.value
	}
	const validatePlaylist = () => {
		if (!/[A-Za-zŽžÀ-ÿ0-9]/.test(playlistTitle.value)) {
			throw new Error('playlist.title.invalidError')
		}
	}

	try {
		validatePlaylist()
		loading.value = true

		const response = await $singaApi.Playlists.add({ title: playlistTitle.value })
		const respData = response
		const lengthCheckedTitle = useTruncate(respData.title)
		const playlist = respData
		saveToPlaylist(playlist)
		$oruga.notification.open({
			component: PlaylistCreated,
			props: {
				playlistTitle: lengthCheckedTitle,
				playlistLink: `/playlists/${playlist.slug}/${playlist.hash}`
			},
			icon: 'checkmark-outline',
			iconSize: 'medium'
		})
		loading.value = false
	} catch (e) {
		console.error(e)
		$oruga.notification.open({
			message: t('playlist.title.invalidError'),
			variant: 'warning'
		})
	}
}

const initContext = async (song: Song) => {
	emit('setActive')
	favorited.value = await getFavoriteStatus('songs', song.id) || null
	// Send an event if this was opened from the discover page
	if (props.discoverPageEvent.length > 0) {
		segmentEvent(props.discoverPageEvent, { song_id: song.resource_id, song_name: song.name })
	}
}

const toggle = (toggleState: boolean) => {
	isOpen.value = toggleState
	if (toggleState) {
		initContext(props.song)
	} else {
		closeAll()
	}
}

const addSongToQueue = async (song: Song) => {
	const queueItem = await queueItemSelection(song, undefined, undefined, t('song.error.play'))
	if (!queueItem) { return }
	closeDropdown()
	await addToQueue(queueItem, props.link, 'Song context menu', false, t('song.error.queue'))
	emit('reset')
}

const playPreviewClick = () => {
	if (isAudioPlaying.value) {
		return
	}
	emit('playPreviewClick', 'Song list item grid')
}

onUnmounted(() => {
	useEventOff('queueActions:addToQueue')
})

const isPreviewPlaying = computed(() => {
	return previewPlayerStore.playing && previewPlayerStore.currentSong === props.song.variants[0].id
})
</script>

<style lang="sass" scoped>
:deep(.menu-content)
	text-align: left
	border-radius: $radius-default
	background-color: $color-grey-80
	box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.35)
	max-width: 250px
	overflow: hidden
	padding: $spacing-8 0
	width: 216px
a.dropdown-item
	border-radius: 0px !important
:deep(.menu-content > .dropdown-item)
	text-overflow: ellipsis
	overflow: hidden
a.dropdown-item:hover
	background-color: $transparent-white-8 !important
.btn-wrap
	display: flex
	align-items: center
	gap: 8px
.dropdown-item
	&.disabled
		// color: $color-grey-50
		cursor: default
		pointer-events: none
		&:hover
			background-color: $transparent-white-4
.scrollable
	max-height: 180px
	min-height: 80px
	overflow: auto
	overflow-x: hidden
	.dropdown-item
		max-width: 100%
		text-overflow: ellipsis
		overflow: hidden
	.center
		margin: auto
.new-singlist-trigger
	display: flex
	align-items: center
	gap: $spacing-4
.new-playlist-wrap
	padding: $spacing-16 $spacing-24
	display: flex
	flex-direction: column
	gap: $spacing-4

.load-more-wrap
	text-align: center
	margin-bottom: $spacing-8

.create-singlist
	display: flex
	align-items: center
	justify-content: space-between

div[data-reka-popper-content-wrapper]
	z-index: 45 !important
.context-menu-list-button.is-small
	padding: $spacing-4+2px $spacing-8+2px
	border: none
	box-shadow: none
.mini-player
	padding: $spacing-8 20px !important
	max-width: 52px
</style>
