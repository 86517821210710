import type { UseFetchOptions } from 'nuxt/app'
import { useFetchApiClient } from './useFetchApiClient'

export function useAPI<T>(url: string | (()=> string), options: UseFetchOptions<T> = {}) {
	const apiClient = useFetchApiClient()
	return useFetch(url, {
		dedupe: 'cancel',
		...options,
		$fetch: apiClient
	})
}
