<template lang="pug">
DropdownMenuRoot(v-model:open="toggleState" :modal="false")
	DropdownMenuTrigger.menu-trigger(aria-label="Customise options" :as-child="true")
		slot(name="trigger")
	DropdownMenuPortal(v-bind="portalControlId && { to: portalControlId }" :defer="true")
		DropdownMenuContent.menu-content(align='start' side='bottom' :side-offset="5" :hide-when-detached="true")
			slot(name="content")
			DropdownMenuArrow.dropdown-arrow
</template>

<script lang="ts" setup>
import {
	DropdownMenuArrow,
	DropdownMenuContent,
	DropdownMenuPortal,
	DropdownMenuRoot,
	DropdownMenuTrigger
} from 'reka-ui'

defineProps({
	portalControlId: {
		type: String || null,
		default: null
	}
})

const toggleState = ref(false)

const emits = defineEmits(['toggle'])

useEventOn('contextMenu:close', () => {
	toggleState.value = false
})

watch(toggleState, () => {
	emits('toggle', toggleState.value)
})
</script>

<style lang="sass" scoped>
.menu-trigger
	margin-left: auto
	display: flex
:deep(.dropdown-item)
	padding: 8px 24px
	font-size: 16px
	line-height: 24px
	letter-spacing: 0.1px
:deep(.menu-content)
	text-align: left
	border-radius: $radius-default
	background-color: $color-grey-80
	box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.35)
	padding: $spacing-8 0
	width: 216px
:deep(.dropdown-arrow)
	fill: $color-grey-80

div[data-reka-popper-content-wrapper]
	z-index: 45 !important
</style>
