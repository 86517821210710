import { useRoute } from '#imports'
import type { Playlist } from '~/types'

type PlaylistResponse = {
	results: Playlist[]
	hasNextPage: boolean
	count: number
	error?: unknown
}

type PlaylistParams = {
	page: number
	page_size?: number
	search?: string
	market?: string
	country?: string
	sort?: string
	is_official?: boolean
	[key: string]: any // Allow additional parameters
}

export const usePlaylistLists = (apiResource: Function, initialParams: any = {}) => {
	const route = useRoute()

	const geoLocationStore = useGeoLocationStore()
	const { contentMarket } = storeToRefs(geoLocationStore)

	/**
	 * Load list of playlists from the API
	 *
	 * @param {PlaylistParams} params - Parameters for the playlist request
	 * @returns {Promise<PlaylistResponse>}
	 * @throws {Error} - API request error
	 */
	const loadPlaylists = async (params: PlaylistParams): Promise<PlaylistResponse> => {
		if (!apiResource || import.meta.prerender) {
			return {
				results: [],
				hasNextPage: false,
				count: 0
			}
		}

		try {
			const currentParams = { ...initialParams }

			// Add route sort if present
			if (route.query.sort) {
				currentParams.sort = route.query.sort
			}

			const payload = {
				...currentParams,
				...params,
				market: params.market || contentMarket.value.market
			}
			const response = await apiResource(payload)

			if (!response || !response.results) {
				return {
					results: [],
					hasNextPage: false,
					count: 0
				}
			}

			return {
				results: response.results,
				hasNextPage: response.next !== null && response.next !== '',
				count: response.count
			}
		} catch (err) {
			console.error('Error loading playlists:', err)
			return {
				results: [],
				hasNextPage: false,
				count: 0,
				error: err
			}
		}
	}

	return {
		loadPlaylists
	}
}
