<template lang="pug">
.DownloadAppModal
	header
		span.title {{ action === 'pause' ? t('downloadModal.pause.title') :  t('global.downloadApp') }}
		span.sub-title(v-if="action !== 'pause'") {{ t('global.numberOfSongs') }}
		button.delete.close(type="button" @click="$emit('close')")
	section
		img.download-image(:class="{ 'paused': action === 'pause' }" src="/img/download-app-image.png" alt="Illustration of Singa App")
		//- nuxt-img.download-image(:class="{ 'paused': action === 'pause' }" src="/img/download-app-image.png" alt="Illustration of Singa App" sizes="md:180px lg:250px" format="webp")
	footer
		NuxtLink(v-if="isIos" :to="'https://itunes.apple.com/fi/app/singa-karaoke-lyrics-music/id802874608?mt=8'")
			SingaButton.is-regular(:class="action === 'pause' ? 'is-primary' : 'is-white'") {{ t('global.downloadApp.appStore') }}
		NuxtLink(v-else-if="isAndroid" :to="'https://play.google.com/store/apps/details?id=com.singa.client'")
			SingaButton.is-white.is-regular {{ t('global.downloadApp.appStore') }}
		.sub-text
			.sub-title
				SingaIcon.icon(icon="checkmark-outline" size="small")
				span {{ t('global.singFullSong') }}
			.sub-title
				SingaIcon.icon(icon="checkmark-outline" size="small")
				span {{ t('global.guideVocals') }}
			.sub-title
				SingaIcon.icon(icon="checkmark-outline" size="small")
				span {{ t('global.buildSinglist') }}
</template>

<script setup lang="ts">
defineProps({
	action: {
		type: String,
		default: 'finish'
	}
})
const { t } = useI18n()

const { isIos, isAndroid } = useDevice()
</script>

<style lang="sass" scoped>
.DownloadAppModal
	background: transparent
	backdrop-filter: blur(10px)
	height: 100%
	text-align: center
	padding: $spacing-16
	width: 100%
	margin: auto
	padding-top: $spacing-80
	.close
		position: absolute
		top: $spacing-16
		right: $spacing-16
		max-width: 28px
		max-height: 28px
		width: 28px
		height: 28px
		&:hover
			background-color: $color-grey-30
	@media (min-height: 900px)
		display: flex
		justify-content: center
		align-items: center
		flex-direction: column
		padding-top: 0
:deep(.modal-content)
	max-width: 100%
.modal-action-background
	background-color: $transparent-white-4
	border-radius: $radius-default
	padding: $spacing-24
.title, .sub-title
	display: block
	color: $color-grey-30
.sub-text
	display: flex
	flex-direction: column
	gap: 4px
	margin-top: 24px
	.sub-title
		@include fontSize(s)
		display: flex
		justify-content: center
		align-items: center
		gap: 4px
	.icon
		color: $primary
.title
	@include font(basier, bold)
	@include fontSize(3xl)
	display: block
.sub-title
	@include font(basier, medium)
	@include fontSize(m)
.download-image
	display: inline-block
	text-align: center
	padding: 36px 0 48px
	max-width: 180px
	max-height: 350px
	margin: auto
	@media (min-width: $mobile-small)
		max-width: 100%
	&.paused
		display: block
		height: auto
		width: 180px
		padding: 12px 0px 36px
header
	text-align: center
</style>
