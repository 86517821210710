<template lang="pug">
div
	SingaTooltip(:content="tooltip")
		.song-tag(:class="[{ 'song-detail': variant === 'detail', 'original': original, 'popular': popular, 'static-tag' : notClickable }]" @click="handleClick()")
			span {{ tag }}
</template>

<script setup lang="ts">
const props = defineProps({
	tooltip: {
		type: String,
		default: null
	},
	tag: {
		type: String,
		default: ''
	},
	original: {
		type: Boolean,
		default: false
	},
	popular: {
		type: Boolean,
		default: false
	},
	variant: {
		type: String,
		default: 'list'
	},
	notClickable: {
		type: Boolean,
		default: false
	}
})

const emit = defineEmits([
	'clicked'
])

const handleClick = () => {
	if (!props.notClickable) {
		emit('clicked')
	}
}
</script>

<style lang="sass" scoped>
.song-tag
	display: flex
	min-width: 0
	border-radius: $radius-round
	transition: .3s
	span
		overflow: hidden
		white-space: nowrap
		text-overflow: ellipsis
		display: block
	&.song-detail
		background-color: $transparent-white-16
		padding: 2px $spacing-8
		&.popular
			background-color: $color-white
			span
				color: $color-black
		&.original
			background-color: $color-purple-50
			span
				color: $color-white
		span
			color: $color-grey-20
			@include fontSize(xxs)
			@include font(basier, medium)
		&:hover
			background-color: $transparent-white-24
			&.original
				background-color: $color-purple-60
		@media (min-width: $tablet)
			padding: 4px $spacing-16
			span
				@include fontSize(xs)
	&:not(.song-detail)
		background-color: $transparent-white-12
		padding: 2px $spacing-8
		&.popular
			background-color: $color-white
			span
				color: $color-black
		&.original
			background-color: $color-purple-50
			span
				color: $color-white
		span
			color: $color-grey-40
			@include fontSize(xxs)
	&:not(.static-tag)
		cursor: pointer
		&:hover
			background-color: $color-grey-70
			&.original
				background-color: $color-purple-60
</style>
