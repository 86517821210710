export const useSongProperties = (song: Song) => {
	if (!song || !song.artists) {
		return null
	}
	const useImage = useGetImageSize()
	const songName = song?.name
	const { getAllArtists, getArtistSlugs } = useArtistNames(song.artists)
	const artistString = getAllArtists()
	const artistSlug = getArtistSlugs()
	const image = useImage(song?.image, 'medium', 'song', true)
	const id = song?.id
	const resource_id = song?.resource_id

	const songObject = { name: song?.name, link: `/artists/${artistSlug}/${song?.slug}/${song?.hash}/` }
	const artistObjects = song?.artists.map((artist: Artist) => { return { name: artist.name, link: `/artists/${artist.slug}/${artist.hash}` } })
	return {
		songName, artistString, image, artistObjects, songObject, id, resource_id
	}
}
