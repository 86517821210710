<template lang="pug">
.notification-message
	a(@click="openLink(songLink)") {{ songName }}
	span &nbsp;{{ t('song.addedToQueue') }}&nbsp;
</template>

<script setup lang="ts">
const { t } = useI18n()

const emit = defineEmits(['close'])

defineProps({
	songLink: {
		type: String,
		required: true
	},
	songName: {
		type: String,
		required: true
	}
})

const localePath = useLocalePath()

const openLink = (link: string) => {
	navigateTo(localePath(link))
	emit('close')
}
</script>
