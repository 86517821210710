<template lang="pug">
.switch(:class="{ 'disabled' : disabled }")
	label(v-if="label")
		span.label {{ label }}
	SwitchRoot.SwitchRoot(
		:modelValue="modelValue"
		@update:modelValue="emitToggle"
		@click.stop
	)
		SwitchThumb.SwitchThumb
</template>

<script lang="ts" setup>
import { SwitchRoot, SwitchThumb } from 'reka-ui'

defineProps({
	modelValue: {
		type: Boolean,
		required: true
	},
	label: {
		type: String,
		required: false
	},
	disabled: {
		type: Boolean,
		required: false
	}
})

const emit = defineEmits(['toggle'])
const emitToggle = (value: boolean) => {
	emit('toggle', value)
}
</script>

<style lang="sass" scoped>
.switch
	display: flex
	align-items: center
	justify-content: flex-end
	margin: 0
	&.disabled
		pointer-events: none
		opacity: 0.4

.SwitchRoot
	cursor: pointer
	width: 48px
	height: 28px
	background-color: $transparent-white-12
	border-radius: $radius-round
	padding: 0
	border: none

.SwitchRoot[data-state='checked']
	background-color: $color-green-60

.SwitchThumb
	display: block
	width: 26px
	height: 26px
	background-color: $color-grey-50
	border-radius: $radius-round
	border: none
	box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2)
	transition: transform 250ms
	transform: translateX(2px)
	will-change: transform

.SwitchThumb[data-state='checked']
	transform: translateX(20px)
	background-color: white

.label
	color: $color-grey-30
	@include font(basier, regular)
	@include fontSize(s)
</style>
