import { useProgrammatic, ConfigProgrammatic, Notification, Modal, Field } from '@oruga-ui/oruga-next'
import { bulmaConfig } from '@oruga-ui/theme-bulma'

import { SingaBaseIcon } from '#components'

declare module '#app' {
	interface NuxtApp {
		$oruga: any
	}
}

const customBulmaConfig = {
	...bulmaConfig,
	iconComponent: 'base-icon',
	iconPack: 'ion',
	css: false,
	sizes: {
		default: 'is-size-5',
		small: '',
		medium: 'is-size-3',
		large: 'is-size-1'
	},
	notification: {
		override: true,
		duration: 5000,
		position: 'top-right',
		rootClass: (_: string, { props }: any) => {
			const classes = ['notification']
			if (props.variant) {
				classes.push(`is-${props.variant}`)
			}
			return classes.join(' ')
		},
		wrapperClass: 'media',
		contentClass: 'media-content',
		iconClass: 'media-left',
		closeClass: 'delete',
		positionClass: 'is-',
		noticeClass: 'b-notices',
		noticePositionClass: 'is-',
		closeIcon: ''
	}
}

export default defineNuxtPlugin({
	enforce: 'post',
	parallel: true,
	setup(nuxtApp) {
		nuxtApp.vueApp
			.component('base-icon', SingaBaseIcon)
			.use(Notification)
			.use(Modal)
			.use(Field)
		ConfigProgrammatic.setOptions(customBulmaConfig)
		const { oruga } = useProgrammatic()
		return {
			provide: {
				oruga
			}
		}
	}
})
