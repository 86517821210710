import type { NitroFetchOptions } from 'nitropack'
import { useFetchApiClient } from './useFetchApiClient'

export function useRawFetch(url: string, options: NitroFetchOptions<string & {}> = {}) {
	const apiClient = useFetchApiClient()
	return apiClient(url, {
		...options,
		responseType: options.responseType || 'json'
	})
}
