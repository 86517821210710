<template lang="pug">
NuxtLayout(name="default")
	.wrapper
		.errorpage.container.section
			SingaLogo.logo
			h1.not-found {{ t('404.notFound') }}
			p {{ error.statusCode }} {{ error.statusMessage }}
			span
				| {{ t('404.notFound.details1') }}
				|
				NuxtLink.link(:to="localePath('/search/results/')") {{ t('404.notFound.details2') }}
				|
				| {{ t('404.notFound.details3') }}
				|
				NuxtLink.link(:to="'https://help.singa.com/'") {{ t('404.notFound.details4') }}
				| .
			SingaButton.is-transparent-dark.is-regular(@click="goHome") {{ t('general.home') }}
</template>

<script setup lang="ts">
const { t } = useI18n()
const localePath = useLocalePath()

const props = defineProps({
	error: {
		type: Object,
		required: false,
		default: null
	}
})

if (props.error) {
	console.log('error', props.error)
}
const goHome = () => navigateTo({ path: localePath('/') })
</script>

<style lang="sass" scoped>
.wrapper
	display: block
	min-height: 500px

.errorpage
	max-width: 420px
	text-align: center
	display: flex
	flex-direction: column
	align-items: center
	row-gap: $spacing-24
	min-height: 550px
	margin: 0 auto
	@include fontSize(s)

.not-found
	margin-top: $spacing-8
	@include font(basier, medium)
	@include fontSize(3xl)

.logo
	margin-top: $spacing-80
	margin-bottom: $spacing-16

.button
	margin-bottom: $spacing-80
	width: 120px

.link
	text-decoration: underline
	color: white
	&:hover
		color: $color-grey-50
</style>
