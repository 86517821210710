import type { $Fetch } from 'nitropack'
import type { SongRequestQuery } from '../types'
import { useRuntimeConfig } from '#imports'
import { useRawFetch } from '~/composables/api/useRawFetch'
import { useRawFetchProxy } from '~/composables/api/useRawFetchProxy'
import { useAPI } from '~/composables/api/useApi'

export const createApiWrapper = () => {
	const config = useRuntimeConfig()
	const resolveUrl = config.public.SINGA_RESOLVE_PREFIX
	return {
		Me: {
			get: (params: any): ReturnType<$Fetch> => useRawFetch('/me/', { query: params }),
			patch: (params: any): ReturnType<$Fetch> => useRawFetch('/me/', { method: 'PATCH', body: { ...params } }),
			delete: (params: any): ReturnType<$Fetch> => useRawFetch('/me/', { method: 'DELETE', body: params.data, headers: params.headers }),
			Preferences: {
				get: (ids: any): ReturnType<$Fetch> => useRawFetch(`/me/preferences/?variants=${ids.join(',')}`),
				put: (variantId: any, data: any): ReturnType<$Fetch> => useRawFetch(`me/preferences/songvariants/${variantId}/`, { method: 'PUT', body: { ...data } })
			},
			Reset: {
				Email: {
					getResetToken: (newEmail: string): ReturnType<$Fetch> => useRawFetch('/me/reset/email/', { method: 'POST', body: { email: newEmail } }),
					checkResetToken: (token: string): ReturnType<$Fetch> => useRawFetch(`/me/reset/email/${token}/`),
					useResetToken: (token: string): ReturnType<$Fetch> => useRawFetch(`/me/reset/email/${token}/`, { method: 'PATCH' }),
					cancelResetToken: (token: string): ReturnType<$Fetch> => useRawFetch(`/me/reset/email/${token}/`, { method: 'DELETE' })
				},
				Password: {
					getResetToken: (userOrEmail: string): ReturnType<$Fetch> => useRawFetch('/me/reset/password/', { method: 'POST', body: { user: userOrEmail } }),
					checkResetToken: (token: string): ReturnType<$Fetch> => useRawFetch(`/me/reset/password/${token}/`),
					useResetToken: (token: string, newPassword: string): ReturnType<$Fetch> => useRawFetch(`/me/reset/password/${token}/`, { method: 'PATCH', body: { password: newPassword } }),
					cancelResetToken: (token: string): ReturnType<$Fetch> => useRawFetch(`/me/reset/password/${token}/`, { method: 'DELETE' })
				}
			},
			SongHistory: {
				list: (params: object): ReturnType<$Fetch> => useRawFetch('/me/song-history/', { query: params }),
				delete: (): ReturnType<$Fetch> => useRawFetch('/me/song-history/', { method: 'DELETE' })
			},
			Playlists: {
				list: (params: object): ReturnType<$Fetch> => useRawFetch('/me/playlists/songs/', { query: params })
			},
			Stripe: {
				customerPortalSession: (body: object): ReturnType<$Fetch> => useRawFetchProxy('/api/stripe/customerPortalSession', { method: 'POST', body }),
				checkoutSession: (body: object): ReturnType<$Fetch> => useRawFetchProxy('/api/stripe/checkoutSession', { method: 'POST', body })
			},
			JWT: {
				get: (): ReturnType<$Fetch> => useRawFetch('/me/iterable/jwt/', { method: 'POST', body: { } })
			}
		},
		Users: {
			UserExists: (params: any): ReturnType<$Fetch> => useRawFetch('/users/exists/', { method: 'POST', body: params })
		},
		Favorites: {
			list: (params: object): ReturnType<$Fetch> => useRawFetch('/me/favorites/exists?type=songs,playlists,artists', { method: 'POST', params }),
			checkFavoriteStatus: (params: object): ReturnType<$Fetch> => useRawFetch('/me/favorites/exists/', { method: 'POST', params }),
			FavoriteSongs: {
				list: (params: object): ReturnType<$Fetch> => useRawFetch('/me/favorites/songs/', { query: params }),
				addFavorite: (id: number): ReturnType<$Fetch> => useRawFetch(`/me/favorites/songs/${id}/`, { method: 'PUT' }),
				deleteFavorite: (id: number): ReturnType<$Fetch> => useRawFetch(`/me/favorites/songs/${id}/`, { method: 'DELETE' })
			},
			FavoriteArtists: {
				list: (params: object): ReturnType<$Fetch> => useRawFetch('/me/favorites/artists/', { query: params }),
				addFavorite: (id: number): ReturnType<$Fetch> => useRawFetch(`/me/favorites/artists/${id}/`, { method: 'PUT' }),
				deleteFavorite: (id: number): ReturnType<$Fetch> => useRawFetch(`/me/favorites/artists/${id}/`, { method: 'DELETE' })
			},
			FavoritePlaylists: {
				list: (params: object): ReturnType<$Fetch> => useRawFetch('/me/favorites/playlists/songs/', { query: params }),
				addFavorite: (id: number): ReturnType<$Fetch> => useRawFetch(`/me/favorites/playlists/songs/${id}/`, { method: 'PUT' }),
				deleteFavorite: (id: number): ReturnType<$Fetch> => useRawFetch(`/me/favorites/playlists/songs/${id}/`, { method: 'DELETE' })
			}
		},
		Auth: {
			getTokens: (credentials: object): ReturnType<$Fetch> => useRawFetchProxy('/api/oauth/token', {
				body: { ...credentials },
				method: 'POST'
			}),
			getGoogleProfile: (payload: string): ReturnType<$Fetch> => useRawFetchProxy(`/api/google/${payload}`),
			register: (credentials: object): ReturnType<$Fetch> => useRawFetchProxy('/api/registration/', {
				body: { ...credentials },
				method: 'POST'
			})
		},
		Sheets: {
			addRow: (sheet: 'deleteAccount', payload: object): ReturnType<$Fetch> => useRawFetchProxy(`/api/google/sheet/${sheet}/`, { body: { ...payload }, method: 'POST' })
		},
		Connections: {
			getTokens: (payload: object): ReturnType<$Fetch> => useRawFetchProxy('/api/connections/social', { // Not supposed to have a trailing backslash
				body: { ...payload },
				method: 'POST'
			}),
			connectDevice: (pin: string): ReturnType<$Fetch> => useRawFetchProxy(`/api/connections/${pin}`, { method: 'PATCH', body: { state: 'accepted' } })
		},
		Songs: {
			get: ({ id }: { id: number }): ReturnType<$Fetch> => useRawFetch(`/songs/${id}/`),
			post: ({ song, artist }: { song: string, artist: string }): ReturnType<$Fetch> => useRawFetch('/songs/requests/', { method: 'POST', body: { song, artist } }),
			list: (params: SongRequestQuery): ReturnType<$Fetch> => useRawFetch('/songs/', { query: params }),
			nextPage: ({ nextUrl }: { nextUrl: string }) => useAPI(nextUrl),
			log: (data: any): ReturnType<$Fetch> => useRawFetch('/log/song/', { method: 'POST', body: { ...data } }),
			getVariant: (songId: number, variantId: number) => useAPI(`/songs/${songId}/variants/${variantId}/`),
			related: ({ id }: { id: number }) => useAPI(`/songs/${id}/related/`),
			postError: (data: any): ReturnType<$Fetch> => useRawFetch('songs/reports/', { method: 'POST', body: { ...data } })
		},
		Artists: {
			get: ({ id, params }: { id: number, params: object }): ReturnType<$Fetch> => useRawFetch(`/artists/${id}/`, { query: params }),
			list: (params: any): ReturnType<$Fetch> => useRawFetch('/artists/', { query: params }),
			nextPage: ({ nextUrl }: { nextUrl: string }): ReturnType<$Fetch> => useRawFetch(nextUrl),
			artistSongs: (params: any, id: any): ReturnType<$Fetch> => useRawFetch(`/artists/${id}/songs/`, { query: params }),
			getArtistImages: ({ id, params }: { id: number, params: object }): ReturnType<$Fetch> => useRawFetch(`/artists/${id}/images/`, { query: params })
		},
		Playlists: {
			get: ({ id, params }: { id: number, params: object }): ReturnType<$Fetch> => useRawFetch(`/playlist/${id}/`, { query: params }),
			list: (params: any): ReturnType<$Fetch> => useRawFetch('/playlists/songs/', { query: params }),
			nextPage: ({ nextUrl }: { nextUrl: string }) => useAPI(nextUrl),
			playlistSongs: (params: any, id: { id: number, params: object }): ReturnType<$Fetch> => useRawFetch(`/playlists/songs/${id}/entries/`, { query: params }),
			reOrderSongs: ({ id, rangeStart, insertBefore }: { id: number, rangeStart: number, insertBefore: number }): ReturnType<$Fetch> => useRawFetch(`/playlists/songs/${id}/entries/`, { method: 'PUT', body: { range_start: rangeStart, insert_before: insertBefore } }),
			getArtistImages: ({ id, params }: { id: number, params: object }) => useAPI(`/playlist/${id}/images/`, { query: params }),
			add: ({ title, description, availability }: { title: string, description?: string, availability?: 'public' | 'private' }): ReturnType<$Fetch> => useRawFetch('/playlists/songs/', { method: 'POST', body: { title, description, availability } }),
			edit: ({ id, title, description }: { id: number, title: string, description?: string }): ReturnType<$Fetch> => useRawFetch(`/playlists/songs/${id}/`, { method: 'PATCH', body: { title, description } }),
			togglePublicity: ({ id, publicity }: { id: number, publicity: string }): ReturnType<$Fetch> => useRawFetch(`/playlists/songs/${id}/`, { method: 'PATCH', body: { availability: publicity } }),
			delete: (id: number): ReturnType<$Fetch> => useRawFetch(`/playlists/songs/${id}/`, { method: 'DELETE' }),
			addVariant: ({ id, params }: { id: number, params: object }): ReturnType<$Fetch> => useRawFetch(`/playlists/songs/${id}/entries/`, { method: 'POST', body: { ...params } }),
			deleteVariant: (id: number, params: any): ReturnType<$Fetch> => useRawFetch(`/playlists/songs/${id}/entries/`, { method: 'DELETE', ...params })
		},
		ScheduledPlaylists: {
			get: (params: { params: object }): ReturnType<$Fetch> => useRawFetch('/playlists/songs/scheduled/', { query: params })
		},
		Media: {
			getAudio: (songId: number, variantId: number, pitch: number): ReturnType<$Fetch> => useRawFetch(`/songs/${songId}/variants/${variantId}/audio/?pitch=${pitch}`),
			getLyrics: (songId: number, variantId: number): ReturnType<$Fetch> => useRawFetch(`/songs/${songId}/variants/${variantId}/lyrics/`),
			getParsedLyrics: (songId: number, variantId: number): ReturnType<$Fetch> => useRawFetch(`/songs/${songId}/variants/${variantId}/lyrics/as-lines/`),
			getPreviewAudio: (songId: number, variantId: number): ReturnType<$Fetch> => useRawFetch(`/songs/${songId}/variants/${variantId}/`),
			getPreviewLyrics: (songId: number, variantId: number): ReturnType<$Fetch> => useRawFetch(`/songs/${songId}/variants/${variantId}/lyrics/preview/`),
			getVariantImages: (songId: number, variantId: number): ReturnType<$Fetch> => useRawFetch(`/songs/${songId}/variants/${variantId}/images/`)
		},
		Genres: {
			list: (params: any): ReturnType<$Fetch> => useRawFetch('/genres/', { query: params }),
			get: (id: any): ReturnType<$Fetch> => useRawFetch(`/genres/${id}/`),
			getImages: ({ id, params }: { id: number, params: object }): ReturnType<$Fetch> => useRawFetch(`/genres/${id}/images/`, { query: params })
		},
		Venues: {
			list: (params: any): ReturnType<$Fetch> => useRawFetch('/venues/', { query: params }),
			search: (params: string) => useAPI(`/venues/?search=${params}`)
		},
		Cities: {
			get: (): ReturnType<$Fetch> => useRawFetch('/venues/cities/?page_size=1000')
		},
		GenreImages: {
			get: (genreId: number) => useAPI(`imagebanks/${genreId}/entries/`)
		},
		Languages: {
			list: (params: any): ReturnType<$Fetch> => useRawFetch('/songs/languages/', { query: params })
		},
		Search: {
			get: (params: object): ReturnType<$Fetch> => useRawFetch('/search/', { query: params })
		},
		URL: {
			get: (url: any, params: object) => useAPI(url, { query: params })
		},
		Resolve: {
			Artists: {
				get: (slug: string, hash: string): ReturnType<$Fetch> => useRawFetch(`/resolve/?url=${resolveUrl}/artists/${slug}/${hash}`)
			},
			Playlists: {
				get: (slug: string, hash: string): ReturnType<$Fetch> => useRawFetch(`/resolve/?url=${resolveUrl}/lists/${slug}/${hash}`)
			},
			Songs: {
				get: (slug: string, songSlug: string, hash: string): ReturnType<$Fetch> => useRawFetch(`/resolve/?url=${resolveUrl}/artists/${slug}/${songSlug}/${hash}`)
			},
			Venues: {
				get: (slug: string): ReturnType<$Fetch> => useRawFetch(`/resolve/?url=${resolveUrl}/karaoke-near-me/${slug}`)
			}
		},
		Products: {
			getPlans: (params: object): ReturnType<$Fetch> => useRawFetch('/products/plans/', { query: params }),
			getPasses: (params: object): ReturnType<$Fetch> => useRawFetch('/products/passes/', { query: params }),
			getPrices: (params: object): ReturnType<$Fetch> => useRawFetch('/products/prices/', { query: params })
		}
	}
}
