<template lang="pug">
div(ref="app")
	NuxtLayout
		NuxtPage
		ClientOnly
			SingaCookieConsent(v-if="!cookieConsent")
	transition(name="fade-slide")
		Teleport(to="body" :disabled="isMobile" v-if="!isPresentationRoute")
			.fixed-bottom(v-if="showMiniPlayer")
				PlayerElement
			.expand-player.fixed-bottom(to="body" v-else-if="showExpandPlayer")
				SingaButton.is-small.is-regular.player-button(@click="showMiniPlayer = true" icon-left="chevron-up") {{ t('player.title') }}
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useTimeout } from '@vueuse/core'
import { usePlayerStore } from '~/pinia/player/karaokePlayer'

const { loadIterable } = useIterable()
const { isMobile, isDesktop } = useDevice()
const config = useRuntimeConfig()

const karaokeStore = usePlayerStore()
const { showMiniPlayer } = storeToRefs(karaokeStore)
const cookieConsent = useCookieConsent()

const { isLoggedIn, login } = useAuth()
const { getInitSettings } = useMediaFileStore()
const { loadSegment } = useSegment()
const { initFullstory } = useFullstory()
const queueStore = useQueueStore()
const { hasAddedSongToQueue } = storeToRefs(queueStore)

const { storeQueryParams } = useQueryParams()
const { t } = useI18n()

const showExpandPlayer = computed(() => {
	return !showMiniPlayer.value && hasAddedSongToQueue.value
})

const scriptDelay = useTimeout(3000)
const loadScripts = computed(() => scriptDelay.value && import.meta.client)

const { proxy } = useScriptGoogleTagManager({
	id: config.public.gtm.id,
	scriptOptions: {
		bundle: false,
		trigger: loadScripts
	}
})

const gtagConsent = () => {
	if (import.meta.client) {
		proxy.dataLayer.push({
			event: 'consent',
			action: 'update'
		},
		{
			analytics_storage: 'granted',
			ad_storage: 'granted',
			ad_user_data: 'granted',
			ad_personalization: 'granted',
			allow_ad_personalization_signals: 'true'
		})
	}
}

// Send consent to gtag after loading scripts
watch(loadScripts, (value: boolean) => {
	if (value && import.meta.client) {
		gtagConsent()
	}
})
const { setMetaImg } = useMetaTags()
const metaImg = setMetaImg()

useHead({
	meta: [
		...metaImg
	]
})

const contextMenuHandler = useDebounceFn(() => {
	const activeDropdown = document.getElementsByClassName('dropdown is-active')
	if (activeDropdown && activeDropdown.length > 0) {
		useEventEmit('contextMenu:close')
	}
}, 10)

const route = useRoute()

const isPresentationRoute = computed(() => {
	return typeof route.name === 'string' && route.name.includes('presentation')
})

onMounted(async () => {
	window.addEventListener('resize', contextMenuHandler)
	window.addEventListener('scroll', contextMenuHandler)

	await login()
	if (import.meta.client && !import.meta.dev && !import.meta.prerender) {
		loadIterable()
		loadSegment()
		consoleLogSingaLogo()
		if (isDesktop) {
			initFullstory()
		}
		gtagConsent()
		storeQueryParams()
	}

	// Don't queue persist on karaoke preview mode
	queueStore.previewQueue = useDevice().isMobile || !isLoggedIn.value

	if (isLoggedIn.value) {
		const { segmentPageView } = useSegment()
		const route = useRoute()
		getInitSettings()
		segmentPageView(route.path, route.fullPath)
	}
})

onUnmounted(() => {
	const contextMenuHandler = useDebounceFn(() => {
		const activeDropdown = document.getElementsByClassName('dropdown is-active')
		if (activeDropdown && activeDropdown.length > 0) {
			useEventEmit('contextMenu:close')
		}
	}, 10)
	window.removeEventListener('resize', contextMenuHandler)
	window.removeEventListener('scroll', contextMenuHandler)
})
</script>

<style lang="sass" scoped>
.fixed-bottom
	width: 100%
	position: fixed
	bottom: 0
	left: 0
	z-index: 60
	background: black

.fade-slide-enter-active, .fade-slide-leave-active
	transition: opacity .8s, transform .8s

.fade-slide-enter-from, .fade-slide-leave-to
	opacity: 0
	transform: translateY(100%)

.fade-slide-enter-to, .fade-slide-leave-from
	opacity: 1
	transform: translateY(0)

.expand-player
	background: none
	border-bottom: 10px solid $color-grey-90
	display: flex
	justify-content: flex-end
	.player-button
		padding-top: 22px
		background: $color-grey-90
		color: $color-grey-30
		@include font(basier, medium)
		@include fontSize(s)
		border-radius: $radius-default $radius-default 0 0
		border: none
		height: 38px
		margin-right: $spacing-24
		line-height: $spacing-24
		:hover
			color: $color-grey-40
		&:deep(.iconify)
			width: 20px
			height: 20px
		&:deep(.base-icon)
			margin-right: 8px
</style>
