import FreemiumModal from '../components/modals/Freemium.vue'
import { useNuxtApp } from '#imports'

export const openFreemiumModal = async (calledFromQueue = false) => {
	const { $oruga, $preview, $i18n } = useNuxtApp()
	const version = $preview ? 'draft' : 'published'

	const userStore = useUserStore()
	const { numberOfFreeSongsLeft, hasPremiumSub } = storeToRefs(userStore)

	// If the user has a premium subscription, we don't need to show the modal
	if (hasPremiumSub.value) {
		return
	}
	// Check user data to update the number of free songs left
	const authCookie = useTokenCookie()
	await userStore.getUser(false, authCookie.value as any)

	if (numberOfFreeSongsLeft.value > 2 && !calledFromQueue) {
		return
	}

	const story = await useAsyncStoryblok(
		'modals/freemium',
		{ version, language: $i18n.locale.value, resolve_links: 'url' }
	)
	$oruga.modal.open({
		component: FreemiumModal,
		width: 768,
		props: {
			fromQueue: calledFromQueue,
			story: story.value
		}
	})

	return { openFreemiumModal }
}
