type Nullable<T> = T | null
export const useTokenCookie = () => {
	const maxAge = 365 * 24 * 60 * 60
	const expires = new Date(Date.now() + maxAge * 1000)

	const authCookie: Ref<Nullable<Tokens>> = useCookie('sng_tokens', {
		sameSite: 'lax',
		maxAge,
		expires,
		path: '/'
	})
	return authCookie
}
