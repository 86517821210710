export const useResolve = () => {
	/**
	 * Resolve an artist object with the given slug and hash from the route parameters. If it's not found, throw an error to get to the 404 page.
	 *
	 * @param {string} slug - Artist slug
	 * @param {string} hash - Artist hash
	 * @throws {Error} - API request error
	 */
	const resolveArtistObject = async (slug: string, hash: string) => {
		if (import.meta.prerender) { return }

		const { $singaApi } = useNuxtApp()
		try {
			return await $singaApi.Resolve.Artists.get(slug, hash)
		} catch (error) {
			console.log('error in resolve', error)
			throw createError({ statusCode: 404 })
		}
	}

	/**
	 * Resolve a venue object with the given slug from the route parameters. If it's not found, throw an error to get to the 404 page.
	 *
	 * @param {string} slug - Venuel slug
	 * @throws {Error} - API request error
	 */
	const resolveVenueObject = async (slug: string) => {
		if (import.meta.prerender) { return }

		const { $singaApi } = useNuxtApp()
		try {
			const response = await $singaApi.Resolve.Venues.get(slug)
			return response
		} catch (error) {
			console.log('error in resolve', error)
			throw createError({ statusCode: 404 })
		}
	}

	/**
	 * Resolve a playlist object with the given slug and hash from the route parameters. If it's not found, throw an error to get to the 404 page.
	 *
	 * @param {string} slug - playlist slug
	 * @param {string} hash - playlist hash
	 * @throws {Error} - API request error
	 */
	const resolvePlaylistObject = async (slug: string, hash: string) => {
		if (import.meta.prerender) { return }

		const { $singaApi } = useNuxtApp()
		try {
			return await $singaApi.Resolve.Playlists.get(slug, hash)
		} catch (error) {
			console.log('error in resolve', error)
			throw createError({ statusCode: 404 })
		}
	}

	/**
	 * Resolve a song object with the given slug and hash from the route parameters. If it's not found, throw an error to get to the 404 page.
	 *
	 * @param {string} slug - Song's artist slug
	 * @param {string} songSlug - Song title slug
	 * @param {string} hash - Song hash
	 * @throws {Error} - API request error
	 */
	const resolveSongObject = async (slug: string, songSlug: string, hash: string) => {
		if (import.meta.prerender) { return }

		const { $singaApi } = useNuxtApp()
		const { contentMarket } = useGeoLocationStore()
		try {
			const response = await $singaApi.Resolve.Songs.get(slug, songSlug, hash)
			// console.info(error)
			if (response) {
				response.geoblocked = !response.available_markets.includes(contentMarket.market)
			}
			return response
		} catch (error) {
			console.log('error in resolve', error)
			throw createError({ statusCode: 404 })
		}
	}
	return {
		resolveArtistObject,
		resolveVenueObject,
		resolvePlaylistObject,
		resolveSongObject
	}
}
