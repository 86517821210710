import { defineStore } from 'pinia'
import type { SongLog } from '../../types'
import { useSecondScreenStore } from '../secondScreen/secondScreenStore'
import { useQueueStore } from '../queueStore'

const formatTime = (mins: number, secs: number) => {
	let ret = ''
	ret += '' + mins + ':' + (secs < 10 ? '0' : '')
	ret += '' + secs
	return ret
}
const clamp = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max)

export const useAudioPlayerStore = defineStore('audioPlayer', {
	state: (): AudioPlayerState => (
		{
			currentSeconds: 0,
			currentMinutes: 0,
			durationSeconds: 0,
			loaded: false,
			playing: false,
			volume: 100,
			sessionTimer: 0,
			seeking: false,
			settingsOpened: false,
			beforeRestart: {
				timeStamp: 0,
				wasPlaying: false
			},
			playingOutro: false
		}
	),
	getters: {
		isAudioPlaying(state): any {
			return state.playing
		},
		hasSongStarted(state): any {
			const queueStore = useQueueStore()
			if (!queueStore.firstInQueue?.variant) {
				return false
			}
			const startTime = queueStore.firstInQueue?.variant?.start / 1000
			return state.currentSeconds > startTime
		},
		roundedDuration(state): any {
			return ~~state.currentSeconds
		},
		isOverThirtySeconds(state): any {
			return ~~state.currentSeconds > 30
		},
		isSessionOverThirty(state): any {
			return ~~state.sessionTimer > 30
		},
		timeUntilEnd(state): any {
			return Math.ceil(state.durationSeconds / 60) - state.currentMinutes
		},
		timeUntilEndDetail(state): any {
			const secondsLeft = state.durationSeconds - state.currentSeconds
			const mins = ~~((secondsLeft % 3600) / 60)
			const secs = Math.max((~~secondsLeft % 60), 0)
			return formatTime(mins, secs)
			// Output like "1:01" or "4:03:59" or "123:03:59"
		},
		timePassedDetail(state): any {
			const timePassed = state.currentSeconds
			const mins = ~~((timePassed % 3600) / 60)
			const secs = ~~timePassed % 60
			return formatTime(mins, secs)
			// Output like "1:01" or "4:03:59" or "123:03:59"
		},
		isSeeking(state): any {
			return state.seeking
		}
	},
	actions: {
		setPlaying(value: boolean) {
			this.playing = value
		},
		setSettingsOpened(value: boolean) {
			this.settingsOpened = value
		},
		saveCurrentStateBeforeRestart(nullifyValues: boolean) {
			if (!nullifyValues) {
				this.beforeRestart.timeStamp = this.currentSeconds
				this.beforeRestart.wasPlaying = this.playing
			} else {
				this.beforeRestart.timeStamp = 0
				this.beforeRestart.wasPlaying = false
			}
		},
		resumeAudioPlayer() {
			const { $audioPlayer } = useNuxtApp()
			const userStore = useUserStore()
			$audioPlayer.play()
			useEventEmit('playerEvent:resume', useSecondScreenStore().isSecondScreenActive)

			window.top?.postMessage(JSON.stringify({ method: 'SINGA_PLAYER_RESUMED' }), '*')
			const { segmentEvent } = useSegment()
			const { firstInQueue } = useQueueStore()
			if (!userStore.showDemoPlayer) {
				segmentEvent('Player Playback Resumed', {
					song_id: firstInQueue?.entry.resource_id,
					popout_player: useSecondScreenStore().isSecondScreenActive,
					position: this.currentSeconds,
					session_id: firstInQueue?.entryID,
					song_length: firstInQueue?.variant.duration
				})
			}
			this.playing = true
		},
		async pauseAudioPlayer(log = false) {
			const userStore = useUserStore()

			try {
				const { $audioPlayer } = useNuxtApp()
				$audioPlayer.pause()
				useEventEmit('playerEvent:pause', useSecondScreenStore().isSecondScreenActive)

				if (log && !userStore.showDemoPlayer) {
					const { segmentEvent } = useSegment()
					const { firstInQueue } = useQueueStore()
					segmentEvent('Player Playback Paused', {
						song_id: firstInQueue?.entry.resource_id,
						popout_player: useSecondScreenStore().isSecondScreenActive,
						position: this.currentSeconds,
						session_id: firstInQueue?.entryID,
						song_length: firstInQueue?.variant.duration
					})
					await this.logSong()
				}
				window.top?.postMessage(JSON.stringify({ method: 'SINGA_PLAYER_PAUSED' }), '*')
				this.playing = false
			} catch (error: any) {
				if (error && error.message) {
					console.log('pause error', error?.message)
				} else {
					console.log('pause error, no message')
				}
			}
		},
		async sendSongLog(songData: SongLog) {
			const { $singaApi } = useNuxtApp()
			if (songData) {
				await $singaApi.Songs.log(songData)
			}
		},
		async logSong() {
			const queueStore = useQueueStore()
			const userStore = useUserStore()

			const currentSong = queueStore.firstInQueue
			const songLogData: SongLog = {
				context: 'song.game',
				duration: currentSong?.variant.duration,
				platform: 'web',
				seconds: this.sessionTimer,
				session: currentSong?.entryID,
				session_time: this.sessionTimer,
				song_is_premium: currentSong?.variant.is_plus,
				user_has_subscription: !!userStore.user?.subscription?.is_active,
				variant: currentSong?.variant.id
			}
			// Update song history when song starts
			if (songLogData.seconds === 0 && userStore.user) {
				const { addEntryToSongHistory } = useDiscoverStore()
				addEntryToSongHistory(toRaw(queueStore.firstInQueue.entry))
				useEventEmit('song:updateHistory')
			}
			if (!userStore.showDemoPlayer) {
				try {
					await this.sendSongLog(songLogData)
				} catch (error) {
					console.log(error)
				}
			}
		},
		setCurrentMinutes(minutes: number) {
			this.currentMinutes = minutes
		},
		resetTime() {
			this.durationSeconds = 0
			this.sessionTimer = 0
			this.currentSeconds = 0
			this.currentMinutes = 0
		},
		setSongDuration(duration: number) {
			this.durationSeconds = duration
			this.sessionTimer = 0
			this.currentSeconds = 0
			this.currentMinutes = 0
		},
		updateCurrentTime(timeStamp: number) {

			// dispatch('second-screen/sendMessageToSecondScreen',{
			//   method: 'SET_PLAYER_TIME_STAMP',
			//   timeStamp: timeStamp
			// }, {root: true})
		},
		seekAudioPlayer(timeStamp: number, clear = true) {
			console.log('seek in store', timeStamp)
			const { $audioPlayer } = useNuxtApp()
			$audioPlayer.seek(timeStamp)
			useEventEmit('player:seekAudioPlayer', { timeStamp, clear })
		},
		decreaseVolume(amount: number) {
			const mediaStore = useMediaFileStore()
			const { $audioPlayer } = useNuxtApp()
			const oldVolume = mediaStore.settings.volume
			const newValue = clamp(mediaStore.settings.volume - amount, 0.0, 100.0)
			mediaStore.settings.volume = newValue

			useEventEmit('player:changeVolume', { oldVolume: oldVolume, volume: newValue })

			const newVolume = newValue / 100.0

			$audioPlayer.setVolume(newVolume)
		},
		increaseVolume(amount: number) {
			const mediaStore = useMediaFileStore()
			const { $audioPlayer } = useNuxtApp()
			const oldVolume = mediaStore.settings.volume
			const newValue = clamp(mediaStore.settings.volume + amount, 0.0, 100.0)
			mediaStore.settings.volume = newValue

			useEventEmit('player:changeVolume', { oldVolume: oldVolume, volume: newValue })

			const newVolume = newValue / 100.0

			$audioPlayer.setVolume(newVolume)
		},
		rewindAudioPlayer(amount: number) {
			const rewindAmount = clamp(this.currentSeconds - amount, 0, this.durationSeconds)
			useEventEmit('player:rewindAudioPlayer', { seconds: amount })

			this.seekAudioPlayer(rewindAmount)
		},
		skipAudioPlayer(amount: number) {
			const skipAmount = clamp(this.currentSeconds + amount, 0, this.durationSeconds)
			useEventEmit('player:skipAudioPlayer', { seconds: amount })

			this.seekAudioPlayer(skipAmount)
		},
		updateCurrentSeconds(timeStamp: number) {
			if (~~timeStamp !== ~~this.currentSeconds) {
				this.currentSeconds = ~~timeStamp
				this.incrementSessionTimer()
				const minutes = ~~(timeStamp / 60)
				if (minutes !== this.currentMinutes) {
					this.currentMinutes = minutes
				}

				const queueItem = useQueueStore().firstInQueue
				if (!this.playingOutro && this.currentSeconds >= queueItem?.variant?.outroStart) {
					this.playingOutro = true

					const { segmentEvent } = useSegment()
					segmentEvent('Player Outro Started', {
						fullscreen_mode: document.fullscreenElement !== null,
						song_id: queueItem.entry.resource_id,
						pitch: queueItem.pitch,
						popout_player: useSecondScreenStore().isSecondScreenActive,
						position: this.currentSeconds,
						session_id: queueItem.entryID,
						song_length: queueItem.variant.duration,
						vocals_on: queueItem.vocals
					})
				}
			}
		},
		incrementSessionTimer() {
			this.sessionTimer += 1
		},
		resetSessionTimer() {
			this.sessionTimer = 0
		},
		setSeeking(value: boolean) {
			const eventName = value ? 'Player Playback Seek Started' : 'Player Playback Seek Completed'
			const { segmentEvent } = useSegment()
			const { firstInQueue } = useQueueStore()
			segmentEvent(eventName, {
				song_id: firstInQueue?.entry.resource_id,
				popout_player: useSecondScreenStore().isSecondScreenActive,
				position: this.currentSeconds,
				session_id: firstInQueue?.entryID,
				song_length: firstInQueue?.variant.duration
			})
			this.seeking = value
		}
	}
})
