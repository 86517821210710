<template lang="pug">
SingaSlider.mini-player(
	:value="audioProgress"
	:tooltip-always="true"
	:max="durationSeconds - 3"
	:step="0.1"
	@change="change"
	@drag-end="dragEnd"
	@drag-start="dragStart"
	@dragging="dragging"
	:custom-formatter="(val: number) => formatedTime(val)"
	:disabled="!hasSongStarted"
	:hideThumbBeforeProgress="true"
	:letDragToZero="false"
)
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useAudioPlayerStore } from '~/pinia/player/audioPlayerStore'

const { $audioPlayer } = useNuxtApp()
const audioPlayerStore = useAudioPlayerStore()
const { setSeeking, seekAudioPlayer } = audioPlayerStore
const { currentSeconds, durationSeconds, hasSongStarted } = storeToRefs(audioPlayerStore)
const previousValue = ref(0)

const formatedTime = (value: number) => {
	const mins = ~~((value % 3600) / 60)
	const secs = ~~value % 60

	// Output like "1:01" or "4:03:59" or "123:03:59"
	let ret = ''
	ret += '' + mins + ':' + (secs < 10 ? '0' : '')
	ret += '' + secs
	return ret
}

const dragStart = () => {
	setSeeking(true)
	$audioPlayer.pause()
}

const dragEnd = () => {
	setSeeking(false)
	$audioPlayer.play()
}

const change = (value: number, shouldClear: boolean) => {
	if (!hasSongStarted.value) {
		return
	}
	seekAudioPlayer(value, shouldClear)
}

const dragging = (value: number) => {
	const shouldClear = value < previousValue.value && (Math.abs(value - previousValue.value) > 0.5)
	previousValue.value = value
	seekAudioPlayer(value, shouldClear)
}

const audioProgress = computed(() => currentSeconds.value)
</script>
