export default defineNuxtPlugin({
	name: 'preview',
	enforce: 'pre',
	parallel: true,
	async setup(nuxtApp) {
		try {
			const route = useRoute()
			const preview = await !!route.query?._storyblok || import.meta.dev
			return { provide: { preview } }
		} catch (error) {
			console.error('Preview plugin error:', error)
			return { provide: { preview: false } }
		}
	}
})
